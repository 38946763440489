import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import config from '../../config';
import DropDown, { DropDownElement } from '../helpers/DropDown';
import MailingInfoTable from './MailingInfoTable';

const columnDefs = config.mailingInfo.getIn(['table', 'columnDefs']);

/**
 * Mailing page component.
 *
 * @param props {Object}.
 * @param props.targetCompany {Immutable.Map} Map with company information.
 * @param props.onCompanyInfoChange {Function} Handle company info change event.
 * @param props.onToggleDBA {Function} Handle toggle dba input event.
 * @param props.onToggleCountry {Function} Handle toggle country input event.
 */
const MailingInfoComponent = ({
  mailingInfo,
  values,
  onChange,
  onGetNextPageData,
  onDoubleClickRow,
  onReload,
  onMarkCompleted,
  onSetNextActions,
  onUpdateCell,
  onCancelMailing,
  ...rest
}) => {
  const iconRender =
    mailingInfo.getIn(['header', 'isemail']) === true ? (
      <i className="fa fa-folder-o" />
    ) : (
      <i className="fa fa-envelope-o" />
    );

  const footerDropDown = values.get('footerDropDown').map((elem, i) => {
    const attributes = elem.get('attributes').toJS();

    return (
      <DropDownElement {...attributes} key={i} onClick={rest[elem.get('onClickField')]}>
        {elem.get('name')}
      </DropDownElement>
    );
  });

  const isSubmitted = mailingInfo.getIn(['header', 'date'], true);
  const isDisabledNextAction = !mailingInfo.get('setNextAction', false);

  return (
    <div className="mailHistory">
      <div className="top_side">
        <div className="container text-center">
          <strong>Mailing</strong>
        </div>
        <div className="container pmHeader">
          <div className="row">
            <div className="col-md-2 col-xs-12 colHeader">
              <span>PM:</span>
              <strong>{mailingInfo.getIn(['header', 'pmFLName'])}</strong>
              <br />
              <span>Date Mailed:</span>
              <strong>{mailingInfo.getIn(['header', 'date'])}</strong>
            </div>
            <div className="col-md-2 col-xs-12 colHeader">
              <span>Module:</span>
              <strong>
                {mailingInfo.getIn(['header', 'recordOwnerUserName'])}
                <br />
                {mailingInfo.getIn(['header', 'recordSubOwnerUserName'])}
              </strong>
            </div>
            <div className="col-md-2 col-xs-12 colHeader">
              <span>Buyer:</span>
              <strong>
                {mailingInfo.getIn(['header', 'buyerLegalName'])}
                <br />
                {mailingInfo.getIn(['header', 'projectCategory'])} (
                {mailingInfo.getIn(['header', 'projectHarvcoLeadUserName'])})
              </strong>
            </div>
            <div className="col-md-2 col-xs-12 colHeader">
              <strong>
                {iconRender} {mailingInfo.getIn(['header', 'activity'])}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div className="main">
        <div className="container" role="main">
          <div className="position-relative full-height">
            <MailingInfoTable
              columnDefs={columnDefs}
              data={mailingInfo}
              onChange={onChange}
              onDoubleClickRow={onDoubleClickRow}
              onGetNextPageData={onGetNextPageData}
              onUpdateCell={onUpdateCell}
            />
          </div>
        </div>
      </div>
      <footer className="navbar-fixed-bottom">
        <div className="container">
          <div className="row">
            <div className="container-fluid text-right">
              <div className="btns">
                <DropDown
                  align="right"
                  btnContent="Download"
                  className="btn-default btn-xs"
                  id="dropdownMenu7"
                  wrapperClassName="ib"
                >
                  {footerDropDown}
                </DropDown>
                <button className="btn btn-default btn-xs" disabled={isSubmitted} onClick={onMarkCompleted}>
                  <i className="icon-user icon-white" />
                  Mark Completed
                </button>
                <button className="btn btn-default btn-xs" disabled={isSubmitted} onClick={onCancelMailing}>
                  <i className="icon-user icon-white" />
                  Cancel Mailing
                </button>
                <button className="btn btn-default btn-xs" disabled={isDisabledNextAction} onClick={onSetNextActions}>
                  <i className="icon-user icon-white" />
                  Set Next Actions
                </button>
                <button className="btn btn-default btn-xs" onClick={onReload}>
                  <i className="icon-user icon-white" />
                  Reload
                </button>
                <button className="btn btn-default btn-xs" type="submit">
                  <i className="icon-user icon-white" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

MailingInfoComponent.propTypes = {
  mailingInfo: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onMarkCompleted: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  onSetNextActions: PropTypes.func.isRequired,
  onUpdateCell: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Map).isRequired,
};

export const MailingInfo = memo(MailingInfoComponent);
