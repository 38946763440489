import React from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';
import FormGroup from '../helpers/FormGroup';
import { InputProps } from '../helpers/AutoComplete';

const ActiveTargetPopup = props => {
  const { isFinancialBuyer, buyerName, suggestDirector, suggestAnalyst, processing, message, onClose, onCreate } = props;

  if (isFinancialBuyer) {
    const header = (
      <span className="text-danger">
        <i className="fa fa-exclamation-triangle" /> Can't activate Target role for this company
      </span>
    );
    const footer = (
      <Button className="btn-default btn-xs" onClick={onClose} autoFocus>
        Close
      </Button>
    );

    return (
      <Popup footer={footer} header={header} id="isFinancialBuyer">
        <p>Financial buyers (PEGs/LBOs/VCs) can't be approached as target companies.</p>
      </Popup>
    );
  }

  const header = <span>Activate Target role for this company</span>;
  const footer = [
    <Button key="create" className="btn-default btn-xs" disabled={processing} onClick={onCreate}>
      Create
    </Button>,
    <Button key="close" className="btn-default btn-xs" disabled={processing} onClick={onClose} autoFocus>
      Close
    </Button>,
  ];
  const alert = message ? (
    <div className="row">
      <div className="col-xs-8">
        <p className="text-danger text-bold mt10">{message}</p>
      </div>
    </div>
  ) : null;
  const spinner = processing ? <i className="fa fa-spinner fa-pulse fa-3x fa-fw" /> : null;

  return (
    <Popup footer={footer} header={header} id="isAddingTargetPopup">
      <p>Active the "Target" role for "{buyerName}", marking it as an approachable target company?</p>
      <div className="row">
        <FormGroup className="col-xs-8">
          <InputProps className="form-control input-sm" label="Module" name="suggestDirector" placeholder="Module">
            {suggestDirector}
          </InputProps>
        </FormGroup>
        <FormGroup className="col-xs-8">
          <InputProps
            className="form-control input-sm"
            label="Analyst/Associate"
            name="suggestAnalyst"
            placeholder="Analyst/Associate"
          >
            {suggestAnalyst}
          </InputProps>
        </FormGroup>
      </div>
      {alert}
      {spinner}
    </Popup>
  );
};

export default ActiveTargetPopup;
