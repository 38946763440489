import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { List } from 'immutable';

import { WysiwygView } from '../../helpers/WysiwygView';

class ContactExecutiveEmailLogLine extends PureComponent {
  render() {
    return ContactExecutiveEmailLogLineRender(this.props, this.context.onEditEvent);
  }
}

/**
 * Component is used to display 1 contact's email.
 *
 * @param props {Object} Any extra props that allowed on <p> tag.
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.email {Immutable.Map} Map, that should describe email. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is email's eventId.
 * @returns {XML}
 * @private
 * @class
 */
const ContactExecutiveEmailLogLineRender = ({ onEditEvent, onMouseEnter, onMouseLeave, email, ...rest }) => {
  const className = classNames({ 'text-bold': email.get('isMarked') });
  const content = email.get('content', '');

  // todo add date display
  return (
    <div
      {...rest}
      className={className}
      onDoubleClick={event => onEditEvent(event, email.set('id', email.get('eventId')))}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <WysiwygView html={content} onDoubleClick={event => onEditEvent(event, email.set('id', email.get('eventId')))} />
    </div>
  );
};

class ContactExecutiveEmailPanel extends PureComponent {
  render() {
    return ContactExecutiveEmailPanelRender(this.props);
  }
}

const ContactExecutiveEmailPanelRender = props => {
  const { emails, onEditEvent, onMouseEnter, onMouseLeave } = props;
  const content =
    emails.size === 0 ? (
      <p className="text-center"> No rows to display </p>
    ) : (
      emails.map((email, i) => (
        <ContactExecutiveEmailLogLine
          key={i}
          email={email}
          onEditEvent={onEditEvent}
          onMouseEnter={event => onMouseEnter(event, email.get('eventId'))}
          onMouseLeave={event => onMouseLeave(event, email.get('eventId'))}
        />
      ))
    );

  return (
    <div className="panel panel-default">
      <div className="panel-heading" data-parent="#accordion" data-target="#acc-slide-6" data-toggle="collapse">
        <h4 className="panel-title"> Email Log / Buyer Notes </h4>
      </div>
      <div className="panel-collapse collapse" id="acc-slide-6">
        <div className="panel-body">{content}</div>
      </div>
    </div>
  );
};

ContactExecutiveEmailPanelRender.propTypes = {
  emails: PropTypes.instanceOf(List).isRequired,
  onEditEvent: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default ContactExecutiveEmailPanel;
