import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map } from 'immutable';

import { WysiwygView } from '../helpers/WysiwygView';
import { CompanyLabel } from './CompanyLabel';

/**
 * Component is used to display 1 deal note.
 *
 * @param props {Object} Any extra props that allowed on <p> tag.
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.note {Immutable.Map} Map, that should describe note. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is deal note's eventId.
 * @returns {XML}`
 * @private
 * @class
 */
const CompanyDealNoteComponent = ({ onEditEvent, onMouseEnter, onMouseLeave, note, className = '', ...rest }) => {
  const isMarked = note.get('isMarked', false);
  const eventId = note.get('eventId');
  const isBoldContent = isMarked ? 'font-weight: bold;' : 'font-weight: normal;';

  const topContent = (
    <div className="contact__deal-note" onDoubleClick={event => onEditEvent(event, note.set('id', eventId))}>
      <strong>{note.get('date')}</strong> <span className="text-muted">{note.get('userName', '')}</span>{' '}
      <CompanyLabel note={note} />
    </div>
  );
  const content = note.get('content', '');
  const wrapBoldContent = `<span style='${isBoldContent}'>${content}</span>`;

  return (
    <div
      {...rest}
      className={className}
      onMouseEnter={event => onMouseEnter(event, eventId)}
      onMouseLeave={event => onMouseLeave(event, eventId)}
    >
      {topContent}
      <WysiwygView html={wrapBoldContent} onDoubleClick={event => onEditEvent(event, note.set('id', eventId))} />
    </div>
  );
};

CompanyDealNoteComponent.propTypes = {
  note: PropTypes.instanceOf(Map).isRequired,
  onEditEvent: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

/** Company deal note component. */
export const CompanyDealNote = memo(CompanyDealNoteComponent);
