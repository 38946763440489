import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { SecretCodeForm } from './SecretCodeForm';
import { AuthForm } from './AuthForm';

const { string, bool, func } = PropTypes;

const ERROR_401 = '401 Unauthorized';

/**
 * Stateless component for login form.
 *
 * @param props {Object}.
 * @param props.onSubmit {Function} Handle submit form event.
 * @param props.onGoogle2FAAuth {Function} Handle onGoogle2FAAuth form event.
 * @param props.error {String} Validate & API error.
 * @param props.caption {React.ReactElement} Additional caption.
 * @param props.isFetching {boolean} Is in fetching API state or not.
 * @param props.google2FaStatus {Map} Google 2FA status.
 * @param props.google2FaSecret {Map} Google 2FA secret.
 * @returns {JSX.Element} Form component.
 * @class
 */
const LoginFormComponent = ({
  caption,
  error,
  isFetching,
  google2FaStatus,
  google2FaSecret,
  onSubmit,
  onGoogle2FAAuth,
}) => {
  let errorContent = error ? <p className="error text-danger">{error}</p> : null;

  if (error === ERROR_401) {
    errorContent = (
      <p className="error text-danger">Your authorization token has expired. Enter username and password again.</p>
    );
  }

  const loadingContent = isFetching ? (
    <div>
      <div className="loading" />
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    </div>
  ) : null;

  return (
    <div className="card card-container">
      <div className="profile-logo" />
      <p className="profile-name-card" id="profile-name" />
      {isSecretCodeForm(google2FaStatus, google2FaSecret, error) ? (
        <SecretCodeForm
          errorContent={errorContent}
          google2FaSecret={google2FaSecret}
          loadingContent={loadingContent}
          onSubmit={onGoogle2FAAuth}
        />
      ) : (
        <AuthForm
          caption={caption}
          errorContent={errorContent}
          loadingContent={loadingContent}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

const isSecretCodeForm = (google2FaStatus, google2FaSecret, error) => {
  if (error === ERROR_401) {
    return false;
  }

  if (google2FaStatus && !google2FaStatus.get('isGoogle2FAPassed')) {
    return true;
  }

  if (google2FaStatus && !google2FaStatus.get('hasGoogle2FASecret')) {
    return true;
  }

  if (google2FaSecret) {
    return true;
  }

  return false;
};

LoginFormComponent.propTypes = {
  error: string.isRequired,
  google2FaSecret: PropTypes.instanceOf(Map),
  google2FaStatus: PropTypes.instanceOf(Map),
  isFetching: bool.isRequired,
  onGoogle2FAAuth: func.isRequired,
  onSubmit: func.isRequired,
};

export const LoginForm = memo(LoginFormComponent);
