import React, { memo, PureComponent } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';

const TableCellWrapperComponent = ({ data, id, onFocus, onBlur }) =>
  <td>
    <ContextMenuTrigger id={id.toString()}>
      <div onBlur={() => onBlur(id - 1)} onClick={() => onFocus(id - 1)}>
        {data}
      </div>
    </ContextMenuTrigger>
  </td>

/** Table cell wrapper component. */
export const TableCellWrapper = memo(TableCellWrapperComponent);
