import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { PushNextActionDatesPopup } from '../../components/Popup/PushNextActionDatesPopup';
import { showError } from '../../utils/MessagePopup';

class PushNextActionDatesPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange(event) {
    if (event.target) {
      const { name, type, checked, value } = event.target;

      if (type.toUpperCase() === 'CHECKBOX' && checked) {
        this.context.updatePopupFormData({
          forward: name === 'forward',
          backward: name === 'backward',
          following: name === 'following',
        });
      } else {
        this.context.updatePopupFormData({
          [name]: type.toUpperCase() === 'CHECKBOX' ? checked : value,
        });
      }
    }
  }

  handleDateChange(date) {
    this.context.updatePopupFormData({
      followingDate: date,
    });
  }

  handleSuccessResponse(skipNA) {
    if (skipNA) {
      const alstr = `${skipNA} companies were skipped because they had no next action`;

      showError(this.context.openPopup, List([alstr]));
    }
  }

  handleUpdate() {
    const { popup } = this.props;
    const onUpdate = popup.getIn(['props', 'onUpdate'], () => {});
    const refreshGrid = popup.getIn(['props', 'refreshGrid'], () => {});

    onUpdate(popup.get('formData'), {
      beforeStart: this.context.startPopupLoading,
      afterSuccess: ({ response }) => {
        this.context.endPopupLoading();
        this.handleSuccessResponse(response.numberOfSkipped);
        refreshGrid();
      },
      skipGlobalErrorHandler: true,
      afterError: ({ resBody }) => {
        this.context.endPopupLoading(resBody);
      },
    });
  }

  handleClose() {
    this.context.closePopup();
  }

  render() {
    const { popup } = this.props;

    return (
      <div>
        <PushNextActionDatesPopup
          formData={popup.get('formData')}
          isPopupFetching={popup.get('isPopupFetching')}
          isPopupFetchingError={popup.get('isPopupFetchingError')}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onDateChange={this.handleDateChange}
          onUpdate={this.handleUpdate}
        />
      </div>
    );
  }
}

PushNextActionDatesPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  updatePopupFormData: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
};

export { PushNextActionDatesPopupContainer };
export default connect((state, props) => ({
  ...props,
}))(PushNextActionDatesPopupContainer);
