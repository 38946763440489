import React, { memo } from 'react';

const AuthFormComponent = ({ caption, errorContent, loadingContent, onSubmit }) => (
  <form className="form-signin" onSubmit={onSubmit}>
    <span className="reauth-email" id="reauth-email" />
    <input className="form-control" id="inputUsername" name="username" placeholder="Username" type="text" required />
    <input
      className="form-control"
      id="inputPassword"
      name="password"
      placeholder="Password"
      type="password"
      required
    />
    {errorContent}
    <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit">
      Sign in
    </button>
    {loadingContent}
    {caption}
  </form>
);

export const AuthForm = memo(AuthFormComponent);
