import React, { memo } from 'react';

const ProductsComponent = ({ data }) => (
  <>
    <div className="mb5">{data.product1}</div>
    <div className="mb5">{data.product2}</div>
    <div>{data.product3}</div>
  </>
);

export const Products = memo(ProductsComponent);
