import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import { appendCounter, deleteCounter, refresh, startFind, endFind } from '../actions/autoComplete';
import { getOriginal, unwrap } from '../utils/ChangeSpy';
import _AutoComplete from '../components/helpers/AutoComplete';
import PrettyError from '../components/decorators/PrettyError';
import DisplayError from '../components/decorators/DisplayError';
import uniqueId from '../utils/uniqueId';

const AutoComplete = PrettyError(DisplayError(_AutoComplete));

const AutoCompleteContainer = ({
  appendCounter,
  deleteCounter,
  refresh,
  startFind,
  endFind,
  find,
  valueName,
  idName = '',
  suggestsName = '',
  change,
  inputProps,
  suggestions = List(),
  value = '',
  idValue,
  render,
  focusInputOnSuggestionClick,
  suggestIfEmpty,
  loading,
  loadable = true,
  highlightFirst,
  addNew,
  appendToContainer = false,
  minSuggestLength,
  rootPath,
  autoComplete,
  onSelectCallback,
  rollbackOnBlur = true,
}) => {
  const id = useMemo(() => uniqueId(), []);

  useEffect(() => {
    appendCounter(id);

    return () => {
      deleteCounter(id);
    };
  }, [id, appendCounter, deleteCounter]);

  const getCounter = () => autoComplete.get(id);

  const handleFetchData = ({ value }) => {
    refresh(id);
    startFind(id);
    find({
      filter: value,
      page: 1,
      afterSuccess: ({ response }) => endFind(id, response),
    });
  };

  const handleFetchNextData = ({ value }) => {
    const counter = getCounter();

    if (counter.get('hasOther')) {
      startFind(id);
      find({
        filter: value,
        page: counter.get('page'),
        afterSuccess: ({ response }) => endFind(id, response),
      });
    }
  };

  const handleUpdateSuggestions = (param) => {
    if ('text' in param) {
      change({ name: valueName, value: param.text });
    }

    if ('suggestions' in param) {
      change({ name: suggestsName, value: param.suggestions });
    }
  };

  const handleSelect = (event, { suggestion }) => {
    if (suggestion) {
      change({ name: valueName, value: suggestion.text });
      change({ name: idName, value: suggestion.id });
    }

    if (onSelectCallback) {
      onSelectCallback(suggestion);
    }
  };

  const handleBlur = (...rest) => {
    refresh(id);

    if (rollbackOnBlur) {
      const originalValue = getOriginal(value);
      const originalIdValue = getOriginal(unwrap(idValue));
      if (idValue !== null && !idValue) {
        change({ name: valueName, originalValue });
        change({ name: idName, value: originalIdValue });
      }
    };
  }

  const getLoading = () => {
    if (!loadable) {
      return false;
    }

    if (loading) {
      return loading;
    }

    const counter = getCounter();
    return counter ? counter.get('loading') : false;
  };

  const renderSuggestion = (suggestion) => {
    const { text, ...rest } = suggestion;
    return render ? render(suggestion) : <span {...rest}>{text}</span>;
  };

  const name = inputProps?.name || valueName;

  const getSuggestionValue = (suggestion) => {
    return suggestion.value ?? '';
  }

  return (
    <AutoComplete
      addNew={addNew}
      appendToContainer={appendToContainer}
      checkOn="text"
      focusInputOnSuggestionClick={focusInputOnSuggestionClick}
      formGroupClass=" "
      getNextSuggestion={handleFetchNextData}
      getSuggestion={handleFetchData}
      getSuggestionValue={getSuggestionValue}
      highlightFirst={highlightFirst}
      inputProps={{ ...inputProps, onBlur: handleBlur }}
      loading={getLoading()}
      minSuggestLength={minSuggestLength}
      name={name}
      onSuggestionSelected={handleSelect}
      onUpdateSuggestions={handleUpdateSuggestions}
      renderSuggestion={renderSuggestion}
      suggestIfEmpty={suggestIfEmpty}
      suggestions={suggestions}
      text={value}
    />
  );
};

AutoCompleteContainer.propTypes = {
  appendCounter: PropTypes.func.isRequired,
  deleteCounter: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  startFind: PropTypes.func.isRequired,
  endFind: PropTypes.func.isRequired,
  find: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  focusInputOnSuggestionClick: PropTypes.bool,
  idName: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  render: PropTypes.func,
  rootPath: PropTypes.arrayOf(PropTypes.string),
  suggestIfEmpty: PropTypes.bool,
  suggestions: PropTypes.instanceOf(List),
  suggestsName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valueName: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const getField = (field, name) => {
    if (!props.rootPath) return props[field];
    return props.rootPath.reduce((root, key) => root[key] || {}, state).getIn(name.split('.'));
  };

  return {
    autoComplete: state.autoComplete,
    inputProps: props.inputProps,
    suggestions: getField('suggestions', props.suggestsName),
    value: getField('value', props.valueName) || '',
    idValue: getField('idValue', props.idName),
  };
};

export default memo(connect(
  mapStateToProps,
  {
    appendCounter,
    deleteCounter,
    startFind,
    endFind,
    refresh,
  },
  mergeProps,
  connectOptions,
)(AutoCompleteContainer));
