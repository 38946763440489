import React, { useEffect, useRef, useCallback, memo } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import getZIndex from '../../utils/getZIndex';
import uniqueId from '../../utils/uniqueId';

const ErrorContent = ({ children, onClose }) => (
  <div className="alert alert-danger alert-dismissible input-custom-alert" role="alert">
    <button aria-label="Close" className="close" onClick={onClose} type="button">
      <span aria-hidden="true">&times;</span>
    </button>
    {children}
  </div>
);

const PhoneInputComponent = ({
  autoFocus = false,
  error,
  onErrorClose,
  onUndo,
  changed = false,
  value,
  onChange,
  className,
  name,
  onBlur,
  maxLength = 30,
  disabled,
  placeholder,
}) => {
  const id = useRef(uniqueId());
  const componentRef = useRef(null);
  const portalRef = useRef(null);

  const handleFocus = useCallback(() => {
    if (onErrorClose) {
      onErrorClose();
    }
  }, [onErrorClose]);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        try {
          const element = document.getElementById(id.current);
          if (element) {
            element.focus();
          }
        } catch (e) {
          console.warn(e);
        }
      }, 0);
    }
  }, [autoFocus]);

  useEffect(() => {
    return () => {
      if (portalRef.current?.node) {
        const { node } = portalRef.current;
        if (node.parentNode) {
          node.parentNode.removeChild(node);
        }
      }
    };
  }, []);

  const renderError = () => {
    if (!error) return null;

    const targetElement = componentRef.current;
    if (!targetElement) return null;

    const bodyRect = document.documentElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();

    if (targetRect.bottom === 0 && targetRect.top === 0) return null;

    const zIndex = getZIndex(targetElement) + 1;
    const top = targetRect.bottom - bodyRect.top;
    const left = targetRect.left - bodyRect.left;
    const { width } = targetRect;

    const style = {
      position: 'absolute',
      minWidth: '150px',
      maxHeight: '200px',
      overflowY: 'auto',
      top,
      left,
      width,
      zIndex,
    };

    const container = (
      <div ref={portalRef} style={style}>
        <ErrorContent onClose={onErrorClose}>{error}</ErrorContent>
      </div>
    );

    return createPortal(container, document.body);
  };

  const divClass = classNames('clearfix', className);
  const inputClass = classNames('form-control input-md', { changed });

  const label = onUndo ? (
    <label htmlFor={id.current}>
      Phone -
      <a href="#" onClick={onUndo} title="Undo">
        undo
      </a>
    </label>
  ) : (
    <label htmlFor={id.current}>Phone</label>
  );

  return (
    <div ref={componentRef} className={divClass}>
      {label}
      <span className="control-wrap">
        <input
          className={inputClass}
          disabled={disabled}
          id={id.current}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          value={value}
        />
      </span>
      {renderError()}
    </div>
  );
};

export const PhoneInput = memo(PhoneInputComponent);
