import React, { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import classNames from 'classnames';

import { execIfFunction } from '../../utils/isFunction';
import terminateBubblingEvent from '../../utils/terminateBubblingEvent';

const ComboSelectBoxComponent = ({
  className,
  name,
  nameKey = 'name',
  valueKey = 'value',
  options,
  placeholder,
  onChange,
  value,
  disabled,
  width: initialWidth,
}) => {
  const [stateOptions, setStateOptions] = useState(options);
  const [opened, setOpened] = useState(false);
  const [inputText, setInputText] = useState('');
  const [cursor, setCursor] = useState(-1);
  const [width, setWidth] = useState(initialWidth);

  const containerRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = () => setOpened(false);
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, []);

  useEffect(() => {
    setStateOptions(options);
  }, [options]);

  useEffect(() => {
    if (cursor >= 0 && menuRef.current) {
      const scroll = getCursorHeight(menuRef.current, cursor);
      const menuScrollHeight = menuRef.current.scrollHeight;
      const menuHeight = menuRef.current.clientHeight;

      if (scroll < menuScrollHeight - menuHeight) {
        menuRef.current.scrollTop = scroll;
      }
    }

    if (containerRef.current && !width) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, [cursor, width]);

  useEffect(() => {
    if (Map.isMap(value)) {
      const selectedValue =
        options.filter(option => option.get('value') === value.get('value')).getIn([0, 'name'], inputText) || inputText;
      setInputText(selectedValue);
    }
  }, [value, options, inputText]);

  const getCursorHeight = (node, cursorIndex) => {
    let sum = 0;
    const len = Math.min(node.childNodes.length, cursorIndex);

    for (let i = 0; i < len; i++) {
      sum += node.childNodes[i].offsetHeight || 0;
    }

    return sum;
  };

  const handleTextChange = (event) => {
    terminateBubblingEvent(event);

    const { value } = event.target;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    setStateOptions(
      inputLength > 0
        ? options.filter(
          (op) =>
            op.get(nameKey).toLowerCase().slice(0, inputLength) === inputValue
        )
        : options
    );
    setOpened(true);
    setInputText(value);
  };

  const handleMove = (event) => {
    const end = stateOptions.size - 1;
    const code = event.keyCode || event.which;

    if (code === 38) {
      setCursor((prevCursor) => (prevCursor > 0 ? prevCursor - 1 : 0));
    } else if (code === 40) {
      setCursor((prevCursor) => (prevCursor < end ? prevCursor + 1 : end));
    }
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (cursor > -1 && cursor < stateOptions.size) {
        const selectedOption = stateOptions.get(cursor);
        handleChange({
          target: {
            name: selectedOption.get(nameKey),
            value: selectedOption.get(valueKey),
          },
        });
      }
    }
  };

  const handleChange = (event) => {
    const { name: selectedName, value: selectedValue } = event.target;

    setStateOptions(
      options.map((option) =>
        option.set('selected', option.get(valueKey) === selectedValue)
      )
    );
    setOpened(false);
    setInputText(selectedName);
    execIfFunction(onChange, {
      target: {
        name,
        value: selectedValue,
        type: 'comboselectbox',
        checked: null,
      },
    });
  };

  const handleClick = (event) => {
    terminateBubblingEvent(event);
    setOpened((prevOpened) => !prevOpened);
  };

  const dropDownClassName = classNames(className, 'dropdown combo-select', {
    open: opened,
  });
  const hasData = stateOptions.size > 0;

  const styles = width ? { style: { width } } : {};

  const mapOption = (option, index) => (
    <MenuItem
      key={index}
      hover={cursor === index}
      name={option.get(nameKey)}
      onClick={handleChange}
      selected={option.get('selected') === true}
      value={option.get(valueKey)}
    />
  );

  return (
    <div ref={containerRef} className={dropDownClassName}>
      <div className="input-group" onClick={(event) => event.stopPropagation()}>
        <input
          autoComplete="off"
          className="form-control"
          disabled={disabled}
          name="comboInput"
          onChange={handleTextChange}
          onKeyDown={handleMove}
          onKeyPress={handleEnter}
          placeholder={placeholder}
          type="text"
          value={inputText}
        />
        <span className="input-group-btn">
          <button
            className={classNames('btn btn-default', { disabled })}
            disabled={disabled}
            onClick={handleClick}
            type="button"
          >
            <i aria-hidden="true" className="fa fa-caret-down" />
          </button>
        </span>
      </div>
      <Menu
        {...styles}
        className="auto-suggestion dropdown-menu"
        hasData={hasData}
        setMenuElement={(menu) => (menuRef.current = menu)}
      >
        {stateOptions.map(mapOption)}
      </Menu>
    </div>
  );
};

ComboSelectBoxComponent.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  name: PropTypes.string,
  nameKey: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(List).isRequired,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  width: PropTypes.number,
};

export const ComboSelectBox = memo(ComboSelectBoxComponent);

const MenuItemComponent = ({ name, value, selected = false, onClick, hover = false }) => {
  const className = classNames({ active: selected });
  const hoverClass = classNames({ cursor: hover });

  return (
    <li className={className}>
      <a
        className={hoverClass}
        onClick={() => onClick({ target: { name, value } })}
        style={{
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        title={name}
      >
        <span style={{ textOverflow: 'ellipsis' }}>{name}</span>
      </a>
    </li>
  );
};

MenuItemComponent.propTypes = {
  hover: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

export const MenuItem = memo(MenuItemComponent);

const MenuComponent = ({ children, hasData, setMenuElement, ...rest }) => {
  if (hasData) {
    return (
      <ul ref={setMenuElement} style={{ overflowX: 'hidden' }} {...rest}>
        {children}
      </ul>
    );
  }

  return (
    <ul ref={setMenuElement} style={{ overflowX: 'hidden' }} {...rest}>
      <li>No data</li>
    </ul>
  );
};

MenuComponent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hasData: PropTypes.bool.isRequired,
  setMenuElement: PropTypes.func.isRequired,
};

export const Menu = memo(MenuComponent);
