import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import * as mainApprovalActions from '../actions/approval/main';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import { OnlineApprovalList } from '../components/OnlineApprovalLists';
import { Spinner } from '../components/helpers/Spinner';
import { showInformation } from '../utils/MessagePopup';

/**
 * Generate Online Approval Lists container component.
 *
 * @class
 */
class OnlineApprovalListsContainer extends PureComponent {
  componentDidMount() {
    this.loadApprovalsData();
  }

  loadApprovalsData = () => {
    const {
      history,
      loadPreviewOnlineApprovals,
      match: { params },
    } = this.props;

    loadPreviewOnlineApprovals(params.projectId, params.approvalId, history.location.search, 'online');
  };

  handleSubmit = (data, approvingUser) => {
    const { projectId, approvalId, body } = this.prepareSaveOnlineApprovalsParams(data, approvingUser);

    const afterSuccess = () => {
      showInformation(this.context.openPopup, 'Approvals were submitted and received successfully');
      this.loadApprovalsData();
    };

    this.props.saveOnlineApprovals({
      projectId,
      approvalId,
      body,
      afterSuccess,
    });
  };

  handleSaveProgress = (data, approvingUser) => {
    const { projectId, approvalId, body } = this.prepareSaveOnlineApprovalsParams(data, approvingUser);

    const afterSuccess = () => {
      showInformation(this.context.openPopup, 'The progress has been saved');
    };

    this.props.saveProgressOnlineApprovals({
      projectId,
      approvalId,
      body,
      afterSuccess,
    });
  };

  /**
   * Prepare params for save online approvals and save progress online approvals requests.
   *
   * @param {Array} data Approvals list.
   * @param {object} approvingUser Approving user first name and last name.
   */
  prepareSaveOnlineApprovalsParams(data, approvingUser) {
    const {
      match: { params },
    } = this.props;
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    const body = {
      token,
      list: data,
      completedByFirstName: approvingUser.firstName,
      completedByLastName: approvingUser.lastName,
    };

    return {
      projectId: params.projectId,
      approvalId: params.approvalId,
      body,
    };
  }

  handleUpdate = data => {
    this.props.updateOnlineApprovals(data);
  };

  render() {
    const { onlineApprovalLoading, onlineApproval, saving } = this.props;

    return (
      <div className="full-height">
        <Helmet title="Generate Online Approval Lists" />

        {onlineApprovalLoading ? (
          <Spinner />
        ) : (
          <OnlineApprovalList
            onlineApproval={onlineApproval}
            onSaveProgress={this.handleSaveProgress}
            onSubmit={this.handleSubmit}
            onUpdate={this.handleUpdate}
            saving={saving}
          />
        )}
      </div>
    );
  }
}

OnlineApprovalListsContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ...state.approval,
  onlineApprovalLoading: state.approval.common.get('onlineApprovalLoading'),
  saving: state.approval.common.get('onlineApprovalSaving'),
  onlineApproval: state.approval.common.get('onlineApproval'),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(mainApprovalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(OnlineApprovalListsContainer);
