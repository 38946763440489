import React, { memo } from 'react';

import { LinkOut } from '../helpers/LinkOut';

const CompanyCellComponent = ({ data }) => {
  const city = data.companyCity ? `${data.companyCity},` : null;

  return (
    <div>
      <div>
        <b>{data.companyLegalName}</b>
      </div>
      <div className="mt10">
        {data.companyCountryId && <div>{data.companyCountryName}</div>}
        <div>
          {city} {data.companyState}
        </div>
        <div className="multiline-text">
          {data.companyContactFirstName} {data.companyContactLastName}
        </div>
        <div className="multiline-text">{data.companyContactTitle}</div>
        <LinkOut to={data.companyWeb}>{data.companyWeb}</LinkOut>
      </div>
    </div>
  );
};

export const CompanyCell = memo(CompanyCellComponent);
