import PropTypes from 'prop-types';

import React, { memo } from 'react';
import { Map } from 'immutable';
import config from '../../../config';
import { CompanyExecuteTable } from './CompanyExecuteTable';

const columnDefs = config.tables.getIn(['companyExecutive', 'columnDefs']);

const CompanyExecutiveComponent = ({ executive, onDoubleClickRow }) =>
  <CompanyExecuteTable columnDefs={columnDefs} data={executive} onDoubleClickRow={onDoubleClickRow} />;

CompanyExecutiveComponent.propTypes = {
  executive: PropTypes.instanceOf(Map).isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
};

/** Company executive component. */
export const CompanyExecutive = memo(CompanyExecutiveComponent);
