import React, { memo } from 'react';
import classNames from 'classnames';
import { fromJS } from 'immutable';

import FormGroup from '../../../helpers/FormGroup';
import Select from '../../../helpers/Select';

const OPTIONS = fromJS([
  { value: '', name: '' },
  { value: 'A', name: 'A' },
  { value: 'B', name: 'B' },
  { value: 'C', name: 'C' },
]);

const CeoAbcComponent = ({ className, ...rest }) => {
  const cls = classNames('input-sm', className);

  return (
    <FormGroup>
      <Select {...rest} className={cls} label="ABC" options={OPTIONS} />
    </FormGroup>
  );
};

/** CeoAbc component. */
export const CeoAbc = memo(CeoAbcComponent);
