import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { buildSortByParam } from 'src/helpers/paramBuilder';
import { openInternalLink } from 'src/utils/url';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { TradeShow } from '../../components/Company/TradeShow';
import {
  addUser,
  loadTradeShow,
  loadTradeShowCompanies,
  loadTradeShowStatistics,
  removeEvent,
  removeUser,
  sendTradeShow,
  updateTextField,
  updateTradeShowIndustries,
  handleCompaniesTableSorting,
} from '../../actions/tradeShow';
import { isDeepChanged, toJS, unwrap } from '../../utils/ChangeSpy';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES } from '../../utils/industryTagsHelper';
import UserEditor from './TradeShowUserAutoComplete';
import { showError, showInformation } from '../../utils/MessagePopup';
import { createTradeShowErrorMessages } from '../../utils/createTradeShowErrorMessages';

const ALL_FILTER = 'All';
const EVENT_DELETE_INFO =
  'Are you sure you want to delete this Trade Show Event? This is irreversible, and all target companies to which this event was assigned will lose the associate with this date.';

const TradeShowContainer = (props, context) => {
  const [tradeShowErrors, setTradeShowErrors] = useState({
    industryCategoriesError: false,
    businessModelsError: false,
    usersError: false,
    nameError: false,
    abbrError: false,
    descriptionError: false,
  });
  const { tradeShowId } = props.match.params;

  const [yearFilter, setYearFilter] = useState(ALL_FILTER);

  useEffect(() => {
    if (tradeShowId) {
      props.loadTradeShow(tradeShowId);
    }
  }, []);

  useEffect(() => {
    if (tradeShowId) {
      props.loadTradeShowStatistics(tradeShowId, yearFilter);
    }
  }, [yearFilter]);

  useEffect(() => {
    if (tradeShowId) {
      const sortBy = buildSortByParam(props.tradeShow);

      props.loadTradeShowCompanies({ tradeShowId, year: yearFilter, sortBy: sortBy === '' ? null : sortBy });
    }
  }, [props.sortModel]);

  useEffect(() => {
    if (
      tradeShowErrors.abbrError ||
      tradeShowErrors.businessModelsError ||
      tradeShowErrors.descriptionError ||
      tradeShowErrors.nameError ||
      tradeShowErrors.usersError ||
      tradeShowErrors.industryCategoriesError
    ) {
      showError(context.openPopup, createTradeShowErrorMessages(tradeShowErrors));
    }
  }, [tradeShowErrors]);

  const dataValidation = () => {
    const tradeShow = toJS(props.tradeShow);

    setTradeShowErrors({
      industryCategoriesError: !tradeShow.industryCategories.length,
      businessModelsError: !tradeShow.businessModels.length,
      usersError: !tradeShow.users.length,
      nameError: !tradeShow.name.value,
      abbrError: !tradeShow.abbr.value,
      descriptionError: !tradeShow.description.value,
    });

    return !(
      !tradeShow.industryCategories.length ||
      !tradeShow.businessModels.length ||
      !tradeShow.users.length ||
      !tradeShow.name.value ||
      !tradeShow.abbr.value ||
      !tradeShow.description.value
    );
  };

  const onTextChange = (event, name) => {
    const { value } = event.target;

    props.updateTextField(name, value);
  };

  const handleEventRowInsert = () => {
    context.openPopup('AddTradeShowEventPopup', {});
  };

  const handleEventRemove = id => {
    context.openPopup('ConfirmPopup', {
      message: EVENT_DELETE_INFO,
      onOk: () => {
        props.removeEvent(id);
        context.closePopup();
      },
      onCancel: () => context.closePopup(),
      yes: 'Delete',
      no: 'Cancel',
    });
  };

  const handleIndustryClick = () => {
    const { tradeShow, updateTradeShowIndustries } = props;
    const tags = unwrap(tradeShow.get(INDUSTRY_CATEGORIES));
    const models = unwrap(tradeShow.get(BUSINESS_MODELS));

    context.openPopup('TagsManagementPopup', {
      addNew: false,
      clientView: true,
      selectedTags: tags.reduce((acc, t) => {
        acc[t.get('id')] = true;

        return acc;
      }, {}),
      selectedModels: models.reduce((acc, m) => {
        acc[m.get('id')] = true;

        return acc;
      }, {}),
      callback: (tagsSelected, modelsSelected) => {
        updateTradeShowIndustries(tagsSelected, modelsSelected);
      },
    });
  };

  const handleRowDelete = (event, { id }) => {
    props.removeUser(id);
  };

  const handleYearFilterChange = value => {
    setYearFilter(value);
  };

  const handleSubmit = () => {
    if (dataValidation()) {
      const afterSuccess = !tradeShowId
        ? action => {
          props.history.push(`/trade-show/${action.response.data.id}`);
          showInformation(context.openPopup, 'Trade Show was added successfully.');
        }
        : () => {
          showInformation(context.openPopup, 'Trade Show was saved successfully.');
        };

      props.sendTradeShow(tradeShowId, toJS(props.tradeShow), afterSuccess);
    }
  };

  if (props.tradeShow.get('addedAsNew')) {
    props.history.push(`/trade-show/${props.tradeShow.get('id')}`);
  }

  const tags = props.tradeShow.get(INDUSTRY_CATEGORIES);
  const models = props.tradeShow.get(BUSINESS_MODELS);

  const handleCompaniesSortingChange = data => {
    props.handleCompaniesTableSorting({
      filterName: data.filterName,
      filterData: data.filterData,
    });
  };

  const handleGetNextPageData = page => {
    const sortBy = buildSortByParam(props.tradeShow);

    props.loadTradeShowCompanies({ tradeShowId, year: yearFilter, sortBy, page });
  };

  const handleRowDoubleClick = ({ data: { targetId } }) => {
    openInternalLink(`/company/${targetId}`);
  };

  return (
    <TradeShow
      businessModels={models}
      data={props.tradeShow}
      disableSave={!isDeepChanged(props.tradeShow) || props.tradeShow.get('sending')}
      errors={tradeShowErrors}
      industryTags={tags}
      onAddEvent={handleEventRowInsert}
      onCompaniesSortingChange={handleCompaniesSortingChange}
      onEventRemove={handleEventRemove}
      onGetNextPageData={handleGetNextPageData}
      onIndustryClick={handleIndustryClick}
      onRowDelete={handleRowDelete}
      onRowDoubleClicked={handleRowDoubleClick}
      onSubmit={handleSubmit}
      onTextChange={onTextChange}
      onYearFilterChange={handleYearFilterChange}
      tradeShowId={tradeShowId}
      UserEditor={UserEditor}
      yearFilter={yearFilter}
    />
  );
};

TradeShowContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  hasPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

function mapStateToProps(state) {
  return {
    tradeShow: state.tradeShow,
    sortModel: state.tradeShow.getIn(['sortBy', 'sortModel']),
  };
}

export default connect(
  mapStateToProps,
  {
    updateTextField,
    loadTradeShow,
    loadTradeShowCompanies,
    loadTradeShowStatistics,
    updateTradeShowIndustries,
    sendTradeShow,
    addUser,
    removeEvent,
    removeUser,
    handleCompaniesTableSorting,
  },
  mergeProps,
  connectOptions,
)(TradeShowContainer);
