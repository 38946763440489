import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { List } from 'immutable';
import classNames from 'classnames';
import uniqueId from 'src/utils/uniqueId';

import LoadAnimation from '../decorators/LoadAnimation';

import { CompanyBuyer } from './CompanyBuyer';

/**
 * Company buyers' list component.
 *
 * @param props {Object}.
 * @param props.buyers {Immutable.List} Buyer definition.
 * @param props.onClick {Function} On buyer's click callback.
 * @param props.addBuyer {Function} On add Buyer click callback.
 * @returns {XML}
 * @class
 */
const CompanyBuyersComponent = LoadAnimation(({
  buyers,
  id = uniqueId(),
  onActivate,
  addBuyer,
  onDel,
  onClick,
  suggestApprovalList,
  onChangeApprove,
  suggestProject,
  statuses,
  onUpdateHighStatus,
  canEditData,
}) => {
  const buyersContent = buyers.map((buyer, i) => (
    <CompanyBuyer
      key={i}
      buyer={buyer}
      canEditData={canEditData}
      headerClassName={classNames({
        'text-bolder': i === 0,
        'text-regular': i !== 0,
      })}
      isFirst={i === 0}
      onActivate={event => onActivate(event, i)}
      onChangeApprove={(...rest) => onChangeApprove(...rest, i)}
      onClick={event => onClick(event, i)}
      onDel={onDel}
      onUpdateHighStatus={onUpdateHighStatus}
      parentId={id}
      prefix={`buyers.${i}`}
      statuses={statuses}
      suggestApprovalList={suggestApprovalList}
      suggestProject={suggestProject}
    />
  ));

  return (
    <div className="accordion-right-side mt0" id={id}>
      <p className="mb0"> Buyers </p>
      <form>{buyersContent}</form>

      <div className="clearfix" />
      <div aria-hidden="true" className="plus-buyer" onClick={addBuyer}>
        <i className="fa fa-plus-square fa-2x" />
        <span> + Add new buyer... </span>
      </div>
    </div>
  );
});

CompanyBuyersComponent.propTypes = {
  addBuyer: PropTypes.func.isRequired,
  buyers: PropTypes.instanceOf(List).isRequired,
  id: PropTypes.string,
  onActivate: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onDel: PropTypes.func.isRequired,
};

/** Company buyers component. */
export const CompanyBuyers = memo(CompanyBuyersComponent);
