import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Map, List } from 'immutable';
import { debounce } from 'underscore';

import prepareDetail from '../target/entitiesApiPrepape';
import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import {
  handleUpdateCompany,
  startEditDetailEntity,
  saveInfo,
  closeValidationError,
  saveCompanyDetailEntity,
  saveBIC,
  revertChanges,
  findComparables,
} from '../../../actions/company/buyer/criteria';
import {
  getBuyerProjectsAddon,
  getBuyerProjectsPlatform,
  getStrategicBuyerProjects,
} from '../../../actions/company/buyer/projects';
import { getChanged, isDeepChanged, toJS, getIn } from '../../../utils/ChangeSpy';
import { Criteria } from '../../../components/Company/CompanyBuyer/Criteria';
import { loadIndustries, updateIndustryTags, saveIndustries } from '../../../actions/project/industry';
import { loadTemplates } from '../../../actions/project/template';
import {
  loadProject,
  updateProjectInfo,
  saveProject,
  revertProjectChanges,
  updateProjectCommonInfo,
  loadProjectsComparables,
  saveProjectsComparables,
  clearComparables,
  changeComparables,
  loadProjectCriteria,
  saveProjectCriteria,
} from '../../../actions/project/main';
import { TYPE_FINANCIAL, TYPE_STRATEGIC } from '../../../config/constants/common';
import AutoCompleteContainer from '../../AutoComplete';
import { showError } from '../../../utils/MessagePopup';
import { BIC, BUSINESS_MODEL_ERROR, INDUSTRY_CATEGORY_ERROR } from '../../../utils/industryTagsHelper';

class CriteriaContainer extends PureComponent {
  state = {
    industryCategories: '',
    businessModels: '',
    type: null,
    comparableValue: ['', '', '', '', ''],
    comparablesLoadable: true,
    comparablesData: [{}, {}, {}, {}, {}],
    comparablesIds: ['', '', '', '', ''],
    canLoadComparables: false,
    projectId: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.type !== state.type) {
      const afterSuccess = ({ response }) => {
        if (
          (props.type === TYPE_FINANCIAL && response.data.length > 0) ||
          (props.type === TYPE_STRATEGIC && response.data.buyerAddonProjects.length > 0)
        ) {
          const { id } = props.type === TYPE_FINANCIAL ? response.data[0] : response.data.buyerAddonProjects[0];

          props.loadProjectsComparables(id);
          props.loadIndustries(id);
          props.loadTemplates(id);
          props.loadProject(id);
          props.loadProjectCriteria(props.match.params.companyId, id);
        }
      };

      if (props.type === TYPE_FINANCIAL) {
        props.getBuyerProjectsPlatform({
          companyId: props.match.params.companyId,
          afterSuccess,
        });
      }

      if (props.type === TYPE_STRATEGIC) {
        props.getStrategicBuyerProjects({
          companyId: props.match.params.companyId,
          afterSuccess,
        });
      }
    }

    return null;
  }

  componentWillUnmount() {
    this.context.deleteValidateCallbacks(this.validate);
    this.context.removeOnSaveCallback(this.handleSubmit);

    this.props.revertChanges();
    this.props.revertProjectChanges();
  }

  componentDidMount() {
    this.context.addValidateCallBacks(this.validate);
    this.context.addOnSaveCallback(this.handleSubmit);
  }

  componentDidUpdate(prevProps) {
    this.setState({ type: this.props.type });

    if (this.props.comparables !== prevProps.comparables) {
      this.setComparables(this.props.comparables);
    }
  }

  setComparables(comparables) {
    this.setState(prevState => {
      let values = prevState.comparableValue;
      let data = prevState.comparablesData;
      let ids = prevState.comparablesIds;

      values = values.map((value, index) => {
        if (comparables[index]) {
          return comparables[index].legalName.trim();
        }

        return value;
      });

      data = data.map((item, index) => {
        if (comparables[index]) {
          return comparables[index];
        }

        return item;
      });

      ids = ids.map((id, index) => {
        if (comparables[index]) {
          return comparables[index].id;
        }

        return id;
      });

      return {
        comparableValue: values,
        comparablesData: data,
        comparablesIds: ids,
      };
    });
  }

  getChildContext() {
    return {
      onErrorClose: this.handleErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  handleErrorClose = (event, field) => {
    if (field === 'selectedBICs') {
      this.setState({ industryCategories: '', businessModels: '' });
    }
    this.props.closeValidationError({ field });
  };

  validate = () => {
    let isValid = true;
    const { criteria, common, projectData } = this.props;
    const bic = criteria.get(BIC);

    if (toJS(projectData).category.name && bic.size) {
      const industryCategories = getIn(common, 'selectedBICs');
      const businessModels = getIn(common, 'selectedBMs');

      if (!businessModels.size) {
        this.setState({ businessModels: BUSINESS_MODEL_ERROR });
        isValid = false;
      }

      if (!industryCategories.size) {
        this.setState({
          industryCategories: INDUSTRY_CATEGORY_ERROR,
        });
        isValid = false;
      }
    }

    return isValid;
  };

  getCanEditData() {
    return !this.props.isDuplicateCompany;
  }

  handleSubmit = event => {
    event.preventDefault();

    const {
      common,
      projectData,
      criteria,
      match,
      saveInfo,
      saveBIC,
      saveProject,
      saveIndustries,
      saveProjectsComparables,
      saveProjectCriteria,
      loadProjectCriteria,
    } = this.props;

    if (criteria.get('isValid')) {
      const body = getChanged(criteria);
      const commonBody = getChanged(common);
      const bic = criteria.get(BIC);
      const { companyId } = match.params;

      delete body.businessModels;
      delete body.industryCategories;

      if (Object.keys(commonBody).length) {
        saveProjectCriteria(companyId);
      }

      if (Object.keys(body).length) {
        saveInfo({ companyId, body, afterSuccess: () => loadProjectCriteria(companyId, projectData.get('id')) });
      }

      if (toJS(this.props.projectData).category.name) {
        const tags = getIn(common, 'selectedBICs');
        const models = getIn(common, 'selectedBMs');

        if (tags.size === 0 || models.size === 0) {
          showError(this.context.openPopup, ['Industry tags or Business models are missing']);
        } else {
          saveBIC({
            companyId,
            data: bic
              .filter(b => b.get('industryCategoryId') > 0)
              .map(b => ({
                industryCategoryId: getIn(b, 'industryCategoryId', ''),
                contactId: getIn(b, 'contactId') || null,
                notes: getIn(b, 'notes') || '',
                priority: 0,
              }))
              .toJS(),
            afterSuccess: () => {
              saveProject();
              saveIndustries(this.state.projectId);
              saveProjectsComparables(projectData.get('id'), this.state.comparablesIds);
            },
          });
        }
      }
    }
  };

  onChange({ name, value }) {
    if (name === 'details.name') {
      this.props.updateProjectInfo('details', value);
    } else {
      this.props.handleUpdateCompany({ name, value });
    }
  }

  onChangeCommon({ name, value }) {
    this.props.updateProjectCommonInfo(name, value);
  }

  loadProjectData(id) {
    const {
      loadIndustries,
      loadTemplates,
      loadProject,
      loadProjectsComparables,
      match,
      loadProjectCriteria,
    } = this.props;

    loadProjectsComparables(id);
    loadIndustries(id);
    loadTemplates(id);
    loadProject(id);
    loadProjectCriteria(match.params.companyId, id);
  }

  handleChangeProject = event => {
    const projectId = event.target.value;

    this.props.clearComparables();
    this.setState({
      projectId: event.target.value,
      comparableValue: ['', '', '', '', ''],
      comparablesData: [{}, {}, {}, {}, {}],
      comparablesIds: ['', '', '', '', ''],
    });
    this.loadProjectData(projectId);
  };

  handleDetailAdd = event => {
    event.stopPropagation();

    const { common, updateIndustryTags } = this.props;

    const tags = getIn(common, 'selectedBICs');
    const models = getIn(common, 'selectedBMs');

    this.context.openPopup('TagsManagementPopup', {
      forBuyer: true,
      clientView: true,
      selectedTags: tags.reduce((acc, t) => {
        acc[t] = true;

        return acc;
      }, {}),
      selectedModels: models.reduce((acc, m) => {
        acc[m] = true;

        return acc;
      }, {}),
      callback: (tagSelected, modelSelected) => updateIndustryTags(tagSelected, modelSelected),
    });
  };

  handleDetailEdit = (event, detailName, detailIndex) => {
    this.props.startEditDetailEntity({ name: detailName, index: detailIndex });
  };

  handleDetailSubmit = (event, detailName) => {
    const base = this.props.criteria.get(detailName);
    const { companyId } = this.props.match.params;

    if (!isDeepChanged(base)) return;
    if (!this.props.criteria.get(`${detailName}IsValid`, true)) return;

    const body = toJS(base);

    body.forEach(entity => {
      Object.keys(entity).forEach(key => {
        if (entity[key] instanceof moment) {
          try {
            entity[key] = entity[key].format('YYYY-MM-DD');
          } catch (err) {
            console.error("Can't transform ", key, entity[key], err);
          }
        }
      });
    });
    this.props.saveCompanyDetailEntity({
      companyId,
      name: detailName,
      body,
      realBody: prepareDetail(detailName, body, this.context.currentUser.get('id')),
    });
  };

  getDetailsCallback() {
    this.__detailsCallback = this.__detailsCallback || {
      onAdd: this.handleDetailAdd,
      onClick: this.handleDetailEdit,
      onSubmit: this.handleDetailSubmit,
    };

    return this.__detailsCallback;
  }

  handleFormChange = ({ target: { name, type, value, checked } }) => {
    if (type === 'checkbox') return this.onChange({ name, value: checked });

    return this.onChange({ name, value });
  };

  handleProjectFormChange = ({ target: { name, type, value, checked } }) => {
    if (type === 'checkbox') return this.onChangeCommon({ name, value: checked });

    return this.onChangeCommon({ name, value });
  };

  getErrorMessage = () => {
    const { industryCategories, businessModels } = this.state;

    return `${industryCategories} ${businessModels}`.trim();
  };

  handleGetSuggestion = debounce((value, index) => {
    const updatedValues = this.state.comparableValue;

    updatedValues[index - 1] = value.trim();
    this.setState({ comparableValue: updatedValues, comparablesLoadable: true });
    this.props.findComparables(value);
  }, 500);

  handleUpdateSuggestions = ({ value }, index) => {
    if (!List.isList(value)) {
      const updatedValues = this.state.comparableValue;

      updatedValues[index - 1] = value;

      this.setState({ comparableValue: updatedValues, comparablesLoadable: true, canLoadComparables: true });
    }
  };

  handleSuggestSelectCallback = (data, index) => {
    const updatedValues = this.state.comparableValue;
    const updatedData = this.state.comparablesData;
    const updatedIds = this.state.comparablesIds;

    updatedValues[index - 1] = data.legalName.trim();
    updatedData[index - 1] = data;
    updatedIds[index - 1] = data.id;

    this.setState(
      {
        comparableValue: updatedValues,
        comparablesLoadable: false,
        canLoadComparables: false,
        comparablesData: updatedData,
        comparablesIds: updatedIds,
      },
      () => {
        this.onChange({ name: 'comparablesIds', value: this.state.comparablesIds });
        this.props.changeComparables(this.state.comparablesIds, this.state.comparablesData);
      },
    );
  };

  searchFieldFocus = index => {
    if (this.state.comparableValue[index]) {
      this.setState({
        canLoadComparables: false,
        comparablesLoadable: false,
      });
    } else {
      this.setState({
        canLoadComparables: true,
        comparablesLoadable: true,
      });
    }
  };

  searchFieldFocusLeave = index => {
    if (Object.keys(this.state.comparablesData[index]).length) {
      this.setState({
        canLoadComparables: false,
        comparablesLoadable: false,
      });
    }
  };

  menuStateChanged = () => {
    this.setState({
      canLoadComparables: false,
    });
  };

  onComparableDelete(index) {
    this.setState(
      prevState => ({
        comparableValue: prevState.comparableValue.map((item, i) => (i === index ? '' : item)),
        comparablesIds: prevState.comparablesIds.map((item, i) => (i === index ? '' : item)),
        comparablesData: prevState.comparablesData.map((item, i) => (i === index ? {} : item)),
      }),
      () => {
        this.onChange({ name: 'comparablesIds', value: this.state.comparablesIds });
        this.props.changeComparables(this.state.comparablesIds, this.state.comparablesData);
      },
    );
  }

  getSuggestComparables(index) {
    const { criteria } = this.props;
    const id = `projectComparablesId${index}`;
    const name = `comparablesSuggest${index}`;

    if (Object.keys(this.state.comparablesData[index - 1]).length) {
      return <span>{this.state.comparableValue[index - 1]}</span>;
    }

    return (
      <AutoCompleteContainer
        change={value => this.handleUpdateSuggestions(value, index)}
        find={({ filter }) => {
          this.handleGetSuggestion(filter, index);
        }}
        idName={id}
        idValue={criteria.get(id)}
        inputProps={{
          className: 'form-control',
          placeholder: 'Select Comparable',
          disabled: !this.getCanEditData(),
        }}
        loadable={this.state.comparablesLoadable && this.state.canLoadComparables}
        onSelectCallback={data => this.handleSuggestSelectCallback(data, index)}
        render={suggestion => <span>{suggestion.legalName}</span>}
        suggestions={criteria.get('comparables')}
        suggestsName="comparables"
        value={this.state.comparableValue[index - 1]}
        valueName={name}
      />
    );
  }

  render() {
    const {
      criteria,
      industries,
      type,
      projects,
      projectData,
      industryLoading,
      loading,
      addOnProjects,
      common,
    } = this.props;
    const detailsCallbacks = this.getDetailsCallback();

    if (toJS(projects).platform.isFetching) {
      return <></>;
    }

    return (
      <Criteria
        addOnProjects={addOnProjects}
        canEditData={this.getCanEditData()}
        comparablesData={this.state.comparablesData}
        comparablesSuggest1={this.getSuggestComparables(1)}
        comparablesSuggest2={this.getSuggestComparables(2)}
        comparablesSuggest3={this.getSuggestComparables(3)}
        comparablesSuggest4={this.getSuggestComparables(4)}
        comparablesSuggest5={this.getSuggestComparables(5)}
        criteria={criteria}
        detailsCallbacks={detailsCallbacks}
        errorMsg={this.getErrorMessage()}
        industries={industries}
        loadingProjectData={industryLoading || loading}
        menuStateChanged={this.menuStateChanged}
        onChange={this.handleFormChange}
        onChangeCommon={this.handleProjectFormChange}
        onChangeProject={this.handleChangeProject}
        onDelete={id => this.onComparableDelete(id)}
        onErrorClose={this.handleErrorClose}
        projectCommonData={common}
        projectData={projectData}
        projects={projects}
        searchFieldFocus={this.searchFieldFocus}
        searchFieldFocusLeave={this.searchFieldFocusLeave}
        showComparablesDeleteMenu={false}
        type={type}
      />
    );
  }
}

CriteriaContainer.propTypes = {
  isDuplicateCompany: PropTypes.bool.isRequired,
};

CriteriaContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map),
  openPopup: PropTypes.func,
  closePopup: PropTypes.func,
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
  addValidateCallBacks: PropTypes.func,
  deleteValidateCallbacks: PropTypes.func,
};

CriteriaContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};

function mapStateToProps(state) {
  return {
    criteria: state.targetCompany.buyer.criteria,
    inputErrors: state.targetCompany.buyer.criteria.get('inputErrors'),
    projects: state.targetCompany.buyer.projects,
    addOnProjects: state.targetCompany.buyer.addOnProject.buyerProjects,
    common: state.project.common,
    industryLoading: state.project.common.get('industryLoading'),
    comparables: toJS(state.project.common.get('comparables')),
    industries: state.project.common.get('bic').concat(state.project.common.get('bm')),
    projectData: state.project.projectData,
    type: state.targetCompany.buyer.info.get('buyerType'),
    loading:
      state.targetCompany.buyer.projects.getIn(['addon', 'isFetching']) ||
      state.targetCompany.buyer.projects.getIn(['platform', 'isFetching']) ||
      state.project.common.get('templateLoading') ||
      state.project.common.get('projectLoading'),
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  };
}

export { CriteriaContainer };
export default connect(
  mapStateToProps,
  {
    handleUpdateCompany,
    startEditDetailEntity,
    saveInfo,
    closeValidationError,
    saveCompanyDetailEntity,
    saveBIC,
    findComparables,
    revertChanges,
    revertProjectChanges,
    getBuyerProjectsAddon,
    getBuyerProjectsPlatform,
    getStrategicBuyerProjects,
    loadIndustries,
    updateIndustryTags,
    updateProjectInfo,
    updateProjectCommonInfo,
    saveProject,
    saveIndustries,
    saveProjectsComparables,
    saveProjectCriteria,
    loadProjectsComparables,
    clearComparables,
    changeComparables,
    loadTemplates,
    loadProject,
    loadProjectCriteria,
  },
  mergeProps,
  connectOptions,
)(CriteriaContainer);
