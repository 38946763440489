import React, { memo } from 'react';

const DateRenderComponent = ({ value }) => {
  const format = date => {
    return (date && date.isValid() && date.format('MM/DD/YYYY')) || '';
  }

  const dateDisplay = format(value);

  return <span>{dateDisplay}</span>;
}

/** Date render component. */
export const DateRender = memo(DateRenderComponent);
