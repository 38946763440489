import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';
import { push, replace } from 'connected-react18-router';
import moment from 'moment';

import { isResearcher } from 'src/utils/checkPermissions';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { getCompanyInfo } from '../../actions/companyDetail';
import { deleteAddonProject } from '../../actions/company/buyer/addOnProject/main';
import { findUsers, getSubsidiaries, handleUpdateCompany, saveInfo } from '../../actions/company/buyer/info';
import { fetchBuyerStatuses } from '../../actions/statuses';
import { getBuyers } from '../../actions/company/companyTarget';
import AutoCompleteContainer from '../AutoComplete';
import CompanyBuyerRouting from '../../routing/CompanyBuyerRouting';
import { CompanyBuyer } from '../../components/Company/CompanyBuyer/index';
import stopProp from '../../decorators/stopPropagation';
import preventDefault from '../../decorators/preventDefault';
import confirm from '../decorators/confirm';
import { toJS, unwrap } from '../../utils/ChangeSpy';

const HOURS_LIMIT = 24;
const ALLOWED_ROLES = ['superuser', 'director', 'financeadmin'];

/**
 *
 * @returns {React.Component}
 */
class CompanyBuyerContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
    this.onAddAddonProject = this.onAddAddonProject.bind(this);
    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.handleUpdateHighStatus = this.handleUpdateHighStatus.bind(this);
    this.onRemoveAddonProject = preventDefault(
      stopProp(
        confirm('Are you sure you wish to delete current project?', this.onRemoveAddonProject.bind(this), context),
      ),
    );
  }

  componentDidMount() {
    const {
      getSubsidiaries,
      fetchBuyerStatuses,
      match: {
        params: { companyId },
      },
      getBuyers,
    } = this.props;

    this.checkIfBuyer();

    getSubsidiaries({ companyId });
    this.checkRedirection();
    fetchBuyerStatuses();
    getBuyers({ companyId });
  }

  hasAccess() {
    return this.context.currentUser;
  }

  onChange({ target: { name, value } }) {
    this.props.handleUpdateCompany({ name, value });
  }

  onAddAddonProject(event) {
    event.stopPropagation();
    event.preventDefault();

    this.context.openPopup('AddAddonProjectPopup', { companyId: this.props.match.params.companyId });
  }

  onRemoveAddonProject(_, addonProjectId) {
    const {
      getCompanyInfo,
      deleteAddonProject,
      match: {
        params: { companyId },
      },
      history,
    } = this.props;

    deleteAddonProject({
      addonProjectId,
      afterSuccess: () => {
        this.context.closePopup();
        history.replace(`/company/${companyId}/buyer/add-on-project`);
        setTimeout(() => {
          getCompanyInfo({ companyId });
        }, 100);
      },
    });
  }

  checkIfBuyer() {
    const {
      loading,
      type,
      getSubsidiaries,
      match: {
        params: { companyId },
      },
      push,
    } = this.props;

    if (this.context.hasPopup()) return;
    if (loading) return;

    if (!type) {
      if (isNaN(companyId)) return push('/');

      const isResearcherUser = isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug']));

      if (isResearcherUser) {
        this.context.openPopup('InformationPopup', {
          message: {
            texts: ["You don't have permission for this action"],
            className: 'text-danger',
          },
          header: (
            <span>
              <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" />
              Error Alert
            </span>
          ),
        });

        this.context.onClosePopup(() => push(`/company/${companyId}`));

        return;
      }

      this.context.openPopup('BuyerRolePopup', { companyId });
      this.context.onClosePopup(() => {
        if (!type) {
          return push(`/company/${companyId}`);
        }

        return getSubsidiaries({ companyId });
      });
    }
  }

  componentDidUpdate(oldProps) {
    this.checkIfBuyer();
    this.checkRedirection(oldProps);
  }

  checkRedirection(oldProps) {
    const {
      loading,
      location,
      match: {
        params: { companyId },
      },
    } = this.props;

    if (loading === true) return;
    if (oldProps && oldProps.loading === false) return;

    if (location.pathname === `/company/${companyId}/buyer`) {
      this.tryRedirect(companyId);
    } else if (location.pathname === `/company/${companyId}/buyer/`) {
      this.tryRedirect(companyId);
    }
  }

  tryRedirect() {
    const { companyId } = this.props.match.params;

    return setTimeout(() => this.props.replace(`/company/${companyId}/buyer/marketing`), 0);
  }

  onSuggestChange({ name, value }) {
    this.props.handleUpdateCompany({ name, value });
  }

  getSuggest() {
    const { buyer, findUsers } = this.props;
    const name = 'buyerUserSuggest';
    const idName = 'buyerHarvcoLeadId';
    // This doesn't work - we pass value for disabled as object.
    const isResearcherUser = isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug']));

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={findUsers}
        idName={idName}
        idValue={buyer.get(idName)}
        inputProps={{
          className: 'form-control input-sm bold-control-md',
          placeholder: 'HarvCo Contact',
          disabled: { isResearcherUser },
        }}
        suggestions={buyer.get('suggests')}
        suggestsName="suggests"
        value={buyer.getIn(name.split('.'))}
        valueName={name}
      />
    );
  }

  handleUpdateHighStatus() {
    const {
      statuses,
      buyer,
      match: {
        params: { companyId },
      },
      company,
      user,
    } = this.props;

    const updateAt = buyer.getIn(['highStatusUpdated']);
    const roles = toJS(user).roles.map(item => item.slug);
    const canEditWithoutExpiration = ALLOWED_ROLES.some(role => roles.includes(role));

    const statusUpdatedHoursPassed = moment().diff(updateAt, 'hours');

    this.context.openPopup('UpdateHighStatusPopup', {
      statuses,
      highStatus: unwrap(buyer.getIn(['buyerHighStatus'])),
      currentStatus: unwrap(buyer.getIn(['buyerCurrentStatus'])),
      highStatusUpdateAt: updateAt ? moment(updateAt) : '',
      companyId,
      type: 'buyer',
      directorEmail: company.get('recordOwnerEmail'),
      isStatusExpired: canEditWithoutExpiration ? false : statusUpdatedHoursPassed > HOURS_LIMIT,
    });
  }

  render() {
    const {
      type,
      buyer,
      loading,
      location,
      statuses,
      buyerProjects,
      isDuplicateCompany,
      match: {
        params: { companyId },
      },
    } = this.props;

    if (loading || !this.hasAccess()) return null;

    return (
      <CompanyBuyer
        buyer={buyer}
        buyerProjects={buyerProjects}
        companyId={companyId}
        harvcoSuggest={this.getSuggest()}
        isDuplicateCompany={isDuplicateCompany}
        location={location}
        onAddAddonProject={this.onAddAddonProject}
        onChange={this.onChange}
        onHighStatusUpdate={this.handleUpdateHighStatus}
        onRemoveAddonProject={this.onRemoveAddonProject}
        statuses={statuses}
        type={type}
        userRole={this.context.currentUser.getIn(['roles', 0, 'slug'], null)}
      >
        <CompanyBuyerRouting />
      </CompanyBuyer>
    );
  }
}

CompanyBuyerContainer.propTypes = {
  isDuplicateCompany: PropTypes.bool.isRequired,
};

CompanyBuyerContainer.contextTypes = {
  hasPopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

function mapStateToProps(state) {
  return {
    loading: state.targetCompany.info.get('isFetching') || !state.targetCompany.buyer.info.get('loaded'),
    buyer: state.targetCompany.buyer.info,
    company: state.targetCompany.target,
    user: state.auth.get('user'),
    type: state.targetCompany.buyer.info.get('buyerType'),
    statuses: fromJS(state.statuses.get('buyerStatuses')),
    projectData: fromJS(state.project.projectData),
    buyerProjects: state.targetCompany.buyer.addOnProject.buyerProjects,
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  };
}

export { CompanyBuyerContainer };
export default connect(
  mapStateToProps,
  {
    getCompanyInfo,
    deleteAddonProject,
    handleUpdateCompany,
    getSubsidiaries,
    findUsers,
    saveInfo,
    fetchBuyerStatuses,
    push,
    replace,
    getBuyers,
  },
  mergeProps,
  connectOptions,
)(CompanyBuyerContainer);
