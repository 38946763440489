import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import Popup from '../../helpers/Popup';
import FormGroup from '../../helpers/FormGroup';
import DropDown, { DropDownElement } from '../../helpers/DropDown';
import Button from '../../helpers/Button';
import AutoComplete, { InputProps } from '../../helpers/AutoComplete';
import _Input from '../../helpers/Input';
import _Textarea from '../../helpers/Textarea';
import ErrorDisplay from '../../decorators/DisplayError';
import PrettyError from '../../decorators/PrettyError';
import { PhoneEditor } from './PhoneEditor';

const Input = PrettyError(ErrorDisplay(_Input));
const Textarea = PrettyError(ErrorDisplay(_Textarea));

const renderSuggestion = suggest => <span>{suggest.text}</span>;

const AddCompanyContactPopup = (props, context) => {
  const {
    titleProps,
    onChange,
    contact,
    prefixes,
    suffixSuggest,
    phoneCodes,
    onSave,
    onUndo,
    onBlur,
    onFocus,
    onFindEmail,
  } = props;

  const prefix = contact.get('prefix');

  const header = 'Create contact profile';
  const footer = (
    <Button className="btn-primary btn-xs" onClick={onSave}>
      Save
    </Button>
  );

  const prefixesContent = prefixes.map((prefix, i) => {
    const attributes = prefix.get('attributes').toJS();

    return (
      <DropDownElement {...attributes} key={i} value={prefix}>
        {prefix.get('name')}
      </DropDownElement>
    );
  });

  const advisorInfo = titleProps.showExtraInfo ? (
    <div className="row mb15">
      <FormGroup className="col-lg-4 col-sm-4">
        <Input
          label="Company"
          name="pivot.company"
          onChange={onChange}
          placeholder="Company"
          value={contact.getIn(['pivot', 'company'])}
          showLabel
        />
      </FormGroup>
      <FormGroup className="col-lg-4 col-sm-4">
        <Input
          className="input-md"
          label="Title"
          name="pivot.advTitle"
          onChange={onChange}
          placeholder="Title"
          value={contact.getIn(['pivot', 'advTitle'])}
          showLabel
        />
      </FormGroup>
      <FormGroup className="col-lg-4 col-sm-4">
        <Input
          className="input-md"
          label="Web"
          name="pivot.advWeb"
          onChange={onChange}
          placeholder="Web"
          value={contact.getIn(['pivot', 'advWeb'])}
          showLabel
        />
      </FormGroup>
    </div>
  ) : null;

  return (
    <Popup footer={footer} header={header} id="newExecProf">
      <div className="row media-450">
        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3">
            Full Name
          </label>
          <DropDown
            btnContent={prefix}
            className="btn-default btn-xs small-line full-width text-right"
            id="newlpProfilePrefix"
            name="prefix"
            onChange={onChange}
            root={contact}
            deep
          >
            {prefixesContent}
          </DropDown>
        </FormGroup>

        <FormGroup className="col-lg-6 col-sm-6 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3" />
          <Input
            className="input-md"
            id="w-full-name7"
            label="Full Name"
            name="fullName"
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            placeholder="Full Name"
            root={contact}
            autoFocus
            deep
            required
          />
        </FormGroup>

        <FormGroup className="col-lg-3 col-sm-3 col-xs-4">
          <label className="control-label" htmlFor="w-full-name3" />
          {suffixSuggest}
        </FormGroup>
      </div>

      <div className="clearfix" />

      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="First Name"
            name="firstName"
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            placeholder="First Name"
            root={contact}
            canDisplayError
            deep
            showErrorOnFocus
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            className="input-md"
            label="Last Name"
            name="lastName"
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            placeholder="Last Name"
            root={contact}
            canDisplayError
            deep
            showErrorOnFocus
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            label="Nick"
            name="nick"
            onChange={onChange}
            placeholder="Nick"
            root={contact}
            deep
            showErrorOnFocus
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-lg-6 col-sm-6">
          <Input
            label="Pronunciation"
            name="pronounce"
            onChange={onChange}
            placeholder="Pronunciation"
            root={contact}
            deep
            showLabel
          />
        </FormGroup>
      </div>
      <div className="row">
        <InputProps className="form-control input-sm" label="Title" name="pivot.title" placeholder="Title" required>
          <AutoComplete
            checkOn="text"
            formGroupClass="col-lg-6 col-sm-6"
            getSuggestion={titleProps.onFetch}
            getSuggestionValue={titleProps.getValue}
            loading={titleProps.loading}
            minSuggestLength={0}
            onSuggestionSelected={titleProps.onSelect}
            onUpdateSuggestions={titleProps.onUpdate}
            renderSuggestion={renderSuggestion}
            suggestions={titleProps.suggestions}
            text={titleProps.value}
          />
        </InputProps>
        <FormGroup className="col-lg-5 col-sm-5">
          <Input
            label="Email"
            name="email"
            onChange={onChange}
            placeholder="Email"
            root={contact}
            type="emailVerify"
            deep
            showLabel
          />
        </FormGroup>
        <div className="col-lg-1 col-sm-1">
          <label>&nbsp;</label>
          <div className="clearfix">
            <Button className="btn-primary btn-xs pull-right" onClick={onFindEmail}>
              Finder
            </Button>
          </div>
        </div>
      </div>
      {advisorInfo}
      <PhoneEditor
        contact={contact}
        onBlur={onBlur}
        onChange={onChange}
        onErrorClose={context.onErrorClose}
        onUndo={onUndo}
        phoneCodes={phoneCodes}
        phoneError={context.inputErrors.get('unformattedPhone')}
      />
      <div className="row">
        <FormGroup className="col-lg-12 col-sm-12">
          <Textarea
            label="Contact Notes"
            name="notes"
            onChange={onChange}
            placeholder="Contact Notes"
            prefix="pivot"
            root={contact}
            rows="3"
            deep
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

AddCompanyContactPopup.contextTypes = {
  onErrorClose: PropTypes.func,
  inputErrors: PropTypes.instanceOf(Map),
};

export default AddCompanyContactPopup;
