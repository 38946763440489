import PropTypes from 'prop-types';
import React, { memo } from 'react';
import moment from 'moment';

/**
 * Stateless component for top header.
 *
 * @param props {Object}.
 * @param props.userName {string} Name of logined user.
 * @returns {React.Component}
 */
const TopHeaderComponent = ({ userName }) => {
  return <h1 className="text-center">{`Next Actions - ${userName} - ${moment().format('L')}`}</h1>;
};

TopHeaderComponent.propTypes = {
  userName: PropTypes.string.isRequired,
};

export const TopHeader = memo(TopHeaderComponent);
