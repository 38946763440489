import React, { memo } from 'react';
import { List } from 'immutable';
import Popup from '../../helpers/Popup';
import FormGroup from '../../helpers/FormGroup';
import Button from '../../helpers/Button';
import _Select from '../../helpers/Select';
import _Input from '../../helpers/Input';
import ErrorDisplay from '../../decorators/DisplayError';
import PrettyError from '../../decorators/PrettyError';

const Input = PrettyError(ErrorDisplay(_Input));
const Select = PrettyError(ErrorDisplay(_Select));

const InternationalFormatComponent = ({ isNew, phoneCodes = List(), onChange, channel, onSave, onUndo, onBlur }) => {
  const header = (
    <div>
      {`${isNew ? 'Create' : 'Change'} contact profile info`} - International
      <a href="#" onClick={onUndo} title="undo">
        undo
      </a>
    </div>
  );

  const footer = (
    <Button className="btn-primary btn-xs" onClick={onSave}>
      {isNew ? 'Create' : 'Save'}
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="extFormatContact">
      <div className="row">
        <FormGroup className="col-sm-2">
          <_Input
            className="input-md"
            label="Country Code"
            maxLength="10"
            name="code"
            placeholder="Country Code"
            root={channel}
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-sm-4">
          <Input
            className="input-md"
            label="Phone"
            maxLength="50"
            name="unformattedPhone"
            onChange={onChange}
            placeholder="Phone"
            root={channel}
            autoFocus
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-sm-3">
          <Select
            className="input-md"
            label="Country"
            name="country"
            nameKey="name"
            onBlur={onBlur}
            onChange={onChange}
            options={phoneCodes}
            placeholder="Country"
            root={channel}
            valueKey="code"
            canDisplayError
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-sm-3">
          <_Input className="input-md" label="Type" name="type" placeholder="Type" value="International" showLabel />
        </FormGroup>
      </div>
    </Popup>
  );
};

export const InternationalFormat = memo(InternationalFormatComponent);
