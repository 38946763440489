import React, { memo } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import Grid from '../helpers/AgGridTable';
import config from '../../config';
import { RowIndex } from './RowIndex';
import { CompanyCell } from './CompanyCell';
import { PlainPriority } from './PlainPriority';
import { Sales } from './Sales';
import { Products } from './Products';
import { Markets } from './Markets';
import { Facilities } from './Facilities';
import { CustomFields } from './CustomFields';
import { Description } from './Description';

const PreviewBodySection = ({ onlineApproval }) => {
  const optionalFields = [];
  const searchParams = new URLSearchParams(window.location.search);

  let api = null;

  for (const value of searchParams.keys()) {
    optionalFields.push(value);
  }

  const columnDefs = config.tables.getIn(['approval', 'online']).map(col => {
    if (optionalFields.includes(col.get('field'))) {
      // eslint-disable-next-line no-param-reassign
      col = col.set('hide', false);
    }

    switch (col.get('field')) {
      case 'index':
        return col.merge({
          cellRendererFramework: RowIndex,
        });

      case 'companyLegalName':
        return col.merge({
          cellRendererFramework: CompanyCell,
        });

      case 'companyDescription':
        return col.merge({
          cellRendererFramework: Description,
        });

      case 'products':
        return col.merge({
          cellRendererFramework: Products,
        });

      case 'markets':
        return col.merge({
          cellRendererFramework: Markets,
        });

      case 'facilities':
        return col.merge({
          cellRendererFramework: Facilities,
        });

      case 'custom_fields':
        return col.merge({
          cellRendererFramework: CustomFields,
        });

      case 'companyRevenue':
        return col.merge({
          cellRendererFramework: Sales,
        });

      case 'priority':
        return col.merge({
          cellRendererFramework: PlainPriority,
        });

      default:
        return col;
    }
  });

  const rowData = onlineApproval.get('approvalListData');

  const handleGridReady = params => {
    api = params.api;
    api.sizeColumnsToFit();
  };

  const handleGridSizeChanged = () => {
    if (api) {
      api.sizeColumnsToFit();
    }
  };

  const getRowNodeId = data => data.id;

  return (
    <div className="mt20 full-height table-generate-approval-list">
      <Grid
        columnDefs={columnDefs}
        getRowNodeId={getRowNodeId}
        onGridReady={handleGridReady}
        onGridSizeChanged={handleGridSizeChanged}
        rowData={rowData}
        rowHeight={130}
      />
    </div>
  );
};

PreviewBodySection.propTypes = {
  onlineApproval: PropTypes.instanceOf(Map).isRequired,
};

export default memo(PreviewBodySection);
