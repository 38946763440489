import React, { memo } from 'react';
import classNames from 'classnames';
import { fromJS } from 'immutable';

import FormGroup from '../../helpers/FormGroup';
import Select from '../../helpers/Select';

const OPTIONS = fromJS([
  { value: '', name: '' },
  { value: 'Verbal', name: 'Verbal' },
  { value: 'Internal', name: 'Internal' },
  { value: 'Reviewed', name: 'Reviewed' },
  { value: 'Audited', name: 'Audited' },
]);

const DataQualityComponent = ({ className, ...rest }) => {
  const cls = classNames('input-sm', className);

  return (
    <FormGroup>
      <Select {...rest} className={cls} label="Data Quality" options={OPTIONS} showLabel />
    </FormGroup>
  );
};

/** Data quality component. */
export const DataQuality = memo(DataQualityComponent);
