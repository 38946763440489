import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { unwrap } from '../../../../utils/ChangeSpy';

/**
 * WebInput will trim off protocol in display mode,
 * but show full url in edit mode.
 */
const WebsiteFieldComponent = ({ value, placeholder, onTextChange, className, name }) => {
  const getLinkUrl = value => {
    const url = value ? unwrap(value) : '';

    return url.indexOf('http') > -1 ? url : `http://${url}`;
  };

  const url = getLinkUrl(value);

  return (
    <div className="website-field input-group">
      <div className="clearfix">
        <span className="control-wrap">
          <input
            className={className}
            onChange={event => {
              onTextChange(event, name);
            }}
            placeholder={placeholder}
            type="text"
            value={value}
          />
        </span>
      </div>
      <span className="input-group-addon">
        <a href={url} rel="noopener noreferrer" target="_blank">
          <i className="fa fa-reply" title="Open link in a new tab" />
        </a>
      </span>
    </div>
  );
};

WebsiteFieldComponent.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
};

/** Website field component. */
export const WebsiteField = memo(WebsiteFieldComponent);
