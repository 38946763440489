import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map, List } from 'immutable';
import { ContextMenuTrigger } from 'react-contextmenu';

/**
 * Stateless component for a next action row.
 *
 * @param props {Object}.
 * @param props.action {Immutable.Map} Next action information.
 * @param props.className {String} Row's className.
 * @param props.children {Any} Children elements.
 * @returns {React.Component}
 */
const GridRowComponent = ({ action, className = '', children }) => {
  return (
    <ContextMenuTrigger
      action={action}
      attributes={{ className }}
      collect={event => ({ selected: List([event.action]) })}
      id="actionContextMenu"
      renderTag="tr"
    >
      {children}
    </ContextMenuTrigger>
  );
};

GridRowComponent.propTypes = {
  action: PropTypes.instanceOf(Map).isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
};

export const GridRow = memo(GridRowComponent);
