import React, { memo } from 'react';

const FacilitiesComponent = ({ data }) => (
  <>
    <div className="mb5">{data.facility1}</div>
    <div className="mb5">{data.facility2}</div>
    <div>{data.facility3}</div>
  </>
);

export const Facilities = memo(FacilitiesComponent);
