import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { createPortal } from 'react-dom';

import { Spinner } from 'src/components/helpers/Spinner';
import Table, { Column, Row } from '../helpers/Table';
import config from '../../config';
import uniqueId from '../../utils/uniqueId';
import { noop } from '../../utils/noop';

const noWrap = v => v;

const ContextMenuWrapper = (onClick, extraWrap = noWrap) => (value, data) => {
  const id = uniqueId();

  return (
    <div>
      <ContextMenuTrigger id={id}>{extraWrap(value, data)}</ContextMenuTrigger>

      <ContextMenu id={id}>
        <MenuItem data={data.toJS()} onClick={onClick}>
          <i className="fa fa-remove" /> Delete
        </MenuItem>
      </ContextMenu>
    </div>
  );
};

const wrapBacklogged = (text, data) => {
  const activity = data.getIn(['activityMap', 'text'], '');

  if (data.get('backlogged', false) && !data.get('completed', false) && config.BACKLOGGED_EVENTS.includes(activity))
    return (
      <span>
        <i className="fa fa-clock-o" /> {text}
      </span>
    );

  return <span>{text}</span>;
};

/**
 * Stateless component for contact's activities.
 * Display table where rows are activities.
 *
 * @link http://screencloud.net/v/jS6q on page (right top part)
 * @link http://screencloud.net/v/7yc2 component itself
 * @param props {Object}.
 * @param props.events {Immutable.List} List with activities info. If now activities
 *     are on contact - should be an empty List.
 * @returns {React.Component}
 */
const CompanyActivities = ({
  events,
  isDuplicateCompany,
  onAddEvent,
  onEditEvent,
  onDeleteEvent,
  onMouseEnter = noop,
  onMouseLeave = noop,
}) => {
  /**
   * Var for activity rows.
   *
   * @type {React.Component[]}
   */
  const rows = events
    .get('all')
    .map((eventData, i) => (
      <Row
        key={i}
        className={getRowClassName(eventData)}
        data={eventData}
        onDoubleClick={event => onEditEvent(event, eventData)}
        onMouseEnter={event => onMouseEnter(event, eventData.get('id'))}
        onMouseLeave={event => onMouseLeave(event, eventData.get('id'))}
      />
    ));

  const dateTitle = (
    <span onClick={onAddEvent}>
      <i aria-hidden="true" className="fa fa-plus-square fa-2x" /> Date
    </span>
  );

  const current = events.get('current');
  const style = {
    top: current.get('tooltipTop'),
    left: current.get('tooltipLeft'),
  };

  const container = (
    <div className="eventTooltip" style={style}>
      {current.get('tooltipText')}
    </div>
  );
  const portal = createPortal(container, document.querySelector('body'));
  const tooltipPortal = current.get('showTooltip') && portal;

  const isLoading = events.get('isListLoading');

  return (
    <div className="companyActivities flexWrapper flexItem">
      <Table bodyClassName="flexItem" className="table table-striped table-hover table-dates flexItem flexWrapper">
        <Column
          field="dateString"
          title={dateTitle}
          valueWrapper={isDuplicateCompany ? undefined : ContextMenuWrapper(onDeleteEvent)}
        />
        <Column
          field="userName"
          title="Name"
          valueWrapper={isDuplicateCompany ? undefined : ContextMenuWrapper(onDeleteEvent)}
        />
        <Column
          field="fullActivity"
          title="Activity"
          valueWrapper={isDuplicateCompany ? undefined : ContextMenuWrapper(onDeleteEvent, wrapBacklogged)}
        />
        {rows}
      </Table>
      {isLoading && <Spinner />}
      {tooltipPortal}
    </div>
  );
};

CompanyActivities.propTypes = {
  events: PropTypes.instanceOf(Map).isRequired,
  isDuplicateCompany: PropTypes.bool.isRequired,
};

export default CompanyActivities;

function getRowClassName(data) {
  const level = data.get('warnLevel');

  return classNames('clickable', {
    info: level === 0 || level === 1,
    warning: level === 2,
    danger: level === 3,
    'text-bold': data.get('isMarked'),
  });
}
