import React, { memo } from 'react';
import classNames from 'classnames';
import { Map, List } from 'immutable';

import LoadAnimation from '../../decorators/LoadAnimation';
import FormGroup from '../../helpers/FormGroup';
import _Textarea from '../../helpers/Textarea';
import _Wysiwyg from '../../helpers/Wysiwyg/Wysiwyg';
import _Checkbox from '../../helpers/Checkbox';
import _Input from '../../helpers/Input';
import _Select from '../../helpers/Select';
import _ActivityDropdown from '../../helpers/ActivityDropdown';
import config from '../../../config';
import { unwrap } from '../../../utils/ChangeSpy';
import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';
import FormPreventEnterSubmit from '../../helpers/FormPreventEnterSubmit';
import { SharedDealNote } from './SharedDealNote';

const ActivityDropdown = PrettyError(DisplayError(_ActivityDropdown));
const Textarea = PrettyError(DisplayError(_Textarea));
const Wysiwyg = PrettyError(DisplayError(_Wysiwyg));
const Checkbox = PrettyError(DisplayError(_Checkbox));
const Input = PrettyError(DisplayError(_Input));
const Select = PrettyError(DisplayError(_Select));

const defaultActivities = List();
const defaultActivity = Map();
const defaultSelectedEvent = Map();

/**
 * Always check if current action is auth and remove state.
 *
 * @param transactionValueAtClose {string}.
 * @param ttmEbitdaAtClose {string}.
 * @returns {string} Returns implied multiple.
 */
function getImpliedMultipleValue(transactionValueAtClose, ttmEbitdaAtClose) {
  if (Number(ttmEbitdaAtClose) === 0 || Number.isNaN(Number(ttmEbitdaAtClose))) {
    return '-';
  }

  const result = (Number(transactionValueAtClose) / Number(ttmEbitdaAtClose)).toFixed(2);

  return `${result}x`;
}

const TargetFormComponent = ({
  buyer,
  event,
  contacts,
  onChange,
  onSave,
  onActivityChange,
  suggestUser,
  suggestDirector,
  suggestAnalyst,
  suggestDealMaker,
  hasEverNext,
  isNew,
  shouldPrompt,
  onRef,
  onNext,
  onAddBuyerNote,
  onChangeCompanyIds
}) => {
  let backlogged = null;
  const noteText = 'Deal Notes';
  const activities = config.values.getIn(['eventActivities', 'target'], defaultActivities);
  const activity = event.getIn(['activityMap', 'text', 'value'], '') || '';
  const selectedEvent = event.getIn(['activityMap', 'selected'], defaultSelectedEvent);
  const descriptionClass = classNames('row', {
    hide: !selectedEvent.get('showDescription'),
  });
  const ndaExecutedClass = classNames('col-xs-4', {
    hide: !selectedEvent.get('showNDAExecuted'),
  });
  const amountClass = classNames('col-xs-3', {
    hide: !selectedEvent.get('showOfferAmount'),
  });
  const amountHintClass = classNames('col-xs-12', {
    hide: !selectedEvent.get('showOfferAmount'),
  });

  const closeDateClass = classNames('col-xs-3', {
    hide: activity !== 'LOI Executed',
  });
  const dealClosedClass = classNames({ hide: activity !== 'Deal Closed' });
  const deferredCompensationClass = classNames({ hide: activity !== 'Deferred Compensation' });
  const buyerNoteClass = classNames('text-right', {
    'col-xs-offset-4': !selectedEvent.get('showNDAExecuted') && !selectedEvent.get('showOfferAmount'),
    hide:
      activity === '' ||
      activity === 'Email' ||
      activity === 'Update Call' ||
      !event.getIn(['completed', 'value'], false),
  });
  const buyerLinkText = selectedEvent.get('showEmail') ? 'Hide Buyer Notes' : 'Add Buyer Notes';
  const disabled = (hasEverNext && isNew) || shouldPrompt;

  let note = null;

  if (unwrap(event.get('completed'))) {
    const dealNotesClass = classNames(
      {
        'col-xs-12': !selectedEvent.get('showEmail'),
        'col-xs-6': !!selectedEvent.get('showEmail'),
        hide: !selectedEvent.get('showDealNotes'),
      },
      'wysiwygContainer',
    );
    const emailClass = classNames(
      {
        'col-xs-12': !selectedEvent.get('showDealNotes'),
        'col-xs-6': !!selectedEvent.get('showDealNotes'),
        hide: !selectedEvent.get('showEmail'),
      },
      'all-h100p',
    );

    note = (
      <div className="row h100p d-flex">
        <FormGroup className={dealNotesClass}>
          <Wysiwyg
            label={noteText}
            name="dealNotes"
            onChange={onChange}
            placeholder={noteText}
            root={event}
            tabIndex={6}
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className={emailClass}>
          <Textarea
            label="Email Text / Buyer Notes"
            name="emailText"
            placeholder="Email Text / Buyer Notes"
            root={event}
            rows="4"
            tabIndex="6"
            deep
            showLabel
          />
        </FormGroup>
      </div>
    );
  } else {
    const backloggedStat = event.get('backlogged', false) ? 'Backlogged' : '(click to backlog)';

    if (config.BACKLOGGED_EVENTS.includes(selectedEvent.get('value'))) {
      backlogged = (
        <div className="row">
          <FormGroup className="col-xs-12 group-checkbox">
            <Checkbox deep="checked" label={backloggedStat} name="backlogged" root={event} tabIndex="5" />
          </FormGroup>
        </div>
      );
    }
  }

  const impliedMultipleValue = getImpliedMultipleValue(
    unwrap(event.get('transactionValueAtClose')),
    unwrap(event.get('ttmEbitdaAtClose')),
  );

  return (
    <FormPreventEnterSubmit
      ref={onRef}
      className="flexWrapper h100p"
      id="popup-edit-event-form"
      onChange={onChange}
      onSubmit={onSave}
    >
      <div className="row">
        <FormGroup className="col-xs-3">
          <Input
            autoFocus={isNew}
            className="input-sm"
            label="Date"
            name="date"
            onChange={onChange}
            placeholder="Date"
            root={event}
            tabIndex="1"
            type="date"
            deep
            required
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-xs-3">{suggestDirector}</FormGroup>
        <FormGroup className="col-xs-3">
          <Input
            className="input-sm"
            label="Buyer"
            name="buyerName"
            placeholder="Buyer"
            root={event}
            deep
            readOnly
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-xs-3">
          <Input
            className="input-sm"
            label="Target"
            name="targetName"
            placeholder="Target"
            root={event}
            deep
            readOnly
            showLabel
          />
        </FormGroup>
      </div>

      <div className="row">
        <FormGroup className="col-xs-3 group-checkbox">
          <Checkbox deep="checked" disabled={disabled} label="Completed" name="completed" root={event} tabIndex="2" />
          <div className="completed-by">{suggestUser}</div>
        </FormGroup>
        <div className="col-xs-3">
          <div>{suggestAnalyst}</div>
          <div className="mt2">{suggestDealMaker}</div>
        </div>

        <FormGroup className="col-xs-3">
          <Input
            className="input-sm"
            label="Project"
            name="projectName"
            placeholder="Project"
            root={event}
            deep
            readOnly
            showLabel
          />
        </FormGroup>
        <FormGroup className="col-xs-3">
          <Select
            className="input-sm"
            label="Contact"
            name="contactId"
            options={contacts}
            placeholder="Contact"
            root={event}
            deep
            showLabel
          />
        </FormGroup>
      </div>

      <div className="row">
        <FormGroup className="col-xs-3">
          <ActivityDropdown
            activities={activities}
            disabled={unwrap(event.get('activity')) === 'One-Off'}
            info={event.get('activityMap', defaultActivity)}
            isSinglePanel={false}
            name="activity"
            onChange={onActivityChange}
            onNext={onNext}
            tabIndex="4"
          />
        </FormGroup>
        {unwrap(event.get('completed')) && buyer && (
          <div className="sharedDealNoteList">
            <SharedDealNote buyer={buyer} onChangeCompanyIds={onChangeCompanyIds} />
          </div>
        )}
      </div>

      <div className="row pt15">
        <div className="col">
          <FormGroup className={ndaExecutedClass}>
            <Input
              className="input-sm"
              label="NDA Term (months)"
              name="ndaTerm"
              placeholder=""
              root={event}
              deep
              showLabel
            />
          </FormGroup>

          <FormGroup className={amountClass}>
            <Input
              className="input-sm"
              label="Offer Amount ($M)"
              min={0}
              name="offerSize"
              placeholder=""
              root={event}
              type="number"
              deep
              showLabel
            />
          </FormGroup>

          <FormGroup className={closeDateClass}>
            <Input
              className="input-sm"
              label="Projected Close Date"
              name="projectedCloseDate"
              onChange={onChange}
              placeholder="Date"
              root={event}
              type="date"
              deep
              showLabel
            />
          </FormGroup>

          <FormGroup className={classNames(dealClosedClass, 'transactionValueAtClose', 'col-xs-3')}>
            <Input
              className="input-sm"
              label="Transaction Value at Close ($M)"
              max={1000}
              min={0}
              name="transactionValueAtClose"
              placeholder=""
              root={event}
              type="number"
              deep
              showLabel
            />
          </FormGroup>

          <FormGroup className={classNames(dealClosedClass, 'col-xs-3')}>
            <Input
              className="input-sm"
              label="Transaction Close Date"
              name="transactionCloseDate"
              onChange={onChange}
              placeholder="Date"
              root={event}
              type="date"
              deep
              showLabel
            />
          </FormGroup>

          <FormGroup className={classNames(dealClosedClass, 'col-xs-3')}>
            <Input
              className="input-sm"
              label="Deal Fee at Close"
              min={0}
              name="dealFeeAtClose"
              placeholder=""
              root={event}
              type="number"
              deep
              showLabel
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <FormGroup className={classNames(dealClosedClass, 'col-xs-3', 'group-checkbox')}>
          <Checkbox deep="checked" label="Deferred Compensation" name="isDeferredCompensation" root={event} />
        </FormGroup>

        <FormGroup className={classNames(dealClosedClass, 'col-xs-3')}>
          <Input
            className="input-sm"
            label="TTM EBITDA at Close ($M)"
            max={1000}
            min={0}
            name="ttmEbitdaAtClose"
            placeholder=""
            root={event}
            type="number"
            deep
            showLabel
          />
        </FormGroup>

        <FormGroup className={classNames(dealClosedClass, 'col-xs-3')}>
          <b className="invisible display-block">TTM EBITDA at Close ($M)</b>
          <b>Implied Multiple:</b> {impliedMultipleValue}
        </FormGroup>
      </div>

      <div className="row">
        <FormGroup className={classNames(deferredCompensationClass, 'col-xs-3')}>
          <Input
            className="input-sm"
            label="Deferred Compensation Amount ($M)"
            min={0}
            name="deferredCompensationAmount"
            placeholder=""
            root={event}
            type="number"
            deep
            showLabel
          />
        </FormGroup>

        <FormGroup className={classNames(deferredCompensationClass, 'dealFee', 'col-xs-3')}>
          <Input
            className="input-sm"
            label="Deal Fee"
            min={0}
            name="dealFee"
            placeholder=""
            root={event}
            type="number"
            deep
            showLabel
          />
        </FormGroup>
      </div>

      <div className="row">
        <FormGroup className={amountHintClass}>
          Enter a numerical offer amount only. If the offer is a range, use the average value. Enter the details of the
          offer in the 'Description' field, including any deferred payments or details about the range that was offered.
          If the offer is in a foreign currency, please convert to USD, and write actual currency in 'Description'
          field.
        </FormGroup>
      </div>

      <div className="row">
        <FormGroup className={buyerNoteClass}>
          <a href="#" onClick={onAddBuyerNote}>
            {buyerLinkText}
          </a>
        </FormGroup>
      </div>

      {backlogged}

      <div className={descriptionClass}>
        <FormGroup className="col-sm-12">
          <Textarea
            label="Description"
            name="description"
            placeholder="Description"
            root={event}
            rows="2"
            tabIndex="5"
            deep
            showLabel
          />
        </FormGroup>
      </div>

      <div className="flexItem all-h100p">{note}</div>
    </FormPreventEnterSubmit>
  );
};

export const TargetLoadableForm = LoadAnimation(memo(TargetFormComponent));
