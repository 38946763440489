import React, {memo, useState} from 'react';

import Popup from '../../helpers/Popup';
import Button from '../../helpers/Button';

import { TagReminders } from './TagReminders';
import { ModelBusiness } from './ModelBusiness';
import { TagRow, Item } from './TagRow';

const Row = ({ tag1, tag2, tag3, onChange, onClick }) => {
  const ids = [tag1.get('id'), tag2?.get('id'), tag3?.get('id')].filter(Boolean);

  const handleClick = (id) => onClick(id, ids);

  return (
    <TagRow>
      <Item onChange={onChange} onClick={handleClick} tag={tag1} />
      <Item onChange={onChange} onClick={handleClick} tag={tag2} />
      <Item onChange={onChange} onClick={handleClick} tag={tag3} />
    </TagRow>
  );
};

const BUSINESS_MODELS_ERROR = 'Select at least one Business Model tag';
const TAGS_ERROR =
  'Select at least one Broad Industry tag (Sub-Category, End Market, Theme, and Business Function tags are optional)';

const TagsManagementPopupComponent = ({
  businessModels,
  tags,
  onChange,
  onModelChange,
  onClick,
  onClose,
  clientView = false,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => setCollapsed((prev) => !prev);

  const hasCheckedBM = businessModels.has('value')
    ? businessModels
      .get('value')
      .toJS()
      .some(({ checked }) => checked)
    : false;

  const hasCheckedTags =
    tags.size > 0 ? tags.toJS().some((tag) => tag.checked || tag.children.some(({ checked }) => checked)) : false;

  const footer = (
    <Button key="close" className="btn-primary btn-xs" data-dismiss="modal" onClick={onClose} autoFocus>
      Close
    </Button>
  );

  const header = (
    <div>
      Industry Tags
      <a className="tag-link" href="/tag" rel="noopener noreferrer" target="_blank">
        Tags Management
      </a>
    </div>
  );

  const rows = [];
  for (let i = 0; i < tags.size; i += 3) {
    rows.push(
      <Row
        key={i}
        onChange={onChange}
        onClick={onClick}
        tag1={tags.get(i)}
        tag2={tags.get(i + 1)}
        tag3={tags.get(i + 2)}
      />
    );
  }

  return (
    <Popup footer={footer} header={header} id="tagsManagementPopup">
      <div className="tag-list">
        <div className="business-model">
          <ModelBusiness
            businessModels={businessModels}
            collapsed={collapsed}
            onClick={toggleCollapse}
            onModelChange={onModelChange}
          />
          <TagReminders hide={hasCheckedBM} message={BUSINESS_MODELS_ERROR} />
        </div>
        <div className="industry-tags">
          <span>
            <b>Broad Industry Category Tags</b> (Click on the arrow to view Sub-Category, End Market, Theme, and
            Business Function Tags)
          </span>
          <TagReminders hide={hasCheckedTags} message={TAGS_ERROR} />
          {rows}
        </div>
      </div>
    </Popup>
  );
};

export const TagsManagementPopup = memo(TagsManagementPopupComponent);
