import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import config from '../../config';
import { isResearcher } from '../../utils/checkPermissions';
import { showError } from '../../utils/MessagePopup';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import { CompanyExecutive } from '../../components/Company/CompanyExecutive';

const { object } = PropTypes;

class CompanyExecutiveContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
  }

  componentDidMount() {
    if (!this.hasAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.history.push('/');
        this.context.closePopup();
      });
    }
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  handleDoubleClickRow(row) {
    window.open(`/contact/${row.data.contactId}`, '_blank');
  }

  render() {
    if (!this.hasAccess()) return null;

    const { executive } = this.props;

    return (
      <div className="tab-pane active full-height" id="slide-3">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p0 full-height">
          <CompanyExecutive executive={executive} onDoubleClickRow={this.handleDoubleClickRow} />
        </div>
      </div>
    );
  }
}

CompanyExecutiveContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  closePopup: PropTypes.func.isRequired,
};

CompanyExecutiveContainer.propTypes = {
  executive: object.isRequired,
};

export { CompanyExecutiveContainer };
export default connect(
  state => ({
    executive: state.targetCompany.executive,
  }),
  {},
  mergeProps,
  connectOptions,
)(CompanyExecutiveContainer);
