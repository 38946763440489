import PropTypes from 'prop-types';
import React, { useEffect, useRef, useCallback, memo } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../helpers/AgGridTable';
import { convertDateString } from '../../utils/dateFormat';
import { handleSortModelChanged } from '../../utils/sorting';

const DateRenderer = ({ value }) => {
  return <span>{convertDateString(value)}</span>;
};

const CheckboxCellRenderer = ({ data }) => {
  if (!data) return <span />;

  return <input defaultChecked={data.selected} type="checkbox" />;
};

const MailingCreateTableComponent = ({ columnDefs, data, onChange, onDoubleClickRow, onGetNextPageData, onUpdateSelectedRow }) => {
  const gridApiRef = useRef(null);
  const gridColumnApiRef = useRef(null);
  const lastColumnDefsRef = useRef(null);
  const columnDefsRef = useRef(null);

  const getColumnDefs = useCallback(() => {
    if (columnDefs !== lastColumnDefsRef.current) {
      lastColumnDefsRef.current = columnDefs;
      columnDefsRef.current = columnDefs.map(columnDef => {
        const field = columnDef.get('field');

        switch (field) {
          case 'selected':
            return columnDef.set('cellRendererFramework', CheckboxCellRenderer);

          case 'naDate':
            return columnDef.set('cellRendererFramework', DateRenderer);

          default:
            return columnDef;
        }
      });
    }

    return columnDefsRef.current;
  }, [columnDefs]);

  const onGridReady = useCallback(params => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;
  }, []);

  const onCellClicked = useCallback(params => {
    const {
      colDef: { field },
      data: { order, selected },
    } = params;

    if (field === 'selected') {
      onUpdateSelectedRow({
        index: order,
        field: 'selected',
        value: !selected,
      });
    }
  }, [onUpdateSelectedRow]);

  const handleRowDoubleClicked = useCallback(
    row => {
      onDoubleClickRow(row);
    },
    [onDoubleClickRow]
  );

  const handleSort = useCallback(
    sortModel => {
      handleSortModelChanged(sortModel, data, onChange);
    },
    [data, onChange]
  );

  const getRowNodeId = useCallback(data => data.order, []);

  useEffect(() => {
    // TODO: need to check
    if (
      data.getIn(['sortBy', 'sortModel']) !==
      lastColumnDefsRef.current?.getIn(['sortBy', 'sortModel'])
    ) {
      // gridApiRef.current.setSortModel(data.getIn(['sortBy', 'sortModel']).toJS());
    }

    if (gridApiRef.current) {
      gridApiRef.current.sizeColumnsToFit();
    }
  }, [data]);

  return (
    <div className="MailingCreateTable">
      <AgGridTable
        columnDefs={getColumnDefs()}
        getRowNodeId={getRowNodeId}
        isFetching={data.get('isFetching')}
        onCellClicked={onCellClicked}
        onGetNextPageData={onGetNextPageData}
        onGridReady={onGridReady}
        onRowDoubleClicked={handleRowDoubleClicked}
        onSortModelChanged={handleSort}
        page={data.get('page')}
        rowData={data.get('queryResults')}
        totalPages={data.get('totalPages')}
        sortable
      />
    </div>
  );
};

MailingCreateTableComponent.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onUpdateSelectedRow: PropTypes.func.isRequired,
};

export const MailingCreateTable = memo(MailingCreateTableComponent);
