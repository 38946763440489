import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import { Project } from '../../../components/Company/CompanyBuyer/AddOnProjectCustom';
import config from '../../../config';
import {
  handleAbortRequest,
  loadPage,
  savePage,
  reset,
  updateProjectInfo,
  selectProjectSuggestion,
  clearError,
  revertChanges,
} from '../../../actions/company/buyer/addOnProject/main';
import {
  saveUsers,
  selectUserSuggestion,
  deleteUserRow,
  addEmptyUser,
  removeEmptyUser,
} from '../../../actions/company/buyer/addOnProject/suggestion';
import { sortTarget } from '../../../actions/company/buyer/addOnProject/target';
import { clearTemplatePopupError } from '../../../actions/company/buyer/addOnProject/templatePopup';
import {
  saveApproval,
  updateApprovals,
  insertApprovalRow,
  switchApprovalRowToEdit,
  sortApproval,
  deleteApproval,
  loadApprovals,
} from '../../../actions/company/buyer/addOnProject/approval';
import {
  changeToEditMode,
  updateTemplateName,
  switchTemplateRowToEdit,
  deleteTemplateRow,
} from '../../../actions/company/buyer/addOnProject/template';
import {
  updateProjectTags,
  setTagsValidationError,
  closeTagsErrors,
} from '../../../actions/company/buyer/addOnProject/projectTags';
import detectDirty from '../../../components/Project/detectDirty';
import { openInternalLink } from '../../../utils/url';
import { showError } from '../../../utils/MessagePopup';
import deletionCheck from '../../../utils/deletingPermission';
import deleteApprovalConfirmation from '../../services/deleteApprovalConfirmation';
import { unwrap } from '../../../utils/ChangeSpy';
import UserEditor from './UserAutoComplete';
import downloadFile from '../../../utils/downloadFile';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES } from '../../../utils/industryTagsHelper';

const types = config.values.getIn(['project', 'types']);
const IS_DEFAULT_ADDON = false;

/**
 * State container component for project page.
 *
 * @param props {Object}.
 * @param loggedUser {Immutable.Map} Current logged user.
 * @param projectData {Immutable.Map} Project information.
 * @param tags {Immutable.Map} Industry tags data.
 * @param users {Immutable.List} User list.
 * @param approvals {Immutable.List} Approval list.
 * @param templates {Immutable.List} Template list.
 * @param targets {Immutable.List} Target list.
 * @param common {Immutable.Map} Common object.
 * @param savePending {Boolean} Saving status.
 * @returns {React.Component}
 * @constructor
 */
class AddOnProjectCustomContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleRowInsert = this.handleRowInsert.bind(this);
    this.handleRowDelete = deletionCheck(this.props.loggedUser, this.handleRowDelete.bind(this), context);
    this.handleSuggestionClose = this.handleSuggestionClose.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSuggetionSelect = this.handleSuggetionSelect.bind(this);
    this.handleProjectInputChange = this.handleProjectInputChange.bind(this);
    this.handleSuggestionInputClick = this.handleSuggestionInputClick.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRowDoubleClick = this.handleRowDoubleClick.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleApprovalChange = this.handleApprovalChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleProjectSuggestionSelect = this.handleProjectSuggestionSelect.bind(this);
    this.addEmptyRow = this.addEmptyRow.bind(this);
    this.removeEmptyRow = this.removeEmptyRow.bind(this);
    this.validate = this.validate.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.handleUpdateTags = this.handleUpdateTags.bind(this);
    this.handleDeleteApprovalRow = deleteApprovalConfirmation({
      deleteApproval: this.handleDeleteApprovalRow.bind(this),
      loggedUser: this.props.loggedUser,
      openPopup: context.openPopup,
      closePopup: context.closePopup,
    });
  }

  componentWillUnmount() {
    this.context.deleteValidateCallbacks(this.validate);
    this.context.removeOnSaveCallback(this.handleSave);
    this.props.revertChanges();
    this.props.handleAbortRequest();
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      loadPage,
    } = this.props;

    if (id) {
      loadPage(id, [id], IS_DEFAULT_ADDON);
    }
    this.context.addValidateCallBacks(this.validate);
    this.context.addOnSaveCallback(this.handleSave);
  }

  componentDidUpdate(nextProps) {
    const {
      match: {
        params: { id },
      },
      common,
      templatePopup,
      loadPage,
      handleAbortRequest,
    } = this.props;

    if (id !== nextProps.match.params.id) {
      handleAbortRequest();
      loadPage(id, [id], IS_DEFAULT_ADDON);
    }

    if (common !== nextProps.common || templatePopup !== nextProps.templatePopup) {
      if (this.context.hasPopup()) {
        return this.context.onClosePopup(() => {
          this.showErrorsPopup();
        });
      }
      this.showErrorsPopup();
    }
  }

  getCanEditData() {
    return !this.props.isDuplicateCompany;
  }

  showErrorsPopup() {
    const { common, templatePopup, clearTemplatePopupError, clearError } = this.props;
    const errors = common.get('errors');

    if (errors.size > 0) {
      const callback = () => clearError();

      showError(this.context.openPopup, errors, callback);
    } else if (templatePopup) {
      const errors = templatePopup.get('errors');

      if (errors && errors.size > 0) {
        const callback = () => clearTemplatePopupError();

        showError(this.context.openPopup, errors, callback);
      }
    }
  }

  handleRowClick({ row, type }) {
    const { switchUserRowToEdit, switchApprovalRowToEdit, switchTemplateRowToEdit } = this.props;

    if (type === types.get('USERS')) {
      switchUserRowToEdit(row.get('id'));
    } else if (type === types.get('APPROVALS')) {
      switchApprovalRowToEdit(row.get('index'));
    } else if (type === types.get('TEMPLATES')) {
      switchTemplateRowToEdit(row.get('id'));
    }
  }

  handleRowDoubleClick({ row, type }) {
    const { approvals, match } = this.props;
    const id = row.get('id');

    if (type === types.get('APPROVALS')) {
      const approval = approvals.find(a => a.get('id') === id);

      if (approval || id === 'no_approval_lists') {
        openInternalLink(`/project/${match.params.id}/approval/${id}`, false);
      }
    } else if (type === types.get('USERS')) {
      openInternalLink(`/user/${id}`);
    } else if (type === types.get('TARGETS')) {
      openInternalLink(`/company/${id}`);
    }
  }

  handleRowInsert(data) {
    this.props.insertApprovalRow({ ...data, projectId: this.props.match.params.id });
  }

  handleDeleteApprovalRow(event, approvalId) {
    const { match, common, loadApprovals, deleteApproval } = this.props;

    deleteApproval({
      projectId: match.params.id,
      approvalId,
      afterSuccess: () => {
        loadApprovals({
          projectId: match.params.id,
          field: common.get('approvalSortField'),
          direction: common.get('approvalSortDirection'),
          isDefaultAddon: false,
        });
      },
    });
  }

  handleRowDelete(e, { id, type }) {
    if (type === types.get('USERS')) {
      this.props.deleteUserRow(id);
    } else if (type === types.get('TEMPLATES')) {
      this.props.deleteTemplateRow(this.props.projectData.get('id'), id);
    }
  }

  handleTextChange({ event, data: { type, row } }) {
    const { id } = this.props.match.params;
    const {
      target: { name, value, checked },
    } = event;

    if (type === types.get('USERS')) {
      this.props.updateUserText(row.get('id'), value);
    } else if (type === types.get('PROJECT')) {
      this.props.updateProjectInfo(name, event.target.type.toUpperCase() === 'CHECKBOX' ? checked : value, id);
    } else if (type === types.get('TEMPLATES')) {
      this.props.updateTemplateName(row.get('id'), value);
    }
  }

  handleRowChange({ type, rowIndex, field, value }) {
    if (type === 'approvals') {
      this.props.updateApprovals(rowIndex, field, value);
    }
  }

  handleApprovalChange(params) {
    this.props.updateApprovals(params.data.index, params.colDef.field, params.newValue);
  }

  handleSuggestionClose({ data }) {
    const { type, row } = data;

    if (type === 'users') {
      this.props.switchUserRowToEdit(row.get('id'));
    }
  }

  handleProjectSuggestionSelect({ data, suggestion }) {
    this.props.selectProjectSuggestion(data.name, suggestion.get('id'), suggestion.get('name'));
  }

  handleSuggetionSelect({ type, rowIndex, id, text }) {
    if (type === 'users') {
      this.props.selectUserSuggestion({ rowIndex, id, text });
    }
  }

  handleUpdateTags() {
    if (!this.getCanEditData()) {
      return;
    }

    const { tags, updateProjectTags } = this.props;
    const categories = unwrap(tags.get(INDUSTRY_CATEGORIES));
    const models = unwrap(tags.get(BUSINESS_MODELS));
    const selectedTags = categories.reduce((acc, tag) => {
      acc[tag.get('id')] = true;

      return acc;
    }, {});
    const selectedModels = models.reduce((acc, tag) => {
      acc[tag.get('id')] = true;

      return acc;
    }, {});

    this.context.openPopup('TagsManagementPopup', {
      addNew: false,
      clientView: true,
      selectedTags,
      selectedModels,
      callback: updateProjectTags,
    });
  }

  handleErrorClose() {
    this.props.closeTagsErrors();
  }

  handleSort(e, { type, direction, field }) {
    if (type === 'targets') {
      this.props.sortTarget(field, direction);
    } else if (type === 'approvals') {
      this.props.sortApproval(field, direction);
    }
  }

  handleProjectInputChange({ event }) {
    const { name, value, checked } = event.target;

    this.props.updateProjectInfo(name, name === 'active' ? checked : value);
  }

  handleSuggestionInputClick({ event }) {
    event.stopPropagation();
    this.props.reset();
  }

  validate() {
    const { tags, setTagsValidationError } = this.props;
    let error = '';
    const businessModels = unwrap(tags.get(BUSINESS_MODELS));
    const industryCategories = unwrap(tags.get(INDUSTRY_CATEGORIES));

    if (!businessModels.size) {
      error = error.concat('Business Model is required\n');
    }

    if (!industryCategories.size) {
      error = error.concat('Industry Tags is required');
    }

    setTagsValidationError(error);

    return error.length === 0;
  }

  handleSave(form, cb) {
    const { match, savePage, saveApproval, saveUsers } = this.props;

    setTimeout(() => {
      if (match.params.id) {
        if (form === 'approvals') {
          saveApproval(cb);
        } else if (form === 'users') {
          saveUsers();
        } else {
          savePage(true); // true need to load data for a single project approvals
        }
      }
    }, 1);
  }

  handleDownload(event, { id }) {
    const { templates } = this.props;
    const template = templates.find(t => t.get('id') === id);

    if (template) {
      downloadFile({
        url: template.get('url'),
      });
    }
  }

  addEmptyRow(name) {
    if (name === 'users') {
      this.props.addEmptyUser();
    }
  }

  removeEmptyRow(name) {
    if (name === 'users') {
      this.props.removeEmptyUser();
    }
  }

  render() {
    if (!this.props.loggedUser) return null;
    if (!this.props.projectId) return null;

    return (
      <Project
        {...this.props}
        addEmptyRow={this.addEmptyRow}
        canEditData={this.getCanEditData()}
        onApprovalChange={this.handleApprovalChange}
        onDeleteApprovalRow={this.handleDeleteApprovalRow}
        onDeleteRow={this.handleRowDelete}
        onDownload={this.handleDownload}
        onErrorClose={this.handleErrorClose}
        onProjectInputChange={this.handleProjectInputChange}
        onProjectSuggestionSelect={this.handleProjectSuggestionSelect}
        onRowChange={this.handleRowChange}
        onRowClick={this.handleRowClick}
        onRowDoubleClick={this.handleRowDoubleClick}
        onRowInsert={this.handleRowInsert}
        onSave={this.handleSave}
        onSort={this.handleSort}
        onSuggestionClose={this.handleSuggestionClose}
        onSuggestionInputClick={this.handleSuggestionInputClick}
        onSuggestionSelect={this.handleSuggetionSelect}
        onTextChange={this.handleTextChange}
        onUpdateTags={this.handleUpdateTags}
        removeEmptyRow={this.removeEmptyRow}
        UserEditor={UserEditor}
      />
    );
  }
}

AddOnProjectCustomContainer.propTypes = {
  approvals: PropTypes.instanceOf(Immutable.List).isRequired,
  common: PropTypes.instanceOf(Immutable.Map).isRequired,
  isDuplicateCompany: PropTypes.bool.isRequired,
  loggedUser: PropTypes.instanceOf(Immutable.Map),
  projectData: PropTypes.instanceOf(Immutable.Map).isRequired,
  savePending: PropTypes.bool.isRequired,
  tags: PropTypes.instanceOf(Immutable.Map).isRequired,
  targets: PropTypes.instanceOf(Immutable.List).isRequired,
  templates: PropTypes.instanceOf(Immutable.List).isRequired,
  users: PropTypes.instanceOf(Immutable.List).isRequired,
};

AddOnProjectCustomContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  hasPopup: PropTypes.func.isRequired,
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
  addValidateCallBacks: PropTypes.func,
  deleteValidateCallbacks: PropTypes.func,
};

const mapStateToProps = state => {
  const { templatePopup, ...rest } = state.targetCompany.buyer.addOnProject;

  return {
    ...rest,
    loggedUser: state.auth.get('user'),
    projectId: Number(state.targetCompany.buyer.addOnProject.projectId),
    savePending: detectDirty(state.targetCompany.buyer.addOnProject),
    templatePopup,
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  };
};

export default connect(
  mapStateToProps,
  {
    handleAbortRequest,
    loadPage,
    savePage,
    reset,
    selectUserSuggestion,
    sortTarget,
    sortApproval,
    updateProjectInfo,
    selectProjectSuggestion,
    deleteUserRow,
    clearError,
    updateApprovals,
    insertApprovalRow,
    switchApprovalRowToEdit,
    changeToEditMode,
    updateTemplateName,
    switchTemplateRowToEdit,
    deleteTemplateRow,
    clearTemplatePopupError,
    saveApproval,
    saveUsers,
    addEmptyUser,
    removeEmptyUser,
    deleteApproval,
    loadApprovals,
    updateProjectTags,
    setTagsValidationError,
    closeTagsErrors,
    revertChanges,
  },
  mergeProps,
  connectOptions,
)(AddOnProjectCustomContainer);
