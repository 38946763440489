import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import FormGroup from '../helpers/FormGroup';
import _Input from '../helpers/Input';
import { InputProps } from '../helpers/AutoComplete';
import Span from '../helpers/Span';
import _Select from '../helpers/Select';
import { getIn } from '../../utils/ChangeSpy';
import { isResearcher } from '../../utils/checkPermissions';
import If from '../helpers/If';

import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import { CompanyNDA } from './CompanyNDA';

const Input = PrettyError(DisplayError(_Input));
const Select = PrettyError(DisplayError(_Select));

const CompanyStatus = (props, context) => {
  const {
    isUnapproved,
    companyInfo,
    suggestAnalyst,
    suggestDealMaker,
    suggestDirector,
    statuses,
    onChange,
    onRef,
    hasPermissions,
    currentStatus,
    currentUser,
    canEditData,
    lastEventBuyerText,
    isLoadingLeadMemoReport,
    onLeadMemoReport,
    isDuplicateCompany,
  } = props;
  const highStatus = getIn(companyInfo, 'buyers.0.highStatus');
  const statusIndex = statuses.findIndex(status => getIn(status, ['value']) === highStatus);
  const title =
    statusIndex === -1 ? statuses.getIn([0, 'name'], 'Statuses not found') : statuses.getIn([statusIndex, 'name']);
  const highestStatus = getIn(companyInfo, 'highestStatus.value');
  const researcher = isResearcher(currentUser.getIn(['roles', 0, 'slug'], null));
  let disabledStatuses = false;
  let defaultOption;
  let highestStatusText;

  if (highestStatus >= 5) {
    highestStatusText = Map({
      highestStatus: researcher ? 'Dialog' : highestStatus,
    });
  } else if (highestStatus >= 3) {
    highestStatusText = Map({
      highestStatus: researcher ? 'Research Complete' : highestStatus,
    });
  }

  if ((hasPermissions && isUnapproved) || (!hasPermissions && currentStatus > 1.4)) {
    disabledStatuses = true;

    if (currentStatus > 1.4 && currentStatus < 2) {
      defaultOption = 'Research Complete; Needs Internal Review';
    } else if (currentStatus >= 2 && currentStatus < 2.99) {
      defaultOption = 'Client No Interest';
    } else if (currentStatus >= 2.99 && currentStatus <= 10) {
      defaultOption = 'Research Complete';
    }
  }

  if (isDuplicateCompany) {
    disabledStatuses = true;
  }

  /** Open request NDA popup. */
  const openNdaPopup = () => {
    const { companyId } = props;

    context.openPopup('NdaRequestPopup', { companyId });
  };

  /** Open request NDA popup. */
  const openLeadMemo = () => {
    const { companyId } = props;

    onLeadMemoReport(companyId);
  };

  return (
    <form ref={onRef} className="d-flex form-inline status-bar">
      <FormGroup className="pl0 w160 form-inline-block">
        <InputProps
          className="form-control input-sm text-bold"
          disabled={!canEditData}
          name="target.suggestDirector"
          placeholder="Module"
        >
          {suggestDirector}
        </InputProps>
      </FormGroup>
      <FormGroup className="w160 form-inline-block">
        <InputProps
          className="form-control input-sm text-bold"
          disabled={!canEditData}
          name="target.suggestAnalyst"
          placeholder="Dealmaker 1"
        >
          {suggestAnalyst}
        </InputProps>
      </FormGroup>
      <FormGroup className="w160 form-inline-block">
        <InputProps
          className="form-control input-sm text-bold"
          disabled={!canEditData}
          name="target.suggestDealMaker"
          placeholder="Dealmaker 2"
        >
          {suggestDealMaker}
        </InputProps>
      </FormGroup>
      <FormGroup className="w260 form-inline-block">
        <Select
          defaultText={defaultOption}
          disabled={disabledStatuses}
          emptyVal=""
          label="Status"
          name="currentStatus"
          onChange={onChange}
          options={statuses}
          prefix="buyers.0"
          root={companyInfo}
          selectClassName="input-sm text-bold"
          deep
          showValue
        />
      </FormGroup>
      <FormGroup className="w160 form-inline-block">
        <Input
          className="input-sm bold-control-md"
          name="highestStatus"
          placeholder="High"
          root={highestStatusText || companyInfo}
          title={title}
          deep
          readOnly
        />
      </FormGroup>
      <If value={hasPermissions}>
        <FormGroup className="form-inline-block">
          <div className="company__nda-block">
            <CompanyNDA company={companyInfo} />
            <button
              className="btn btn-xs btn-default"
              disabled={isDuplicateCompany}
              onClick={openNdaPopup}
              type="button"
            >
              NDA
            </button>
            <button
              className="btn btn-xs btn-default"
              disabled={isLoadingLeadMemoReport || isDuplicateCompany}
              onClick={openLeadMemo}
              type="button"
            >
              Lead Memo
            </button>
          </div>
        </FormGroup>
      </If>
      <FormGroup className="flex-1 pr0 form-inline-block text-right">
        <Span deep="children" name="lastEventBuyerText" root={lastEventBuyerText} />
      </FormGroup>
      <div className="clearfix" />
    </form>
  );
};

CompanyStatus.propTypes = {
  isDuplicateCompany: PropTypes.bool.isRequired,
};

CompanyStatus.contextTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default CompanyStatus;
