import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, memo } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../helpers/AgGridTable';
import { handleSortModelChanged } from '../../utils/sorting';
import { DateRenderer } from './DateRenderer';
import { IconRenderer } from './IconRenderer';

const MailingHistoryTableComponent = ({ columnDefs, data, onChange, onDoubleClickRow, onGetNextPageData }) => {
  const gridApiRef = useRef(null);

  const getRowNodeId = useCallback((row) => row.order, []);

  const processedColumnDefs = useMemo(() => {
    return columnDefs.map((columnDef) => {
      const field = columnDef.get('field');

      switch (field) {
        case 'icon':
          return columnDef.set('cellRendererFramework', IconRenderer);
        case 'date':
          return columnDef.set('cellRendererFramework', DateRenderer);
        default:
          return columnDef;
      }
    });
  }, [columnDefs]);

  const handleGridReady = useCallback((params) => {
    gridApiRef.current = params.api;
    params.api.sizeColumnsToFit();
  }, []);

  const handleSort = useCallback(
    (sortModel) => {
      handleSortModelChanged(sortModel, data, onChange);
    },
    [data, onChange]
  );

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.sizeColumnsToFit();

      // TODO: need to check
      const sortModel = data.getIn(['sortBy', 'sortModel']);
      if (sortModel) {
        // gridApiRef.current.setSortModel(sortModel.toJS());
      }
    }
  }, [data]);

  return (
    <div className="MailingHistoryTable">
      <AgGridTable
        columnDefs={processedColumnDefs}
        getRowNodeId={getRowNodeId}
        isFetching={data.get('isFetching')}
        onGetNextPageData={onGetNextPageData}
        onGridReady={handleGridReady}
        onRowDoubleClicked={onDoubleClickRow}
        onSortModelChanged={handleSort}
        page={data.get('page')}
        rowData={data.get('queryResults')}
        totalPages={data.get('totalPages')}
        sortable
      />
    </div>
  );
};

MailingHistoryTableComponent.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
};

export const MailingHistoryTable = memo(MailingHistoryTableComponent);
