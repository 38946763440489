import React, { memo } from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';

import Approvals from './Approvals';
import { ProjectMenu } from './ProjectMenu';
import { Industries } from './Industries';
import Users from './Users';

const DELETE_MENU_ID = 'projectDeleteContextMenu';
const TEMPLATE_MENU_ID = 'projectTemplateContextMenu';

/**
 * Project component.
 *
 * @param props {Object}.
 * @param props.users {Immutable.List} User List.
 * @param props.approvals {Immutable.List} Approval List.
 * @param props.common {Immutable.Map} Common List.
 * @param props.approvalColumns {Immutable.List} Approval column configuration.
 * @param props.onSuggestionSelect {Function} Handle event of selecting a suggestion.
 * @param props.onDeleteRow {Function} Handle event of deleting a row.
 * @param props.onRowInsert {Function} Handle event of adding a new row.
 * @param props.onRowChange {Function} Handle event of changing text.
 * @param props.onRowClick {Function} Handle event of clicking on a table row.
 * @param props.onRowDoubleClick {Function} Handle event of double clicking on a table row.
 * @param props.onSort {Function} Handle event of sorting table.
 * @param props.onDownload {Function} Handle event of downloading template file.
 * @param {boolean} props.canEditData Whether user can edit any active element that can be changed or clicked.
 * @returns {React.Component}
 * @constructor
 */
const MidPanelComponent = ({
  users,
  approvals,
  canEditData,
  common,
  tags,
  onErrorClose,
  onSort,
  onSave,
  onRowClick,
  onRowDoubleClick,
  onRowInsert,
  onDeleteRow,
  onDeleteApprovalRow,
  onSuggestionSelect,
  onRowChange,
  onDownload,
  onApprovalChange,
  UserEditor,
  addEmptyRow,
  removeEmptyRow,
  onUpdateTags,
}) =>
  <div className="row project mb10">
    <Industries
      isFetching={tags.get('loading')}
      onErrorClose={onErrorClose}
      onUpdateTags={onUpdateTags}
      tags={tags}
    />
    <Users
      addEmptyRow={addEmptyRow}
      canEditData={canEditData}
      colClass="col-users"
      data={users}
      editor={UserEditor}
      isFetching={common.get('userLoading')}
      onDelete={onDeleteRow}
      onSave={onSave}
      onSuggestionSelect={onSuggestionSelect}
      removeEmptyRow={removeEmptyRow}
    />
    <Approvals
      approvals={approvals}
      canEditData={canEditData}
      common={common}
      onApprovalChange={onApprovalChange}
      onChange={onRowChange}
      onClick={onRowClick}
      onDelete={onDeleteApprovalRow}
      onRowDoubleClick={onRowDoubleClick}
      onRowInsert={onRowInsert}
      onSave={onSave}
      onSort={onSort}
    />
    <ProjectMenu id={DELETE_MENU_ID} onDelete={onDeleteRow} />
    <ProjectMenu id={TEMPLATE_MENU_ID} onDelete={onDeleteRow} onDownload={onDownload} isTemplate />
  </div>

MidPanelComponent.propTypes = {
  approvals: PropTypes.instanceOf(List).isRequired,
  canEditData: PropTypes.bool.isRequired,
  common: PropTypes.instanceOf(Map).isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onRowChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowInsert: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func,
  users: PropTypes.instanceOf(List).isRequired,
};

/** Mid panel component. */
export const MidPanel = memo(MidPanelComponent);
