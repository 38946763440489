import React, { useState, useEffect, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { List, fromJS } from 'immutable';

import { CompanyBuyerLeads } from '../../../components/Company/CompanyBuyer/CompanyBuyerLeads';
import { buildSortByParam } from '../../../helpers/paramBuilder';
import targetStatuses from '../../../utils/targetStatuses';
import { fetchTargetStatuses } from '../../../actions/statuses';
import { getBuyerTargets, updateLeads } from '../../../actions/company/buyer/leads';

const CompanyBuyerLeadsContainerComponent = ({ match }) => {
  const [statuses, setStatuses] = useState(List());

  const { companyId } = match.params;

  const leads = useSelector((state) => state.targetCompany.buyer.leads);
  const isDuplicateCompany = useSelector((state) => state.targetCompany.mergeInfo.get('isDuplicateCompany'));

  const dispatch = useDispatch();

  const fetchStatuses = useCallback(() => {
    dispatch(
      fetchTargetStatuses(({ response: { data } }) => {
        setStatuses(targetStatuses(fromJS(data)));
      })
    );
  }, [dispatch]);

  const loadBuyerTargets = useCallback(
    (options) => {
      dispatch(getBuyerTargets(options));
    },[dispatch]);

  const handleUpdateLeads = useCallback(
    (data) => {
      dispatch(updateLeads(data));
    }, [dispatch]);

  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    const sortBy = buildSortByParam(leads);

    loadBuyerTargets({
      companyId,
      current_status: 5,
      sortBy,
    });
  }, []);

  useEffect(() => {
    const oldSortBy = buildSortByParam(leads);
    const sortBy = buildSortByParam(leads);

    if (sortBy !== oldSortBy || leads.get('status') !== leads.get('status')) {
      loadBuyerTargets({
        companyId,
        page: 1,
        current_status: leads.getIn(['status', 'selected', 'value'], 5),
        sortBy,
      });
    }
  }, [loadBuyerTargets, companyId, leads]);

  const handleGetNextPageData = (page) => {
    const sortBy = buildSortByParam(leads);

    loadBuyerTargets({
      companyId,
      page,
      current_status: leads.getIn(['status', 'selected', 'value'], 5),
      sortBy,
    });
  };

  const handleDoubleClickRow = (row) => {
    window.open(`/company/${row.data.targetId}`, '_blank');
  };

  const handleCurrentStatusChange = (event) => {
    const { value } = event.target;

    handleUpdateLeads({
      filterName: 'status',
      filterData: {
        selected: {
          name: value,
          value,
        },
      },
    });
    const sortBy = buildSortByParam(leads);

    loadBuyerTargets({
      companyId,
      current_status: value,
      sortBy,
    });
  };

  const handleChange = (data) => {
    handleUpdateLeads({
      filterName: data.filterName,
      filterData: data.filterData,
    });
  };

  return (
    <div className="tab-pane active full-height flexItem flexWrapper" id="slide-3">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p0 full-height flexItem flexWrapper">
        <CompanyBuyerLeads
          isDuplicateCompany={isDuplicateCompany}
          leads={leads}
          onChange={handleChange}
          onCurrentStatusChange={handleCurrentStatusChange}
          onDoubleClickRow={handleDoubleClickRow}
          onGetNextPageData={handleGetNextPageData}
          statuses={statuses}
        />
      </div>
    </div>
  );
};

CompanyBuyerLeadsContainerComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const CompanyBuyerLeadsContainer = memo(CompanyBuyerLeadsContainerComponent);
