import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, memo } from 'react';
import { List } from 'immutable';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import Select from '../../helpers/Select';
import { sortByNumber } from '../../../utils/sorting';
import SorterRow from '../../helpers/SorterRow';

/**
 * Stateless component for sort panel.
 *
 * @param props {Object}.
 * @param props.sorts {Immutable.List} List of user's sorters.
 * @param props.onChangeSort {Function} To handle changing a sorting field.
 * @param props.onChangeOrder {Function} To handle changing sorting order.
 * @returns {React.Component} Component.
 */
const SorterComponent = ({ sorts, onChangeSort, onChangeOrder }) => {
  const getColumns = useCallback((sorts) => {
    return sortByNumber(
      sorts.filter((sort) => sort.get('included') === true),
      'order',
      -1
    )
      .map((column) => ({
        name: column.get('si'),
        text: column.get('text'),
        direction: column.get('sd') || 1,
      }))
      .toJS();
  }, []);

  const [items, setItems] = useState(() => getColumns(sorts));

  useEffect(() => {
    setItems(getColumns(sorts));
  }, [sorts]);

  const mapToSorts = useCallback((items) => {
    const htbItems = {};
    for (let i = 0; i < items.length; i++) {
      htbItems[items[i].name] = i;
    }
    return htbItems;
  }, []);

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setItems((prevItems) => {
      const newItems = arrayMoveImmutable(prevItems, oldIndex, newIndex);
      onChangeOrder(mapToSorts(newItems));
      return newItems;
    });
  };

  return (
    <>
      <p>Sort Order</p>
      <SortableList
        distance={1}
        items={items}
        lockAxis="y"
        onChangeSort={onChangeSort}
        onSortEnd={handleSortEnd}
      />
      <Select
        defaultText="Add..."
        id="sortFieldSelect"
        label="Sort fields"
        name="sorts"
        nameKey="text"
        onChange={(event) =>
          onChangeSort(event.target.value, 1, true, items.length)
        }
        options={sorts}
        value="Add..."
        valueKey="si"
      />
    </>
  );
};

const SortableList = SortableContainer(({ items, onChangeSort }) => (
  <div className="sort-menu">
    {items.map((item, index) => (
      <SorterRow
        key={index}
        childProps={{
          columnName: item.name,
          direction: item.direction,
          onChangeSort,
        }}
        index={index}
      >
        {item.text}
      </SorterRow>
    ))}
  </div>
));

SorterComponent.propTypes = {
  onChangeOrder: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  sorts: PropTypes.instanceOf(List).isRequired,
};

export const Sorter = memo(SorterComponent);
