import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import { removeRuntimeError, addRuntimeError } from '../actions/applicationErrors';

/**
 * Wrapper that displays Runtime's errors.
 */
const RuntimeErrorDisplayWrapper = ({
  children,
  global = false,
  errors,
  onDevOnly = false,
  addRuntimeError,
  removeRuntimeError,
}, context) => {
  useEffect(() => {
    if (global) {
      const handleError = (error) => {
        if (
          error.message === 'ResizeObserver loop completed with undelivered notifications.' ||
          error.message === 'ResizeObserver loop limit exceeded'
        ) {
          error.stopImmediatePropagation();
        } else {
          addRuntimeError({ error });
        }
      };

      window.addEventListener('error', handleError);

      return () => {
        window.removeEventListener('error', handleError);
      };
    }
  }, [global, addRuntimeError]);

  useEffect(() => {
    if (errors.size === 0 || context.hasPopup()) return;

    const error = errors.get(0);

    if (onDevOnly && process.env.NODE_ENV === 'production') {
      removeRuntimeError({ error });
      return;
    }

    openPopup(error);
  }, [errors, onDevOnly, removeRuntimeError, context]);

  const openPopup = (error) => {
    context.openPopup('RuntimeErrorPopup', {
      error,
    });

    context.onClosePopup(() => {
      handleClose(error);
    });
  };

  const handleClose = (error) => {
    removeRuntimeError({ error });
  };

  return <div className="full-height">{children}</div>;
};

RuntimeErrorDisplayWrapper.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  hasPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

RuntimeErrorDisplayWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  global: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  onDevOnly: PropTypes.bool,
  addRuntimeError: PropTypes.func.isRequired,
  removeRuntimeError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.applicationErrors.get('runtime'),
});

export { RuntimeErrorDisplayWrapper };
export default connect(
  mapStateToProps,
  { removeRuntimeError, addRuntimeError },
  mergeProps,
  connectOptions
)(RuntimeErrorDisplayWrapper);
