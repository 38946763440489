import PropTypes from 'prop-types';
import React, { memo, useRef } from 'react';
import { List } from 'immutable';
import classNames from 'classnames';
import { ResizableBox } from 'react-resizable';

import { CompanyDealNote } from './CompanyDealNote';

/**
 * Component is used to display list of deal notes.
 *
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onToggle {Function} Toggle deal note section.
 * @param props.isClosed {Boolean} Set to true will close note section.
 * @param props.dealNotes {Immutable.List} List of Maps, that should describe note. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is deal note's eventId.
 * @returns {XML}
 * @class
 */
const CompanyDealNotesComponent = ({
  onEditEvent,
  onMouseEnter,
  onMouseLeave,
  onToggle,
  isClosed,
  dealNotes,
  dealNoteSize,
  onChange,
}) => {
  const panelRef = useRef()

  const onResizeStop = (event, data) => {
    if (onChange) {
      onChange({ target: { name: 'dealNotes', value: data } });
    }
  }

  if (isClosed) {
    return (
      <div className="dn-panel note-close react-resizable">
        <div className="switcher" onClick={onToggle}>
          <i aria-hidden="true" className="fa fa-caret-square-o-right fa-2x" />
        </div>
        <div ref={panelRef} className="content" />
        <span className="react-resizable-handle" />
      </div>
    );
  }

  const nonEmptyNotes = dealNotes.filter(note => note.get('content') !== '<p><br></p>'); // Check if the wysiwyg text not empty.

  const content =
    nonEmptyNotes.size === 0 ? (
      <p>No rows to display</p>
    ) : (
      nonEmptyNotes.map(note => (
        <CompanyDealNote
          key={note.get('eventId')}
          note={note}
          onEditEvent={onEditEvent}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      ))
    );
  const className = classNames('dn-panel', {
    'note-close': isClosed,
    normal: !isClosed,
  });
  const boxProps = {
    axis: 'x',
    minConstraints: dealNoteSize.minConstraints,
    maxConstraints: [window.innerWidth - 100, dealNoteSize.height],
    width: dealNoteSize.width,
    height: dealNoteSize.height,
  };

  return (
    <ResizableBox {...boxProps} className={className} onResizeStop={onResizeStop}>
      <div className="switcher" onClick={onToggle}>
        <i aria-hidden="true" className="fa fa-caret-square-o-left fa-2x" />
      </div>
      <div ref={panelRef} className="content">
        {content}
      </div>
    </ResizableBox>
  );
}

CompanyDealNotesComponent.propTypes = {
  dealNotes: PropTypes.instanceOf(List).isRequired,
  dealNoteSize: PropTypes.object.isRequired,
  isClosed: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

/** Company deal notes component. */
export const CompanyDealNotes = memo(CompanyDealNotesComponent);
