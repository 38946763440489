import React, { memo } from 'react';

/**
 * Company label component.
 * @param props.note {Immutable.Map} Note.
 */
const CompanyLabelComponent = ({ note }) => {
  const name = note.get('legalName', '');

  if (!name) return <span />;

  const projectName = note.get('projectName', '');

  if (!projectName) return <span className="text-muted">({name})</span>;

  return (
    <span className="text-muted">
      ({name} - {projectName})
    </span>
  );

};

/** Company label component. */
export const CompanyLabel = memo(CompanyLabelComponent);
