import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map, List } from 'immutable';

import connectOptions, { mergeProps } from '../../../utils/connectOptions';
import Marketing from '../../../components/Company/CompanyBuyer/Marketing';
import copyToClipboard from '../../../utils/copyToClipboard';
import { checkAccess } from '../../../utils/checkPermissions';
import prepareDetail from '../target/entitiesApiPrepape';
import confirm from '../../decorators/confirm';
import stopPropagation from '../../../decorators/stopPropagation';
import { orderContactService } from '../../services/contact';
import { fetchEmailTemplates } from '../../../actions/emailTemplates';
import { enterCompanyDealNote, leaveCompanyDealNote } from '../../../actions/company/companyEvents';
import configureEmailMergedFields from '../../../utils/configureEmailMergedFields';
import getContactMailLink from '../../../utils/getContactMailLink';
import {
  openContact,
  handleUpdateCompany,
  addDetailEntity,
  delDetailEntity,
  startEditDetailEntity,
  saveInfo,
  closeValidationError,
  saveCompanyDetailEntity,
  delContactChannel,
  delContact,
  reorderContacts,
  reorderChannels,
  findDirectors,
  findUsers,
  revertChanges,
  deleteFile,
  getFiles,
  uploadFile,
} from '../../../actions/company/buyer/marketing';
import { unwrap, getChanged, isDeepChanged, toJS, getIn } from '../../../utils/ChangeSpy';
import { saveBIC, updateBuyerIndustries } from '../../../actions/company/buyer/criteria';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES } from '../../../utils/industryTagsHelper';
import AutoCompleteContainer from '../../AutoComplete';

const emptyList = List();

const openEmail = (event, { fullLink }) => {
  window.location.href = fullLink;
};

class MarketingContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onMarketingSubmit = this.onMarketingSubmit.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onDetailSubmit = this.onDetailSubmit.bind(this);
    this.bindMouseEvents();
    this.bindContact(context);

    this.onDetailAdd = this.onDetailAdd.bind(this);
    this.handleUpdateTags = this.handleUpdateTags.bind(this);
    this.onDetailDel = stopPropagation(confirm('Delete this?', this.onDetailDel.bind(this), context));
    this.onDetailChange = this.onDetailChange.bind(this);
    this.onDetailEdit = this.onDetailEdit.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.clearFormError = this.clearFormError.bind(this);
    this.reorderContacts = this.reorderContacts.bind(this);
    this.reorderChannels = this.reorderChannels.bind(this);
    this.handleOpenEmail = this.handleOpenEmail.bind(this);
    this.getEmailMergedFields = this.getEmailMergedFields.bind(this);
    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.handleDeleteFile = stopPropagation(confirm('Delete file?', this.handleDeleteFile.bind(this), context));
    this.uploadFile = this.uploadFile.bind(this);

    this.state = {
      hasPermissions: false,
      industryCategory: '',
      businessModel: '',
    };
  }

  componentWillUnmount() {
    this.context.removeOnSaveCallback(this.onMarketingSubmit);
    this.props.revertChanges();
  }

  componentDidMount() {
    const {
      getFiles,
      match: {
        params: { companyId },
      },
    } = this.props;

    const hasPermissions = checkAccess(this.context.currentUser.getIn(['roles', 0]));

    this.setState({ hasPermissions });
    this.context.addOnSaveCallback(this.onMarketingSubmit);

    getFiles({ companyId });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
    };
  }

  bindContact(context) {
    this.addContact = this.addContact.bind(this);
    this.openContact = this.openContact.bind(this);
    this.delContact = stopPropagation(confirm('Delete contact?', this.delContact.bind(this), context));
    this.delContactChannel = stopPropagation(
      confirm('Delete this field from contact?', this.delContactChannel.bind(this), context),
    );
    this.editContact = this.editContact.bind(this);
    this.editContactChannel = this.editContactChannel.bind(this);
  }

  onErrorClose(event, field) {
    this.props.closeValidationError({ field });
  }

  bindMouseEvents() {
    this.onDialNotesMouseEnter = this.onDialNotesMouseEnter.bind(this);
    this.onDialNotesMouseLeave = this.onDialNotesMouseLeave.bind(this);
    this.onEmailMouseEnter = this.onEmailMouseEnter.bind(this);
    this.onEmailMouseLeave = this.onEmailMouseLeave.bind(this);
  }

  getCanEditData() {
    return !this.props.isDuplicateCompany;
  }

  /**
   * Checks industry tags validation and adds error string to state, if tags are not valid.
   *
   * @param ids Industry categories ids.
   * @param businessModelIds Business models ids.
   * @returns {boolean} Validation status.
   */
  checkIndustriesValidation(ids, businessModelIds) {
    let result = true;

    if (!ids.length) {
      this.setState({
        industryCategory: 'Industry Tags is required',
      });
      result = false;
    }

    if (!businessModelIds.length) {
      this.setState({
        businessModel: 'Business Model is required',
      });
      result = false;
    }

    return result;
  }

  handleUpdateTags() {
    const { criteria, updateBuyerIndustries } = this.props;
    const tags = unwrap(criteria.get(INDUSTRY_CATEGORIES));
    const models = unwrap(criteria.get(BUSINESS_MODELS));

    this.context.openPopup('TagsManagementPopup', {
      addNew: false,
      clientView: true,
      selectedTags: tags.reduce((acc, t) => {
        acc[t.get('id')] = true;

        return acc;
      }, {}),
      selectedModels: models.reduce((acc, m) => {
        acc[m.get('id')] = true;

        return acc;
      }, {}),
      callback: (tagSelecteds, modelSelecteds) => {
        updateBuyerIndustries(tagSelecteds, modelSelecteds);
      },
    });
  }

  clearFormError() {
    this.setState({
      businessModel: '',
      industryCategory: '',
    });
  }

  onMarketingSubmit(event) {
    event.preventDefault();
    if (!this.props.company.get('isValid')) return;

    const body = getChanged(this.props.company);
    const tags = getChanged(this.props.criteria);

    const industryCategories = toJS(this.props.criteria.get(INDUSTRY_CATEGORIES));
    const businessModels = toJS(this.props.criteria.get(BUSINESS_MODELS));

    const criteriaBody = { businessModels, industryCategories };

    if (
      this.checkIndustriesValidation(
        industryCategories.map(({ id }) => id),
        businessModels.map(({ id }) => id),
      )
    ) {
      const { companyId } = this.props.match.params;

      if (Object.keys(tags).length) {
        const bic = this.props.criteria.get('bic');

        this.props.saveBIC({
          companyId,
          data: bic
            .filter(b => b.get('industryCategoryId') > 0)
            .map(b => ({
              industryCategoryId: getIn(b, 'industryCategoryId', ''),
              contactId: getIn(b, 'contactId') || null,
              notes: getIn(b, 'notes') || '',
              priority: 0,
            }))
            .toJS(),
          afterSuccess: () => {},
          tags: criteriaBody,
        });
      }

      if (Object.keys(body).length) {
        if ('buyerLastResearchedDate' in body) {
          try {
            body.buyerLastResearchedDate = body.buyerLastResearchedDate.format('YYYY-MM-DD');
          } catch (err) {
            console.error(err);
          }
        }

        this.props.saveInfo({ companyId, body });
      }

      this.onDetailSubmit();
    }
  }

  /** Deal Notes methods */
  onDialNotesMouseEnter(event, eventId) {
    this.props.enterCompanyDealNote({ eventId });
  }

  onDialNotesMouseLeave(event, eventId) {
    this.props.leaveCompanyDealNote({ eventId });
  }

  /** Emails methods */
  onEmailMouseEnter(event, eventId) {
    this.props.enterCompanyEmail({ eventId });
  }

  onEmailMouseLeave(event, eventId) {
    this.props.leaveCompanyEmail({ eventId });
  }

  openContact(event, index) {
    this.props.openContact({ index });
  }

  onChange({ name, value }) {
    this.props.handleUpdateCompany({ name, value });
  }

  static copyContact(event, params) {
    event.stopPropagation();
    copyToClipboard(params.value);
  }

  delContact(event, contact) {
    const contactId = contact.id;
    const { entityContactsId } = contact;
    const { companyId } = this.props.match.params;

    this.props.delContact({ contactId, entityContactsId, companyId }, this.context.closePopup);
  }

  delContactChannel(event, channelType, channelIndex) {
    const contactId = this.props.contacts.getIn([this.props.openedContact, 'id']);
    const channelId = this.props.contacts.getIn([this.props.openedContact, channelType, channelIndex, 'id']);

    this.props.delContactChannel({ contactId, type: channelType, channelId }, this.context.closePopup);
  }

  editContact() {
    const { openedContact } = this.props;

    this.context.openPopup('EditContactPopup', {
      openedContact,
      mode: 'buyer',
    });
  }

  addContact() {
    this.context.openPopup('AddCompanyContactPopup', { mode: 'buyer' });
  }

  editContactChannel(channelType, channelIndex) {
    const { openedContact } = this.props;
    const contactId = this.props.contacts.getIn([openedContact, 'id']);
    const channelId = this.props.contacts.getIn([openedContact, channelType, channelIndex, 'id']);

    if (channelType === 'phones') {
      this.context.openPopup('EditPhoneContactChannelPopup', {
        contactId,
        channelType,
        channelIndex,
        openedContact,
        channelId,
        mode: 'buyer',
      });
    } else {
      this.context.openPopup('EditEmailContactChannelPopup', {
        contactId,
        channelType,
        channelIndex,
        openedContact,
        channelId,
        mode: 'buyer',
      });
    }
  }

  /**
   * Get object with email's mergeable fields.
   *
   * @return {object} Mergeable fields.
   */
  getEmailMergedFields() {
    const { companyInfo, contacts, activeBuyer, companyTarget } = this.props;
    const contact = companyInfo.getIn(['entityContacts', 0]);
    const products = this.__details.get('targetProducts');
    const markets = this.__details.get('targetMarkets');

    return configureEmailMergedFields(contact, contacts, activeBuyer, companyInfo, markets, products, companyTarget);
  }

  /**
   * Parse email fields and open mailto link.
   *
   * @param {string} email Recipient email.
   * @param {Map} template Email data.
   */
  handleOpenEmail(email, template) {
    const { user, contacts, openedContact } = this.props;
    const mergedFields = this.getEmailMergedFields();
    const contact = contacts.get(openedContact);
    const mailLink = getContactMailLink(email, template, mergedFields, user, contact);

    window.open(mailLink, '_blank');
  }

  onDetailAdd(event, detailName) {
    this.props.addDetailEntity({ name: detailName });
  }

  onDetailDel(event, detailName, data) {
    const id = unwrap(data.id);
    const { companyId } = this.props.match.params;
    const entities = this.props.company.get(detailName);
    const body = toJS(entities)
      .filter(entity => entity.id !== id)
      .map(entity => {
        Object.keys(entity).forEach(key => {
          if (entity[key] instanceof moment) {
            try {
              entity[key] = entity[key].format('YYYY-MM-DD');
            } catch (err) {
              console.error("Can't transform ", key, entity[key], err);
            }
          }
        });

        return entity;
      });

    this.props.delDetailEntity(
      {
        name: detailName,
        id,
        companyId,
        body,
        realBody: prepareDetail(detailName, body, this.context.currentUser.get('id')),
      },
      this.context.closePopup,
    );
  }

  onDetailEdit(event, detailName, detailIndex) {
    this.props.startEditDetailEntity({ name: detailName, index: detailIndex });
  }

  onDetailChange(event) {
    const {
      target: { name, value },
    } = event;

    this.onChange({ name, value });
  }

  onDetailSubmit() {
    const detailName = 'harvcoTags';
    const base = this.props.company.get(detailName);
    const { companyId } = this.props.match.params;

    if (!isDeepChanged(base)) return;
    if (!this.props.company.get(`${detailName}IsValid`, true)) return;

    const body = toJS(base);

    body.forEach(entity => {
      Object.keys(entity).forEach(key => {
        if (entity[key] instanceof moment) {
          try {
            entity[key] = entity[key].format('YYYY-MM-DD');
          } catch (err) {
            console.error("Can't transform ", key, entity[key], err);
          }
        }
      });
    });
    this.props.saveCompanyDetailEntity({
      companyId,
      name: detailName,
      body,
      realBody: prepareDetail(detailName, body, this.context.currentUser.get('id')),
    });
  }

  UNSAFE_componentWillReceiveProps(oldProps) {
    ['harvcoTags'].some(key => {
      if (oldProps.company.get(key) !== this.props.company.get(key)) {
        this.__details = null;

        return true;
      }

      return false;
    });
  }

  getDetails() {
    this.__details = this.__details || this.props.company.filter((v, key) => ['harvcoTags'].indexOf(key) !== -1);

    return this.__details;
  }

  getDetailsCallback() {
    this.__detailsCallback = this.__detailsCallback || {
      onAdd: this.onDetailAdd,
      onDelete: this.onDetailDel,
      onChange: this.onDetailChange,
      onClick: this.onDetailEdit,
    };

    return this.__detailsCallback;
  }

  static getCompanyName(company) {
    return unwrap(company.get('abbrName')) ? company.get('abbrName') : company.get('legalName');
  }

  static getBuyerName(buyer) {
    if (!buyer) return null;

    return unwrap(buyer.get('dsplBuyerAbbrName')) ? buyer.get('dsplBuyerAbbrName') : buyer.get('dsplBuyerLegalName');
  }

  onFormChange({ target: { name, value } }) {
    this.onChange({ name, value });
  }

  reorderContacts(contacts) {
    const { companyId } = this.props.match.params;
    const sendToApi = contacts
      .map(contact => ({
        id: contact.get('id'),
        entityContactsId: contact.get('entityContactsId'),
      }))
      .toJS();

    this.props.reorderContacts(contacts, companyId, sendToApi);
  }

  reorderChannels(contacts) {
    const { openedContact } = this.props;

    this.props.reorderChannels(contacts, openedContact);
  }

  onSuggestChange({ name, value }) {
    this.onChange({ name, value });
  }

  getSuggest() {
    const { company, findUsers } = this.props;

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={findUsers}
        idName="buyerLastResearcherId"
        idValue={company.get('buyerLastResearcherId')}
        suggestions={company.get('suggests')}
        suggestsName="suggests"
        value={company.get('suggestResearcher')}
        valueName="suggestResearcher"
      />
    );
  }

  handleDeleteFile(event, file) {
    const {
      deleteFile,
      getFiles,
      match: {
        params: { companyId },
      },
    } = this.props;

    deleteFile(
      {
        id: file.get('id'),
        companyId: file.get('companyId'),
      },
      () => {
        this.context.closePopup();
        getFiles({ companyId });
      },
    );
  }

  uploadFile(event) {
    const { currentTarget } = event;
    const formData = new FormData(currentTarget);

    event.preventDefault();

    const { companyId } = this.props.match.params;
    const afterSuccess = () => currentTarget.reset();

    this.props.uploadFile({ companyId, formData, afterSuccess });
  }

  render() {
    if (!this.props.company.get('loaded')) return null;

    const {
      fetchEmailTemplates,
      openedContact,
      contacts,
      companyName,
      company,
      criteria,
      buyerType,
      dealNotes,
      findDirectors,
      emailTemplates,
      files,
    } = this.props;

    const contactsProps = {
      reorderContacts: this.reorderContacts,
      reorderChannels: this.reorderChannels,
      companyName,
      contacts,
      buyerName: MarketingContainer.getBuyerName(company),
      addContact: this.addContact,
      delContact: this.delContact,
      opened: openedContact,
      openContact: this.openContact,
      onCopy: MarketingContainer.copyContact,
      onDel: this.delContactChannel,
      onEdit: this.editContact,
      openEmail,
      onEditChannel: this.editContactChannel,
      onOpenEmail: this.handleOpenEmail,
    };

    const businessModels = criteria.get(BUSINESS_MODELS);
    const industryCategories = criteria.get(INDUSTRY_CATEGORIES);
    const detailsCallbacks = this.getDetailsCallback();
    const suggestResearcher = this.getSuggest();

    return (
      <Marketing
        businessModels={businessModels}
        buyerType={buyerType}
        canEditData={this.getCanEditData()}
        companyInfo={company}
        contactsProps={contactsProps}
        currentUser={this.context.currentUser}
        dealNotes={dealNotes}
        details={this.getDetails()}
        detailsCallbacks={detailsCallbacks}
        emailTemplates={emailTemplates}
        error={{
          businessModel: this.state.businessModel,
          industryCategory: this.state.industryCategory,
        }}
        fetchEmailTemplates={fetchEmailTemplates}
        files={files}
        findDirectors={findDirectors}
        hasPermissions={this.state.hasPermissions}
        industryCategories={industryCategories}
        onChange={this.onFormChange}
        onClick={this.handleUpdateTags}
        onDeleteFile={this.handleDeleteFile}
        onEditEvent={this.context.onEditEvent}
        onEnterDealNotes={this.onDialNotesMouseEnter}
        onErrorClose={this.clearFormError}
        onLeaveDealNotes={this.onDialNotesMouseLeave}
        statuses={company.get('statuses')}
        suggestResearcher={suggestResearcher}
        uploadFile={this.uploadFile}
      />
    );
  }
}

function mapStateToProps(state) {
  const company = state.targetCompany.buyer.marketing;
  const { criteria } = state.targetCompany.buyer;
  const companyInfo = state.targetCompany.info.get('info');
  const companyTarget = state.targetCompany.target;
  const activeBuyer = companyTarget.get('buyers').find(buyer => unwrap(buyer.get('activeBuyer')));

  return {
    user: state.auth.get('user'),
    activeBuyer,
    companyTarget,
    companyInfo,
    companyName: MarketingContainer.getCompanyName(companyInfo),
    company,
    criteria,
    buyerType: companyInfo.get('buyerType'),
    inputErrors: company.get('inputErrors'),
    files: company.get('files', emptyList),
    dealNotes: state.targetCompany.events.get('buyerDealNotes', emptyList),
    openedContact: company.get('openedContact', 0),
    contacts: company.get('contacts', emptyList),
    emailTemplates: state.emailTemplates.get('data', emptyList),
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  };
}

MarketingContainer.propTypes = {
  isDuplicateCompany: PropTypes.bool.isRequired,
};

MarketingContainer.contextTypes = {
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map),
  onEditEvent: PropTypes.func,
  openPopup: PropTypes.func,
  closePopup: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        enterCompanyDealNote,
        leaveCompanyDealNote,
        openContact,
        handleUpdateCompany,
        addDetailEntity,
        delDetailEntity,
        startEditDetailEntity,
        saveInfo,
        saveBIC,
        closeValidationError,
        saveCompanyDetailEntity,
        delContactChannel,
        delContact,
        reorderContacts,
        fetchEmailTemplates,
        findDirectors,
        findUsers,
        revertChanges,
        updateBuyerIndustries,
        deleteFile,
        getFiles,
        uploadFile,
      },
      dispatch,
    ),
    reorderChannels: orderContactService(dispatch, reorderChannels),
  };
}

MarketingContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
};
export { MarketingContainer };
export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(MarketingContainer);
