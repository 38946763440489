import React, { memo } from 'react';

import FormGroup from '../../helpers/FormGroup';
import _Checkbox from '../../helpers/Checkbox';
import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';

const Checkbox = PrettyError(DisplayError(_Checkbox));

const SharedDealNoteComponent = ({ buyer, onChangeCompanyIds }) => {
  let selectedCompaniesIdsList = [];

  if (buyer.get('subsidiaries').get(0) && buyer.get('subsidiaries').get(0).size > 0) {
    selectedCompaniesIdsList = [...buyer.get('subsidiaries')];
  }

  if (buyer.get('parent').size > 0) {
    selectedCompaniesIdsList.push(buyer.get('parent'));
  }

  if (selectedCompaniesIdsList.length === 0) return null;

  const companiesIdsList = selectedCompaniesIdsList.map(selectedCompaniesId => (
    <div key={selectedCompaniesId.get('id')} className="col-md-12">
      <FormGroup className="group-checkbox">
        <Checkbox
          label={selectedCompaniesId.get('legalName')}
          name="selectedCompaniesIds"
          onChange={onChangeCompanyIds}
          value={selectedCompaniesId.get('id')}
        />
      </FormGroup>
    </div>
  ));

  return (
    <div>
      <label>&nbsp;</label>
      {companiesIdsList}
    </div>
  );
};


export const SharedDealNote = memo(SharedDealNoteComponent);
