import PropTypes from 'prop-types';
import React, { PureComponent, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map, List } from 'immutable';
import classNames from 'classnames';
import Draggable from 'react-draggable';

import { ResizablePanel, PanelHeader, PanelBody } from '../helpers/ResizablePanel';
import Checkbox from '../helpers/Checkbox';
import Table, { Row } from '../helpers/TableToAgGridTable';
import { userSelector } from '../../selectors/user';
import { loadWidgetData } from '../../actions/dashboards';
import { isResearcher } from '../../utils/checkPermissions';

const COLOR_RED = '#ff0000';
const COLOR_GREEN = '#008000';
const TCPTitle =
  'This is the date associated with the last Approval List. Dates in Green mean this is the date the last list was sent, but it has not yet been returned. Dates in Red is the date the last Approval List was returned.';

function getColumnsTCP(columns) {
  return columns.map(row => ({
    ...row.toJS(),
    cellStyle(params) {
      if (params.column.colId === 'date') {
        const color = params.data.listType === 'green' ? COLOR_GREEN : COLOR_RED;

        return { color };
      }

      return null;
    },
    cellRenderer(params) {
      if (params.column.colId === 'date') {
        return `<span title='${TCPTitle}'>${params.value}</span>`;
      }

      if (params.column.colId === 'percentOfContacted') {
        if (params.value === '0') {
          return '';
        }

        return `<span>${params.data.contactedCount}(${params.value})</span>`;
      }

      return `<span>${params.value}</span>`;
    },
  }));
}

function getColumnsBacklog(columns) {
  return columns.map(row => ({
    ...row.toJS(),
    cellStyle(params) {
      if (params.data.periodType === 'future') {
        return { color: COLOR_GREEN };
      }

      if (params.data.periodType === 'past') {
        return { color: COLOR_RED };
      }
    },
    cellRenderer(params) {
      return `<span>${params.value}</span>`;
    },
  }));
}

class CheckboxCellRenderer extends PureComponent {
  render() {
    return <input defaultChecked={this.props.data.selected} onChange={this.props.onChange} type="checkbox" />;
  }
}

function getColumnsPD(columns, onChangePD) {
  return columns.map(row => ({
    ...row.toJS(),
    cellRendererFramework(params) {
      if (params.colDef.field === 'selected') {
        return <CheckboxCellRenderer data={params.data} onChange={event => onChangePD(event, params.data)} />;
      }

      return <span>{params.value}</span>;
    },
  }));
}

/**
 * For the Backlog widget get sum of Quantity column.
 *
 * @param {string} name Widget name.
 * @param {Map} widget Widget data.
 * @returns {string} String with the sum of quantity.
 */
function getQuantitySum(name, widget) {
  if (name !== 'backlog' || widget.get('data').size === 0) return;

  const data = widget.get('data').toJS();
  const quantitySum = data.reduce((sum, { targetCount }) => sum + targetCount, 0);

  return `(${quantitySum})`;
}

function renderColumns(name, columns, onChangePD) {
  switch (name) {
    case 'tcp':
      return getColumnsTCP(columns);

    case 'backlog':
      return getColumnsBacklog(columns);

    case 'pd':
      return getColumnsPD(columns, onChangePD);

    default:
      return columns;
  }
}

/**
 * Dashboard Row. Display data column by column.
 *
 * @param props {Object}.
 * @param props.data {Immutable.Map} Map with some data. Which of values will be displayed
 *    decides by `columns` prop.
 * @param props.columns {Immutable.List} List of columns to display. Every column should has
 *    an `key` property to provide, where in `props.data` value is placed.
 * @private
 * @returns {React.Component}
 */
class DashboardData extends PureComponent {
  constructor(props) {
    super(props);
    this.onGridReady = this.onGridReady.bind(this);
  }
  onGridReady(params) {
    this.api = params.api;
  }

  componentDidUpdate(oldProps) {
    if (this.api) {
      this.api.redrawRows();
      if (oldProps.loading !== this.props.loading) {
        this.api.sizeColumnsToFit();
      }
    }
  }

  render() {
    const {
      width,
      height,
      data,
      columns,
      onSort,
      onDoubleClick,
      onCellDoubleClick,
      name,
      clickable,
      loading,
      onColumnResize,
      onChangePD,
    } = this.props;
    const className = classNames({ clickable }, 'green');
    const rows = data.map((row, i) => {
      const updatedRow = row.set('#', i + 1);
      return <Row key={i} className={className} data={updatedRow} />;
    });
    return (
      <Table
        api={this.api}
        columns={renderColumns(name, columns, onChangePD)}
        height={height}
        loading={loading}
        onCellDoubleClicked={meta => onCellDoubleClick(meta, name)}
        onColumnResize={columns => onColumnResize(columns, name)}
        onGridReady={this.onGridReady}
        onRowDoubleClicked={meta => onDoubleClick(meta, name)}
        onSortModelChanged={sortOrder => onSort(sortOrder, name)}
        width={width}
        autoSizeColumnsForData
        enableSorting
        sortable
      >
        {rows}
      </Table>
    );
  }
}

/**
 * Dashboard Panel. Show "No rows to display" if `data` property is empty List.
 *
 * @param props {Object}.
 * @param props.title {String} Name of panel. Title is displayed at the top of the panel.
 * @param props.data {Immutable.List} List with some data to display. If empty - panels shows "No rows to display".
 * @param props.columns {Immutable.List} List of columns to display. Every column should has
 *    an `key` and `name` properties.
 * @returns {React.Component}
 */
function DashboardPanel(props) {
  const {
    widget,
    name,
    onRowDoubleClick,
    onCellDoubleClick,
    onColumnResize,
    onClose,
    onDragStart,
    onDragEnd,
    onResizeEnd,
    onSort,
    zIndex,
    onClick,
    isDataFiltered,
    onSwitchFilterData,
    onChangePD,
    onChangeAllPD,
    onConfirmDeletion,
    onConfirmReject,
  } = props;

  const dispatch = useDispatch();
  /**
   * User data.
   */
  const userData = useSelector(userSelector);

  /**
   * C1s widget user query selected.
   */
  const [tc1UserQuery, setTc1UserQuery] = useState(true);
  /**
   * C1s widget module query selected.
   */
  const [tc1ModuleQuery, setTc1ModuleQuery] = useState(false);

  /**
   * Has user access to dashboard panels computed value.
   */
  const hasAccess = useMemo(() => !isResearcher(userData.getIn(['roles', 0, 'slug'], null)), [userData]);

  /**
   * C1s widget query effect depends on module and user.
   */
  useEffect(() => {
    if (hasAccess) {
      const name = 'tc1';
      const user = userData.get('id');
      const module = userData.get('moduleId');

      if (tc1UserQuery && !tc1ModuleQuery) {
        dispatch(loadWidgetData({ name, query: { user } }));
      }

      if (tc1ModuleQuery && !tc1UserQuery) {
        dispatch(loadWidgetData({ name, query: { module } }));
      }

      if (tc1UserQuery && tc1ModuleQuery) {
        dispatch(loadWidgetData({ name, query: { user, module } }));
      }

      if (!tc1UserQuery && !tc1ModuleQuery) {
        dispatch(loadWidgetData({ name }));
      }
    }
  }, [tc1ModuleQuery, tc1UserQuery]);

  const visible = widget.get('visible');

  if (!visible) return null;

  const title = widget.get('title');
  const columns = widget.get('columns');
  const loading = widget.get('loading');
  const template = widget.get('urlTemplate');
  const data = widget.get('data', List());
  const style = {
    position: 'absolute',
    ...widget.get('style').toJS(),
  };
  let titleActions = null;

  const handleC1sQueryChange = event => {
    const { name, checked } = event.target;

    if (name === 'user') setTc1UserQuery(checked);
    if (name === 'module') setTc1ModuleQuery(checked);
  };

  if (title === 'Research') {
    titleActions = (
      <a className="ml10" href="#" onClick={onSwitchFilterData}>
        {isDataFiltered ? 'Show All' : 'Show My'}
      </a>
    );
  }

  if (title === 'Backlog') {
    titleActions = (
      <div className="backlog-captions">
        <span className="backlog-captions-square backlog-captions-future" />
        <span>- 10 days future Backlog</span>
        <span className="backlog-captions-square backlog-captions" />
        <span>- Current Backlog</span>
        <span className="backlog-captions-square backlog-captions-past" />
        <span>- 10 days past Backlog</span>
      </div>
    );
  }

  /**
   * "C1s (T)" widget header customization.
   */
  if (title === 'C1s (T)') {
    titleActions = (
      <div className="d-flex">
        <div className="ml20 mr20">
          <Checkbox label="Module" name="module" onChange={handleC1sQueryChange} />
        </div>
        <Checkbox label="User" name="user" onChange={handleC1sQueryChange} defaultChecked />
      </div>
    );
  }

  /**
   * "Deletion Requests" widget header customization.
   */
  if (title === 'Deletion Requests') {
    const isDisabled = data.filter(item => item.get('selected')).size === 0;

    titleActions = (
      <div className="d-flex">
        <div className="ml20 mr20 d-flex">
          <Checkbox label="Select All" name="pd" onChange={onChangeAllPD} />
        </div>
        <button className="ml20 mr20" disabled={isDisabled} onClick={onConfirmDeletion}>
          Confirm
        </button>
        <button disabled={isDisabled} onClick={onConfirmReject}>
          Reject
        </button>
      </div>
    );
  }

  return (
    <Draggable
      axis="both"
      defaultPosition={{ x: style.left, y: style.top }}
      handle=".handle-drag"
      onStart={event => onDragStart(event, name)}
      onStop={event => onDragEnd(event, name)}
      zIndex={zIndex}
    >
      <div style={{ zIndex }}>
        <ResizablePanel
          className="modal-container"
          height={style.height}
          name={name}
          onClick={event => onClick(event, name)}
          onResizeEnd={(...args) => onResizeEnd(...args, name)}
          width={style.width}
        >
          <PanelHeader className="handle-drag" onClick={event => onClick(event, name)} onClose={event => onClose(event, name)}>
            <div className="widget-header">
              <b>
                {title} {getQuantitySum(name, widget)}
              </b>
              {titleActions}
            </div>
          </PanelHeader>
          <PanelBody>
            <DashboardData
              clickable={!!template}
              columns={columns}
              data={data}
              height={`calc(${style.height}px - 34px)`}
              loading={loading}
              name={name}
              onCellDoubleClick={onCellDoubleClick}
              onChangePD={onChangePD}
              onColumnResize={onColumnResize}
              onDoubleClick={onRowDoubleClick}
              onSort={onSort}
              width={style.width}
            />
          </PanelBody>
        </ResizablePanel>
      </div>
    </Draggable>
  );
}

DashboardPanel.propTypes = {
  widget: PropTypes.instanceOf(Map).isRequired,
};

export default DashboardPanel;
