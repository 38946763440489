import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';

import config from '../../../../../config';

/**
 * Project text input component.
 *
 * @param props {Object}.
 * @param props.field {String} Field name.
 * @param props.onTextChange {Function} Handle event of changing text.
 * @param {boolean} props.canEditData Whether user can edit any active element that can be changed or clicked.
 * @returns {React.Component}
 * @class
 */
const ProjectTextInputComponent = ({ field, onTextChange, canEditData, className: cls }) => {
  const className = classNames(cls, 'form-control', {
    changed: field.get('dirty'),
  });
  const type = config.values.getIn(['project', 'types', 'PROJECT']);

  return (
    <div className="form-group">
      <label className="col-md-4 pl0" htmlFor="projectCategoryInput">
        Project Label
      </label>
      <div className="col-md-8">
        <input
          className={className}
          name="category"
          onChange={event => onTextChange({ event, data: { type } })}
          readOnly={!canEditData}
          type="text"
          value={field.get('name')}
        />
      </div>
    </div>
  );
};

ProjectTextInputComponent.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  field: PropTypes.instanceOf(Map).isRequired,
  onTextChange: PropTypes.func.isRequired,
};

/** Project text input component. */
export const ProjectTextInput = memo(ProjectTextInputComponent);
