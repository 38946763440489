import React from 'react';
import classNames from 'classnames';

import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import ButtonReal from '../helpers/Button';

import _Input from '../helpers/Input';
import _Select from '../helpers/Select';
import AutoComplete, { InputProps } from '../helpers/AutoComplete';
import uniqueId from '../../utils/uniqueId';
import { unwrap, isChanged } from '../../utils/ChangeSpy';
import LoadAnimation from '../decorators/LoadAnimation';
import DropDown from '../helpers/DropDown/Changable';
import { PhoneInput } from '../helpers/PhoneInput';

import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import { getTickerExchange } from '../Company/getTickerExchange';
import canHasParent from '../Company/canHasParent';

import _Board from './BIC';
import { createIndustryErrorMsg } from '../../utils/industryTagsHelper';

const Input = PrettyError(DisplayError(_Input));
const Select = PrettyError(DisplayError(_Select));

const Row = ({ children }) => <div className="row media-450">{children}</div>;

const Button = LoadAnimation(({ ...props }) => <ButtonReal {...props} />);

const Board = PrettyError(DisplayError(_Board));

const id = uniqueId();

const AddBuyerCompanyPopup = props => {
  const {
    suggestHarvco,
    company,
    isValid,
    onChange,
    entityTypes,
    states,
    onToggleCountry,
    suggestionProps,
    onParentCompanyClick,
    onCreate,
    submitting,
    onBlur,
    onErrorClose,
    error,
    tags,
    businessModels,
    onBICClick,
  } = props;

  const header = 'New Buyer Company Profile';
  const footer = (
    <Button
      className="btn-primary btn-xs"
      disabled={!isValid || submitting}
      form={id}
      loading={company.get('isFetching')}
      type="submit"
    >
      Create {submitting ? <i className="fa fa-spinner fa-spin" style={{ position: 'static' }} /> : null}
    </Button>
  );

  const showCountry = company.get('showCountry', false);

  const toggleCountryButton = (
    <i
      aria-hidden="true"
      className={classNames('fa', {
        'fa-minus': showCountry,
        'fa-plus': !showCountry,
      })}
    />
  );

  const countryWrapperClass = classNames('f-country col-lg-4 col-sm-4', {
    hide: !showCountry,
  });
  const countryClass = classNames('input-sm', { hidden: !showCountry });

  const parentCompany = company.get('parentCompany');
  const parentCompanyClass = canHasParent(company.get('companyType')) ? 'col-md-12 mt10' : 'hidden';

  const selectedCompany = parentCompany.get('selected');
  const numberEmployees = selectedCompany && selectedCompany.get('employees') ? selectedCompany.get('employees') : 0;
  const selectedCompanyInfo = selectedCompany ? (
    <div>
      {selectedCompany.get('companyType')} ${selectedCompany.get('revenue')} / {numberEmployees} emp.{' '}
      {selectedCompany.get('city')}, {selectedCompany.get('state')}
    </div>
  ) : null;

  const Ticker = getTickerExchange(company);
  const onSubmitProps = submitting ? {} : { onSubmit: onCreate };

  const errorMsg = createIndustryErrorMsg(error);

  return (
    <Popup footer={footer} header={header} id="newFundRecord">
      <form id={id} onChange={onChange} {...onSubmitProps}>
        <Row>
          <FormGroup className="col-lg-6 col-xs-6">
            <InputProps
              className="form-control input-sm"
              label="Primary Harvco Contact"
              placeholder="Harvco Contact"
              autoFocus
              required
            >
              {suggestHarvco}
            </InputProps>
          </FormGroup>
          <FormGroup className="col-lg-6 col-xs-6">
            <Select
              className="input-sm"
              label="Buyer Type"
              name="buyerType"
              onChange={onChange}
              options={company.get('buyerTypes')}
              root={company}
              deep
              required
              showLabel
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-lg-6 col-xs-6">
            <Input
              className="input-sm"
              label="Name"
              name="legalName"
              placeholder="Name"
              root={company}
              deep
              required
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-lg-6 col-xs-6">
            <Input className="input-sm" label="Abbr" name="abbrName" placeholder="Abbr" root={company} deep showLabel />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-lg-6 col-xs-6">
            <Input
              className="input-sm"
              label="DBA/Alt."
              name="dbaName"
              placeholder="DBA/Alt"
              root={company}
              deep
              showLabel
            />
          </FormGroup>

          <FormGroup className="col-lg-6 col-xs-6">
            <Input className="input-sm" label="Web" name="web" placeholder="Web" root={company} deep showLabel />
          </FormGroup>
        </Row>
        <Row>
          <div className="form-group col-lg-6 col-xs-6">
            <PhoneInput
              changed={isChanged(company.get('phone'))}
              error={error.phone}
              name="phone"
              onBlur={onBlur}
              onErrorClose={onErrorClose}
              value={unwrap(company.get('phone'))}
            />
          </div>
          <FormGroup className="col-lg-6 col-xs-6">
            <Input
              className="input-sm"
              label="Address/Alt."
              name="address"
              placeholder="Address"
              root={company}
              deep
              showLabel
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-lg-4 col-xs-4">
            <Input className="input-sm" label="City" name="city" placeholder="City" root={company} deep showLabel />
          </FormGroup>
          <FormGroup className="col-lg-4 col-xs-4">
            <label>State</label>
            <DropDown
              className="form-control"
              id="dropdownMenu2"
              name="state"
              onChange={onChange}
              options={states}
              root={company}
              deep
            />
          </FormGroup>

          <FormGroup className="mt17 city-index2 col-lg-4 col-xs-4">
            <div className="input-group">
              <Input
                className="input-sm"
                disabled={showCountry}
                error={error.zip}
                label="Zip"
                maxLength="30"
                name="zip"
                onBlur={onBlur}
                onFocus={() => {
                  onErrorClose();
                }}
                placeholder="Zip"
                root={company}
                deep
              />
              <span className="input-group-addon" onClick={onToggleCountry}>
                {toggleCountryButton}
              </span>
            </div>
          </FormGroup>
        </Row>

        <Row>
          <FormGroup className={countryWrapperClass}>
            <Input
              className={countryClass}
              label="Country"
              name="country"
              placeholder="Country"
              root={company}
              deep
              showLabel
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-lg-4 col-xs-4">
            <Select
              className="input-sm"
              label="Company Type"
              name="companyType"
              options={entityTypes}
              root={company}
              deep
              required
              showLabel
            />
          </FormGroup>
          <div className="col-md-8">
            <span>&nbsp;</span>
            <Ticker company={company} exchangeName="tickerExchange" symbolName="tickerSymbol" />
          </div>
        </Row>
        <Row>
          <div className={parentCompanyClass}>
            <AutoComplete {...suggestionProps}>
              <span className="input-group-addon">
                <i aria-hidden="true" className="fa fa-external-link" onClick={onParentCompanyClick} />
              </span>
            </AutoComplete>
            {selectedCompanyInfo}
          </div>
        </Row>
        <Row>
          <FormGroup className="col-lg-6 col-xs-6">
            <Board
              error={errorMsg}
              models={businessModels}
              onClick={onBICClick}
              onErrorClose={onErrorClose}
              tags={tags}
              canDisplayError
            />
          </FormGroup>
        </Row>
      </form>
    </Popup>
  );
};

export default AddBuyerCompanyPopup;
