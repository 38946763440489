import PropTypes from 'prop-types';

import React from 'react';
import Immutable from 'immutable';

import Popup from '../helpers/Popup';
import { InlineInput } from '../helpers/Input';
import FormGroup from '../helpers/FormGroup';
import Button from '../helpers/Button';
import { ComboSelectBox } from '../helpers/ComboSelectBox';
import withDoNothingOption from '../../hocs/withDoNothingOption';

const ComboSelectBoxWithDoNothingOption = withDoNothingOption(ComboSelectBox);

const MarkMailingCompletedPopup = props => {
  const {
    statusCodes,
    onUpdate,
    onClose,
    onStatusChange,
    onDateChange,
    formData,
    isPopupFetching,
    isPopupFetchingError,
  } = props;
  const header = 'Mark This Mailing as Completed';
  const footer = [
    <Button key="addTag" className="btn-primary btn-xs" onClick={onUpdate}>
      Update
    </Button>,
    <Button key="close" className="btn-default btn-xs" onClick={onClose}>
      Close
    </Button>,
  ];
  const loading = isPopupFetching ? (
    <div className="loading">
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    </div>
  ) : null;
  const errorContent = isPopupFetchingError ? (
    <div className="error text-danger">
      <p>{isPopupFetchingError.get('message')}</p>
      <ul>
        {isPopupFetchingError.get('errors', []).map((error, i) => (
          <li key={i}>{error}</li>
        ))}
      </ul>
    </div>
  ) : null;

  return (
    <Popup footer={footer} header={header} id="pushNextActionDatesPopup" loading={loading}>
      <div className="row media-450">
        <div className="col-xs-12">
          <div>
            This will update all of these companies to indicate that this mailing was completed on the following date:
          </div>
          <div className="form-inline row mt10">
            <FormGroup className="col-xs-4">
              <InlineInput
                label="Date"
                name="date"
                onChange={onDateChange}
                placeholder="date"
                type="date"
                value={formData.get('date')}
                autoFocus
                showLabel
              />
            </FormGroup>
            <FormGroup className="col-xs-4">
              <div>
                <label>New Status</label>
              </div>

              <ComboSelectBoxWithDoNothingOption
                className="na-cb-sel-box"
                name="nextActionStatus"
                onChange={onStatusChange}
                options={statusCodes}
                value={formData.getIn(['status', 'selected'])}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      {errorContent}
    </Popup>
  );
};

MarkMailingCompletedPopup.propTypes = {
  formData: PropTypes.instanceOf(Immutable.Map).isRequired,
  onClose: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  statusCodes: PropTypes.instanceOf(Immutable.List).isRequired,
};

export default MarkMailingCompletedPopup;
