import config from 'src/config/index';

import DateEditor from './DateEditor';
import TextEditor from './TextEditor';
import AgHeader from './AgHeader';
import { GeneralFormat } from './GeneralFormat';


/** Column defs. */
export const columnDef = (checkEditable, onInsert) =>
  config.tables.getIn(['project', 'approval', 'columns']).map(col => {
    const field = col.get('field');

    if (field === 'dateReceived') {
      return col.merge({
        editable: checkEditable,
        cellRendererFramework: GeneralFormat,
        headerComponentFramework: AgHeader,
        sortable: true,
        cellEditorFramework: DateEditor,
      });
    }

    if (field === 'dateSent') {
      return col.merge({
        editable: checkEditable,
        cellRendererFramework: GeneralFormat,
        headerComponentFramework: AgHeader,
        sortable: true,
        cellEditorFramework: DateEditor,
      });
    }

    if (field === 'applistLabel') {
      return col.merge({
        editable: checkEditable,
        cellEditorFramework: TextEditor,
        headerComponentFramework: AgHeader,
        onClick: onInsert,
        sortable: true,
        cellRendererFramework: GeneralFormat,
      });
    }

    if (field === 'numberApproved') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'Approved% = #Approved / #Targets',
      });
    }

    if (field === 'numberOfContacted') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'Contacted% = #Contacted / #Approved',
      });
    }

    if (field === 'numberOfLeads') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'Leads% = #Leads / #Approved',
      });
    }

    if (field === 'numberOfCc') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'CC% = #CC / #Approved',
      });
    }

    if (field === 'numberOfVisit') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'Visit% = #Visit / #Approved',
      });
    }

    if (field === 'numberOfNextActions') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'NAs% = #NAs / #Targets',
      });
    }

    if (field === 'numberOfPriorityA') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'A% = #A / #Targets',
      });
    }

    if (field === 'numberOfPriorityB') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'B% = #B / #Targets',
      });
    }

    if (field === 'numberOfPriorityC') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'C% = #C / #Targets',
      });
    }

    if (field === 'numberOfApprovedX') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: GeneralFormat,
        headerTooltip: 'X% = #X / #Targets',
      });
    }

    return col.merge({
      headerComponentFramework: AgHeader,
      sortable: true,
      cellRendererFramework: GeneralFormat,
    });
  });
