import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import outsideClick from 'react-click-outside';
import moment from 'moment';

import Grid from '../helpers/AgGridTable';
import config from '../../config';
import { RowIndex } from './RowIndex';
import { CompanyCell } from './CompanyCell';
import { PriorityRadio } from './PriorityRadio';
import { Sales } from './Sales';
import Checkbox from '../helpers/Checkbox';
import { Products } from './Products';
import { Markets } from './Markets';
import { Facilities } from './Facilities';
import { CustomFields } from './CustomFields';
import { Description } from './Description';
import MultilineTextEditor from './MultilineTextEditor';
import { BuyerComment } from './BuyerComment';

const OnlineBodySection = ({ onlineApproval, onSaveProgress, onSubmit, saving, onUpdate }) => {
  const [agree, setAgree] = useState(false);
  const [requiredRows, setRequiredRows] = useState([]);
  const [firstName, setFirstName] = useState(onlineApproval.get('completedByFirstName'));
  const [lastName, setLastName] = useState(onlineApproval.get('completedByLastName'));

  const apiRef = useRef(null);
  const componentRef = useRef(null);

  const columns = config.tables.getIn(['approval', 'online']).map(col => {
    const searchParams = new URLSearchParams(window.location.search);
    const optionalFields = [];
    const isCompletedList = Boolean(onlineApproval.get('dateReceived'));

    // eslint-disable-next-line no-restricted-syntax
    for (const value of searchParams.keys()) {
      optionalFields.push(value);
    }

    if (optionalFields.includes(col.get('field'))) {
      col = col.set('hide', false);
    }

    switch (col.get('field')) {
      case 'index':
        return col.merge({
          cellRendererFramework: RowIndex,
        });

      case 'companyLegalName':
        return col.merge({
          cellRendererFramework: CompanyCell,
        });

      case 'companyDescription':
        return col.merge({
          cellRendererFramework: Description,
        });

      case 'products':
        return col.merge({
          cellRendererFramework: Products,
        });

      case 'markets':
        return col.merge({
          cellRendererFramework: Markets,
        });

      case 'facilities':
        return col.merge({
          cellRendererFramework: Facilities,
        });

      case 'custom_fields':
        return col.merge({
          cellRendererFramework: CustomFields,
        });

      case 'companyRevenue':
        return col.merge({
          cellRendererFramework: Sales,
        });

      case 'priority':
        return col.merge({
          suppressFlash: true,
          cellRendererFramework: PriorityRadio,
          cellEditorFramework: PriorityRadio,
          cellRendererParams: {
            onChange: (event, data) => handlePriorityChange(event, data),
            isDisabled: isCompletedList,
          },
        });

      case 'bsnClient':
        return col.merge({
          hide: false,
          editable: true,
          cellRendererFramework: BuyerComment,
          cellEditorFramework: MultilineTextEditor,
        });

      default:
        return col;
    }
  });

  useEffect(() => {
    getRequiredRowsList();
  }, [onlineApproval]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        if (apiRef.current) {
          apiRef.current.stopEditing();
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getRequiredRowsList = () => {
    if (!onlineApproval.get('approvalListData')) {
      return;
    }

    const approvals = onlineApproval.get('approvalListData').toJS();
    const requiredRowsList = [];
    const approvalsList = approvals.map(approval => approval.targetBuyerPriority);
    const emptyPriority = '';
    let indexes = approvalsList.indexOf(emptyPriority);

    while (indexes !== -1) {
      requiredRowsList.push(indexes + 1);
      indexes = approvalsList.indexOf(emptyPriority, indexes + 1);
    }

    setRequiredRows(requiredRowsList);
  };

  const updateTable = useCallback((data) => {
    const approvals = onlineApproval
      .get('approvalListData')
      .toJS()
      .map(priority => (priority.id === data.id ? data : priority));

    onUpdate(approvals);
  }, [onlineApproval, onUpdate]);

  const handlePriorityChange = async (event, data) => {
    await updateTable(data);

    const rowNode = apiRef.current.getRowNode(data.id);
    apiRef.current.refreshCells({
      columns: ['bsnClient', 'priority'],
      rowNodes: [rowNode],
      force: true,
    });

    getRequiredRowsList();
  };

  const handleGridReady = (params) => {
    apiRef.current = params.api; // Set the grid API to the ref
    apiRef.current.sizeColumnsToFit();
  };

  const handleGridSizeChanged = () => {
    if (apiRef.current) {
      apiRef.current.sizeColumnsToFit();
    }
  };

  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value.trim());
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value.trim());
  };

  const handleSubmit = () => {
    if (apiRef.current) {
      apiRef.current.stopEditing();
    }

    const { onlineList, firstName, lastName } = prepareSaveOnlineApprovalsParams();
    onSubmit(onlineList, { firstName, lastName });
  };

  const handleSaveProgress = () => {
    if (apiRef.current) {
      apiRef.current.stopEditing();
    }

    const { onlineList, firstName, lastName } = prepareSaveOnlineApprovalsParams();
    onSaveProgress(onlineList, { firstName, lastName });
  };

  const prepareSaveOnlineApprovalsParams = () => {
    if (onlineApproval.size === 0) {
      return { firstName: undefined, lastName: undefined, onlineList: undefined };
    }

    const onlineList = onlineApproval
      .get('approvalListData')
      .map(approval => ({
        id: approval.get('id'),
        targetBuyerId: approval.get('targetBuyerId'),
        bsnClient: approval.get('bsnClient'),
        targetBuyerPriority: approval.get('targetBuyerPriority'),
      }))
      .toJS();

    return { firstName, lastName, onlineList };
  };

  const handleCellClicked = (cell) => {
    const { column: { colId }, node: { rowIndex } } = cell;
    if (apiRef.current) {
      apiRef.current.startEditingCell({ rowIndex, colKey: colId });
    }
  };

  const handleCellEditingStopped = ({ data }) => {
    updateTable(data);
    if (apiRef.current) {
      apiRef.current.refreshCells({
        columns: ['bsnClient', 'priority'],
        force: true,
        suppressFlash: true,
      });
    }
  };

  const getRowNodeId = (data) => data.id;

  const clientName = onlineApproval.get('buyerLegalName');
  const requiredRowsMessage = requiredRows.toString();
  const errorClassRequiredRows = classNames('col-sm-12 mb10', {
    hidden: requiredRows.length === 0,
  });
  const dateReceived = onlineApproval.get('dateReceived');
  const completedByFirstName = onlineApproval.get('completedByFirstName');
  const completedByLastName = onlineApproval.get('completedByLastName');
  const isCompletedList = Boolean(dateReceived);
  const isFulfilledName = firstName && lastName;

  return (
    <>
      <div className="mt20 table-generate-approval-list table-online" ref={componentRef}>
        <div className="col-sm-12 full-height">
          <Grid
            columnDefs={columns}
            getRowNodeId={getRowNodeId}
            headerHeight={35}
            onCellClicked={handleCellClicked}
            onCellEditingStopped={handleCellEditingStopped}
            onFirstDataRendered={getRequiredRowsList}
            onGridReady={handleGridReady}
            onGridSizeChanged={handleGridSizeChanged}
            rowData={onlineApproval.get('approvalListData')}
            rowHeight={130}
          />
        </div>
      </div>
      <div className="row mt20">
        <div className={errorClassRequiredRows}>
          <span className="text-danger pt10 pb10">Need to select a priority for {requiredRowsMessage} row(s)</span>
        </div>
        {!agree && (
          <div className="col-sm-12 mb10">
            <span className="text-danger pt10 pb10">
              Need to click Approval List acknowledgment in lower left corner
            </span>
          </div>
        )}
        {!isFulfilledName && (
          <div className="col-sm-12 mb10">
            <span className="text-danger pt10 pb10">
              Need to input First and Last name of Approval List submitter at bottom of page
            </span>
          </div>
        )}
        {isCompletedList ? (
          <div className="col-sm-6 ml20 text-danger">
            This Approval List has been completed and submitted by {completedByFirstName} {completedByLastName} on{' '}
            {moment(dateReceived).format('MM/DD/YYYY')}
          </div>
        ) : (
          <>
            <div className="col-sm-5">
              <Checkbox
                capitalizeLabel={false}
                id="agree"
                label={`Pursuant to the engagement agreement, the prioritized companies (except for Excluded targets)
                  on the preceding Approval List shall be deemed Approved Target Companies to be approached
                  by Harvey & Company LLC on behalf of ${clientName}. This list constitutes Harvey Confidential Information under the agreement.`}
                name="agree"
                onChange={handleAgreeChange}
              />
            </div>
            <div className="col-sm-5">
              <label className="mr20">
                <span>First Name</span>
                <input className="form-control" onChange={handleFirstNameChange} value={firstName} />
              </label>
              <label>
                <span>Last Name</span>
                <input className="form-control" onChange={handleLastNameChange} value={lastName} />
              </label>
            </div>
            <div className="col-sm-2 text-right pr20">
              <button className="btn btn-primary btn-xs mr8" disabled={saving} onClick={handleSaveProgress}>
                Save Progress
              </button>
              <button
                className="btn btn-primary btn-xs"
                disabled={!agree || requiredRows.length > 0 || saving || firstName === '' || lastName === ''}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

OnlineBodySection.propTypes = {
  onlineApproval: PropTypes.instanceOf(Map).isRequired,
  onSaveProgress: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default outsideClick(OnlineBodySection);
