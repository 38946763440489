import React, { memo } from 'react';

const MarketsComponent = ({ data }) => (
  <>
    <div className="mb5">{data.market1}</div>
    <div className="mb5">{data.market2}</div>
    <div>{data.market3}</div>
  </>
);

export const Markets = memo(MarketsComponent);
