import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { CompaniesTable } from './CompaniesTable';

const BottomBlockComponent = ({
  data,
  events,
  onYearFilterChange,
  onCompaniesSortingChange,
  onGetNextPageData,
  onRowDoubleClicked,
}) =>
  <div className="row mr1 ml1 mb20">
    <CompaniesTable
      data={data}
      events={events}
      onCompaniesSortingChange={onCompaniesSortingChange}
      onGetNextPageData={onGetNextPageData}
      onRowDoubleClicked={onRowDoubleClicked}
      onYearFilterChange={onYearFilterChange}
    />
  </div>

BottomBlockComponent.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  events: PropTypes.instanceOf(Array).isRequired,
  onCompaniesSortingChange: PropTypes.func,
  onGetNextPageData: PropTypes.func,
  onRowDoubleClicked: PropTypes.func,
  onYearFilterChange: PropTypes.func,
};

/** Bottom block component. */
export const BottomBlock = memo(BottomBlockComponent);
