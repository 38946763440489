import React, { memo } from 'react';

const CompanyNDAComponent = ({ company }) => {
  const nda = company.get('nda');

  if (!nda || !nda.get('start') || !nda.get('end')) return null;

  const title = `${nda.get('start')} - ${nda.get('end')}
${nda.get('duration')}
${nda.get('buyer')} - ${nda.get('project')}`;
  const label = nda.get('isActive') ? 'NDA is active' : 'NDA Expired';

  return (
    <div className="text-bold" title={title}>
      {nda.get('client')} {label}
    </div>
  );
};

/** Company NDA component. */
export const CompanyNDA = memo(CompanyNDAComponent);
