import PropTypes from 'prop-types';
import React, { useState, useEffect, memo } from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';

import uniqueId from '../../utils/uniqueId';
import { unwrap, isChanged } from '../../utils/ChangeSpy';

/**
 * WebInput will trim off protocol in display mode,
 * but show full URL in edit mode.
 */
const WebInputComponent = ({ id = uniqueId(), name = 'web', maxLength = 150, value, placeholder = 'Web', onChange, ...extraProps }) => {
  const [inputValue, setInputValue] = useState('');
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!touched) {
      setInputValue(getShortURL(unwrap(value)));
    }
  }, [value, touched]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleBlur = () => {
    if (onChange) {
      onChange({ target: { name, value: inputValue } });
    }
    setInputValue((prevValue) => getShortURL(prevValue));
  };

  const handleFocus = () => {
    setTouched(true);
    setInputValue(unwrap(value));
  };

  const getShortURL = (url) => {
    if (!url) return '';

    const tmp = new URL(getLinkUrl(url));

    const shortUrl = `${tmp.host}${tmp.pathname}${tmp.search}${tmp.hash}`;

    return shortUrl[shortUrl.length - 1] === '/' ? shortUrl.slice(0, -1) : shortUrl;
  };

  const getLinkUrl = (value) => {
    const url = unwrap(value);

    return url.indexOf('http') > -1 ? url : `http://${url}`;
  };

  const url = getLinkUrl(value);
  const inputClass = classNames('form-control input-md', {
    changed: isChanged(value),
  });

  return (
    <div className="form-group full-width-xxl b-web input-group">
      <div className="clearfix">
        <span className="control-wrap">
          <input
            className={inputClass}
            id={id}
            maxLength={maxLength}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={placeholder}
            type="text"
            value={inputValue}
            {...extraProps}
          />
        </span>
      </div>
      <span className="input-group-addon">
        <a href={url} rel="noopener noreferrer" target="_blank">
          <i className="fa fa-reply" title="Open link in a new tab" />
        </a>
      </span>
    </div>
  );
};

WebInputComponent.propTypes = {
  onChange: PropTypes.func, // Spy value
  value: PropTypes.instanceOf(Map).isRequired,
};

export const WebInput = memo(WebInputComponent);
