import React, { Fragment, memo, useState } from 'react';
import { fromJS } from 'immutable';

import classNames from 'classnames';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import FormGroup from '../../../helpers/FormGroup';
import Textarea from '../../../helpers/Textarea';
import Select from '../../../helpers/Select';
import { CriteriaRanges } from './CriteriaRanges';
import { CriteriaCheckboxes } from './CriteriaCheckboxes';
import CriteriaIndustries from './CriteriaIndustries';
import { TYPE_FINANCIAL, TYPE_STRATEGIC } from '../../../../config/constants/common';
import { Spinner } from '../../../helpers/Spinner';
import { TableCellWrapper } from './TableCellWrapper';

const COMPARABLE_TITLE =
  'Here you may enter the top 5 comparables for this search. These would represent the best examples of representative acquisition candidates for this buyer';

export const CriteriaComponent = ({
  addOnProjects,
  canEditData,
  comparablesData,
  criteria,
  type,
  projects,
  projectData,
  projectCommonData,
  industries,
  detailsCallbacks,
  onChange,
  onChangeCommon,
  onChangeProject,
  onDelete,
  errorMsg,
  loadingProjectData,
  onErrorClose,
  menuStateChanged,
  searchFieldFocusLeave,
  comparablesSuggest1,
  comparablesSuggest2,
  comparablesSuggest3,
  comparablesSuggest4,
  comparablesSuggest5,
  searchFieldFocus,
}) => {

  const handleComparableRemoveClick = id => {
    if (!canEditData) {
      return;
    }
    onDelete(id);
  };

  let projectOptions = fromJS({ value: '', name: '' });

  if (type === TYPE_FINANCIAL) {
    projectOptions = projects.getIn(['platform', 'queryResults']);
  }

  if (type === TYPE_STRATEGIC) {
    projectOptions = addOnProjects;
  }

  const projectScopeClass = classNames({ 'dirty-field': projectData.getIn(['details', 'dirty']) }, 'form-control');

  const comparablesSuggest = value => {
    switch (value) {
      case 1:
        return comparablesSuggest1;

      case 2:
        return comparablesSuggest2;

      case 3:
        return comparablesSuggest3;

      case 4:
        return comparablesSuggest4;

      case 5:
        return comparablesSuggest5;

      default:
        return null;
    }
  };

  const openCompany = id => {
    window.open(`/company/${id}`);
  };

  return (
    <div className="tab-pane active tab-target criteria--section" id="buy-slide-3">
      <div className="criteria--panel">
        <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
          <div className="b-criteria">
            <form className="form-group text-center">
              <Select
                className="input-sm"
                defaultText={projectOptions.size ? null : 'No Project Selected'}
                label="Project"
                name="targetCriteria"
                nameKey="category"
                onChange={onChangeProject}
                options={projectOptions}
                valueKey="id"
                showLabel
              />
            </form>
            <div className="criteria-tabs">
              <input
                className="criteria-tab-btn-1"
                id="tab-btn-1"
                name="tab-btn"
                type="radio"
                value=""
                defaultChecked
              />
              <label className="head-label" htmlFor="tab-btn-1">
                Default Criteria
              </label>
              {projectOptions.size > 1 && (
                <>
                  <input className="criteria-tab-btn-2" id="tab-btn-2" name="tab-btn" type="radio" value="" />
                  <label className="head-label" htmlFor="tab-btn-2">
                    Project Specific
                  </label>
                </>
              )}

              <div className="content-1">
                <hr />
                <CriteriaRanges buyer={criteria} disabled={!canEditData} onChange={onChange} />
                <hr />
                <CriteriaCheckboxes buyer={criteria} disabled={!canEditData} onChange={onChange} />
              </div>
              {projectOptions.size > 1 && (
                <div className="content-2">
                  <hr />
                  <CriteriaRanges buyer={projectCommonData} disabled={!canEditData} onChange={onChangeCommon} />
                  <hr />
                  <CriteriaCheckboxes buyer={projectCommonData} disabled={!canEditData} onChange={onChangeCommon} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
          <div className="wrap-table table-responsive table-criteria-industry">
            <div className="p-relative">
              <CriteriaIndustries
                {...detailsCallbacks}
                canEditData={canEditData}
                data={industries}
                disabled={!projectOptions.size}
                error={errorMsg}
                name="selectedBICs"
                onErrorClose={onErrorClose}
                canDisplayError
              />
              {loadingProjectData && <Spinner />}
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-hover table-comparables mt20">
              <thead>
                <tr>
                  <th>
                    Comparables (
                    <i aria-hidden="true" className="fa fa-question" title={COMPARABLE_TITLE} />)
                  </th>
                  <th>Rev</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Type</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5].map(value => {
                  const data = comparablesData[value - 1];

                  return (
                    <Fragment key={value}>
                      <tr>
                        <TableCellWrapper
                          data={comparablesSuggest(value)}
                          id={value}
                          onBlur={searchFieldFocusLeave}
                          onFocus={searchFieldFocus}
                        />
                        <TableCellWrapper data={data.revenue ? `$${data.revenue}` : ''} id={value} />
                        <TableCellWrapper data={data?.city} id={value} />
                        <TableCellWrapper data={data?.state} id={value} />
                        <TableCellWrapper data={data?.companyType} id={value} />
                        <td className="text-center">
                          {data.id && (
                            <i
                              aria-hidden="true"
                              className="fa fa-external-link cursor-pointer"
                              onClick={() => openCompany(data.id)}
                            />
                          )}
                        </td>
                      </tr>
                      {!(Object.keys(data).length === 0) && (
                        <ContextMenu id={value.toString()} onHide={menuStateChanged} onShow={menuStateChanged}>
                          <MenuItem data={data} onClick={() => handleComparableRemoveClick(value - 1)}>
                            <i className="fa fa-remove" /> Delete
                          </MenuItem>
                        </ContextMenu>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
          <form className="b-criteria">
            <FormGroup>
              <div className="p-relative">
                <Textarea
                  className={projectScopeClass}
                  disabled={!canEditData || !projectOptions.size}
                  label="Project Scope"
                  name="name"
                  onChange={onChange}
                  placeholder="Project Scope"
                  prefix="details"
                  root={projectData}
                  rows="8"
                  deep
                  showLabel
                />
                {loadingProjectData && <Spinner />}
              </div>
              <span className="text-muted">Briefly describe the scope of the search</span>
            </FormGroup>
            <FormGroup>
              <Textarea
                disabled={!canEditData || !projectOptions.size}
                label="Geography"
                name="geography"
                onChange={onChangeCommon}
                placeholder="Geography"
                root={projectCommonData}
                rows="8"
                deep
                showLabel
              />
              <span className="text-muted">Add brief description of geographic focus</span>
            </FormGroup>
          </form>
        </div>
      </div>
    </div>
  );
}

/** Criteria component. */
export const Criteria = memo(CriteriaComponent);
