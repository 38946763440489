import React, { memo } from 'react';
import classNames from 'classnames';
import { fromJS } from 'immutable';

import FormGroup from '../../../helpers/FormGroup';
import Select from '../../../helpers/Select';

const OPTIONS = fromJS([
  { value: '', name: '' },
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
]);

const Ceo123Component = ({ className, ...rest }) => {
  const cls = classNames('input-sm', className);

  return (
    <FormGroup>
      <Select {...rest} className={cls} label="123" options={OPTIONS} />
    </FormGroup>
  );
};

/** Ceo123 component. */
export const Ceo123 = memo(Ceo123Component);
