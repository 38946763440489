import React, { memo } from 'react';

const IconRendererComponent = ({ data }) => {
  if (data.isemail) return <span className="fa fa-envelope-o" />;
  if (data.isletter) return <span className="fa fa-folder-o" />;

  return null;
};

export const IconRenderer = memo(IconRendererComponent);
