import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../../../helpers/AgGridTable';
import TextEditor from '../../../helpers/TextEditor';
import { handleSortModelChanged } from '../../../../utils/sorting';

const DefaultCellRenderer = ({ colDef, data, value }) => {
  const currentCol = colDef.field.replace('.value', '');
  const wrapperClass =
    data[currentCol].value === data[currentCol].__ORIGINAL_VALUE_KEY__ ? '' : 'changed';

  return <div className={wrapperClass}>{value}</div>;
}

const CompanyBuyerCEOTableComponent = ({
  data,
  onDoubleClickRow,
  onGetNextPageData,
  title,
  onAddCEOProject,
  columnDefs,
  canEditData,
  clientLeadEditor,
  harvcoLeadEditor,
  onChange,
  onUpdateCell,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [rowNode, setRowNode] = useState(null);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
      if (rowNode) {
        gridApi.redrawRows({ rowNodes: [rowNode] });
        setRowNode(null)
      }
    }

    if (data.get('focusElement')) {
      document.getElementById(data.get('focusElement')).focus();
    }
  }, [gridApi])

  const getColumnDefs = () =>
    columnDefs.map(columnDef => {
      const field = columnDef.get('field');

      switch (field) {
        case 'category.value':

        // fallthrough
        case 'details.value':

        // fallthrough
        case 'status.value':
          return columnDef.merge({
            cellEditorFramework: TextEditor,
            cellRendererFramework: DefaultCellRenderer,
            editable: canEditData,
          });

        case 'dsplClientLeadFLName.value':
          return columnDef.merge({
            cellEditorFramework: clientLeadEditor,
            cellRendererFramework: DefaultCellRenderer,
            editable: canEditData,
          });

        case 'dsplHarvcoLeadUserName.value':
          return columnDef.merge({
            cellEditorFramework: harvcoLeadEditor,
            cellRendererFramework: DefaultCellRenderer,
            editable: canEditData,
          });

        default:
          return columnDef;
      }
    });

  const handleGridReady = params => {
    setGridApi(params.api);
  }

  const handleSort = sortModel => {
    if (!canEditData) {
      return;
    }

    sortModel[0].colId = sortModel[0].colId.replace('.value', '');
    handleSortModelChanged(sortModel, data, onChange);
  }

  const handleCellClicked = data => {
    if (!canEditData) {
      return;
    }

    gridApi.startEditingCell({
      rowIndex: data.node.rowIndex,
      colKey: data.colDef.field,
    });
  }

  const handleCellValueChanged = event => {
    if (!canEditData) {
      return;
    }

    const { field } = event.colDef;

    if (
      (field === 'category.value' || field === 'details.value' || field === 'status.value') &&
      event.newValue !== event.oldValue
    ) {
      onUpdateCell({
        updateField: field.replace('.value', ''),
        newValue: event.newValue,
        data: event.data,
      });
    }
    // Save row node to redraw it later
    setRowNode(event.node);
  }

  const getRowNodeId = data => {
    return data.order;
  }

  const button = onAddCEOProject ? (
    <button className="addActiveProjectBtn-ceo" onClick={onAddCEOProject}>
      <i className="fa fa-plus" />
    </button>
  ) : null;

  return (
    <div className="CompanyBuyerLeadsTable full-height">
      <div className="title">{title}</div>
      {button}
      <AgGridTable
        columnDefs={getColumnDefs()}
        getRowNodeId={getRowNodeId}
        isFetching={data.get('isFetching')}
        onCellClicked={handleCellClicked}
        onCellValueChanged={handleCellValueChanged}
        onGetNextPageData={onGetNextPageData}
        onGridReady={handleGridReady}
        onRowDoubleClicked={onDoubleClickRow}
        onSortModelChanged={handleSort}
        page={data.get('page')}
        rowData={data.get('queryResults')}
        totalPages={data.get('totalPages')}
        sortable
      />
    </div>
  );
}

CompanyBuyerCEOTableComponent.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onUpdateCell: PropTypes.func.isRequired,
};

/** Company buyer CEO table component. */
export const CompanyBuyerCEOTable = memo(CompanyBuyerCEOTableComponent);
