import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable, { Map, List, fromJS } from 'immutable';
import moment from 'moment';
import Helmet from 'react-helmet';

import { MailingInfo } from '../components/MailingInfo';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import { buildSortByParam } from '../helpers/paramBuilder';
import { showError } from '../utils/MessagePopup';
import config from '../config';
import * as actionCreators from '../actions/mailingInfo';
import { fetchTargetStatuses } from '../actions/statuses';
import { isResearcher } from '../utils/checkPermissions';
import downloadFile from '../utils/downloadFile';

const values = config.mailingInfo.get('values');
const { func } = PropTypes;

/** @class
 *  Mailing page container.
 *  Change title to `"Mailing Page"`.
 */
export class MailingInfoContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      statuses: List(),
    };
  }

  componentDidMount() {
    if (!this.hasAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.history.push('/');
        this.context.closePopup();
      });

      return;
    }

    const { location, getMailingHeaderInfo, getMailingTargets, onFetchStatuses } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    getMailingHeaderInfo({
      batchId: id,
    });
    getMailingTargets({
      batchId: id,
    });

    onFetchStatuses().then(({ data }) => {
      this.setState({
        statuses: fromJS(data),
      });
    });
  }

  componentDidUpdate(oldProps) {
    const { location, mailingInfo, getMailingTargets, clearUpdateCellError } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const updateCellError = mailingInfo.get('updateCellError');

    if (oldProps.mailingInfo !== mailingInfo) {
      const oldSortBy = buildSortByParam(oldProps.mailingInfo);
      const sortBy = buildSortByParam(mailingInfo);

      if (sortBy !== oldSortBy) {
        getMailingTargets({
          batchId: id,
          page: 1,
          sortBy,
        });
      }
    }

    if (updateCellError) {
      clearUpdateCellError();
      showError(this.context.openPopup, mailingInfo.get('errors'), this.handleReload);
    }
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  handleGetNextPageData = page => {
    const { location, mailingInfo, getMailingTargets } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    const sortBy = buildSortByParam(mailingInfo);

    getMailingTargets({
      batchId: id,
      page,
      sortBy,
    });
  };

  handleChange = data => {
    this.props.updateMailingInfo({
      filterName: data.filterName,
      filterData: data.filterData,
    });
  };

  handleDoubleClickRow = row => {
    window.open(`/company/${row.data.targetId}/target`, '_blank');
  };

  handleReload = (callback = () => {}) => {
    const { location, mailingInfo, getMailingTargets } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    const sortBy = buildSortByParam(mailingInfo);

    getMailingTargets({
      batchId: id,
      page: 1,
      sortBy,
      afterSuccess: callback(),
    });
  };

  handleUpdateCell = ({ updateType, updateField, newValue, data }) => {
    const { updateCompany, updatePeople, updateContactTitle, updateEmail } = this.props;

    if (updateType === 'company') {
      updateCompany(
        data.targetId,
        {
          [updateField]: newValue,
        },
        this.handleReload(),
      );
    } else if (updateType === 'people') {
      updatePeople(
        data.contactId,
        {
          [updateField]: newValue,
        },
        this.handleReload(),
      );
    } else if (updateType === 'contactTitle') {
      updateContactTitle(
        data.contactId,
        data.entityContactsId,
        {
          [updateField]: newValue,
        },
        this.handleReload(),
      );
    } else if (updateType === 'email') {
      updateEmail(
        data.contactId,
        data.contactEmailId,
        {
          [updateField]: newValue,
        },
        this.handleReload(),
      );
    }
  };

  handleMarkCompleted = () => {
    const { location, getMailingHeaderInfo, updateMailingStatuses } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    this.context.openPopup('MarkMailingCompletedPopup', {
      onUpdate: this.handleUpdateMarkCompleted,
      updateListStatuses: eventIds =>
        updateMailingStatuses({
          batchId: id,
          body: {
            selected_ids: eventIds,
          },
          callback: () => {
            this.handleReload();
          },
        }),
      refreshGrid: () => {
        const callback = () => {
          getMailingHeaderInfo({
            batchId: id,
            afterSuccess: this.handleSetNextActions,
          });
        };

        this.handleReload(callback);
      },
    });
  };

  handleUpdateMarkCompleted = (formData, callback) => {
    const { location, updateMarkCompleted } = this.props;
    const { statuses } = this.state;
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    const statusId = statuses.findKey(
      item => item.get('fc') === formData.getIn(['status', 'selected', 'value'], undefined),
    );

    updateMarkCompleted({
      batchId: id,
      body: {
        date: formData.get('date', moment()).format('YYYY-MM-DD'),
        status_id: statusId,
      },
      callback,
    });
  };

  handleSetNextActions = () => {
    const { location, mailingInfo, updateMailingStatuses } = this.props;
    const analystName = mailingInfo.getIn(['header', 'recordSubOwnerUserName']) || '';
    const analystId = mailingInfo.getIn(['header', 'recordSubOwnerId']);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    this.context.openPopup('SetNextActionsPopup', {
      currentPage: 'browse',
      onUpdate: this.handleUpdateSetNextActions,
      onSuccessResponse: ({ errors }) => {
        if (errors && errors.length > 0) {
          const eventIds = errors.map(({ eventId }) => eventId);
          let message = '';

          message = errors.reduce((acc, error) => {
            acc += `${error.error}\n`;

            return acc;
          }, '');

          this.context.openPopup('ConfirmPopup', {
            message,
            header: 'The state of the list has been changed',
            submessage: 'Click “Update” to remove these targets from the list',
            yes: 'Update',
            no: 'Cancel',
            onOk: () =>
              updateMailingStatuses({
                batchId: id,
                body: {
                  selected_ids: eventIds,
                },
                callback: () => {
                  this.handleReload();
                  this.context.closePopup();
                },
              }),
            onCancel: () => this.context.closePopup(),
          });
        } else {
          this.handleReload();
        }
      },
      refreshGrid: () => {
        this.forceUpdate();
      },
      assignedTo: {
        suggestions: [],
        text: analystName,
        selected: { text: analystName, id: analystId },
      },
    });
  };

  handleUpdateSetNextActions = (formData, callback) => {
    const { location, updateSetNextActions } = this.props;
    const { statuses } = this.state;
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    const selectedStatus = statuses
      .filter(item => item.get('fc') === formData.getIn(['status', 'selected', 'value'], Map()))
      .get(0);
    const statusId = statuses.indexOf(selectedStatus);
    const params = {
      override: formData.get('override'),
      backlogged: formData.get('backlog', false),
    };

    if (statusId >= 0) {
      params.status_id = statusId;
    }

    if (formData.get('date')) {
      params.date = formData.get('date').format('YYYY-MM-DD');
    }

    if (formData.getIn(['assignedTo', 'selected'])) {
      params.user_id = formData.getIn(['assignedTo', 'selected', 'id']);
    }

    if (formData.getIn(['activity', 'selected'])) {
      params.activity = formData.getIn(['activity', 'selected', 'value']);
    }

    if (formData.get('description')) {
      params.description = formData.get('description');
    }
    updateSetNextActions({
      batchId: id,
      body: {
        params,
      },
      callback,
    });
  };

  handleDownloadMergeSource = () => {
    this.context.openPopup('DownloadMergeSourcePopup', {
      onDownload: params => {
        const searchParams = new URLSearchParams(this.props.location.search);
        const id = searchParams.get('id');

        if (!id) {
          return;
        }

        const url = `${config.API_BASE_URL}/api/v1/events/excel_merge_source/export/`;

        downloadFile({
          url,
          params: {
            batch_id: id,
            file_format: params.format,
            blank_fields: params.blankFields,
            is_save_default: params.isSaveDefault,
          },
        });
      },
    });
  };

  handleQuickMergeSource = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    const url = `${config.API_BASE_URL}/api/v1/events/excel_merge_source/export/`;

    downloadFile({
      url,
      params: {
        batch_id: id,
      },
    });
  };

  handleMailingLabelsMerge = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    const url = `${config.API_BASE_URL}/api/v1/windward/merge_source/`;

    downloadFile({
      url,
      params: {
        batch_id: id,
      },
    });
  };

  handleProjectTemplate = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    const url = `${config.API_BASE_URL}/api/v1/batch/mailing/mailing_template/${id}`;

    downloadFile({ url });
  };

  handleDownloadLogos = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    const url = `${config.API_BASE_URL}/api/v1/batch/${id}/logos`;

    // This request returns a 404 error with a message, if there are no logo files.
    const onResponseJSON = data => {
      showError(this.context.openPopup, [data.message]);
    };

    downloadFile({ url, onResponseJSON });
  };

  handleCancelMailing = () => {
    const { location, history, mailingInfo, cancelMailing } = this.props;
    const targetsNumber = mailingInfo.get('queryResults').size;
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    if (!id) {
      return;
    }

    this.context.openPopup('ConfirmPopup', {
      message: `Do you wish to cancel this mailing and return all ${targetsNumber} targets to the Backlog?`,
      header: 'Cancel Mailing',
      yes: 'Yes',
      no: 'No',
      onOk: () =>
        cancelMailing({
          batchId: id,
          afterSuccess: () => {
            this.context.closePopup();
            history.goBack();
          },
        }),
      onCancel: () => this.context.closePopup(),
    });
  };

  render() {
    if (!this.hasAccess()) return null;

    const { mailingInfo, values } = this.props;

    return (
      <div className="full-height">
        <Helmet title="Mailing Page" />
        <MailingInfo
          mailingInfo={mailingInfo}
          onCancelMailing={this.handleCancelMailing}
          onChange={this.handleChange}
          onDoubleClickRow={this.handleDoubleClickRow}
          onDownloadLogos={this.handleDownloadLogos}
          onDownloadMergeSource={this.handleDownloadMergeSource}
          onGetNextPageData={this.handleGetNextPageData}
          onMailingLabelsMerge={this.handleMailingLabelsMerge}
          onMarkCompleted={this.handleMarkCompleted}
          onProjectTemplate={this.handleProjectTemplate}
          onQuickMergeSource={this.handleQuickMergeSource}
          onReload={this.handleReload}
          onSetNextActions={this.handleSetNextActions}
          onUpdateCell={this.handleUpdateCell}
          values={values}
        />
      </div>
    );
  }
}

MailingInfoContainer.propTypes = {
  clearUpdateCellError: func.isRequired,
  getMailingHeaderInfo: func.isRequired,
  getMailingTargets: func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  mailingInfo: PropTypes.instanceOf(Map).isRequired,
  updateCompany: func.isRequired,
  updateContactTitle: func.isRequired,
  updateEmail: func.isRequired,
  updateMailingInfo: func.isRequired,
  updateMailingStatuses: func.isRequired,
  updateMarkCompleted: func.isRequired,
  updatePeople: func.isRequired,
};

MailingInfoContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const mapStateToProps = state => ({
  mailingInfo: state.mailingInfo,
  loggedUser: state.auth.get('user'),
  values,
});

const mapDispatchToProps = dispatch => ({
  onFetchStatuses: () => dispatch(fetchTargetStatuses()),
  ...bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(MailingInfoContainer);
