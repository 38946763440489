import React, { memo } from 'react';

const SecretCodeFormComponent = ({ loadingContent, errorContent, google2FaSecret, onSubmit }) => (
  <form className="form-qr" onSubmit={onSubmit}>
    {google2FaSecret && (
      <>
        <p className="text-white">For authentication need to install Google Authenticator App on your smartphone.</p>
        <div className="qr-scan-apps">
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img alt="Google Play" src="/img/google-play-button.png" />
          </a>
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img alt="App Store" src="/img/app-store-button.png" />
          </a>
        </div>
        <figure className="qr-image">
          <img alt="QR Code" src={google2FaSecret.get('qrCodeImage')} />
          <figcaption className="text-white pt5">Scan QA and enter a code below</figcaption>
        </figure>
      </>
    )}
    <input
      autoComplete="off"
      className="form-control"
      id="confirmationCode"
      inputMode="number"
      maxLength="6"
      name="confirmationCode"
      placeholder="Enter 2FA Code"
      type="tel"
      autoFocus
      required
    />
    {errorContent}
    <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit">
      Sign in
    </button>
    {loadingContent}
  </form>
);

export const SecretCodeForm = memo(SecretCodeFormComponent);
