import PropTypes from 'prop-types';
import React, {memo, useEffect, useState} from 'react';
import { List } from 'immutable';

const LOGO = 'Logo';
const CUSTOM = 'Custom';

const TableFilesComponent = ({ files, canEditData, onDeleteFile, onUploadFile }) => {
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    if (fileName !== null) {
      onUploadFile();
      setFileName(null);
    }
  }, [fileName]);

  const handleSelectFile = event => {
    setFileName(event.target.files.item(0).name.split('.')[0]);
  };

  const isFileExist = (files, type) =>
    files.size > 0 && files.filter(file => file.get('type') === type).getIn([0, 'id']) !== '';

  const isDisabledDelete = type => {
    if (type === CUSTOM) return false;

    return !isFileExist(files, type);
  };

  const getFileTypes = () => {
    const types = [CUSTOM];
    const isHasLogo = isFileExist(files, LOGO);

    if (!isHasLogo) {
      types.unshift(LOGO);
    }

    return types;
  }

  const handleDeleteClick = (event, onDeleteFile, file) => {
    event.preventDefault();
    event.stopPropagation();
    onDeleteFile(event, file);
  }

  return (
    <>
      <table className="table table-bordered table-align-middle">
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Notes</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {files.map(file => (
            <tr key={file.get('id')}>
              <td className="text-left">{file.get('type')}</td>
              <td className="text-left">{file.get('fileName')}</td>
              <td className="text-left">{file.get('notes')}</td>
              <td className="pt5 pb5 text-center">
                <button
                  className="btn btn-xs btn-link mr10"
                  disabled={isDisabledDelete(file.get('type'))}
                  onClick={event => {
                    handleDeleteClick(event, onDeleteFile, file);
                  }}
                  title="Delete"
                  type="button"
                >
                  <i className="fa fa-trash font-size-14 icon-rejected mr0" />
                </button>
                <button
                  className="btn btn-xs btn-link"
                  onClick={() => window.open(file.get('url'), '_blank')}
                  title="Open"
                  type="button"
                >
                  <i className="fa fa-external-link font-size-14 mr0" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {canEditData && (
        <div className="row mt15">
          <div className="col-sm-3">
            <select className="form-control" name="type">
              {getFileTypes().map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <label className="btn btn-xs btn-primary" htmlFor="company-file-upload-file">
              <input
                className="input-file-button"
                id="company-file-upload-file"
                name="file"
                onChange={handleSelectFile}
                type="file"
              />
              Upload
            </label>
            <input name="name" type="hidden" value={fileName} />
          </div>
        </div>
      )}
    </>
  );
}

TableFilesComponent.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  files: PropTypes.instanceOf(List).isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
};

/** Table files component. */
export const TableFiles = memo(TableFilesComponent);
