import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS } from '../../../../utils/ChangeSpy';

import { OneRowTable } from './OneRowTable';
import { EventsTable } from './EventsTable';

const MidBlockComponent = ({ data, onAddEvent, onEventRemove, yearFilter }) =>
  <div className="row">
    <div className="col-md-7 pr13">
      <EventsTable events={toJS(data.get('events'))} onAddEvent={onAddEvent} onEventRemove={onEventRemove} />
    </div>
    <div className="col-md-5">
      <OneRowTable
        companies={toJS(data.get('companies'))}
        rowData={toJS(data.get('statistics'))}
        yearFilter={yearFilter}
      />
    </div>
  </div>

MidBlockComponent.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  onAddEvent: PropTypes.func,
  onEventRemove: PropTypes.func,
  yearFilter: PropTypes.string,
};

/** Mid block component. */
export const MidBlock = memo(MidBlockComponent);
