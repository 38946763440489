import React, { memo } from 'react';
import classNames from 'classnames';
import { Map, List } from 'immutable';

import LoadAnimation from '../../decorators/LoadAnimation';
import FormGroup from '../../helpers/FormGroup';
import _Textarea from '../../helpers/Textarea';
import _Wysiwyg from '../../helpers/Wysiwyg/Wysiwyg';
import _Checkbox from '../../helpers/Checkbox';
import _Input from '../../helpers/Input';
import _Select from '../../helpers/Select';
import _ActivityDropdown from '../../helpers/ActivityDropdown';
import config from '../../../config';
import { unwrap } from '../../../utils/ChangeSpy';
import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';
import FormPreventEnterSubmit from '../../helpers/FormPreventEnterSubmit';
import { SharedDealNote } from './SharedDealNote';

const ActivityDropdown = PrettyError(DisplayError(_ActivityDropdown));
const Textarea = PrettyError(DisplayError(_Textarea));
const Wysiwyg = PrettyError(DisplayError(_Wysiwyg));
const Checkbox = PrettyError(DisplayError(_Checkbox));
const Input = PrettyError(DisplayError(_Input));
const Select = PrettyError(DisplayError(_Select));

const defaultActivities = List();
const defaultActivity = Map();
const defaultSelectedEvent = Map();

const BuyerForm = props => {
  const {
    buyer,
    event,
    contacts,
    onChange,
    onSave,
    onActivityChange,
    suggestUser,
    hasEverNext,
    isNew,
    shouldPrompt,
    onRef,
    onNext,
    onChangeCompanyIds,
  } = props;

  const noteText = 'Marketing/Approach';
  const activities = config.values.getIn(['eventActivities', 'buyer'], defaultActivities);
  const selectedEvent = event.getIn(['activityMap', 'selected'], defaultSelectedEvent);
  const descriptionClass = classNames('row', {
    hide: !selectedEvent.get('showDescription'),
  });

  const disabled = (hasEverNext && isNew) || shouldPrompt;

  let note = null;

  if (unwrap(event.get('completed'))) {
    const emailClass = classNames('col-xs-6', 'all-h100p', {
      hide: !selectedEvent.get('showEmail'),
    });
    const dealNotesClass = classNames(
      {
        hide: !selectedEvent.get('showDealNotes'),
        'col-xs-6': !!selectedEvent.get('showEmail'),
        'col-xs-12': !selectedEvent.get('showEmail'),
      },
      'wysiwygContainer',
    );

    note = (
      <div className="row h100p">
        <FormGroup className={dealNotesClass}>
          <Wysiwyg
            label={noteText}
            name="dealNotes"
            onChange={onChange}
            placeholder={noteText}
            root={event}
            tabIndex={6}
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className={emailClass}>
          <Textarea
            label="Email Text"
            name="emailText"
            placeholder="Email Text"
            root={event}
            rows="2"
            tabIndex="6"
            deep
            showLabel
          />
        </FormGroup>
      </div>
    );
  }

  return (
    <FormPreventEnterSubmit
      ref={onRef}
      className="h100p flexWrapper"
      id="popup-edit-event-form"
      onChange={onChange}
      onSubmit={onSave}
    >
      <div>
        <div className="row">
          <FormGroup className="col-xs-4">
            <Input
              autoFocus={isNew}
              className="input-sm"
              label="Date"
              name="date"
              onChange={onChange}
              placeholder="Date"
              root={event}
              tabIndex="1"
              type="date"
              deep
              required
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-xs-4">{suggestUser}</FormGroup>
          <FormGroup className="col-xs-4">
            <Input
              className="input-sm"
              label="Buyer"
              name="buyerName"
              placeholder="Buyer"
              root={event}
              deep
              readOnly
              showLabel
            />
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup className="col-xs-4 group-checkbox">
            <Checkbox deep="checked" disabled={disabled} label="Completed" name="completed" root={event} tabIndex="2" />
          </FormGroup>
          <div className="col-xs-4" />
          <FormGroup className="col-xs-4">
            <Select
              className="input-sm"
              label="Contact"
              name="contactId"
              options={contacts}
              placeholder="Contact"
              root={event}
              deep
              showLabel
            />
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup className="col-xs-4">
            <ActivityDropdown
              activities={activities}
              info={event.get('activityMap', defaultActivity)}
              name="activity"
              onChange={onActivityChange}
              onNext={onNext}
              tabIndex="4"
              isSinglePanel
            />
          </FormGroup>
          {unwrap(event.get('completed')) && buyer && (
            <div className="sharedDealNoteList">
              <SharedDealNote buyer={buyer} onChangeCompanyIds={onChangeCompanyIds} />
            </div>
          )}
        </div>

        <div className={descriptionClass}>
          <FormGroup className="col-xs-12">
            <Textarea
              label="Description"
              name="description"
              placeholder="Description"
              root={event}
              rows="4"
              tabIndex="5"
              deep
              showLabel
            />
          </FormGroup>
        </div>
      </div>

      <div className="flexItem all-h100p">{note}</div>
    </FormPreventEnterSubmit>
  );
};

export const BuyerLoadableForm = LoadAnimation(memo(BuyerForm));
