import React, { memo } from 'react';
import classNames from 'classnames';
import { Map, List } from 'immutable';

import LoadAnimation from '../../decorators/LoadAnimation';
import FormGroup from '../../helpers/FormGroup';
import _Textarea from '../../helpers/Textarea';
import _Wysiwyg from '../../helpers/Wysiwyg/Wysiwyg';
import _Checkbox from '../../helpers/Checkbox';
import _Input from '../../helpers/Input';
import _Select from '../../helpers/Select';
import _ActivityDropdown from '../../helpers/ActivityDropdown';
import config from '../../../config';
import { unwrap } from '../../../utils/ChangeSpy';
import PrettyError from '../../decorators/PrettyError';
import DisplayError from '../../decorators/DisplayError';
import FormPreventEnterSubmit from '../../helpers/FormPreventEnterSubmit';

const ActivityDropdown = PrettyError(DisplayError(_ActivityDropdown));
const Textarea = PrettyError(DisplayError(_Textarea));
const Wysiwyg = PrettyError(DisplayError(_Wysiwyg));
const Checkbox = PrettyError(DisplayError(_Checkbox));
const Input = PrettyError(DisplayError(_Input));
const Select = PrettyError(DisplayError(_Select));

const defaultActivities = List();
const defaultActivity = Map();
const defaultSelectedEvent = Map();

const ExecForm = props => {
  const {
    noteText,
    event,
    contacts,
    onChange,
    onSave,
    onActivityChange,
    suggestUser,
    suggestDirector,
    suggestAnalyst,
    hasEverNext,
    isNew,
    shouldPrompt,
    onRef,
    funds,
    onAddBuyerNote,
  } = props;

  const activities = config.values.getIn(['eventActivities', 'exec'], defaultActivities);
  const selectedEvent = event.getIn(['activityMap', 'selected'], defaultSelectedEvent);
  const descriptionClass = classNames('row', {
    hide: !selectedEvent.get('showDescription'),
  });
  const ndaExecutedClass = classNames('col-lg-4 col-sm-12', {
    hide: !selectedEvent.get('showNDAExecuted'),
  });
  const activity = event.getIn(['activityMap', 'text', 'value'], '') || '';
  const buyerNoteClass = classNames('col-xs-4 text-right', {
    hide:
      activity === '' ||
      activity === 'Email' ||
      activity === 'Update Call' ||
      !event.getIn(['completed', 'value'], false),
  });
  const buyerLinkText = selectedEvent.get('showEmail') ? 'Hide Buyer Notes' : 'Add Buyer Notes';
  const disabled = (hasEverNext && isNew) || shouldPrompt;

  let note = null;

  if (unwrap(event.get('completed'))) {
    const dealNotesClass = classNames(
      {
        hide: !selectedEvent.get('showDealNotes'),
        'col-xs-6': !!selectedEvent.get('showEmail'),
        'col-xs-12': !selectedEvent.get('showEmail'),
      },
      'wysiwygContainer',
    );
    const emailClass = classNames({
      'col-xs-12': !selectedEvent.get('showDealNotes'),
      'col-xs-6': selectedEvent.get('showDealNotes'),
      hide: !selectedEvent.get('showEmail'),
    });

    note = (
      <div className="row h100p">
        <FormGroup className={dealNotesClass}>
          <Wysiwyg
            label={noteText}
            name="dealNotes"
            onChange={onChange}
            placeholder={noteText}
            root={event}
            tabIndex={6}
            deep
            showLabel
          />
        </FormGroup>
        <FormGroup className={emailClass}>
          <Textarea
            label="Email Text"
            name="emailText"
            placeholder="Email Text"
            root={event}
            rows="4"
            tabIndex="6"
            deep
            showLabel
          />
        </FormGroup>
      </div>
    );
  }

  let projectName = event.get('eprojectName', '');
  let buyerName = event.get('legalName', '');

  // Get active fund information if creating a new exec next action.
  const key = funds.findKey(f => unwrap(f.get('activeFund')));

  if (isNew && key !== undefined && key !== null) {
    projectName = funds.getIn([key, 'eprojectCategory'], '');
    buyerName = funds.getIn([key, 'fundLegalName'], '');
  }

  return (
    <FormPreventEnterSubmit
      ref={onRef}
      className="h100p flexWrapper"
      id="popup-edit-event-form"
      onChange={onChange}
      onSubmit={onSave}
    >
      <div>
        <div className="row">
          <FormGroup className="col-xs-3">
            <Input
              autoFocus={isNew}
              className="input-sm"
              label="Date"
              name="date"
              onChange={onChange}
              placeholder="Date"
              root={event}
              tabIndex="1"
              type="date"
              deep
              required
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-xs-3">{suggestDirector}</FormGroup>
          <FormGroup className="col-xs-3">
            <Input
              className="input-sm"
              label="Buyer"
              name="buyerName"
              placeholder="Buyer"
              value={buyerName}
              readOnly
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-xs-3">
            <Select
              className="input-sm"
              label="Executive"
              name="contactId"
              options={contacts}
              placeholder="Executive"
              root={event}
              deep
              readOnly
              showLabel
            />
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup className="col-xs-3 group-checkbox">
            <Checkbox deep="checked" disabled={disabled} label="Completed" name="completed" root={event} tabIndex="2" />
            <div className="completed-by">{suggestUser}</div>
          </FormGroup>
          <div className="col-xs-3">{suggestAnalyst}</div>
          <FormGroup className="col-xs-3">
            <Input
              className="input-sm"
              label="Project"
              name="projectName"
              placeholder="Project"
              value={projectName}
              readOnly
              showLabel
            />
          </FormGroup>
          <div className="col-xs-3" />
        </div>

        <div className="row">
          <FormGroup className="col-xs-4">
            <ActivityDropdown
              activities={activities}
              info={event.get('activityMap', defaultActivity)}
              isSinglePanel={false}
              name="activity"
              onChange={onActivityChange}
              tabIndex="4"
            />
          </FormGroup>

          <FormGroup className={ndaExecutedClass}>
            <Input
              className="input-sm"
              label="NDA Term (months)"
              name="ndaTerm"
              placeholder=""
              root={event}
              deep
              showLabel
            />
          </FormGroup>

          <FormGroup className={buyerNoteClass}>
            <a href="#" onClick={onAddBuyerNote}>
              {buyerLinkText}
            </a>
          </FormGroup>
        </div>

        <div className={descriptionClass}>
          <FormGroup className="col-xs-12">
            <Textarea
              label="Description"
              name="description"
              placeholder="Description"
              root={event}
              rows="2"
              tabIndex="5"
              deep
              showLabel
            />
          </FormGroup>
        </div>
      </div>

      <div className="flexItem all-h100p">{note}</div>
    </FormPreventEnterSubmit>
  );
};

export const ExecLoadableForm = LoadAnimation(memo(ExecForm));
