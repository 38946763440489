import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../../helpers/AgGridTable';
import { handleSortModelChanged } from '../../../utils/sorting';
import { noop } from '../../../utils/noop';

const CompanyTradeGroupsTableComponent = ({ columnDefs, data, onChange, onDoubleClickRow }) => {
  const getColumnDefs = () => {
    return columnDefs.map(columnDef => {
      const field = columnDef.get('field');

      switch (field) {
        default:
          return columnDef;
      }
    });
  };

  const handleSort = sortModel => {
    handleSortModelChanged(sortModel, data, onChange);
  };

  const onGridReady = useCallback(params => {
    params.api.sizeColumnsToFit();
  }, []);

  const getRowNodeId = data => data.id;

  return (
    <div className="trade-groups-table">
      <AgGridTable
        columnDefs={getColumnDefs()}
        getRowNodeId={getRowNodeId}
        isFetching={data.get('isFetching')}
        onGetNextPageData={noop}
        onGridReady={onGridReady}
        onRowDoubleClicked={onDoubleClickRow}
        onSortModelChanged={handleSort}
        page={data.get('page')}
        rowData={data.get('queryResults')}
        totalPages={data.get('totalPages')}
        sortable
      />
    </div>
  );
};

CompanyTradeGroupsTableComponent.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
};

/** Company trade groups table component. */
export const CompanyTradeGroupsTable = memo(CompanyTradeGroupsTableComponent);
