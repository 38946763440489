import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';

const ADDITIONAL_BOTTOM_PADDING = 10;

const WysiwygViewComponent = ({ html, onDoubleClick }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const handleResize = () => {
      iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + ADDITIONAL_BOTTOM_PADDING}px`;
    };

    const handleLoadContent = () => {
      const { body, head } = iframe.contentWindow.document;
      const linkElement = iframe.contentWindow.document.createElement('link');

      setTimeout(() => {
        if (iframe) {
          iframe.style.height = `${body.scrollHeight + ADDITIONAL_BOTTOM_PADDING}px`;
        }
      }, 500);

      linkElement.setAttribute('rel', 'stylesheet');
      linkElement.setAttribute('type', 'text/css');
      linkElement.setAttribute('href', '/css/wysiwyg-preview.css');
      head.appendChild(linkElement);

      if (onDoubleClick) {
        iframe.contentWindow.document.ondblclick = onDoubleClick;
      }

      const config = {
        attributes: true,
        childList: true,
        subtree: true,
      };

      const observer = new MutationObserver(() => {
        iframe.style.height = `${body.scrollHeight + ADDITIONAL_BOTTOM_PADDING}px`;
      });

      observer.observe(body, config);
      iframe.contentWindow.addEventListener('resize', handleResize);

      return () => {
        observer.disconnect();
        iframe.contentWindow.removeEventListener('resize', handleResize);
      };
    };

    iframe.addEventListener('load', handleLoadContent);

    return () => {
      iframe.removeEventListener('load', handleLoadContent);
    };
  }, [onDoubleClick]);

  return (
    <iframe
      ref={iframeRef}
      className="wysiwygView"
      marginHeight="0"
      marginWidth="0"
      sandbox="allow-same-origin allow-scripts"
      scrolling="no"
      srcDoc={html}
      title="content"
    />
  );
};

WysiwygViewComponent.propTypes = {
  html: PropTypes.string.isRequired,
  onDoubleClick: PropTypes.func,
};

export const WysiwygView = memo(WysiwygViewComponent);
