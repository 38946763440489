import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map } from 'immutable';
import DatePicker from 'react-datepicker';

import { BrowseCheckbox } from './BrowseCheckbox';
import { BrowseAutoComplete } from './BrowseAutoComplete';

const BrowseActionFilterComponent = ({ filter, info, onChange, onGetSuggestion }) => {
  const handleStartDateChange = date => {
    if (date.isValid()) {
      onChange({
        filterName: filter.get('name'),
        filterData: {
          startDate: date,
        },
      });
    }
  }

  const handleEndDateChange = date => {
    if (date.isValid()) {
      onChange({
        filterName: filter.get('name'),
        filterData: {
          endDate: date,
        },
      });
    }
  }

  const handleEventDescriptionChange = event => {
    const { value } = event.target;

    onChange({
      filterName: filter.get('name'),
      filterData: {
        eventDescription: value,
      },
    });
  }

  const handleAddTag = () => {
    const name = filter.get('name');
    const eventActivity = filter.get('eventActivity');
    let eventSelected = info.get('selected');

    if (!eventSelected) {
      eventSelected = '(any activity)';
    } else {
      eventSelected = eventSelected.get(eventActivity.get('renderField'), '(any activity)');
    }

    let eventDescription = info.get('eventDescription');

    if (eventDescription === '') eventDescription = '(any description)';

    let selectedList = info.get('selectedList');
    const selected = `${eventSelected} - ${eventDescription}`;

    if (!selectedList.includes(selected)) {
      selectedList = selectedList.push(selected);

      onChange({
        filterName: name,
        filterData: {
          selectedList,
          text: '',
          selected: null,
          eventDescription: '',
        },
      });
    }
  }

  const handleRemoveTag = event => {
    const { name } = event.target;
    const tagIndex = parseInt(name.replace('buyerFilterTag', ''), 10);
    let selectedList = info.get('selectedList');

    selectedList = selectedList.delete(tagIndex);

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  }

  const selectedList = info.get('selectedList');
  const tagListContent = selectedList.map((tag, i) => (
    <div key={i} className="MultiselectItem">
      {tag}
      <button
        aria-hidden="true"
        aria-label="close"
        className="MultiselectItem-close close btn-xs"
        name={`buyerFilterTag${i}`}
        onClick={handleRemoveTag}
        type="button"
      >
        &times;
      </button>
    </div>
  ));

  return (
    <BrowseCheckbox filter={filter} info={info} onChange={onChange}>
      <div className="BrowseControl-content BrowseControl-content--activity">
        <div className="form-inline">
          <div className="form-group">
            <label>From</label>
            <DatePicker
              className="form-control"
              dateFormat="MM/DD/YYYY"
              dropdownMode="select"
              onChange={handleStartDateChange}
              selected={info.get('startDate')}
              showMonthDropdown
              showYearDropdown
            />
          </div>
          <div className="form-group">
            <label>To</label>
            <DatePicker
              className="form-control"
              dateFormat="MM/DD/YYYY"
              dropdownMode="select"
              onChange={handleEndDateChange}
              selected={info.get('endDate')}
              showMonthDropdown
              showYearDropdown
            />
          </div>
          {tagListContent}
          <BrowseAutoComplete
            className="form-control"
            filter={filter.get('eventActivity')}
            info={info}
            onChange={onChange}
            onGetSuggestion={onGetSuggestion}
          />
          <div className="form-group input-group">
            <input
              className="form-control"
              onChange={handleEventDescriptionChange}
              placeholder="Event Description"
              type="text"
              value={info.get('eventDescription')}
            />
            <span className="input-group-addon" onClick={handleAddTag}>
              <i aria-hidden="true" className="fa fa-plus" />
            </span>
          </div>
        </div>
      </div>
    </BrowseCheckbox>
  );
}

BrowseActionFilterComponent.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetSuggestion: PropTypes.func.isRequired,
};

/** Browse action filter component. */
export const BrowseActionFilter = memo(BrowseActionFilterComponent);
