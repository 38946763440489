import PropTypes from 'prop-types';
import React, { memo, useRef } from 'react';
import { Map } from 'immutable';

import { ResizablePopup } from '../../helpers/Popup';
import Button from '../../helpers/Button';
import config from '../../../config';
import { unwrap } from '../../../utils/ChangeSpy';
import { TargetLoadableForm } from './TargetForm';
import { ExecLoadableForm } from './ExecForm';
import { BuyerLoadableForm } from './BuyerForm';

const getNewEventHeader = (isNext, hasEverNext) => {
  if (isNext) {
    if (hasEverNext) {
      return (
        <span className="text-danger">
          You are adding a new Next Action / Planned Future Event
          <br />
          <small>(This company already has an existing next action outstanding)</small>
        </span>
      );
    }

    return <span>This is a Next Action / Planned Future Event</span>;
  }

  if (hasEverNext) {
    return (
      <span className="text-danger">
        You are adding a new Past / Completed event
        <br />
        <small>(This company already has an existing next action outstanding)</small>
      </span>
    );
  }

  return <span>You are adding a new Past / Completed event</span>;
};

const getEventHeader = (event, isNew, hasEverNext, shouldPrompt) => {
  const isNext = !unwrap(event.get('completed'));

  if (isNew) {
    if (shouldPrompt) {
      return <span className="text-warning">Save. Please set a new Next Action.</span>;
    }

    return getNewEventHeader(isNext, hasEverNext);
  }

  if (isNext) {
    return <span className="text-warning">This is a Next Action / Planned Future Event</span>;
  }

  return <span>&nbsp;</span>;
};

const EventPopupComponent = ({
  event,
  noteText = 'Deal Notes',
  hasEverNext,
  loading,
  onClose,
  shouldPrompt,
  canSave,
  funds,
  onSave,
  ...rest
}) => {
  const formRef = useRef(null);
  const textareaHeight = 110;

  const handleSave = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onSave(event);
  };

  const isNew = !event.get('id');
  const header =
    loading && event.get('initialFetch') ? null : getEventHeader(event, isNew, hasEverNext, shouldPrompt);

  const footer = [
    <Button
      key="save"
      className="btn-primary btn-xs"
      disabled={!canSave}
      form="popup-edit-event-form"
      onClick={handleSave}
      tabIndex="7"
      type="submit"
    >
      Save
    </Button>,
    <Button key="close" className="btn-default btn-xs" onClick={onClose}>
      Close
    </Button>,
  ];

  let form = null;
  switch (event.get('approach')) {
    case 'exec':
      form = (
        <ExecLoadableForm
          {...rest}
          loading={loading}
          event={event}
          shouldPrompt={shouldPrompt}
          hasEverNext={hasEverNext}
          funds={funds}
          isNew={isNew}
          onRef={(form) => (formRef.current = form)}
          textareaHeight={textareaHeight}
        />
      );
      break;

    case 'buyer':
      form = (
        <BuyerLoadableForm
          {...rest}
          loading={loading}
          event={event}
          shouldPrompt={shouldPrompt}
          hasEverNext={hasEverNext}
          funds={funds}
          isNew={isNew}
          onRef={(form) => (formRef.current = form)}
          textareaHeight={textareaHeight}
        />
      );
      break;

    case 'target':
      form = (
        <TargetLoadableForm
          {...rest}
          loading={loading}
          event={event}
          shouldPrompt={shouldPrompt}
          hasEverNext={hasEverNext}
          funds={funds}
          isNew={isNew}
          onRef={(form) => (formRef.current = form)}
          textareaHeight={textareaHeight}
        />
      );
      break;

    default:
      break;
  }

  const popupDefaultPosition = {
    x: Math.floor((window.innerWidth - 600) / 2),
    y: 225,
  };

  return (
    <ResizablePopup
      {...config.eventPopupSize}
      {...rest}
      defaultPosition={popupDefaultPosition}
      footer={footer}
      header={header}
      id="newPastMain"
    >
      {form}
    </ResizablePopup>
  );
};

EventPopupComponent.propTypes = {
  event: PropTypes.instanceOf(Map).isRequired,
  onActivityChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  hasEverNext: PropTypes.bool,
  loading: PropTypes.bool,
  shouldPrompt: PropTypes.bool,
  canSave: PropTypes.bool,
  funds: PropTypes.array,
};

export const EventPopup = memo(EventPopupComponent);
