import React from 'react';
import classNames from 'classnames';

import Popup from '../helpers/Popup';
import FormGroup from '../helpers/FormGroup';
import ButtonReal from '../helpers/Button';

import _Input from '../helpers/Input';
import _Textarea from '../helpers/Textarea';
import _Select from '../helpers/Select';
import _AutoComplete, { InputProps } from '../helpers/AutoComplete';
import uniqueId from '../../utils/uniqueId';
import { unwrap, isChanged } from '../../utils/ChangeSpy';
import PrettyError from '../decorators/PrettyError';
import MismissAlert from '../decorators/MismissAlert';
import DisplayError from '../decorators/DisplayError';
import canHasParent from '../Company/canHasParent';
import { getTickerExchange } from '../Company/getTickerExchange';
import DropDown from '../helpers/DropDown/Changable';
import { PhoneInput } from '../helpers/PhoneInput';
import _Board from './BIC';
import { createIndustryErrorMsg } from '../../utils/industryTagsHelper';

const Alert = () => (
  <div>
    <p>Please ensure that this address does not contain any of the following:</p>
    <ul>
      <li>abbreviated street labels (e.g. st., Blvd., Ave., etc.)</li>
      <li>abbreviated cardinal directions (i.e. N, S, E, W)</li>
      <li>any other abbreviations, including Ste., etc.</li>
    </ul>
  </div>
);
const Input = PrettyError(DisplayError(_Input));
const Textarea = PrettyError(DisplayError(_Textarea));
const Select = PrettyError(DisplayError(_Select));
const Address = MismissAlert(Textarea, Alert);
const AutoComplete = PrettyError(DisplayError(_AutoComplete));

const Row = ({ children }) => <div className="row media-450">{children}</div>;

const id = uniqueId();
const Board = PrettyError(DisplayError(_Board));

const AddCompanyTargetPopup = props => {
  const {
    suggestDirector,
    suggestAnalyst,
    company,
    isValid,
    onChange,
    entityTypes,
    states,
    onToggleCountry,
    suggestionProps,
    onParentCompanyClick,
    onCreate,
    countryComponent,
    onBlur,
    onErrorClose,
    onClose,
    error,
    submitting,
    businessModels,
    onClick,
    tags,
    fromPositionPopup,
  } = props;

  const header = 'New Target Company Profile';
  const footer = [
    <ButtonReal
      key="footerBtn"
      className="btn-primary btn-xs"
      disabled={!isValid || submitting}
      form={id}
      loading={company.get('isFetching')}
      type="submit"
    >
      Create {submitting ? <i className="fa fa-spinner fa-spin" style={{ position: 'static' }} /> : null}
    </ButtonReal>,
  ];

  if (fromPositionPopup) {
    footer.push(
      <ButtonReal key="closeBtn" className="btn-default btn-xs" onClick={onClose}>
        Close
      </ButtonReal>,
    );
  }

  const showCountry = company.get('showCountry', false);
  const countryWrapperClass = classNames('f-country col-lg-offset-8 col-sm-offset-8 col-lg-4 col-sm-4', {
    hide: !showCountry,
  });
  const parentCompany = company.get('parentCompany');
  const parentCompanyClass = canHasParent(company.get('companyType')) ? 'col-md-12' : 'hidden';

  const selectedCompany = parentCompany.get('selected');
  const numberEmployees = selectedCompany && selectedCompany.get('employees') ? selectedCompany.get('employees') : 0;
  const selectedCompanyInfo = selectedCompany && (
    <div>
      {selectedCompany.get('companyType')} ${selectedCompany.get('revenue')} / {numberEmployees} emp.{' '}
      {selectedCompany.get('city')}, {selectedCompany.get('state')}
    </div>
  );

  const Ticker = getTickerExchange(company);
  const onSubmit = submitting ? {} : { onSubmit: onCreate };

  const errorMsg = createIndustryErrorMsg(error);

  return (
    <Popup footer={footer} header={header} id="newFundRecord">
      <form id={id} onChange={onChange} {...onSubmit}>
        <Row>
          <FormGroup className="col-lg-6 col-xs-6">
            <InputProps className="form-control input-sm" label="Module" placeholder="Module" autoFocus required>
              {suggestDirector}
            </InputProps>
          </FormGroup>
          <FormGroup className="col-lg-6 col-xs-6">
            <InputProps className="form-control input-sm" label="Analyst/Associate" placeholder="Analyst/Associate">
              {suggestAnalyst}
            </InputProps>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-lg-6 col-xs-6">
            <Input
              className="input-sm"
              label="Name"
              name="legalName"
              placeholder="Name"
              root={company}
              deep
              required
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-lg-6 col-xs-6">
            <Input className="input-sm" label="Abbr" name="abbrName" placeholder="Abbr" root={company} deep showLabel />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-lg-6 col-xs-6">
            <Input
              className="input-sm"
              label="DBA/Alt."
              name="dbaName"
              placeholder="DBA/Alt"
              root={company}
              deep
              showLabel
            />
          </FormGroup>

          <FormGroup className="col-lg-6 col-xs-6">
            <Input
              className="input-sm"
              label="Web"
              name="web"
              onChange={onChange}
              placeholder="Web"
              root={company}
              type="web"
              deep
              showLabel
            />
          </FormGroup>
        </Row>
        <Row>
          <div className="form-group col-lg-6 col-xs-6">
            <PhoneInput
              changed={isChanged(company.get('phone'))}
              error={error.phone}
              name="phone"
              onBlur={onBlur}
              onErrorClose={onErrorClose}
              value={unwrap(company.get('phone')) || ''}
            />
          </div>
        </Row>
        <Row>
          <FormGroup className="col-lg-6 col-xs-6 target__addr-panel">
            <Address
              label="Address/Alt."
              name="address"
              placeholder="Address"
              value={company.get('address')}
              showLabel
            />
          </FormGroup>
          <div className="form-group col-lg-6 col-xs-6">
            <Board
              error={errorMsg}
              models={businessModels}
              name="industryCategoryIds"
              onClick={onClick}
              onErrorClose={onErrorClose}
              tags={tags}
              canDisplayError
            />
          </div>
        </Row>
        <Row>
          <FormGroup className="col-lg-4 col-xs-4">
            <Input
              className="input-sm"
              disabled={showCountry}
              label="City"
              name="city"
              placeholder="City"
              root={company}
              deep
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-lg-4 col-xs-4">
            <label>State</label>
            <DropDown
              className="form-control"
              disabled={showCountry}
              id="dropdownMenu2"
              name="state"
              onChange={onChange}
              options={states}
              root={company}
              deep
            />
          </FormGroup>

          <FormGroup className="mt17 city-index2 col-lg-4 col-xs-4">
            <Input
              className="input-sm"
              disabled={showCountry}
              error={error.zip}
              label="Zip"
              maxLength="30"
              name="zip"
              onBlur={onBlur}
              onFocus={() => {
                onErrorClose();
              }}
              placeholder="Zip"
              root={company}
              deep
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className={countryWrapperClass}>
            <label>Country</label>
            <div className="control-wrap mb5" title="ONLY enter for countries outside the U.S. and Canada">
              {countryComponent}
            </div>
          </FormGroup>
        </Row>
        <div>
          <i aria-hidden="true" className="fa fa-ellipsis-h pull-right clickable mr2" onClick={onToggleCountry} />
          <div className="clearfix" />
        </div>

        <Row>
          <FormGroup className="col-lg-4 col-xs-4">
            <Input
              className="input-sm"
              label="Revenue"
              name="revenue"
              placeholder="Revenue"
              root={company}
              deep
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-lg-4 col-xs-4">
            <Input
              className="input-sm"
              label="Employees"
              name="employees"
              placeholder="Employees"
              root={company}
              deep
              showLabel
            />
          </FormGroup>
          <FormGroup className="col-lg-4 col-xs-4">
            <Select
              className="input-sm"
              label="Company Type"
              name="companyType"
              options={entityTypes}
              root={company}
              deep
              required
              showLabel
            />
          </FormGroup>
        </Row>
        <Row>
          <div className="col-md-8">
            <Ticker company={company} exchangeName="tickerExchange" symbolName="tickerSymbol" />
          </div>
        </Row>
        <Row>
          <div className={parentCompanyClass}>
            <AutoComplete {...suggestionProps}>
              <span className="input-group-addon">
                <i aria-hidden="true" className="fa fa-external-link" onClick={onParentCompanyClick} />
              </span>
            </AutoComplete>
            {selectedCompanyInfo}
          </div>
        </Row>
        <Row>
          <FormGroup className="col-lg-12 col-sm-12">
            <Textarea
              label="Description"
              name="description"
              placeholder="Description"
              root={company}
              rows="4"
              deep
              showLabel
            />
          </FormGroup>
        </Row>
      </form>
    </Popup>
  );
};

export default AddCompanyTargetPopup;
