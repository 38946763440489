import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../../../helpers/AgGridTable';
import { handleSortModelChanged } from 'src/utils/sorting';

const ApprovedCellRenderer = ({ data }) => {
  const content = data.approved ? <i className="fa fa-check" /> : null;
  return <div className="approvedWrapper">{content}</div>;
}

const ProjectCell = ({ data }) => {
  const projectCategory = data.dsplProjectCategory ? data.dsplProjectCategory : '';
  const username = data.dsplProjectHarvcoLeadUserName ? ` (${data.dsplProjectHarvcoLeadUserName})` : '';

  return <span>{projectCategory + username}</span>;
}

const CompanyBuyerPlatformProjectTableComponent = ({
  data,
  onDoubleClickRow,
  onGetNextPageData,
  columnDefs,
  canEditData,
  onChange,
}) => {
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi])

  const handleGetRowClass = params => {
    if (!params.data.active) {
      return 'disable';
    }
  }

  const getColumnDefs = () =>
    columnDefs.map(columnDef => {
      const field = columnDef.get('field');

      switch (field) {
        case 'approved':
          return columnDef.set('cellRendererFramework', ApprovedCellRenderer);

        case 'dsplProjectCategory':
          return columnDef.set('cellRendererFramework', ProjectCell);

        default:
          return columnDef;
      }
    });

  const handleGridReady = params => {
    setGridApi(params.api);
  }

  const handleRowDoubleClicked = row => {
    onDoubleClickRow(row);
  }

  const handleSort = sortModel => {
    if (!canEditData) {
      return;
    }
    handleSortModelChanged(sortModel, data, onChange);
  }

  const getRowNodeId = data => {
    return data.order;
  }

  return (
    <div className="CompanyBuyerProjectsTable full-height">
      <AgGridTable
        columnDefs={getColumnDefs()}
        getRowClass={handleGetRowClass}
        getRowNodeId={getRowNodeId}
        isFetching={data.get('isFetching')}
        onGetNextPageData={onGetNextPageData}
        onGridReady={handleGridReady}
        onRowDoubleClicked={onDoubleClickRow}
        onSortModelChanged={handleSort}
        page={data.get('page')}
        rowData={data.get('queryResults')}
        totalPages={data.get('totalPages')}
        sortable
      />
    </div>
  );
}

CompanyBuyerPlatformProjectTableComponent.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
};

/** Company buyer platform project table component. */
export const CompanyBuyerPlatformProjectTable = memo(CompanyBuyerPlatformProjectTableComponent);
