import PropTypes from 'prop-types';
import React, { memo, useRef } from 'react';
import { List } from 'immutable';

import { TableFiles } from './Files/TableFiles';

const CompanyFilesPanelComponent = ({ files, uploadFile, canEditData, onDeleteFile }) => {
  const inputSubmitRef = useRef()

  const handleOpenFileDialog = () => {
    if (inputSubmitRef) {
      inputSubmitRef.current.click();
    }
  };

  return (
    <div className="panel panel-default">
      <div className="panel-heading" data-parent="#accordion" data-target="#acc-slide-5" data-toggle="collapse">
        <h4 className="panel-title">Files</h4>
      </div>
      <div className="panel-collapse collapse" id="acc-slide-5">
        <div className="panel-body">
          <form className="p20" onSubmit={uploadFile}>
            <TableFiles
              canEditData={canEditData}
              files={files}
              onDeleteFile={onDeleteFile}
              onUploadFile={handleOpenFileDialog}
            />
            <button ref={inputSubmitRef} className="hidden" type="submit" />
          </form>
        </div>
      </div>
    </div>
  );
}

CompanyFilesPanelComponent.propTypes = {
  files: PropTypes.instanceOf(List).isRequired,
  uploadFile: PropTypes.func.isRequired,
};

/** Company files panel component. */
export const CompanyFilesPanel = memo(CompanyFilesPanelComponent);
