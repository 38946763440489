import React, { memo } from 'react';

const WarningMessageComponent = ({ show, messageText }) => {
  if (show) {
    return <div>{messageText}</div>;
  }

  return null;
};

export const WarningMessage = memo(WarningMessageComponent);
