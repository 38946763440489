import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { toJS } from '../../../utils/ChangeSpy';

import { TopBlock } from './TopBlock/TopBlock';
import { MidBlock } from './MidBlock/MidBlock';
import { BottomBlock } from './BottomBlock/BottomBlock';

const TradeShowComponent = ({
  data,
  disableSave,
  onTextChange,
  onTableInsert,
  onAddEvent,
  onEventRemove,
  industryTags,
  businessModels,
  onIndustryClick,
  onRowDelete,
  onSubmit,
  onYearFilterChange,
  tradeShowId,
  UserEditor,
  errors,
  yearFilter,
  onCompaniesSortingChange,
  onGetNextPageData,
  onRowDoubleClicked,
}) =>
  <div className="container trade-show-page">
    <div className="m50">
      <TopBlock
        data={data}
        errors={errors}
        models={businessModels}
        onIndustryClick={onIndustryClick}
        onRowDelete={onRowDelete}
        onTableInsert={onTableInsert}
        onTextChange={onTextChange}
        tags={industryTags}
        UserEditor={UserEditor}
      />
      <MidBlock data={data} onAddEvent={onAddEvent} onEventRemove={onEventRemove} yearFilter={yearFilter} />
      <BottomBlock
        data={data}
        events={toJS(data.get('events'))}
        onCompaniesSortingChange={onCompaniesSortingChange}
        onGetNextPageData={onGetNextPageData}
        onRowDoubleClicked={onRowDoubleClicked}
        onYearFilterChange={onYearFilterChange}
      />
    </div>
    <div className="row trade-show-footer">
      <div className="container-fluid text-right save-button-container">
        {!data.get('isFetching') && (
          <button className="btn btn-default btn-xs" disabled={disableSave} onClick={onSubmit} type="submit">
            <i className="icon-user icon-white" />
            {tradeShowId ? 'Save' : 'Add new Trade Show'}
          </button>
        )}
      </div>
    </div>
  </div>

TradeShowComponent.propTypes = {
  businessModels: PropTypes.instanceOf(Map).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  disableSave: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
  industryTags: PropTypes.instanceOf(Map).isRequired,
  onAddEvent: PropTypes.func,
  onCompaniesSortingChange: PropTypes.func,
  onEventRemove: PropTypes.func,
  onGetNextPageData: PropTypes.func,
  onIndustryClick: PropTypes.func,
  onRowDelete: PropTypes.func,
  onRowDoubleClicked: PropTypes.func,
  onSubmit: PropTypes.func,
  onTextChange: PropTypes.func,
  onYearFilterChange: PropTypes.func,
  tradeShowId: PropTypes.string,
  yearFilter: PropTypes.string,
};

/** Trade show component. */
export const TradeShow = memo(TradeShowComponent);
