import React, { memo } from 'react';

import { unwrap } from '../../../utils/ChangeSpy';

import { Checkbox } from './Checkbox';

const ModelBusinessComponent = ({ businessModels, onModelChange, collapsed, onClick }) => {
  const rows = !collapsed ? (
    <ul>
      {unwrap(businessModels).map((bm) => (
        <li key={bm.get('id')}>
          <Checkbox
            checked={bm.get('checked', false)}
            id={bm.get('id')}
            name={bm.get('category')}
            onChange={onModelChange}
          />
        </li>
      ))}
    </ul>
  ) : null;

  return (
    <div className="tag-row">
      <div>
        <div className="checkbox">
          <label className="pl0">
            Business Model
            <i
              aria-hidden="true"
              className={collapsed ? 'fa fa-caret-square-o-down' : 'fa fa-caret-square-o-up'}
              onClick={onClick}
              title={collapsed ? 'Click to expand' : 'Click to collapse'}
            />
          </label>
        </div>
        {rows}
      </div>
    </div>
  );
};

export const ModelBusiness = memo(ModelBusinessComponent);
