import React, { memo, useEffect, useState } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import config from '../../../../config';
import Table from '../../../helpers/AgGridTable';
import AgHeader from './MidPanel/AgHeader';

const ApprovedFormat = ({ value }) => {
  const className = value ? 'fa-check' : 'fa-times';

  return (
    value && <div>
      <i className={`icon-approved fa ${className}`} />
    </div>
  )
}

const TargetsComponent = ({ targets, common, canEditData, onDoubleClick, onSort }) => {
  const [api, setApi] = useState(null);

  useEffect(() => {
    if (api) {
      api.sizeColumnsToFit();
    }
  }, [api])

  const columnDefs = config.tables.getIn(['project', 'targetBuyer', 'columns']).map(col => {
    const field = col.get('field');

    if (field === 'approved') {
      return col.merge({
        headerComponentFramework: AgHeader,
        cellRendererFramework: ApprovedFormat,
        sortable: true,
      });
    }

    return col.merge({
      headerComponentFramework: AgHeader,
      sortable: true,
    });
  });

  const handleRowDoubleClicked = params => {
    if (!canEditData) {
      return;
    }

    onDoubleClick({
      row: Map({
        id: params.data.id,
      }),
      type: 'targets',
    });
  }

  const handleGridSizeChanged = () => {
    if (api) {
      api.sizeColumnsToFit();
    }
  }

  const handleGridReady = params => {
    setApi(params.api);
  }

  const handleSort = e => {
    if (!canEditData) {
      return;
    }

    if (e && e.length) {
      onSort(e, {
        type: 'targets',
        direction: e[0].sort === 'asc' ? 'up' : 'down',
        field: e[0].colId,
      });
    }
  }

  const rowData = targets.map((target, i) => target.set('i', i + 1));

  return (
    <div className="table-target">
      <Table
        columnDefs={columnDefs}
        isFetching={common.get('targetLoading')}
        onGridReady={handleGridReady}
        onGridSizeChanged={handleGridSizeChanged}
        onRowDoubleClicked={handleRowDoubleClicked}
        onSortModelChanged={handleSort}
        rowData={rowData}
        enableSorting
      />
    </div>
  );
}

TargetsComponent.propTypes = {
  canEditData: PropTypes.bool.isRequired,
};

/** Targets component. */
export const Targets = memo(TargetsComponent);
