import React from 'react';
import classNames from 'classnames';
import { ContextMenuTrigger } from 'react-contextmenu';
import { isDeepChanged } from 'src/utils/ChangeSpy';

/**
 * General format component.
 * To fix issue: "Cannot refresh cells with cellRendererFramework".
 */
export const GeneralFormat = ({
  value,
  column: { colId },
  data,
  colDef,
  rowIndex
}) => {

  const collect = () => {
    return {
      rowIndex,
      rowData: data,
    };
  }
  const isNoApproval = data.id === 'no_approval_lists';

  const divClassName = classNames('ag-cell ag-cell-not-inline-editing', {
    'cell cell--marked': isDeepChanged(data),
    'no-approval-list__row': isNoApproval,
  });
  let content = null;

  if (colId === 'dateReceived' || colId === 'dateSent') {
    const dt = value.value;

    content = <div className={divClassName}>{dt && dt.isValid() ? dt.format('MM/DD/YYYY') : ''}</div>;
  } else if (colId === 'applistLabel') {
    content = <div className={divClassName}>{value.value}</div>;
  } else {
    content = <div className={divClassName}>{value}</div>;
  }

  if (isNoApproval) return content;

  return (
    <ContextMenuTrigger collect={collect} id={colDef.contextMenuId}>
      {content}
    </ContextMenuTrigger>
  );
}
