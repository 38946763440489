import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Immutable from 'immutable';
import connectOptions, { mergeProps } from '../utils/connectOptions';
import { Action } from '../components/NextAction';
import {
  getUserSettings,
  changeSorting,
  changeFiltering,
  getNextAction,
  deselectAll,
  selectAll,
  deselectAction,
  selectAction,
  toggleBacklog,
  deleteNextActions,
  removeNAItems,
  saveSettings,
  updateSortOrder,
  updateEvent,
} from '../actions/nextAction/main';
import config from '../config';
import { detectBottom } from '../utils/scrollbar';
import { showError, showInformation } from '../utils/MessagePopup';
import debounce from '../utils/debounce';
import deletionCheck from '../utils/deletingPermission';
import { subscribe } from '../decorators/tabChange';
import { isResearcher } from '../utils/checkPermissions';
import { DELETE_FAIL_TEXT, DELETE_SUCCESSFULLY_TEXT } from '../config/constants/common';
import downloadFile from '../utils/downloadFile';

/**
 * State component for next action page.
 *
 * @param props {Object}.
 * @param loggedUser {Immutable.Map) logged user information.
 * @param actions {Immutable.List} List of next action item.
 * @param selected {Immutable.List} List of selected next action item.
 * @param disabled {Boolean} If set to true, will disabled drop down menu.
 * @param exportingDisabled {Boolean} If set to true, will disabled exporting button.
 * @param settings {Immutable.Map} User's setting of sorting and filtering.
 * @param common {Immutable.Map} Common information.
 * @param actionConfigs {Immutable.List} Action configuration of drop down list menu.
 * @returns {React.Component}
 * @class
 */
class NextAction extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleChangingSort = this.handleChangingSort.bind(this);
    this.handleExportingClick = debounce(this.handleExportingClick.bind(this));
    this.handleFilteringChange = debounce(this.handleFilteringChange.bind(this));
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleDeselectAll = this.handleDeselectAll.bind(this);
    this.handleRowSelected = this.handleRowSelected.bind(this);
    this.handleContextMenuItemClick = this.handleContextMenuItemClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.setGridReference = this.setGridReference.bind(this);
    this.handleBLClick = debounce(this.handleBLClick.bind(this));
    this.handleDeleteNA = deletionCheck(this.props.loggedUser, this.handleDeleteNA.bind(this), context);
    this.handleChangeOrder = debounce(this.handleChangeOrder.bind(this));
    this.showWarningPopup = this.showWarningPopup.bind(this);
    this.handleTabNotification = this.handleTabNotification.bind(this);
  }

  componentDidMount() {
    if (this.hasAccess()) {
      this.props.getUserSettings();
      this.props.getNextAction();
      this.grid.addEventListener('scroll', this.handleScroll);
    } else {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.history.push('/');
        this.context.closePopup();
      });
    }
  }

  componentWillUnmount() {
    if (this.grid) {
      this.grid.removeEventListener('scroll', this.handleScroll);
    }
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  showWarningPopup() {
    showError(this.context.openPopup, config.permisionError, () => {
      this.props.history.push('/');
      this.context.closePopup();
    });
  }

  handleScroll() {
    const { common } = this.props;
    const nextPage = common.get('nextPage');
    const isFetchingAction = common.get('loadingAction');

    if (detectBottom(this.grid) && nextPage > 0 && !isFetchingAction) {
      this.props.getNextAction(nextPage);
    }
  }

  handleChangingSort(field, direction, included, order) {
    if (field !== 'Add...') {
      this.props.changeSorting(field, direction, included, order);
    }
  }

  handleRemovingSortField(field) {
    this.props.removeSort(field);
  }

  handleExportingClick() {
    const { actions, common } = this.props;
    const exporting = common.get('exporting');
    const exportingDisabled = common.get('exportingDisabled');

    if (!exportingDisabled && !exporting) {
      const idList = actions.map(a => a.get('id')).toArray();
      const url = `${config.API_BASE_URL}/api/v1/events/next_actions/export`;

      downloadFile({
        url,
        headers: {
          'content-type': 'application/json',
        },
        method: 'post',
        body: {
          params: {
            selected_ids: idList,
          },
        },
      });
    }
  }

  handleSelectAll() {
    this.props.selectAll();
  }

  handleDeselectAll() {
    this.props.deselectAll();
  }

  handleFilteringChange(event) {
    this.props.changeFiltering(event.target.name, event.target.checked);
  }

  handleRowSelected(event) {
    if (event.target.checked) {
      this.props.selectAction(event.target.value);
    } else {
      this.props.deselectAction(event.target.value);
    }
  }

  /**
   * Delete NA if current user has role of ADMINISTRATOR or PROCESS_MANAGER.
   */
  handleDeleteNA(idList) {
    const beforeStart = () => this.context.startPopupLoading();
    const afterError = () => showError(this.context.openPopup, [DELETE_FAIL_TEXT]);

    const afterSuccess = () => {
      showInformation(this.context.openPopup, DELETE_SUCCESSFULLY_TEXT);
      this.props.removeNAItems(idList);
    };

    this.props.deleteNextActions(idList, beforeStart, afterSuccess, afterError);
  }

  handleContextMenuItemClick(event, { key, selected }) {
    const idList = selected.map(s => s.get('id')).toArray();

    if (key === 'deleteSelected') {
      this.context.openPopup('DeleteConfirmPopup', {
        delete: () => this.handleDeleteNA(idList),
        title: 'Delete Next Action',
      });
    } else if (key === 'exportSelected') {
      const url = `${config.API_BASE_URL}/api/v1/events/next_actions/export`;

      downloadFile({
        url,
        headers: {
          'content-type': 'application/json',
        },
        method: 'post',
        body: {
          params: {
            selected_ids: idList,
          },
        },
      });
    } else if (key === 'flipSelectedToDifferentTeam') {
      const targetIds = selected.map(s => s.get('targetId'));

      this.context.openPopup('InternalFlipPopup', { idList: targetIds });
    } else if (key === 'addUpdateBuyerForSelected') {
      this.context.openPopup('AddUpdateBuyerPopup', { onSave: () => {} });
    }
  }

  setGridReference(input) {
    this.grid = input;
  }

  handleBLClick({ id, backlogged }) {
    this.props.toggleBacklog(id, !backlogged);
  }

  handleChangeOrder(ordereds) {
    const { settings } = this.props;

    this.props.updateSortOrder(ordereds);
    this.props.saveSettings(this.mapToSorts(settings, ordereds).toJS());
  }

  /**
   * Map order list to setting list.
   *
   * @param settings {Immutable.Map}.
   * @param ordereds {Array}.
   */
  mapToSorts(settings, ordereds) {
    const sorts = settings.get('sorts').map(s => s.set('order', ordereds[s.get('si')] || -1));

    return settings.set('sorts', sorts);
  }

  handleTabNotification() {
    this.props.getNextAction();
  }

  render() {
    const { loggedUser, ...rest } = this.props;
    const userName = `${loggedUser.get('firstName', '')} ${loggedUser.get('lastName', '')}`;
    const content = this.hasAccess() ? (
      <Action
        {...rest}
        onBLClick={this.handleBLClick}
        onChangeOrder={this.handleChangeOrder}
        onChangeSort={this.handleChangingSort}
        onDeselectAll={this.handleDeselectAll}
        onExportClick={this.handleExportingClick}
        onFilterChange={this.handleFilteringChange}
        onMenuSelect={this.handleContextMenuItemClick}
        onRowSelected={this.handleRowSelected}
        onSelectAll={this.handleSelectAll}
        setGridReference={this.setGridReference}
        userName={userName}
      />
    ) : null;

    return (
      <React.Fragment>
        <Helmet title="Next Actions" />
        {content}
      </React.Fragment>
    );
  }
}

NextAction.propTypes = {
  actionConfigs: PropTypes.instanceOf(Immutable.List).isRequired,
  actions: PropTypes.instanceOf(Immutable.List).isRequired,
  activityFilters: PropTypes.instanceOf(Immutable.Map).isRequired,
  common: PropTypes.instanceOf(Immutable.Map).isRequired,
  disabled: PropTypes.bool.isRequired,
  exportingDisabled: PropTypes.bool.isRequired,
  loggedUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  mailFilters: PropTypes.instanceOf(Immutable.Map).isRequired,
  selected: PropTypes.instanceOf(Immutable.List).isRequired,
  settings: PropTypes.instanceOf(Immutable.Map).isRequired,
  sorts: PropTypes.instanceOf(Immutable.List).isRequired,
  typeFilters: PropTypes.instanceOf(Immutable.Map).isRequired,
  userFilters: PropTypes.instanceOf(Immutable.Map).isRequired,
};

NextAction.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const stateToProps = state => {
  const na = state.nextAction.main;
  const actions = na.get('actions');
  const selected = actions.filter(a => a.get('selected', false));

  return {
    loggedUser: state.auth.get('user'),
    actions,
    selected,
    disabled: selected.size === 0,
    exportingDisabled: actions.size === 0,
    settings: na.get('settings'),
    sorts: na.getIn(['settings', 'sorts'], Immutable.List()),
    typeFilters: na.getIn(['settings', 'filters', 0], Immutable.Map()),
    mailFilters: na.getIn(['settings', 'filters', 1], Immutable.Map()),
    activityFilters: na.getIn(['settings', 'filters', 2], Immutable.Map()),
    userFilters: na.getIn(['settings', 'filters', 3], Immutable.Map()),
    common: na.get('common'),
    actionConfigs: config.tables.getIn(['nextaction', 'action']),
  };
};

export default connect(
  stateToProps,
  {
    getUserSettings,
    changeSorting,
    changeFiltering,
    getNextAction,
    deselectAll,
    selectAll,
    deselectAction,
    selectAction,
    toggleBacklog,
    deleteNextActions,
    removeNAItems,
    saveSettings,
    updateSortOrder,
    updateEvent,
  },
  mergeProps,
  connectOptions,
)(subscribe(NextAction, 'onEventChange'));
