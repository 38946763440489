import React, { useRef, useState, useEffect, useCallback, memo } from 'react';
import { fromJS, List } from 'immutable';

import Table from '../helpers/AgGridTable';
import config from '../../config';
import { RecipientsHeader } from './RecipientsHeader';
import { InlineInput } from '../helpers/Input';
import TextEditor from './TextEditor';
import { GeneralFormat } from './GeneralFormat';
import { ACTION_DELETE } from '../helpers/DeleteItem';

const FootSectionComponent = ({ sending, onGenerateLink, generateEmailLink, emailLink }) => {
  const [recipients, setRecipients] = useState([]);
  const [expiresAt, setExpiresAt] = useState(null);

  const wrapperRef = useRef(null);
  const apiRef = useRef(null);

  const handleGridReady = useCallback((params) => {
    apiRef.current = params.api;
    apiRef.current.sizeColumnsToFit();
  }, []);

  const handleInsert = useCallback(() => {
    if (!apiRef.current) return;

    apiRef.current.stopEditing();

    let rowIndex = -1;

    apiRef.current.forEachNode((rowNode) => {
      if (rowNode.data.id === 0) {
        rowIndex = rowNode.rowIndex;
        return false;
      }
    });

    if (rowIndex === -1) {
      const transaction = apiRef.current.updateRowData({
        add: [
          {
            id: 0,
            email: '',
          },
        ],
      });

      rowIndex = transaction.add[0].rowIndex;
    }

    apiRef.current.startEditingCell({ rowIndex, colKey: 'email' });
  }, []);

  const columns = config.tables.getIn(['approval', 'recipients']).map((col) =>
    col.merge({
      editable: true,
      headerComponentFramework: RecipientsHeader,
      cellRendererFramework: GeneralFormat,
      cellEditorFramework: TextEditor,
      onClick: handleInsert,
    })
  );

  const getRowNodeId = (data) => data.id;

  const handleCellClicked = useCallback((cell) => {
    const {
      column: { colId },
      node: { rowIndex },
    } = cell;

    apiRef.current.startEditingCell({ rowIndex, colKey: colId });
  }, []);

  const handleRowEditingStopped = useCallback(
    ({ data }) => {
      if (data.id > 0) {
        setRecipients((prevRecipients) =>
          prevRecipients.map((recipient) => (data.id === recipient.id ? data : recipient))
        );
      }

      if (data.id === 0 && data.email) {
        setRecipients((prevRecipients) => [
          ...prevRecipients,
          { ...data, id: prevRecipients.length + 1 },
        ]);
      }

      generateEmailLink(recipients.map((recipient) => recipient.email));
    },
    [generateEmailLink, recipients]
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (apiRef.current) {
          if (!/react-datepicker/.test(event.target.className) && event.target.className !== '') {
            apiRef.current.stopEditing();
          }
        }
      }
    },
    []
  );

  const handleContextMenuClick = useCallback(
    (event, data) => {
      const { id } = data.rowData;

      setRecipients((prevRecipients) =>
        prevRecipients.filter((recipient) => id !== recipient.id)
      );

      generateEmailLink(recipients.map((recipient) => recipient.email));
    },
    [generateEmailLink, recipients]
  );

  const handleGenerateLink = useCallback(() => {
    apiRef.current.stopEditing();

    setTimeout(() => {
      const linkData = {
        expiresAt: expiresAt.toLocaleString(),
      };

      onGenerateLink(linkData);
    }, 100);
  }, [expiresAt, onGenerateLink]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const data = List(fromJS(recipients));
  const isDisabledLink = recipients.length === 0 || sending;

  return (
    <div className="row mt20 pb20">
      <div ref={wrapperRef} className="col-sm-5 table-approval-recipients">
        <Table
          columnDefs={columns}
          contextActionList={ACTION_DELETE}
          editType="fullRow"
          getRowNodeId={getRowNodeId}
          onCellClicked={handleCellClicked}
          onContextMenuClick={handleContextMenuClick}
          onGridReady={handleGridReady}
          onRowEditingStopped={handleRowEditingStopped}
          rowData={data}
          sortable={false}
        />
        <InlineInput
          className="readonly-datepicker"
          label="Pass Exp Date:"
          name="expiresAt"
          onChange={(e) => setExpiresAt(e.target.value)}
          placeholder="Date"
          type="date"
          value={expiresAt}
          showLabel
        />
      </div>
      <div className="col-sm-7 text-right">
        <button
          className="btn btn-primary btn-xs mr15"
          disabled={expiresAt === null || sending}
          onClick={handleGenerateLink}
        >
          Generate Link
        </button>
        <a
          className="btn btn-primary btn-xs generate-email-button"
          disabled={isDisabledLink}
          href={isDisabledLink ? null : emailLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Generate Email
        </a>
      </div>
    </div>
  );
};

export const FootSection = memo(FootSectionComponent);
