import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { GridRow } from './GridRow';

/**
 * Stateless component for a buyer next action row.
 *
 * @param props {Object}.
 * @param props.action {Immutable.Map} Next action row.
 * @param props.index {Number} Row number.
 * @param props.onRowSelected {function} To handle checkbox change event.
 * @returns {React.Component}
 */
const BuyerRowComponent = ({ action, index, onRowSelected }) => {
  const actionId = action.get('id');
  const project = (
    <span
      className={classNames({
        'text-uppercase text-danger text-bold': !action.get('projectId'),
      })}
    >
      {action.get('projectName')}
    </span>
  );
  const buyer = (
    <span
      className={classNames({
        'text-uppercase text-danger text-bold': !action.get('buyerId'),
      })}
    >
      {action.get('buyerName')}
    </span>
  );
  const checked = action.get('selected', false);
  const date = action.get('date');
  const userName = action.get('userName');
  const activity = action.get('activity');
  const linkTo = `/company/${action.get('buyerId')}`;
  const legalName = action.get('legalName');
  const currentStatus = action.get('currentStatus');
  const highStatus = action.get('highStatus');
  const priority = action.get('priority');
  const entityType = action.get('entityType');
  const buyerType = action.get('buyerType');
  const address = action.get('address');
  const web = action.get('web');
  const webLink = action.get('webLink');
  const buyerParent = action.get('buyerParent');

  return (
    <GridRow action={action}>
      <td>{index}</td>
      <td>
        <input checked={checked} name="selected" onChange={onRowSelected} type="checkbox" value={actionId} />
      </td>
      <td>{date}</td>
      <td colSpan="2">{userName}</td>
      <td colSpan="2">{activity}</td>
      <td>
        <Link target="_blank" to={linkTo}>
          {legalName}
        </Link>
      </td>
      <td>
        <strong>{currentStatus}</strong>&nbsp;/&nbsp;{highStatus}
      </td>
      <td className="text-uppercase">{priority}</td>
      <td>{entityType}</td>
      <td colSpan="3">{buyerType}</td>
      <td>{address}</td>
      <td>
        <a href={webLink} rel="noopener noreferrer" target="_blank">
          {web}
        </a>
      </td>
      <td>
        {buyer} - {project}
      </td>
      <td>{buyerParent}</td>
    </GridRow>
  );
};

BuyerRowComponent.propTypes = {
  action: PropTypes.instanceOf(Map).isRequired,
  index: PropTypes.number,
  onClick: PropTypes.func,
  onRowSelected: PropTypes.func.isRequired,
};

export const BuyerRow = memo(BuyerRowComponent);
