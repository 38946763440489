import PropTypes from 'prop-types';

import React, { memo } from 'react';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import classNames from 'classnames';

import { InputProps } from '../helpers/AutoComplete';
import UrlMaker from '../../routing/UrlMaker';
import Span from '../helpers/Span';
import { unwrap, isChanged, toJS } from '../../utils/ChangeSpy';
import uniqueId from '../../utils/uniqueId';
import Input from '../helpers/Input';
import Textarea from '../helpers/Textarea';
import FormGroup from '../helpers/FormGroup';
import LoadAnimation from '../decorators/LoadAnimation';

const ContextMenuWrapper = (onClick, isFirst) => {
  if (isFirst) return value => <div>{value}</div>;

  return (value, data) => {
    const id = uniqueId();

    return (
      <div>
        <ContextMenuTrigger id={id}>{value}</ContextMenuTrigger>

        <ContextMenu id={id}>
          <MenuItem data={data.toJS()} onClick={onClick}>
            <i className="fa fa-remove" /> Delete
          </MenuItem>
        </ContextMenu>
      </div>
    );
  };
};

const ApprovedStatus = ({ approved }) => {
  const isApproved = unwrap(approved);

  const iconClassName = classNames('fa', {
    'fa-check-circle-o text-success': isApproved === true,
    'fa-times text-danger': isApproved === false,
  });

  return (
    <Span checkOn="data-value" data-value={approved}>
      <i className={iconClassName} />
    </Span>
  );
};

const ApprovedSwitcher = ({ approved, changeApprove }) => {
  const isApproved = unwrap(approved);

  const approvedClassName = classNames('fa fa-check-circle-o', {
    'text-success': isApproved === true,
    hidden: isApproved === false,
  });
  const unapprovedClassName = classNames('fa fa-times', {
    hidden: isApproved === true,
    'text-danger': isApproved === false,
  });

  return (
    <FormGroup>
      <Span checkOn="data-value" data-value={approved}>
        <i className={approvedClassName} onClick={event => changeApprove(event, { target: 'on' })} />
        <i className={unapprovedClassName} onClick={event => changeApprove(event, { target: 'off' })} />
      </Span>
    </FormGroup>
  );
};

/**
 * Show red triangle icon on header bar, if buyer information change.
 */
function getHeaderPanelClass(buyer, isOpened) {
  const buyerChanged =
    isChanged(buyer.getIn(['activeBuyer'])) ||
    isChanged(buyer.getIn(['priority'])) ||
    isChanged(buyer.getIn(['bsnHarvco'])) ||
    isChanged(buyer.getIn(['approved'])) ||
    isChanged(buyer.getIn(['dsplProjectCategory'])) ||
    isChanged(buyer.getIn(['bsnClient'])) ||
    isChanged(buyer.getIn(['harveyComment']));

  return classNames('panel-heading cursor-grab', {
    changed: buyerChanged && !isOpened,
  });
}

/**
 * Company buyer component.
 *
 * @param props {Object}.
 * @param props.buyer {Immutable.Map} Buyer definition.
 * @returns {XML}
 * @class
 */
const CompanyBuyerComponent = ({
  id = uniqueId(),
  isFirst,
  headerClassName,
  prefix,
  onDel,
  onClick,
  buyer,
  onActivate,
  onChangeApprove,
  suggestApprovalList,
  suggestProject,
  statuses,
  onUpdateHighStatus,
  canEditData,
}) => {
  const isOpened = buyer.get('isOpened');
  const className = classNames('panel-collapse collapse', { in: isOpened });
  const currentStatus = buyer.getIn(['currentStatus']);
  const highStatus = buyer.getIn(['highStatus']);
  const harveyComment = toJS(buyer.get('harveyComment'));
  const clientComment = toJS(buyer.get('bsnClient'));

  const checkDefaultComment = commentType => {
    let harveyCommentDefault = false;
    let clientCommentDefault = false;

    if (!harveyComment && !clientComment) {
      harveyCommentDefault = true;
      clientCommentDefault = false;
    } else if (harveyComment && clientComment) {
      harveyCommentDefault = false;
      clientCommentDefault = true;
    } else {
      harveyCommentDefault = !!harveyComment;
      clientCommentDefault = !!clientComment;
    }

    switch (commentType) {
      case 'client':
        return clientCommentDefault;

      case 'harvey':
        return harveyCommentDefault;

      default:
        return false;
    }
  };

  const cSt = unwrap(currentStatus);
  const hSt = unwrap(highStatus);
  const currentTitle = statuses.size
    ? statuses.find(status => status.get('value') === cSt, null, statuses.get(0)).get('name')
    : '';

  const highTitle = statuses.size
    ? statuses.find(status => status.get('value') === hSt, null, statuses.get(0)).get('name')
    : '';

  let headerContent = unwrap(buyer.get('dsplBuyerAbbrName')) || unwrap(buyer.get('dsplBuyerLegalName'));

  if (isOpened) {
    const companyId = buyer.get('buyerId');
    const url = UrlMaker.create('/company/:companyId/buyer').mapParams({
      companyId,
    });

    headerContent = (
      <Link target="_blank" to={url}>
        {headerContent}
      </Link>
    );
  }

  const activeState = unwrap(buyer.getIn(['activeBuyer'], false)) ? (
    'currently active'
  ) : (
    <a onClick={onActivate}>make active</a>
  );
  const approved = buyer.getIn(['approved']);
  const stateClass = classNames('pull-right', {
    changed: isChanged(buyer.getIn(['activeBuyer'])),
  });
  const headerPanelClass = getHeaderPanelClass(buyer, isOpened);

  const callUpdateHighStatus = (event, buyer) => {
    event.stopPropagation();
    event.preventDefault();

    if (canEditData) {
      onUpdateHighStatus(buyer);
    }
  };
  const hasUpdated = !!buyer.getIn(['highStatusUpdated']);
  const statusClassName = classNames({
    'cursor-pointer': hasUpdated,
    'cursor-grab': !hasUpdated,
  });

  const header = ContextMenuWrapper(onDel, isFirst)(
    <div className={headerPanelClass} onClick={onClick}>
      <h4 className={classNames('panel-title cursor-pointer', headerClassName)}> {headerContent} </h4>
      <span className="pull-right">
        <b>
          <Span
            checkOn="children"
            className={statusClassName}
            onClick={event => {
              callUpdateHighStatus(event, buyer);
            }}
            title={currentTitle}
          >
            {currentStatus}
          </Span>
        </b>
        <span className={statusClassName}> / </span>
        <Span
          checkOn="children"
          className={statusClassName}
          onClick={event => {
            callUpdateHighStatus(event, buyer);
          }}
          title={highTitle}
        >
          {highStatus}
        </Span>
        <span />
        <Span checkOn="children">{buyer.getIn(['priority'])}</Span>
        <span />
        <ApprovedStatus approved={approved} />
      </span>
    </div>,
    buyer,
  );

  const body = !isOpened ? null : (
    <div className={className} id={id}>
      <div className="panel-body">
        <div className="highlight">
          <div className="row flex-grid col-lg-12">
            <div className="col-lg-6">
              <div className="form-group">
                <InputProps
                  className="form-control input-sm text-bold"
                  disabled={!canEditData}
                  label="Project"
                  placeholder="Project"
                >
                  {suggestProject}
                </InputProps>
              </div>
              <FormGroup>
                <InputProps
                  className="form-control input-sm text-bold"
                  disabled={!canEditData}
                  label="Approval List"
                  placeholder="Approval List"
                >
                  {suggestApprovalList}
                </InputProps>
              </FormGroup>
              <div className="d-flex">
                <FormGroup className="d-flex">
                  <Input
                    className="input-sm w-50"
                    disabled={!canEditData || !unwrap(buyer.get('projectId'))}
                    label="Priority"
                    name={`${prefix}.priority`}
                    placeholder="Priority"
                    value={buyer.get('priority')}
                    showLabel
                  />
                </FormGroup>
                <div className="approve-switcher-block">
                  <ApprovedSwitcher approved={approved} changeApprove={onChangeApprove} />
                </div>
              </div>
              <FormGroup>
                <Textarea
                  disabled={!canEditData}
                  label="Status Summary"
                  name={`${prefix}.bsnHarvco`}
                  placeholder="Status Summary"
                  rows="2"
                  value={buyer.get('bsnHarvco')}
                  showLabel
                />
              </FormGroup>
            </div>
            <div className="col-lg-6">
              <FormGroup>
                <div className="tabs">
                  <input
                    className="tab-btn-1"
                    defaultChecked={checkDefaultComment('harvey')}
                    id="tab-btn-1"
                    name="tab-btn"
                    type="radio"
                    value=""
                  />
                  <label className={toJS(buyer.get('harveyComment')) ? '' : 'muted-label'} htmlFor="tab-btn-1">
                    Harvey Comments
                  </label>
                  <input
                    className="tab-btn-2"
                    defaultChecked={checkDefaultComment('client')}
                    id="tab-btn-2"
                    name="tab-btn"
                    type="radio"
                    value=""
                  />
                  <label className={toJS(buyer.get('bsnClient')) ? '' : 'muted-label'} htmlFor="tab-btn-2">
                    Client Comments
                  </label>

                  <div className="content-1">
                    <Textarea
                      className="tabs-textarea"
                      disabled={!canEditData}
                      name={`${prefix}.harveyComment`}
                      placeholder="Harvey Comments"
                      rows="2"
                      showLabel={false}
                      value={buyer.get('harveyComment')}
                    />
                  </div>
                  <div className="content-2">
                    <Textarea
                      className="tabs-textarea"
                      disabled={!canEditData}
                      name={`${prefix}.bsnClient`}
                      placeholder="Client Comments"
                      rows="2"
                      showLabel={false}
                      value={buyer.get('bsnClient')}
                    />
                  </div>
                </div>
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="row pr7">
          <div className="clearfix" />
          <i className={stateClass}> {activeState} </i>
        </div>
      </div>
    </div>
  );

  return (
    <div className="panel panel-default">
      {header}
      {body}
    </div>
  );
};

CompanyBuyerComponent.propTypes = {
  buyer: PropTypes.instanceOf(Map).isRequired,
  headerClassName: PropTypes.string,
  id: PropTypes.string,
  onActivate: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onDel: PropTypes.func.isRequired,
};

/** Company buyer component. */
export const CompanyBuyer = memo(CompanyBuyerComponent)
