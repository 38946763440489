import Immutable, { List } from 'immutable';

import * as ActionType from '../actions/windward';
import getErrorResponse from '../utils/getErrorResponse';
import getValidator from '../utils/getValidator';
import { setIn, spy, toJS } from '../utils/ChangeSpy';

const SCHEMA = {
  type: 'object',
  properties: {
    module: {
      title: 'Module',
      description: 'should not be empty',
      type: 'number',
      required: true,
    },
    file: {
      title: 'File',
      description: 'should be .xlsx or .docx',
      oneOf: [{ type: 'string', pattern: /([a-zA-Z0-9\s_\\.\-\(\):])+(.xlsx|.docx)$/i }],
      required: true,
    },
  },
};

const FIELDS_TO_SPY = ['module', 'file'];

const defaultState = Immutable.fromJS(
  spy(
    {
      loadingTemplates: false,
      loadingReports: false,
      isUploading: false,
      isDeleting: false,
      templates: List(),
      reports: List(),
      errors: [],
      inputErrors: {},
    },
    FIELDS_TO_SPY,
  ),
);

export default function windward(state = defaultState, action) {
  switch (action.type) {
    case ActionType.UPDATE_WINDWARD_TEMPLATES:
      return checkValidity(setIn(state, action.name, action.value));

    case ActionType.FETCHING_WINDWARD_TEMPLATES:
      return state.merge({
        loadingTemplates: true,
      });

    case ActionType.FETCHED_WINDWARD_TEMPLATES_FAILURE:
      return state.merge({
        errors: action.response,
        loadingTemplates: false,
      });

    case ActionType.FETCHED_WINDWARD_TEMPLATES_SUCCESS:
      return state.merge({
        errors: [],
        templates: action.response.data,
        loadingTemplates: false,
      });

    case ActionType.FETCHING_WINDWARD_REPORTS:
      return state.merge({
        loadingReports: true,
      });

    case ActionType.FETCHED_WINDWARD_REPORTS_FAILURE:
      return state.merge({
        errors: action.response,
        loadingReports: false,
      });

    case ActionType.FETCHED_WINDWARD_REPORTS_SUCCESS:
      return state.merge({
        errors: [],
        reports: action.response.data,
        loadingReports: false,
      });

    case ActionType.UPLOADING_WINDWARD_REPORTS:
      return state.merge({
        isUploading: true,
      });

    case ActionType.UPLOADED_WINDWARD_REPORTS_FAILURE:
      return state.merge({
        errors: getErrorResponse(action.response),
        isUploading: false,
      });

    case ActionType.UPLOADED_WINDWARD_REPORTS_SUCCESS:
      return state.merge({
        errors: [],
        isUploading: false,
      });

    case ActionType.DELETING_WINDWARD_TEMPLATE:
      return state.merge({
        isDeleting: true,
      });

    case ActionType.DELETE_WINDWARD_TEMPLATE_FAILURE:
      return state.merge({
        errors: action.response,
        isDeleting: false,
      });

    case ActionType.DELETE_WINDWARD_TEMPLATE_SUCCESS:
      return state.merge({
        errors: [],
        isDeleting: false,
      });

    case ActionType.CLEAR_WINDWARD_TEMPLATE:
      return state.merge({
        loadingTemplates: false,
        loadingReports: false,
        isUploading: false,
        isValid: false,
        module: null,
        file: null,
        errors: [],
        inputErrors: {},
      });

    default:
      return state;
  }
}

const validator = getValidator(SCHEMA);

function checkValidity(state) {
  const plainState = toJS(state);
  const isValid = validator.isValid(plainState);

  const inputErrors = validator.getFirstError(plainState);

  return state.merge({
    inputErrors,
    isValid,
  });
}
