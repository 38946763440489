import React, { memo } from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import config from 'src/config/index';

/**
 * Project text area component.
 *
 * @param props {Object}.
 * @param props.id {String} Id of control.
 * @param props.name {String} Name of control.
 * @param props.value {String} Value of control.
 * @param props.label {String} Label of control.
 * @param {boolean} props.canEditData Whether user can edit any active element that can be changed or clicked.
 * @returns {React.Component}
 * @constructor
 */
const ProjectTextAreaComponent = ({ id, name, field, label, className = '', onTextChange, canEditData }) => {
  const textClassName = classNames({ changed: field.get('dirty') }, 'form-control');
  const type = config.values.getIn(['project', 'types', 'PROJECT']);
  const rowClassName = classNames(className, 'col-sm-6');

  return (
    <div className={rowClassName}>
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <textarea
          className={textClassName}
          id={id}
          name={name}
          onChange={event => onTextChange({ event, data: { type } })}
          readOnly={!canEditData}
          rows="3"
          value={field.get('name')}
        />
      </div>
    </div>
  );
};

ProjectTextAreaComponent.propTypes = {
  canEditData: PropTypes.bool.isRequired,
  field: PropTypes.instanceOf(Map).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
};

/** Project text area component. */
export const ProjectTextArea = memo(ProjectTextAreaComponent);
