import PropTypes from 'prop-types';

import React, { Component } from 'react';
import outsideClick from 'react-click-outside';

/**
 * FormSubmitOnBlur helper.  It append extra `form` tag to component.
 * So if component is something like:
 * `<div><input/></div>` it will be `<form><div><input/></div></form>`
 * **Warn** on submit via blur 1st argument is browser event (not React.SyntheticEvent) and
 * 2nd is reference to form tag.
 *
 * @example
 *     import FormSubmitOnBlur from '...';
 *     const MyComponent = (props) => {
 *       //onSubmit is called on submit and blur.
 *       return (
 *         <FormSubmitOnBlur onSubmit={props.onSubmit}>
 *           SomeContent
 *         </FormSubmitOnBlur>
 *       )
 *     }
 *     const MyComponent2 = (props) => {
 *       //onSubmit is called on submit. onComponentSubmit is called on blur.
 *       return (
 *         <FormSubmitOnBlur onSubmit={props.onSubmit} onComponentBlur={someOtherFunc}>
 *           SomeContent
 *         </FormSubmitOnBlur>
 *       )
 *     }
 *
 * @class
 */
class FormSubmitOnBlur extends Component {
  /**
   *
   * @param props {Object}.
   * @param [props.onComponentBlur] {Function} Callback spawned on blur of component. If not specified
   * decorator submit form itself.
   * @param props.onSubmit {Function} Default callback passed into form tag.
   * @param [props.focusOnClick] {boolean} If true - click event set focused to true.
   * @param props.children {React.Component} Content of form.
   */
  constructor(props) {
    super(props);

    this.focused = false;
    this.timeout = null;
    this.form = null;
    this.getLink = this.getLink.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  getLink(form) {
    this.form = form;
  }

  handleClickOutside(event) {
    if (!this.focused) return;
    this.focused = false;

    const { onSubmit, onComponentBlur = onSubmit, timeout = 100 } = this.props;

    this.timeout = setTimeout(() => onComponentBlur(event, this.form), timeout);
  }

  onFocus(...args) {
    this.focused = true;
    clearTimeout(this.timeout);
    if (this.props.onFocus) {
      return this.props.onFocus(...args);
    }
  }

  onClick(...args) {
    const { focusOnClick = true, onClick } = this.props;

    if (focusOnClick) {
      this.focused = true;
      clearTimeout(this.timeout);
    }

    if (onClick) {
      return onClick(...args);
    }
  }

  render() {
    const { onComponentBlur, timeout, focusOnClick, children, ...rest } = this.props;

    return (
      <form {...rest} ref={this.getLink} onClick={this.onClick} onFocus={this.onFocus}>
        {children}
      </form>
    );
  }
}

FormSubmitOnBlur.propTypes = {
  children: PropTypes.node.isRequired,
  focusOnClick: PropTypes.bool,
  onComponentBlur: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  timeout: PropTypes.number,
};

export default outsideClick(FormSubmitOnBlur);
