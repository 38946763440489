import React, { memo } from 'react';
import Popup from '../helpers/Popup';
import Button from '../helpers/Button';

const ErrorPopupComponent = ({ onClose, error, formError }) => {
  const header = (
    <span>
      <i className="text-danger fa fa-exclamation-circle" /> Error
    </span>
  );
  const footer = (
    <Button className="btn-default btn-xs" onClick={onClose} autoFocus>
      Close
    </Button>
  );
  let errorContent = null;

  if (error) {
    errorContent = error.split('\n').reduce((res, line, i) => {
      if (res.length) res.push(<br key={-i - 1} />);
      res.push(<span key={i}>{line}</span>);

      return res;
    }, []);
  } else if (formError) {
    errorContent = formError.reduce((ret, val, i) => {
      ret.push(<p key={i}>{val}</p>);

      return ret;
    }, []);
  }

  return (
    <Popup footer={footer} header={header} id="error-popup">
      {errorContent}
    </Popup>
  );
};

export const ErrorPopup = memo(ErrorPopupComponent);
