import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import { List, Map, fromJS } from 'immutable';
import { debounce, bindAll, isEmpty, isEqual } from 'underscore';
import { push, replace } from 'connected-react18-router';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import config from '../config';
import Company from '../components/Company';
import { validateAddress } from '../helpers/googleAPI';
import stopProp from '../decorators/stopPropagation';
import { getIn, isDeepChanged, getChanged, unwrap } from '../utils/ChangeSpy';
import { detectDirty } from '../components/Company/CompanyBuyer/AddOnProject/detectDirty';
import { publish } from '../decorators/tabChange';
import * as companyDetailsActions from '../actions/companyDetail';
import * as companyEventsActions from '../actions/company/companyEvents';
import { getBuyers } from '../actions/company/companyTarget';
import { handleTargetDeleteConfirmation } from '../actions/browse';
import {
  checkAccess,
  canApprove,
  isResearcher,
  isJuniorAnalyst,
  isResearcherIntern,
  isProcessManager,
} from '../utils/checkPermissions';
import checkZip from './services/zipRules';
import naDeletionCheck from './services/deletionCheck';
import CountryDropDown from './CountryDropDown';
import confirm from './decorators/confirm';
import { checkPhone, formatPhone } from './services/phoneRules';
import { showError, showInformation } from '../utils/MessagePopup';
import { DELETE_FAIL_TEXT, DELETION_REQUEST_TEXT } from '../config/constants/common';
import {
  BIC,
  BUSINESS_MODELS,
  BUYER_TAGS_ERROR,
  INDUSTRY_CATEGORIES,
  INDUSTRY_TAGS_ERROR,
} from '../utils/industryTagsHelper';

const tabs = config.tabs.get('company');
const values = config.values.get('company');

function checkLegalName(name) {
  if (!name) return false;

  return /[a-z0-9]/i.test(name);
}

function validate(
  values,
  { zip: zipBlurred = false, phone: phoneBlurred = false, legalName: legalNameBlurred = false },
) {
  const { phone, zip, legalName, showCountry } = values;
  const errors = {};

  if (zipBlurred && !showCountry && !checkZip(zip)) {
    errors.zip =
      'Zip code should follow U.S. (XXXXX or XXXXX-XXXX) or Canadian (A1A 1A1) format; otherwise, please set address to International ("...")';
  }

  if (legalNameBlurred && !checkLegalName(legalName)) {
    errors.legalName = 'Company name is not valid.';
  }

  if (phoneBlurred && phone !== '' && !showCountry && !checkPhone(phone)) {
    errors.phone =
      'Phone must be empty or a valid North American area code and format (###-###-####) or +[numbers, spaces and dashes] if international.';
  }

  return errors;
}

export class CompanyContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      hasPermissions: true,
      canApproveTarget: false,
      isOwnerResearcher: false,
      isDisabledOnSave: false,
      canEditData: false,
      currentStatus: null,
      companyRetainers: null,
    };

    bindAll(
      this,
      'handleCompanyInfoChange',
      'handleToggleDBA',
      'handleToggleCountry',
      'handleUpdateSuggestions',
      'handleSuggestionSelected',
      'handleParentCompanyClick',
      'handleUpdateCountrySuggestions',
      'handleCountrySuggestionSelected',
      'handleEventMouseEnter',
      'handleEventMouseLeave',
      'onAddEvent',
      'onEditEvent',
      'onSave',
      'onBlur',
      'onBlurInfo',
      'isValidPhone',
      'onErrorClose',
      'loadCountries',
      'handleTabClick',
      'validateForm',
      'ifTarget',
      'switchCompanyTypeCheck',
      'confirmTags',
      'onApproveTarget',
      'approveTarget',
      'handleConfirmMerging',
      'handleConfirmMergeRevert',
    );

    this.handleValidateAddress = debounce(this.handleValidateAddress.bind(this), 500);
    this.handleGetSuggestion = debounce(this.handleGetSuggestion.bind(this), 500);
    this.onDeleteEvent = stopProp(
      naDeletionCheck(
        this.props.loggedUser,
        confirm('Delete this event?', this.onDeleteEvent.bind(this), context),
        context,
      ),
    );

    const deleteConfirmText = this.canDeleteConfirmation()
      ? 'Are you sure you want to request your supervisor to delete this company? After the supervisor approval, the company and all associate data will be permanently deleted.'
      : 'Delete this company?';

    this.onDelCompany = stopProp(confirm(deleteConfirmText, this.onDelCompany.bind(this), context));
  }

  UNSAFE_componentWillMount() {
    this._onSaveCallbacks = new Set();
    this._validateCallbacks = new Set();

    const role = this.props.loggedUser.getIn(['roles', 0]);
    const hasPermissions = checkAccess(role);
    const canApproveTarget = canApprove(role);

    this.setState({ hasPermissions, canApproveTarget });
  }

  componentDidMount() {
    const {
      companyInfo,
      getCompanyInfo,
      getBuyers,
      match: {
        params: { companyId },
      },
    } = this.props;

    getCompanyInfo({ companyId });
    getBuyers({ companyId });
    if (companyInfo.get('buyers')) this.setCanEditData();
    this.setState({ isDisabledOnSave: false });
  }

  componentDidUpdate(oldProps) {
    const {
      targetCompany,
      companyInfo,
      location,
      loggedUser,
      target,
      match: {
        params: { companyId },
      },
    } = this.props;

    const researcherId = companyInfo.get('createdResearcherId');
    const isOwnerResearcher = researcherId === loggedUser.get('id');
    const currentStatus = Number(unwrap(target.getIn(['buyers', 0, 'currentStatus'])));

    this.loadCountries();

    if (targetCompany.info.get('isFetching') === true) return;
    if (oldProps.info && oldProps.info.get('isFetching') === false) return;
    if (location.pathname === `/company/${companyId}` || location.pathname === `/company/${companyId}/`)
      this.tryRedirect(companyId);
    if (this.state.isOwnerResearcher !== isOwnerResearcher) this.setState({ isOwnerResearcher });
    if (!isNaN(currentStatus) && this.state.currentStatus !== currentStatus) this.setState({ currentStatus });
    if (!oldProps.companyInfo.get('buyers') && companyInfo.get('buyers')) this.setCanEditData();

    const companyRetainers = this.props.targetCompany.buyer.retainer.get('companyRetainers');
    const isChanged = oldProps.targetCompany.buyer.retainer.get('companyRetainers') !== companyRetainers;

    if (this.state.companyRetainers === null && isChanged) {
      this.setState({
        companyRetainers: companyRetainers.toJS(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isValid = nextProps.targetCompany.target.get('isValid');

    if (isValid) {
      this.setState({ isDisabledOnSave: false });
    }

    if (!this.isDeepChanged() && this.state.isDisabledOnSave) {
      this.setState({ isDisabledOnSave: false });
    }
  }

  canDeleteConfirmation() {
    const userRole = this.context.currentUser.getIn(['roles', 0, 'slug']);
    const roleIsResearcher = isResearcher(userRole);
    const roleIsResearcherIntern = isResearcherIntern(userRole);
    const roleIsJuniorAnalyst = isJuniorAnalyst(userRole);
    const roleIsProcessManager = isProcessManager(userRole);

    return roleIsResearcher || roleIsResearcherIntern || roleIsJuniorAnalyst || roleIsProcessManager;
  }

  setCanEditData() {
    const { target, companyInfo, isDuplicateCompany } = this.props;
    const { hasPermissions, isOwnerResearcher, canEditData } = this.state;
    const roleIsResearcher = isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug']));
    const isUnapproved = !companyInfo.get('isApproved');
    const currentStatus = Number(unwrap(target.getIn(['buyers', 0, 'currentStatus'])));

    if (isDuplicateCompany) {
      this.setState({ canEditData: false });

      return;
    }

    if (!(!roleIsResearcher && isUnapproved)) {
      if (currentStatus < 2) {
        this.setState({ canEditData: true });
      } else if (isOwnerResearcher) {
        this.setState({ canEditData: true });
      } else if (hasPermissions) {
        this.setState({ canEditData: true });
      }
    } else if (canEditData) this.setState({ canEditData: false });
  }

  validateForm(blurred = {}) {
    const info = this.props.targetCompany.info.get('info');
    const legalName = unwrap(info.get('legalName'));
    const phone = unwrap(info.get('phone'));
    const zip = unwrap(info.get('zip'));
    const showCountry = info.getIn(['showCountry']);
    const errors = validate(
      {
        legalName,
        zip,
        phone,
        showCountry,
      },
      blurred,
    );

    this.setState({ errors });

    return errors;
  }

  tryRedirect(companyId) {
    const { availableTabs, replace } = this.props;

    if (availableTabs.get('target')) return setTimeout(() => replace(`/company/${companyId}/target`), 0);
    if (availableTabs.get('buyer')) return setTimeout(() => replace(`/company/${companyId}/buyer/marketing`), 0);
    if (availableTabs.get('exec')) return setTimeout(() => replace(`/company/${companyId}/executive`), 0);
    if (availableTabs.get('tradeGroups')) return setTimeout(() => replace(`/tradeGroups`), 0);
  }

  loadCountries() {
    const {
      getCountries,
      targetCompany: { info },
    } = this.props;
    const loaded = info.getIn(['countries', 'loaded']);

    if (!loaded) getCountries(info.getIn(['countries', 'pagination', 'currentPage'], 0) + 1);
  }

  getChildContext() {
    return {
      addOnSaveCallback: cb => this._onSaveCallbacks.add(cb),
      removeOnSaveCallback: cb => this._onSaveCallbacks.delete(cb),
      addValidateCallBacks: cb => this._validateCallbacks.add(cb),
      deleteValidateCallbacks: cb => this._validateCallbacks.delete(cb),
      onErrorClose: this.onErrorClose,
      inputErrors: Map(),
      onAddEvent: this.onAddEvent,
      onEditEvent: this.onEditEvent,
      onDeleteEvent: this.onDeleteEvent,
      entityId: Number(this.props.match.params.companyId),
    };
  }

  handleEventMouseEnter(event, eventId) {
    const { target, clientX: left } = event;
    const rect = target.getBoundingClientRect();
    const { bottom: top } = rect;

    this.props.enterCompanyEvent({ eventId, left, top });
  }

  handleEventMouseLeave(event, eventId) {
    this.props.leaveCompanyEvent({ eventId });
  }

  handleValidateAddress(name, value) {
    const { targetCompany, handleUpdateCompanyInfo } = this.props;

    handleUpdateCompanyInfo({
      isValidAddress: null,
    });
    validateAddress(value)
      .then(() => {
        if (getIn(targetCompany.info, `info.${name}`) === value) {
          handleUpdateCompanyInfo({
            isValidAddress: true,
          });
        }
      })
      .catch(() => {
        if (getIn(targetCompany.info, `info.${name}`) === value) {
          handleUpdateCompanyInfo({
            isValidAddress: false,
          });
        }
      });
  }

  handleCompanyInfoChange(event) {
    const { name, value } = event.target;

    if (name === 'country') return;

    const updateInfo = {
      [name]: value,
    };

    if (name === 'companyType') {
      this.switchCompanyTypeCheck(value);
    }

    this.props.handleUpdateCompanyInfo(updateInfo);
    if (name === 'address') {
      this.handleValidateAddress(name, value.replace(/[\r\n\t]/g, ' '));
    }
  }

  /**
   * If users change company type from "visible-parent" to "none-parent", revert parent company value to the original value.
   */
  switchCompanyTypeCheck(value) {
    const prevCompanytype = this.props.targetCompany.info.getIn(['info', 'companyType', 'value']);

    if (config.HAS_PARENT_COMPANY.indexOf(prevCompanytype) > -1 && config.HAS_PARENT_COMPANY.indexOf(value) < 0) {
      this.props.revertParentCompany();
    }
  }

  handleToggleDBA() {
    const currentValue = this.props.targetCompany.info.getIn(['info', 'showDBA']);

    this.props.handleUpdateCompanyInfo({
      showDBA: !currentValue,
    });
  }

  /**
   * If users show off or show on country, clear all address information.
   */
  handleToggleCountry() {
    const { targetCompany, handleUpdateCompanyInfo, handleUpdateCountry } = this.props;
    const showCountry = targetCompany.info.getIn(['info', 'showCountry']);

    handleUpdateCompanyInfo({ city: '' });
    handleUpdateCompanyInfo({ zip: '' });
    handleUpdateCompanyInfo({ state: '' });
    handleUpdateCountry({
      text: '',
      selected: {
        id: null,
        text: '',
      },
    });
    handleUpdateCompanyInfo({
      showCountry: !showCountry,
    });
  }

  handleParentCompanyClick() {
    const selectedCompany = this.props.targetCompany.info.get('parentCompany').get('selected');

    if (selectedCompany) {
      window.open(`/company/${selectedCompany.get('id')}`);
    }
  }

  handleUpdateSuggestions(update) {
    this.props.handleUpdateParentCompany(update);
  }

  handleGetSuggestion({ value }) {
    this.props.handleGetSuggestion(value);
  }

  handleGetSuggestionValue(suggestion) {
    return suggestion.legalName;
  }

  handleRenderSuggestion(suggestion) {
    return <div>{suggestion.legalName}</div>;
  }

  handleSuggestionSelected(event, { suggestion }) {
    this.props.handleUpdateParentCompany({
      text: suggestion.legalName,
      selected: suggestion,
      suggestions: [],
    });
  }

  handleUpdateCountrySuggestions(event) {
    this.props.handleUpdateCountry({
      text: event.target.value,
      selected: null,
    });
  }

  handleCountrySuggestionSelected(selection) {
    this.props.handleUpdateCountry({
      text: selection.text,
      selected: selection,
    });
  }

  onAddEvent() {
    if (!this.state.canEditData) return;

    const {
      targetCompany,
      location: { pathname },
    } = this.props;
    const name = 'EventCompanyPopup';

    const isBuyerRoute = pathname.includes('/buyer');
    const isTargetRoute = pathname.includes('/target');

    if (!isTargetRoute && !isBuyerRoute) return;

    if (isTargetRoute) {
      this.context.openPopup(name, {
        event: null,
        approach: 'target',
        companyInfo: targetCompany.info.getIn(['info']),
        companyBuyer: targetCompany.target.getIn(['buyers', 0]),
        companyContacts: targetCompany.target.getIn(['contacts']),
        onReopen: this.onAddEvent,
      });
    } else if (isBuyerRoute) {
      this.context.openPopup(name, {
        event: null,
        approach: 'buyer',
        onReopen: this.onAddEvent,
      });
    }
  }

  onEditEvent(e, event) {
    if (!this.state.canEditData) return;

    const name = 'EventCompanyPopup';

    if (event.get('id') && !event.get('meta')) {
      this.context.openPopup(name, {
        event,
        onReopen: this.onAddEvent,
      });
    }
  }

  onDeleteEvent(e, event) {
    if (!this.state.canEditData) return;

    const { id } = event;
    const {
      context: { closePopup },
    } = this;
    const {
      notifyTab,
      match: {
        params: { companyId },
      },
      deleteCompanyEvent,
    } = this.props;

    deleteCompanyEvent({
      id,
      entityId: companyId,
      afterSuccess: () => {
        notifyTab({ id: event.id }, 'onEventChange');
        closePopup();
      },
    });
  }

  onBlurInfo() {
    const {
      targetCompany,
      saveCompanyDetail,
      match: {
        params: { companyId },
      },
    } = this.props;
    const info = getChanged(targetCompany.info.get('info'));
    const criteria = getChanged(targetCompany.buyer.criteria);
    const buyer = getChanged(targetCompany.buyer.info);
    const errors = this.validateForm({
      zip: true,
      phone: true,
      legalName: true,
    });

    delete criteria.businessModels;
    delete criteria.industryCategories;

    if (isEmpty(info) && isEmpty(criteria) && isEmpty(buyer)) return;

    if (isEmpty(errors)) {
      const body = { ...info, ...criteria, ...buyer };

      if ('parentId' in body) {
        body.parentId = body.parentId || null;
      }

      if ('country' in body) {
        body.countryId = body.country.countryId;
        delete body.country;
      }

      if ('address' in body) {
        body.address = body.address.replace(/[\r\n\t]/g, ' ');
      }

      if (Object.keys(body).length) {
        this.preSaveCheck(body);
        saveCompanyDetail({ companyId, body });
      }
    }
  }

  /**
   * [Private, LBO, Public, VC, ESOP, NFP, Gov, ForPri, ForPub]
   * Those company types don't have parent company
   * Let set parentId = null for them.
   */
  preSaveCheck(data) {
    if ('companyType' in data && config.HAS_PARENT_COMPANY.indexOf(data.companyType) < 0) {
      data.parentId = null;
    }
  }

  onErrorClose(event, field) {
    if (['phone', 'zip', 'legalName'].indexOf(field) > -1) {
      this.setState({ errors: { [field]: '' } });
    }
  }

  onDelCompany() {
    const { companyId } = this.props.match.params;
    const {
      context: { closePopup },
    } = this;

    if (this.canDeleteConfirmation()) {
      this.props.handleTargetDeleteConfirmation({
        body: {
          params: {
            selected_ids: [companyId],
          },
        },
        callback: {
          beforeStart: () => { },
          afterSuccess: () => {
            showInformation(this.context.openPopup, DELETION_REQUEST_TEXT);
          },
          afterError: () => {
            showError(this.context.openPopup, [DELETE_FAIL_TEXT]);
          },
        },
      });
    } else {
      this.props.delCompany({
        companyId,
        afterSuccess: closePopup,
      });
    }
  }

  isDeepChanged() {
    const { targetCompany, project } = this.props;
    const isChangedCriteriaProjectScope = project.projectData.getIn(['details', 'dirty']);
    const companyRetainers = targetCompany.buyer.retainer.get('companyRetainers');

    // We add this structural check because isDeepChanged() can't determine whether elements of the array (Immutable.List) are updated.
    const isRetainersListChanged =
      this.state.companyRetainers !== null &&
      !isEqual(
        companyRetainers.toJS().map(retainer => retainer.id),
        this.state.companyRetainers.map(retainer => retainer.id),
      );

    return (
      isDeepChanged(targetCompany) ||
      isDeepChanged(project) ||
      isDeepChanged(companyRetainers) ||
      isRetainersListChanged ||
      isChangedCriteriaProjectScope ||
      detectDirty(targetCompany.buyer.addOnProject)
    );
  }

  isValidPhone(phone) {
    if (this.props.targetCompany.info.getIn(['info', 'showCountry'])) return true;

    return phone === '' || checkPhone(phone);
  }

  onBlur({ target: { name, value = '' } }) {
    const errors = this.validateForm({ [name]: true });

    if (name === 'phone') {
      const showCountry = this.props.targetCompany.info.getIn(['info', 'showCountry']);

      if (!errors.phone) {
        this.props.handleUpdateCompanyInfo({
          phone: showCountry ? value : formatPhone(value),
        });
      }
    }
  }

  confirmTags(e) {
    const { target, buyer, isValid } = this.props.targetCompany;
    let error = '';
    const { pathname } = this.props.location;

    if (isValid) {
      this.setState({ isDisabledOnSave: false });
    }

    if (pathname.includes('target')) {
      const targetTags = unwrap(target.get(INDUSTRY_CATEGORIES));
      const targetBMs = unwrap(target.get(BUSINESS_MODELS));
      const targetHasTags = !!targetTags.size && !!targetBMs.size;

      if (!targetHasTags) {
        error = INDUSTRY_TAGS_ERROR;
        this.setState({ isDisabledOnSave: false });
      }
    }

    if (pathname.includes('buyer')) {
      if (pathname.includes('criteria')) {
        const buyerTags = buyer.criteria.get(BIC);

        if (!buyerTags.size) {
          error = BUYER_TAGS_ERROR;
          this.setState({ isDisabledOnSave: false });
        }
      } else if (pathname.includes('add-on-project')) {
        const buyerAddOnProjectTags = unwrap(buyer.addOnProject.tags.get(INDUSTRY_CATEGORIES));
        const buyerAddOnProjectBMs = unwrap(buyer.addOnProject.tags.get(BUSINESS_MODELS));

        const buyerAddOnProjectHasTags = !!buyerAddOnProjectTags.size && !!buyerAddOnProjectBMs.size;

        if (!buyerAddOnProjectHasTags) {
          error = INDUSTRY_TAGS_ERROR;
          this.setState({ isDisabledOnSave: false });
        }
      }
      this._validateCallbacks.forEach(cb => cb && cb(e));
    }

    return error;
  }

  onSave(event) {
    const { availableTabs } = this.props;

    this.setState({ isDisabledOnSave: true });
    this.setState({ companyRetainers: null });
    if (availableTabs.get('target') || availableTabs.get('buyer')) {
      event.persist();

      const error = this.confirmTags(event);

      if (error.length === 0) {
        this.onBlurInfo();
        this._onSaveCallbacks.forEach(cb => cb && cb(event));
      } else {
        this.context.openPopup('ErrorPopup', { error });
      }
    } else {
      this.onBlurInfo();
      this._onSaveCallbacks.forEach(cb => cb && cb(event));
    }
  }

  handleTabClick({ name, to }) {
    if (this.props.targetCompany.info.get('isFetching')) return;

    if (this.props.isDuplicateCompany) return;

    if (name === 'TARGET') {
      if (this.ifTarget()) {
        this.props.push(to);
      }
    } else if (name === 'TRADE GROUPS') {
      window.open(to, '_blank');
    } else {
      this.props.push(to);
    }
  }

  /**
   * If company doesn't have target role, so activating target role popup
   * Financial Buyer can't activate target role.
   */
  ifTarget() {
    const {
      buyerType,
      isTarget,
      match: {
        params: { companyId },
      },
      push,
    } = this.props;
    const { openPopup } = this.context;
    const { hasPermissions } = this.state;

    if (isNaN(companyId)) return push('/');

    if (!isTarget) {
      if (!hasPermissions) {
        openPopup('InformationPopup', {
          message: {
            texts: ["You don't have permission for this action"],
            className: 'text-danger',
          },
          header: (
            <span>
              <i aria-hidden="true" className="fa fa-exclamation-triangle text-danger" />
              Error Alert
            </span>
          ),
        });

        return false;
      }

      if (buyerType === 'financial') {
        openPopup('ActiveTargetPopup', {
          isFinancialBuyer: true,
          companyId,
        });

        return false;
      }

      openPopup('ActiveTargetPopup', {
        isFinancialBuyer: false,
        companyId,
      });

      return false;
    }

    return true;
  }

  approveTarget(id, afterSuccess, afterError) {
    return this.props.approveTarget(id, afterSuccess, afterError);
  }

  onApproveTarget() {
    const targetId = this.props.companyInfo.get('id');
    const afterSuccess = () => this.context.closePopup();
    const afterError = () => this.context.closePopup();

    this.context.openPopup('ConfirmPopup', {
      message: 'Are you sure to confirm the company?',
      onOk: () => this.approveTarget(targetId, afterSuccess, afterError),
      onCancel: () => {
        this.context.closePopup();
      },
      yes: 'Yes',
      no: 'No',
    });
  }

  /** Handle confirm companies merging click. */
  handleConfirmMerging() {
    const companyId = this.props.companyInfo.get('id');
    const afterSuccess = () => this.context.closePopup();
    const afterError = () => this.context.closePopup();

    this.context.openPopup('ConfirmPopup', {
      message: 'Are you sure to confirm the merging?',
      onOk: () => this.props.confirmMerging(companyId, afterSuccess, afterError),
      onCancel: () => this.context.closePopup(),
      yes: 'Yes',
      no: 'No',
    });
  }

  /** Handle confirm companies revert merging click. */
  handleConfirmMergeRevert() {
    const companyId = this.props.companyInfo.get('id');

    const afterSuccess = () => {
      this.context.closePopup();
      this.props.history.replace('/');
    };
    const afterError = () => this.context.closePopup();

    this.context.openPopup('ConfirmPopup', {
      message: 'Are you sure you want to revert merge result for this company?',
      onOk: () => this.props.confirmMergeRevert(companyId, afterSuccess, afterError),
      onCancel: () => this.context.closePopup(),
      yes: 'Yes',
      no: 'No',
    });
  }

  static onAddTarget() {
    window.open('/company/new/target');
  }

  static onAddBuyer() {
    window.open('/company/new/buyer');
  }

  static onAddExecutive() {
    window.open('/contact/new/executive');
  }

  static onAddLp() {
    window.open('/contact/new/lp');
  }

  render() {
    const {
      targetCompany: { info, events },
      isDuplicateCompany,
      mergeResultCompanyId,
      isNotApprovedMerge,
      sourceCompanies,
    } = this.props;
    const { canEditData, hasPermissions, canApproveTarget, isDisabledOnSave } = this.state;
    const companyInfoProps = {
      companyInfoProps: {
        onSubmit: this.onBlurInfo,
        onCompanyInfoChange: this.handleCompanyInfoChange,
        onToggleDBA: this.handleToggleDBA,
        onToggleCountry: this.handleToggleCountry,
        onParentCompanyClick: this.handleParentCompanyClick,
      },
      companyEventProps: {
        events,
        onMouseEnter: this.handleEventMouseEnter,
        onMouseLeave: this.handleEventMouseLeave,
      },
      companyMergeInfoProps: {
        isDuplicateCompany,
        mergeResultCompanyId,
        isNotApprovedMerge,
        sourceCompanies,
        onConfirmMerging: this.handleConfirmMerging,
        onConfirmMergeRevert: this.handleConfirmMergeRevert,
      },
    };
    const suggestions = info.getIn(['parentCompany', 'suggestions'], fromJS([]));
    const text = info.getIn(['parentCompany', 'text']) || '';
    const suggestionProps = {
      suggestionProps: {
        name: 'parentCompany',
        inputProps: {
          className: 'parentCompany form-control',
          id: 'parentCompany',
          name: 'parentCompany',
          placeholder: 'Parent Company Name',
          disabled: !canEditData,
        },
        suggestions,
        onUpdateSuggestions: this.handleUpdateSuggestions,
        getSuggestion: this.handleGetSuggestion,
        getSuggestionValue: this.handleGetSuggestionValue,
        renderSuggestion: this.handleRenderSuggestion,
        onSuggestionSelected: this.handleSuggestionSelected,
        checkOn: 'text',
        text,
        loading: info.getIn(['parentCompany', 'loading'], false),
      },
    };

    const countrySuggestion = (
      <CountryDropDown
        disabled={!canEditData}
        name="country"
        onChange={this.handleUpdateCountrySuggestions}
        onSelect={this.handleCountrySuggestionSelected}
        value={info.getIn(['info', 'country', 'countryName'], '')}
      />
    );

    const footerProps = {
      footer: {
        isDisabledOnSave,
        onNewTarget: CompanyContainer.onAddTarget,
        onNewBuyer: CompanyContainer.onAddBuyer,
        onNewExecutive: CompanyContainer.onAddExecutive,
        onNewLp: CompanyContainer.onAddLp,
        onDel: this.onDelCompany,
        hideSave: !this.isDeepChanged(),
        onSave: this.onSave,
        onApproveTarget: this.onApproveTarget,
      },
    };

    return (
      <div className="full-height">
        <Helmet title={`${getIn(info, 'info.legalName', '')} [Company Page]`.trim()} />
        <Company
          {...this.props}
          {...suggestionProps}
          {...footerProps}
          {...companyInfoProps}
          canApproveTarget={canApproveTarget}
          canEditData={canEditData}
          countrySuggestion={countrySuggestion}
          error={{ ...this.state.errors }}
          hasPermissions={hasPermissions}
          onBlur={this.onBlur}
          onClick={this.handleTabClick}
          onErrorClose={this.onErrorClose}
          params={this.props.match.params}
        >
          {this.props.children}
        </Company>
      </div>
    );
  }
}

CompanyContainer.contextTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
};

CompanyContainer.propTypes = {
  confirmMergeRevert: PropTypes.func.isRequired,
  confirmMerging: PropTypes.func.isRequired,
  getCompanyInfo: PropTypes.func.isRequired,
  handleGetSuggestion: PropTypes.func.isRequired,
  handleUpdateCompanyInfo: PropTypes.func.isRequired,
  handleUpdateParentCompany: PropTypes.func.isRequired,
  isDuplicateCompany: PropTypes.bool.isRequired,
  isNotApprovedMerge: PropTypes.bool.isRequired,
  mergeResultCompanyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  sourceCompanies: PropTypes.instanceOf(List).isRequired,
  targetCompany: PropTypes.object.isRequired,
};

CompanyContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
  onAddEvent: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
  onEditEvent: PropTypes.func.isRequired,
  entityId: PropTypes.any,
  addValidateCallBacks: PropTypes.func,
  deleteValidateCallbacks: PropTypes.func,
};

const getAvailableTabs = (function () {
  let availableTabs = fromJS({
    target: false,
    buyer: false,
    exec: false,
    tradeGroups: false,
  });

  return function (state) {
    availableTabs = availableTabs.set('target', state.targetCompany.target.get('isTarget'));
    availableTabs = availableTabs.set('buyer', !!state.targetCompany.buyer.info.get('buyerType'));
    availableTabs = availableTabs.set('exec', state.targetCompany.executive.get('queryResults').size > 0);

    return availableTabs;
  };
})();

const mapStateToProps = state => ({
  availableTabs: getAvailableTabs(state),
  targetCompany: state.targetCompany,
  project: state.project,
  target: state.targetCompany.target,
  companyInfo: state.targetCompany.info.get('info'),
  tabs,
  values,
  loggedUser: state.auth.get('user'),
  buyerType: state.targetCompany.buyer.info.get('buyerType', '').toLowerCase(),
  isTarget: state.targetCompany.target.get('isTarget', false),
  isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  mergeResultCompanyId: state.targetCompany.mergeInfo.get('mergeResultCompanyId'),
  isNotApprovedMerge: state.targetCompany.mergeInfo.get('isNotApprovedMerge'),
  sourceCompanies: state.targetCompany.mergeInfo.get('sourceCompanies'),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(companyDetailsActions, dispatch),
  ...bindActionCreators(companyEventsActions, dispatch),
  ...bindActionCreators({ getBuyers }, dispatch),
  ...bindActionCreators({ handleTargetDeleteConfirmation }, dispatch),
  ...bindActionCreators({ replace, push }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(publish(CompanyContainer));
