import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { List } from 'immutable';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import Table, { Column, Row } from '../../helpers/Table';
import Tooltip from '../../helpers/DataToolTip';
import Input from '../../helpers/Input';
import Span from '../../helpers/Span';
import uniqueId from '../../../utils/uniqueId';

const ContextMenuWrapper = onClick => {
  const ContextMenuWrapped = (value, data) => {
    const id = uniqueId();

    return (
      <div>
        <ContextMenuTrigger id={id}>{value}</ContextMenuTrigger>

        <ContextMenu id={id}>
          <MenuItem data={data.toJS()} onClick={onClick}>
            <i className="fa fa-remove" /> Delete
          </MenuItem>
        </ContextMenu>
      </div>
    );
  };

  return ContextMenuWrapped;
};

const Wrapper = onClick => (value, data, i) => {
  let content = (
    <Tooltip content={data.get('dateString', '')}>
      <Span checkOn="children" minSizes>
        {value}
      </Span>
    </Tooltip>
  );

  if (data.get('isEditing')) {
    content = <Input name={`harvcoTags.${i}.tag`} placeholder="" value={value} autoFocus />;
  }

  return ContextMenuWrapper(onClick)(content, data);
};

class ContactExecutiveHarvcoTags extends PureComponent {
  render() {
    return ContactExecutiveHarvcoTagsRender(this.props);
  }
}

/**
 * Contact executive table with harvco tags.
 *
 * @param props {Object}.
 * @param props.tags {Immutable.List} Tags definition.
 * @returns {XML}
 * @class
 */
const ContactExecutiveHarvcoTagsRender = props => {
  const { tags, onAdd, onClick, onChange, onDel } = props;

  const title = (
    <span onClick={onAdd}>
      <i className="fa fa-plus-square fa-2x" /> HarvCo Tags
    </span>
  );
  const rows = tags.map((tag, i) => (
    <Row key={i} className="clickable" data={tag} onChange={onChange} onClick={event => onClick(event, i)} />
  ));

  return (
    <div className="highlight table-responsive ib">
      <Table className="table table-bordered table-hover">
        <Column className="plus-icon" field="tag" title={title} valueWrapper={Wrapper(onDel)} />
        {rows}
      </Table>
    </div>
  );
};

ContactExecutiveHarvcoTagsRender.propTypes = {
  tags: PropTypes.instanceOf(List).isRequired,
};

export default ContactExecutiveHarvcoTags;
