import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';

import Table from '../../../../helpers/AgGridTable';
import AgHeader from './AgHeader';
import { ChangeSpyCellRender } from './ChangeSpyCellRender';
import { ACTION_DELETE } from 'src/components/helpers/DeleteItem';

/**
 * Table users.
 *
 * @param {object} props Params.
 * @param {boolean} props.canEditData Whether user can edit any active element that can be changed or clicked.
 * @constructor
 */
class Users extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.onGridReady = this.onGridReady.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.onInsert = this.onInsert.bind(this);
    this.handleCellValueChanged = this.handleCellValueChanged.bind(this);
    this.onContextMenuClick = this.onContextMenuClick.bind(this);

    this.columnDefs = fromJS([
      {
        headerName: 'Users',
        field: 'text.value',
        width: 150,
        headerComponentFramework: AgHeader,
        cellRendererFramework: ChangeSpyCellRender,
        cellEditorFramework: this.props.editor,
        onClick: this.onInsert,
        editable: this.props.canEditData,
        enableSorting: false,
      },
    ]);
  }

  onInsert() {
    if (!this.api) return;
    if (!this.props.canEditData) {
      return;
    }
    this.api.stopEditing();

    let rowIndex = -1;

    this.api.forEachNode(rowNode => {
      if (rowNode.data.id.value === -1) {
        rowIndex = rowNode.rowIndex;

        return false;
      }

      return true;
    });
    if (rowIndex === -1) {
      const transaction = this.api.updateRowData({ add: [{ id: { value: -1 }, text: { value: '' } }] });

      rowIndex = transaction.add[0].rowIndex;
    }
    this.api.startEditingCell({ rowIndex, colKey: 'text.value' });
  }

  onGridReady(params) {
    this.api = params.api;
    this.api.sizeColumnsToFit();
    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
  }

  onCellClicked(e) {
    if (!this.props.canEditData) {
      return;
    }

    const {
      column: { colId },
      node: { rowIndex },
    } = e;

    this.api.startEditingCell({ rowIndex, colKey: colId });
  }

  componentDidUpdate() {
    if (this.api) {
      this.api.sizeColumnsToFit();
      this.api.refreshCells({ force: true });
    }
  }

  onContextMenuClick(event, data) {
    if (!this.props.canEditData) {
      return;
    }
    this.props.onDelete(event, { type: 'users', id: data.rowData.id.value });
  }

  getRowNodeId(data) {
    return data.index;
  }

  handleCellValueChanged(event) {
    // Save row node to redraw it later
    this.rowNode = event.node;
  }

  getColumnDefs() {
    if (this.columnDefs !== this.lastColumnDefs) {
      this.lastColumnDefs = this.columnDefs;
      this.lastColumnDefs = fromJS([
        {
          headerName: 'Users',
          field: 'text.value',
          width: 150,
          headerComponentFramework: AgHeader,
          cellRendererFramework: ChangeSpyCellRender,
          cellEditorFramework: this.props.editor,
          onClick: this.onInsert,
          editable: this.props.canEditData,
          enableSorting: false,
        },
      ]);
    }

    return this.lastColumnDefs;
  }

  render() {
    const { isFetching, data, colClass, className } = this.props;

    return (
      <div className={`col-md-2 ${colClass} full-height`}>
        <div className={`table-edit full-height ${className}`}>
          <Table
            columnDefs={this.getColumnDefs()}
            contextActionList={ACTION_DELETE}
            getRowNodeId={this.getRowNodeId}
            isFetching={isFetching}
            onCellClicked={this.onCellClicked}
            onCellValueChanged={this.handleCellValueChanged}
            onContextMenuClick={this.onContextMenuClick}
            onGridReady={this.onGridReady}
            rowData={data}
          />
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  canEditData: PropTypes.bool.isRequired,
};

Users.contextTypes = {
  openPopup: PropTypes.func.isRequired,
};

export default Users;
