import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';

import LoadAnimation from '../../decorators/LoadAnimation';
import { ActionContextMenu } from './ActionContextMenu';
import { TopMenu } from './TopMenu';
import { TargetRow } from './TargetRow';
import { BuyerRow } from './BuyerRow';
import { ExecRow } from './ExecRow';

const Table = LoadAnimation(
  ({ setGridReference, rows }) => (
    <div ref={setGridReference} style={{ overflow: 'auto', height: '100%' }}>
      <table className="t-actions table table-hover table-striped mt5">
        <tbody>{rows}</tbody>
      </table>
    </div>
  ),
  'isFetching',
  'table-responsive min-h300 next-actions-table col-xs-12',
);

/**
 * Stateless component for showing next action.
 *
 * @param props {Object}.
 * @param props.list {Immutable.List} List of next actionList.
 * @param props.selected {Immutable.List} List of selected next actionList.
 * @param props.disabled {Boolean} If true, disabled top drop down menu.
 * @param props.actionConfigs {Immutable.List} List of available actionList for each event.
 * @param props.isFetching {Boolean} Show spinner if true.
 * @param props.onSelectAll {Function} To handle select all checkbox changing.
 * @param props.onRowSelected {Function} To handle row selection.
 * @param props.onMenuSelect {Function} To handle context menu item click event.
 * @param props.setGridReference {Function} To handle setting grid ref.
 * @param props.onBLClick {Function} To handle setting backlog.
 * @returns {React.Component}
 */
const Grid = props => {
  const {
    list,
    selected,
    disabled,
    isFetching,
    actionConfigs,
    setGridReference,
    onSelectAll,
    onDeselectAll,
    onRowSelected,
    onMenuSelect,
    onBLClick,
  } = props;

  const rows = list.map((action, index) => mapRow(action, index + 1, onBLClick, onRowSelected));

  return (
    <div className="col-xs-12 col-sm-12 col-md-11 col-lg-11">
      <TopMenu
        actionConfigs={actionConfigs}
        disabled={disabled}
        onChange={onMenuSelect}
        onDeselectAll={onDeselectAll}
        onSelectAll={onSelectAll}
        selected={selected}
      />
      <Table isFetching={isFetching} rows={rows} setGridReference={setGridReference} />
      <ActionContextMenu actionConfigs={actionConfigs} id="actionContextMenu" onItemClick={onMenuSelect} />
    </div>
  );
};

Grid.propTypes = {
  actionConfigs: PropTypes.instanceOf(List).isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  onBLClick: PropTypes.func.isRequired,
  onMenuSelect: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(List).isRequired,
  setGridReference: PropTypes.func.isRequired,
};

export default Grid;

function mapRow(action, index, onBLClick, onRowSelected) {
  const approach = action.get('approach');

  if (approach === 'target') {
    return <TargetRow key={index} action={action} index={index} onClick={onBLClick} onRowSelected={onRowSelected} />;
  }

  if (approach === 'buyer') {
    return <BuyerRow key={index} action={action} index={index} onRowSelected={onRowSelected} />;
  }

  return <ExecRow key={index} action={action} index={index} onRowSelected={onRowSelected} />;
}
