import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { WysiwygView } from '../helpers/WysiwygView';

const CompanyLabel = ({ note }) => {
  const name = note.get('legalName', '');

  if (!name) return <span />;

  const projectName = note.get('projectName', '');

  if (!projectName) return <span className="text-muted">({name})</span>;

  return <span className="text-muted">({(note.get('fullActivity', '') || '').split(/\n\r?/).join(' ')})</span>;
};


/**
 * Component is used to display 1 contact's email.
 *
 * @param props {Object} Any extra props that allowed on <p> tag.
 * @param props.onMouseEnter {Function} OnMouseEnter callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.onMouseLeave {Function} OnMouseLeave callback. 1st arg is event. 2nd is deal note's eventId.
 * @param props.email {Immutable.Map} Map, that should describe email. #content {string} is note content.
 * #[isMarked=false] {boolean} uf `true` append .text-bold class to element. #eventId is email's eventId.
 * @returns {XML}
 * @private
 * @class
 */
const CompanyEmailLogLineComponent = ({ onEditEvent, onMouseEnter, onMouseLeave, email, ...rest }) => {
  const topContent = (
    <div className="contact__deal-note" onDoubleClick={event => onEditEvent(event, email.set('id', email.get('eventId')))}>
      <strong>{email.get('date')}</strong> <span className="text-muted">{email.get('userName', '')}</span>{' '}
      <CompanyLabel note={email} />
    </div>
  );
  const content = email.get('content', '');

  return (
    <div {...rest} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {topContent}
      <WysiwygView html={content} onDoubleClick={event => onEditEvent(event, email.set('id', email.get('eventId')))} />
    </div>
  );
};

CompanyEmailLogLineComponent.propTypes = {
  isMarked: PropTypes.bool,
};

/** Company email log line component. */
export const CompanyEmailLogLine = memo(CompanyEmailLogLineComponent);
