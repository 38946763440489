import React from 'react';
import classNames from 'classnames';
import Popup from '../helpers/Popup';
import _Button from '../helpers/Button';
import LoadAnimation from '../decorators/LoadAnimation';
import { EventPopupInput } from '../Company/TradeShow/MidBlock/EventPopupInput';

const Button = LoadAnimation(_Button, undefined, undefined, true);

const AddTradeShowEventPopup = props => {
  const { event, onChange, onSave, onClose, canSave } = props;
  const saveClassCss = classNames(canSave ? 'btn-primary btn-xs' : 'btn-xs');
  const footer = [
    <Button key="save" className={saveClassCss} data-dismiss="modal" onClick={onSave} autoFocus>
      Save
    </Button>,
    <Button key="close" className="btn-default btn-xs" data-dismiss="modal" onClick={onClose}>
      Close
    </Button>,
  ];

  return (
    <Popup footer={footer} header="Add event" id="addEventPopup">
      <div className="row">
        <div className="col-md-2">
          <EventPopupInput
            field={event.year}
            label="Year"
            max="2022"
            min="2000"
            name="year"
            onChange={onChange}
            type="number"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <EventPopupInput field={event.title} label="Title" name="title" onChange={onChange} type="text" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <EventPopupInput field={event.city} label="City" name="city" onChange={onChange} type="text" />
        </div>
        <div className="col-md-2">
          <EventPopupInput field={event.state} label="State" name="state" onChange={onChange} type="text" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <EventPopupInput
            field={event.startDate}
            label="Start Date"
            max={event.endDate}
            name="startDate"
            onChange={onChange}
            type="date"
          />
        </div>
        <div className="col-md-3">
          <EventPopupInput
            field={event.endDate}
            label="End Date"
            min={event.startDate}
            name="endDate"
            onChange={onChange}
            type="date"
          />
        </div>
      </div>
    </Popup>
  );
};

export default AddTradeShowEventPopup;
