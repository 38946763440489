import React, { useRef, memo } from 'react';

const RecipientsHeaderComponent = ({ api, column }) => {
  const headerRef = useRef(null);

  const handleClick = (event) => {
    column.colDef.onClick(event, api);
  };

  return (
    <div
      ref={headerRef}
      className="ag-header-cell-label"
    >
      <button
        aria-label="add"
        className="ag-header-button"
        onClick={handleClick}
        title="add"
        type="button"
      >
        <i className="fa fa-plus mr5" />
      </button>
      <span className="mr5">{column.colDef.headerName}</span>
    </div>
  );
};

export const RecipientsHeader = memo(RecipientsHeaderComponent);
