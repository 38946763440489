import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import { UserTable } from './UserTable';
import { UserInfo } from './UserInfo';
import { UserFooter } from './UserFooter';
import { TableHeader } from './TableHeader';
import { ErrorSummary } from './ErrorSummary';
import Checkbox from '../helpers/Checkbox';
import Button from '../helpers/Button';

/**
 * User component.
 *
 * @param {object} props Props.
 * @param {Immutable.List} props.users List of users.
 * @param {Immutable.List} props.roles List of roles.
 * @param {Function} props.formMode Form mode (view|edit).
 * @param {Immutable.Map} props.formError Error on form.
 * @param {Immutable.Map} props.selectedUser Selected user.
 * @param {string}  props.saveStatus Save button's' statuses (error|saving|enabled).
 * @param {boolean} props.hideInactive Hide inactive checkbox's status.
 * @param {boolean} props.userLoading User table's loading state.
 * @param {Function} props.onHideInactiveChange Handle hide/show inactive users.
 * @param {Function} props.onFormChange Handle controls on form changing.
 * @param {Function} props.onUserSuggestionSelected Handle user's suggestion control changing.
 * @param {Function} props.onSaveClick Handle saving form.
 * @param {Function} props.onEditClick Enable/disable editing form mode.
 * @param {Function} props.onAddClick To clear form and ready for adding new user.
 * @param {Function} props.getSuggestions To get user suggestion list.
 * @param  {Function} props.renderSuggestion To render user's suggestion.
 * @param {Array} props.tableCells List of table columns.
 * @returns {React.FunctionComponent}
 */
const UserComponent = ({
  users,
  errors,
  suggestions,
  roles,
  formMode,
  formError,
  selectedUser,
  saveStatus,
  hideInactive,
  onHideInactiveChange,
  onFormChange,
  onAddClick,
  onUserSuggestionSelected,
  onSaveClick,
  onEditClick,
  onRowClick,
  onRemove2FaClick,
  userLoading,
  tableCells,
  supervisorSuggestionMode,
  moduleSuggestionMode,
  onUserSuggestionClose,
  reports,
  onHireEndDateChange,
  onExportUserList,
}) => {
  const selectedUserId = selectedUser.get('id');

  return (
    <div className="container wrap-flex">
      <div className="form-group d-flex group-checkbox mt20 mb20">
        <Checkbox
          defaultChecked={hideInactive}
          id="hide-inactive"
          label="Hide Inactive"
          name="hide-inactive"
          onChange={onHideInactiveChange}
        />
        <Button className="btn-primary btn-xs ml10" onClick={onExportUserList}>
          Export User List
        </Button>
      </div>

      <TableHeader columns={tableCells} />
      <UserTable
        onRemove2FaClick={onRemove2FaClick}
        onRowClick={onRowClick}
        selectedUserId={selectedUserId}
        tableCells={tableCells}
        userLoading={userLoading}
        users={users}
      />

      <div className="row mb60">
        <UserInfo
          formError={formError}
          formMode={formMode}
          moduleSuggestionMode={moduleSuggestionMode}
          onFormChange={onFormChange}
          onHireEndDateChange={onHireEndDateChange}
          onUserSuggestionClose={onUserSuggestionClose}
          onUserSuggestionSelected={onUserSuggestionSelected}
          reports={reports}
          roles={roles}
          selectedUser={selectedUser}
          suggestions={suggestions}
          supervisorSuggestionMode={supervisorSuggestionMode}
        />

        <ErrorSummary errors={errors} formError={formError} formMode={formMode} />
      </div>

      <UserFooter
        formMode={formMode}
        onAddClick={onAddClick}
        onEditClick={onEditClick}
        onSaveClick={onSaveClick}
        saveStatus={saveStatus}
      />
    </div>
  );
};

UserComponent.propTypes = {
  formError: PropTypes.instanceOf(Immutable.Map).isRequired,
  formMode: PropTypes.string.isRequired,
  hideInactive: PropTypes.bool.isRequired,
  moduleSuggestionMode: PropTypes.string.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onHideInactiveChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onUserSuggestionClose: PropTypes.func.isRequired,
  onUserSuggestionSelected: PropTypes.func.isRequired,
  roles: PropTypes.instanceOf(Immutable.List).isRequired,
  saveStatus: PropTypes.string.isRequired,
  selectedUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  suggestions: PropTypes.instanceOf(Immutable.List).isRequired,
  supervisorSuggestionMode: PropTypes.string.isRequired,
  tableCells: PropTypes.instanceOf(Immutable.List).isRequired,
  userLoading: PropTypes.bool.isRequired,
  users: PropTypes.instanceOf(Immutable.List).isRequired,
};

export const User = memo(UserComponent);
