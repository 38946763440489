import React, { memo } from 'react';

/**
 * Check if link doesn't have protocol-relative, add https:// by default.
 */
const LinkOutComponent = ({ children, to, protocol = 'https', ...rest }) => {
  const linkTo = /^(http|https):\/\//i.test(to) ? to : `${protocol}://${to}`;

  return (
    <a {...rest} href={linkTo} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
};

export const LinkOut = memo(LinkOutComponent);
