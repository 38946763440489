import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CompanyBuyerProjects } from '../../../components/Company/CompanyBuyer/CompanyBuyerProjects';
import { buildSortByParam } from '../../../helpers/paramBuilder';

import {
  getBuyerProjectsPlatform,
  getBuyerProjectsAddon,
  updateProjects,
  createPlatformProject,
} from '../../../actions/company/buyer/projects';

import { fetchTargetStatuses } from '../../../actions/statuses';

const { func, object, bool } = PropTypes;

class CompanyBuyerProjectsContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
    this.handleCurrentStatusChange = this.handleCurrentStatusChange.bind(this);
    this.handleGetNextPageDataForPlatform = this.handleGetNextPageDataForPlatform.bind(this);
    this.handleGetNextPageDataForAddon = this.handleGetNextPageDataForAddon.bind(this);
    this.handlePlatformChange = this.handlePlatformChange.bind(this);
    this.handleAddonChange = this.handleAddonChange.bind(this);
    this.handleAddPlatformProject = this.handleAddPlatformProject.bind(this);
    this.handleCreateNewPlatformProject = this.handleCreateNewPlatformProject.bind(this);
    this.handleGenerateSummaryReport = this.handleGenerateSummaryReport.bind(this);
  }

  componentDidMount() {
    const { companyId } = this.props.match.params;
    const { getBuyerProjectsPlatform, getBuyerProjectsAddon } = this.props;

    getBuyerProjectsPlatform({
      companyId,
    });
    getBuyerProjectsAddon({
      companyId,
    });
  }

  componentDidUpdate(oldProps) {
    const { projects, getBuyerProjectsPlatform, getBuyerProjectsAddon } = this.props;
    const { companyId } = this.props.match.params;
    const oldProjects = oldProps.projects;

    const platform = projects.get('platform');
    const oldPlatform = oldProjects.get('platform');
    const addon = projects.get('addon');
    const oldAddon = oldProjects.get('addon');

    if (oldPlatform !== platform) {
      const oldSortBy = buildSortByParam(oldPlatform);
      const sortBy = buildSortByParam(platform);

      if (sortBy !== oldSortBy) {
        getBuyerProjectsPlatform({
          companyId,
          page: 1,
          sortBy,
        });
      }
    }

    if (oldAddon !== addon) {
      const oldSortBy = buildSortByParam(oldAddon);
      const sortBy = buildSortByParam(addon);

      if (sortBy !== oldSortBy) {
        getBuyerProjectsAddon({
          companyId,
          page: 1,
          sortBy,
        });
      }
    }
  }

  getCanEditData() {
    return !this.props.isDuplicateCompany;
  }

  handleGetNextPageDataForPlatform(page) {
    const { companyId } = this.props.match.params;
    const { projects, getBuyerProjectsPlatform } = this.props;
    const platform = projects.get('platform');
    const sortBy = buildSortByParam(platform);

    getBuyerProjectsPlatform({
      companyId,
      page,
      sortBy,
    });
  }

  handleGetNextPageDataForAddon(page) {
    const { companyId } = this.props.match.params;
    const { projects, getBuyerProjectsAddon } = this.props;
    const addon = projects.get('addon');
    const sortBy = buildSortByParam(addon);

    getBuyerProjectsAddon({
      companyId,
      page,
      sortBy,
    });
  }

  handleDoubleClickRow(row) {
    if (!this.getCanEditData()) {
      return;
    }

    window.open(`/project/${row.data.id}`, '_blank');
  }

  handleCurrentStatusChange(event) {
    const { value } = event.target;

    this.props.updateProjects({
      filterName: 'status',
      filterData: {
        selected: {
          name: value,
          value,
        },
      },
    });
  }

  handlePlatformChange(data) {
    this.props.updateProjects({
      filterName: 'platform',
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  }

  handleAddonChange(data) {
    this.props.updateProjects({
      filterName: 'addon',
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  }

  handleAddPlatformProject() {
    if (!this.getCanEditData()) {
      return;
    }

    const { companyId } = this.props.match.params;

    this.context.openPopup('AddNewPlatformProject', {
      buyerId: companyId,
      onCreate: this.handleCreateNewPlatformProject,
      onSuccessResponse: () => {
        this.handleGetNextPageDataForPlatform(1);
      },
    });
  }

  handleCreateNewPlatformProject(formData, callback) {
    const { createPlatformProject } = this.props;
    const body = {
      type: 'Platform',
      active: true,
      category: formData.get('category'),
    };

    if (formData.getIn(['harvcoLead', 'selected'])) {
      body.harvco_lead_id = formData.getIn(['harvcoLead', 'selected', 'id']);
    }

    if (formData.getIn(['clientLead', 'selected'])) {
      body.client_lead_id = formData.getIn(['clientLead', 'selected', 'id']);
    }

    if (formData.getIn(['executive', 'selected'])) {
      body.exec_id = formData.getIn(['executive', 'selected', 'id']);
    }
    body.buyerId = this.props.match.params.companyId;
    createPlatformProject({
      body,
      callback,
    });
  }

  handleGenerateSummaryReport() {
    const { companyId } = this.props.match.params;
    const { fetchTargetStatuses } = this.props;

    if (!this.getCanEditData()) {
      return;
    }

    fetchTargetStatuses(() => {
      this.context.openPopup('ClientSummaryReportPopup', {
        buyerId: companyId,
      });
    });
  }

  render() {
    const { projects } = this.props;

    return (
      <div className="tab-pane active tab-project--section" id="slide-3">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p0 full-height">
          <CompanyBuyerProjects
            canEditData={this.getCanEditData()}
            onAddonChange={this.handleAddonChange}
            onAddPlatformProject={this.handleAddPlatformProject}
            onCurrentStatusChange={this.handleCurrentStatusChange}
            onDoubleClickRow={this.handleDoubleClickRow}
            onGenerateReport={this.handleGenerateSummaryReport}
            onGetNextPageDataForAddon={this.handleGetNextPageDataForAddon}
            onGetNextPageDataForPlatform={this.handleGetNextPageDataForPlatform}
            onPlatformChange={this.handlePlatformChange}
            projects={projects}
          />
        </div>
      </div>
    );
  }
}

CompanyBuyerProjectsContainer.propTypes = {
  createPlatformProject: func.isRequired,
  getBuyerProjectsAddon: func.isRequired,
  getBuyerProjectsPlatform: func.isRequired,
  isDuplicateCompany: bool.isRequired,
  projects: object.isRequired,
  updateProjects: func.isRequired,
};
CompanyBuyerProjectsContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
};
export { CompanyBuyerProjectsContainer };
export default connect(
  state => ({
    projects: state.targetCompany.buyer.projects,
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
  }),
  dispatch =>
    bindActionCreators(
      {
        getBuyerProjectsPlatform,
        getBuyerProjectsAddon,
        updateProjects,
        createPlatformProject,
        fetchTargetStatuses,
      },
      dispatch,
    ),
)(CompanyBuyerProjectsContainer);
