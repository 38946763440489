import React, { memo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { fromJS } from 'immutable';

import FormGroup from '../../helpers/FormGroup';
import Select from '../../helpers/Select';

const OPTIONS = fromJS(
  (function() {
    const temp = [{ value: '', name: '' }];

    for (let month = 0; month < 12; month++) {
      const date = moment({ month }).endOf('month');
      const name = date.format('D MMM');

      temp.push({ value: date.get('month') + 1, name });
    }

    return temp;
  })(),
);

const FinancialYearEndComponent = props => {
  const { className, ...rest } = props;
  const cls = classNames('input-sm', className);

  return (
    <FormGroup>
      <Select {...rest} className={cls} label="FYE" options={OPTIONS} showLabel />
    </FormGroup>
  );
};

/** Financial year end component. */
export const FinancialYearEnd = memo(FinancialYearEndComponent);
