import React, { memo } from 'react';

const PlainPriorityComponent = () => (
  <div className="font-size-14">
    <div>A</div>
    <div>B</div>
    <div>C</div>
    <div>Exclude</div>
  </div>
);

export const PlainPriority = memo(PlainPriorityComponent);
