import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map } from 'immutable';

import config from '../../config';
import { getSafeLink } from '../../utils/url';
import { MailingCreateTable } from './MailingCreateTable';

let columnDefs = config.mailingCreate.getIn(['table', 'columnDefs']);
const targetWeb = columnDefs.findKey(columnDefs => columnDefs.get('field') === 'targetWeb');
const emailVerified = columnDefs.findKey(columnDefs => columnDefs.get('field') === 'emailVerified');
const logos = columnDefs.findKey(columnDefs => columnDefs.get('field') === 'logos');

if (targetWeb) {
  columnDefs = columnDefs.update(targetWeb, value =>
    value.set('cellRenderer', params => {
      const url = getSafeLink(params.value, '#');

      return `<a href="//${url}" target="_blank">${params.value}</a>`;
    }),
  );
}

if (emailVerified) {
  columnDefs = columnDefs.update(emailVerified, value =>
    value.set('cellRenderer', params => {
      const { hasEmail, isVerifyEmail } = params.data;
      const classHasEmail = `fa ${hasEmail ? 'text-success fa-check' : 'text-danger fa-times'}`;
      const titleHasEmail = hasEmail ? 'Email' : 'No Email';
      const classIsVerifyEmail = `fa ${isVerifyEmail ? 'text-success fa-check' : 'text-danger fa-times'}`;
      const titleIsVerifyEmail = isVerifyEmail ? 'Verified' : 'Not Verified';

      return `<div class='text-center'>
          <i class="pr5 ${classHasEmail}" title="${titleHasEmail}"></i>
          <i class="pl5 ${classIsVerifyEmail}" title="${titleIsVerifyEmail}"></i>
        </div>`;
    }),
  );
}

if (logos) {
  columnDefs = columnDefs.update(logos, value =>
    value.set('cellRenderer', params => {
      const { isLogo } = params.data;

      const classHasLogo = `fa ${isLogo ? 'fa-check text-success' : 'fa-times text-danger'}`;
      const titleHasLogo = isLogo ? 'Has logo' : 'Has not logo';

      return `<div class='text-center'>
          <i class="${classHasLogo}" title="${titleHasLogo}"></i>
        </div>`;
    }),
  );
}

/**
 * Create new mailing page component.
 *
 * @param props {Object}.
 * @param props.targetCompany {Immutable.Map} Map with company information.
 * @param props.onCompanyInfoChange {Function} Handle company info change event.
 * @param props.onToggleDBA {Function} Handle toggle dba input event.
 * @param props.onToggleCountry {Function} Handle toggle country input event.
 */
const MailingCreateComponent = ({
  mailingCreate,
  selectionQuantity,
  onUpdateSelectedRow,
  onClickSelectionControl,
  onChangeSelectionQuantity,
  onSelectByQuantity,
  onChange,
  onGetNextPageData,
  onDoubleClickRow,
  onReload,
  onCreateMailing,
}) => {
  const iconRender =
    mailingCreate.getIn(['header', 'isEmail']) === true ? (
      <i className="fa fa-folder-o" />
    ) : (
      <i className="fa fa-envelope-o" />
    );

  return (
    <div className="mailHistory">
      <div className="top_side">
        <div className="container text-center">
          <strong>Create New Mailing</strong>
        </div>
        <div className="container pmHeader">
          <div className="row">
            <div className="col-md-2 col-xs-12 colHeader">
              <span>Module:</span>
              <strong>
                {mailingCreate.getIn(['header', 'recordOwnerUserName'])}
                <br />
                {mailingCreate.getIn(['header', 'recordSubOwnerUserName'])}
              </strong>
            </div>
            <div className="col-md-2 col-xs-12 colHeader">
              <span>Buyer:</span>
              <strong>
                {mailingCreate.getIn(['header', 'buyerLegalName'])}
                <br />
                {mailingCreate.getIn(['header', 'projectCategory'])} (
                {mailingCreate.getIn(['header', 'projectHarvcoLeadUserName'])})
              </strong>
            </div>
            <div className="col-md-2 col-xs-12 colHeader">
              <strong>
                {iconRender} {mailingCreate.getIn(['header', 'activity'])}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div className="mailing-table-controls">
        <div className="mailing-table-control-group">
          <button className="btn btn-default btn-xs mailing-table-control" onClick={onSelectByQuantity} type="button">
            Select
          </button>
          <input
            className="mailing-table-control mailing-table-control__input"
            min="0"
            onChange={onChangeSelectionQuantity}
            type="number"
            value={selectionQuantity}
          />
        </div>
        <div className="mailing-table-control-group">
          <button
            className="btn btn-default btn-xs mailing-table-control"
            onClick={() => onClickSelectionControl(true)}
            type="button"
          >
            Select All
          </button>
        </div>
        <div className="mailing-table-control-group">
          <button
            className="btn btn-default btn-xs mailing-table-control"
            onClick={() => onClickSelectionControl(false)}
            type="button"
          >
            Clear Selection
          </button>
        </div>
      </div>
      <div className="main">
        <div className="container" role="main">
          <div className="position-relative full-height">
            <MailingCreateTable
              columnDefs={columnDefs}
              data={mailingCreate}
              onChange={onChange}
              onDoubleClickRow={onDoubleClickRow}
              onGetNextPageData={onGetNextPageData}
              onUpdateSelectedRow={onUpdateSelectedRow}
            />
          </div>
        </div>
      </div>
      <footer className="navbar-fixed-bottom">
        <div className="container">
          <div className="row">
            <div className="container-fluid text-right">
              <div className="btns">
                <button className="btn btn-default btn-xs" onClick={onCreateMailing} type="submit">
                  <i className="icon-user icon-white" />
                  Create Mailing
                </button>
                <button className="btn btn-default btn-xs" onClick={onReload} type="submit">
                  <i className="icon-user icon-white" />
                  Reload
                </button>
                <button className="btn btn-default btn-xs" type="submit">
                  <i className="icon-user icon-white" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

MailingCreateComponent.propTypes = {
  mailingCreate: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateMailing: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
};

export const MailingCreate = memo(MailingCreateComponent);
