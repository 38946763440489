import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';

const SpinnerComponent = ({ size = SpinnerComponent.size.MIDDLE, className = '', spinnerClassName = '' }) => {
  const spinnerCss = classNames('fa fa-spinner fa-pulse fa-fw', spinnerClassName, {
    'fa-1x': size === SpinnerComponent.size.SMALL,
    'fa-3x': size === SpinnerComponent.size.MIDDLE,
    'fa-5x': size === SpinnerComponent.size.LARGE,
  });

  return (
    <div className={className}>
      <div className="spinner--curtain" />
      <div className="spinner--icon text-center">
        <i className={spinnerCss} />
      </div>
    </div>
  );
};

SpinnerComponent.size = {
  SMALL: 'small',
  MIDDLE: 'middle',
  LARGE: 'large',
};

SpinnerComponent.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SpinnerComponent.size)),
};

export const Spinner = memo(SpinnerComponent);
