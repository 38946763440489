import React, { memo } from 'react';
import PropTypes from 'prop-types';

const EmptyRowComponent = ({ colSpan, text }) => (
  <td className="text-center" colSpan={colSpan}>
    {text}
  </td>
);

EmptyRowComponent.propTypes = {
  colSpan: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

/** Empty row component. */
export const EmptyRow = memo(EmptyRowComponent);
