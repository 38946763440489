import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { SortableItem } from '../decorators/SortableItem';

/**
 * Stateless component for rendering a sorted row.
 *
 * @param props {Object}.
 * @param props.children {Any} Children.
 * @param props.columnName {String} Column abbreviation.
 * @param props.direction {Number} Sort direction (1|-1).
 * @param props.onChangeSort {Function} To handle changing a sorting field.
 * @returns {React.Component}
 */
const SorterRow = props => {
  const {
    childProps: { columnName, direction, onChangeSort, order, defaultOrder },
    children,
    ...rest
  } = props;
  const className = classNames({
    'fa fa-caret-up': direction === 1,
    'fa fa-caret-down': direction !== 1,
  });

  return (
    <div {...rest} className="sort-menu__item">
      {children}
      <i
        aria-hidden="true"
        className={className}
        // We multiply by "-1" to get the inverted sort direction.
        onClick={() => onChangeSort(columnName, direction * -1, true, order)}
      />
      <i
        aria-hidden="true"
        className="fa fa-times"
        onClick={() => onChangeSort(columnName, direction, false, defaultOrder)}
      />
    </div>
  );
};

SorterRow.propTypes = {
  childProps: PropTypes.object,
  children: PropTypes.any,
};

export default SortableItem(SorterRow);
