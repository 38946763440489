import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { debounce, isEmpty } from 'underscore';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import moment from 'moment';
import { push } from 'connected-react18-router';

import { downloadBrowseFile } from 'src/utils/downloadFile';
import connectOptions, { mergeProps } from '../../utils/connectOptions';
import * as browseActionCreators from '../../actions/browse';
import { BrowseInputRange } from '../../components/Browse/BrowseInputRange';
import BrowseInputTag from '../../components/Browse/BrowseInputTag';
import { BrowseAutoCompleteTag } from '../../components/Browse/BrowseAutoCompleteTag';
import BrowseAutoCompleteCountry from '../../components/Browse/BrowseAutoCompleteCountry';
import BrowseBuyerFilter from '../../components/Browse/BrowseBuyerFilter';
import { BrowseActionFilter } from '../../components/Browse/BrowseActionFilter';
import { BrowseDropDown } from '../../components/Browse/BrowseDropDown';
import BrowseTable from '../../components/Browse/BrowseTable';
import { BrowseEmployeeInputRange } from '../../components/Browse/BrowseEmployeeInputRange';
import { BrowseUploadSearch } from '../../components/Browse/BrowseUploadSearch';
import config from '../../config';
import {
  buildRangeParam,
  buildDateParam,
  buildTagParam,
  buildCheckParam,
  buildActionParam,
  buildSortByParam,
  buildDropdownParam,
  buildActivityParam,
  buildUploadParam,
} from '../../helpers/paramBuilder';
import { fetchTargetStatuses } from '../../actions/statuses';
import { BrowseStatusesFilter } from '../../components/Browse/BrowseStatusesFilter';
import { BrowseActivityFilter } from '../../components/Browse/BrowseActivityFilter';
import { showError } from '../../utils/MessagePopup';
import { checkBrowsePageAccess } from '../../utils/checkPermissions';
import { BrowseCheckbox } from '../../components/Browse/BrowseCheckbox';

const SAFE_EXPORT_TARGETS_NUMBER = 300;
const ACTIVITY_GROUPS = [1, 7, 2, 3, 4, 5, 6, 8, 9, 10, 11];

const states = fromJS(config.company.states.map(st => ({ name: st, value: st })));
const activities = config.values
  .getIn(['eventActivities', 'target'])
  .filter(item => ACTIVITY_GROUPS.includes(item.get('group')));
const companyTypes = config.values.get('companyTypes');
const targetsFilter = config.browse.getIn(['filter', 'targets']);
const columnDefs = config.browse.getIn(['table', 'columnDefs', 'targets']);

const NUMBER = /[0-9]/g;

export class BrowseTargets extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubFilterChange = this.handleSubFilterChange.bind(this);
    this.handleSubActivityFilterChange = this.handleSubActivityFilterChange.bind(this);
    this.handleGetSuggestionForStates = debounce(this.handleGetSuggestionForStates.bind(this), 200);
    this.handleGetSuggestionForCountry = debounce(this.handleGetSuggestionForCountry.bind(this), 200);
    this.handleGetSuggestionForContinent = debounce(this.handleGetSuggestionForContinent.bind(this), 200);
    this.handleGetSuggestionForModule = debounce(this.handleGetSuggestionForModule.bind(this), 200);
    this.handleGetSuggestionForAnalyst = debounce(this.handleGetSuggestionForAnalyst.bind(this), 200);
    this.handleGetNextSuggestionForAnalyst = debounce(this.handleGetNextSuggestionForAnalyst.bind(this), 200);
    this.handleGetSuggestionForResearcher = debounce(this.handleGetSuggestionForResearcher.bind(this), 200);
    this.handleGetSuggestionForActivityUser = debounce(this.handleGetSuggestionForActivityUser.bind(this), 200);
    this.handleGetBuyerSuggestionForBuyer = debounce(this.handleGetBuyerSuggestionForBuyer.bind(this), 200);
    this.handleGetProjectSuggestionForBuyer = debounce(this.handleGetProjectSuggestionForBuyer.bind(this), 200);
    this.handleGetApprovalSuggestionForBuyer = debounce(this.handleGetApprovalSuggestionForBuyer.bind(this), 200);
    this.handleGetPrioritySuggestionForBuyer = debounce(this.handleGetPrioritySuggestionForBuyer.bind(this), 200);
    this.handleGetSuggestionForNextAction = debounce(this.handleGetSuggestionForNextAction.bind(this), 200);
    this.handleGetSuggestionForLastAction = debounce(this.handleGetSuggestionForLastAction.bind(this), 200);
    this.handleGetSuggestionForCompanyType = debounce(this.handleGetSuggestionForCompanyType.bind(this), 200);
    this.onToggle = this.onToggle.bind(this);
    this.handleGetNextPageData = this.handleGetNextPageData.bind(this);
    this.handleGetNextSuggestionForModule = debounce(this.handleGetNextSuggestionForModule.bind(this), 200);
    this.handleGetNextSuggestionForResearcher = debounce(this.handleGetNextSuggestionForResearcher.bind(this), 200);
    this.handleGetNextSuggestionForActivityUser = debounce(this.handleGetNextSuggestionForActivityUser.bind(this), 200);
    this.handleGetNextBuyerSuggestionForBuyer = debounce(this.handleGetNextBuyerSuggestionForBuyer.bind(this), 200);
    this.handleGetNextProjectSuggestionForBuyer = debounce(this.handleGetNextProjectSuggestionForBuyer.bind(this), 200);
    this.handleGetNextApprovalSuggestionForBuyer = debounce(
      this.handleGetNextApprovalSuggestionForBuyer.bind(this),
      200,
    );
    this.handleGetNextSuggestionForCountry = debounce(this.handleGetNextSuggestionForCountry.bind(this), 200);
    this.handleGetNextSuggestionForContinent = debounce(this.handleGetNextSuggestionForContinent.bind(this), 200);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.handleAbortRequest = this.handleAbortRequest.bind(this);
    this.handleDownloadExport = this.handleDownloadExport.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.buildFiltersStateParam = this.buildFiltersStateParam.bind(this);
    this.prepareActionData = this.prepareActionData.bind(this);

    this.keepSelection = false;
    this.handleGetSuggestionForDealMaker = debounce(this.handleGetSuggestionForDealMaker.bind(this), 200);
    this.handleGetNextSuggestionForDealMaker = debounce(this.handleGetNextSuggestionForDealMaker.bind(this), 200);
  }

  componentDidMount() {
    const { onFetchStatuses, location, handleFetchCriteria } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const researcher = searchParams.get('researcher');
    const criteria = searchParams.get('criteria');

    onFetchStatuses();

    if (researcher) {
      this.handleChange({
        filterName: 'researcher',
        filterData: {
          checked: true,
        },
      });
    }

    if (criteria) {
      handleFetchCriteria({
        name: 'targets',
        hash: criteria,
      });
    }

    if (!this.checkPageAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.context.closePopup();
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const sortModel = this.props.targets.getIn(['sortBy', 'sortModel']);
    const newSortModel = nextProps.targets.getIn(['sortBy', 'sortModel']);

    if (newSortModel !== sortModel) {
      const requestParam = this.buildRequestParam(nextProps.targets);

      if (isEmpty(requestParam)) {
        return;
      }

      const sortBy = buildSortByParam(nextProps.targets);

      this.props.handleBrowse({
        name: 'targets',
        params: requestParam,
        page: 1,
        sortBy: sortBy.replace(NUMBER, char => `_${char}`),
        keepSelection: this.keepSelection,
      });
      this.keepSelection = false;
    }
  }

  componentDidUpdate(prevProps) {
    const searchParams = new URLSearchParams(this.props.location.search);
    const researcher = searchParams.get('researcher');
    const userId = searchParams.get('userId');

    if (researcher && userId) {
      if (!prevProps.targets.getIn(['researcher', 'checked']) && this.props.targets.getIn(['researcher', 'checked'])) {
        this.handleGetSuggestionForResearcher({ value: researcher });
      }

      if (
        prevProps.targets.getIn(['researcher', 'suggestions']).size !== 1 &&
        this.props.targets.getIn(['researcher', 'suggestions']).size >= 1
      ) {
        this.handleChange({
          filterName: 'researcher',
          filterData: {
            selectedList: [
              this.props.targets
                .getIn(['researcher', 'suggestions'])
                .find(suggestion => suggestion.id === Number(userId)),
            ],
            suggestions: [],
          },
        });
      }

      if (
        prevProps.targets.getIn(['researcher', 'selectedList']).size === 0 &&
        this.props.targets.getIn(['researcher', 'selectedList']).size === 1
      ) {
        this.handleGetNextPageData(1, false);
        this.props.push({
          pathname: this.props.location.pathname,
          search: '',
        });
      }
    }
  }

  /**
   * Prepare moment() date object into ISO string.
   *
   * @param {object} data Action data.
   */
  prepareActionData(data) {
    return {
      ...data,
      endDate: moment.isMoment(data.endDate) ? data.endDate.toISOString() : data.endDate,
      startDate: moment.isMoment(data.startDate) ? data.startDate.toISOString() : data.startDate,
    };
  }

  /**
   * Build all state data to restore it on a getting client.
   *
   * @param {object} props Component props.
   */
  buildFiltersStateParam(props) {
    const stateParams = {};

    // Collect needed filters if it's selected.
    if (props.getIn(['currentStatus', 'checked'])) stateParams.currentStatus = props.get('currentStatus').toJS();
    if (props.getIn(['highStatus', 'checked'])) stateParams.highStatus = props.get('highStatus').toJS();
    if (props.getIn(['revenue', 'checked'])) stateParams.revenue = props.get('revenue').toJS();
    if (props.getIn(['ebitda', 'checked'])) stateParams.ebitda = props.get('ebitda').toJS();
    if (props.getIn(['employees', 'checked'])) stateParams.employees = props.get('employees').toJS();
    if (props.getIn(['states', 'checked'])) stateParams.states = props.get('states').toJS();
    if (props.getIn(['country', 'checked'])) stateParams.country = props.get('country').toJS();
    if (props.getIn(['module', 'checked'])) stateParams.module = props.get('module').toJS();
    if (props.getIn(['analyst', 'checked'])) stateParams.analyst = props.get('analyst').toJS();
    if (props.getIn(['dealMaker2', 'checked'])) stateParams.dealMaker2 = props.get('dealMaker2').toJS();
    if (props.getIn(['researcher', 'checked'])) stateParams.researcher = props.get('researcher').toJS();
    if (props.getIn(['buyer', 'checked'])) stateParams.buyer = props.get('buyer').toJS();
    if (props.get('isNewProfile')) stateParams.isNewProfile = props.get('isNewProfile');
    if (props.get('isUpdatedProfile')) stateParams.isUpdatedProfile = props.get('isUpdatedProfile');
    if (props.get('isConfirmedProfile')) stateParams.isConfirmedProfile = props.get('isConfirmedProfile');
    if (props.getIn(['harvcoTags', 'checked'])) stateParams.harvcoTags = props.get('harvcoTags').toJS();
    if (props.getIn(['industry', 'checked'])) stateParams.industry = props.get('industry').toJS();
    if (props.getIn(['mergeResultOnly', 'checked']))
      stateParams.mergeResultOnly = this.prepareActionData(props.get('mergeResultOnly').toJS());
    if (props.getIn(['nextAction', 'checked']))
      stateParams.nextAction = this.prepareActionData(props.get('nextAction').toJS());
    if (props.getIn(['lastAction', 'checked']))
      stateParams.lastAction = this.prepareActionData(props.get('lastAction').toJS());

    if (props.getIn(['description', 'checked'])) stateParams.description = props.get('description').toJS();
    if (props.getIn(['companyType', 'checked'])) stateParams.companyType = props.get('companyType').toJS();
    if (props.getIn(['areaCode', 'checked'])) stateParams.areaCode = props.get('areaCode').toJS();
    if (props.getIn(['keywords', 'checked'])) stateParams.keywords = props.get('keywords').toJS();
    if (props.getIn(['projectType', 'checked'])) stateParams.projectType = props.get('projectType').toJS();
    if (props.getIn(['uploadSearch', 'checked'])) stateParams.uploadSearch = props.get('uploadSearch').toJS();

    return stateParams;
  }

  buildRequestParam(props) {
    const requestParams = {
      ...buildRangeParam(props, 'current_status_range', 'currentStatus'),
      ...buildRangeParam(props, 'high_status_range', 'highStatus'),
      ...buildRangeParam(props, 'revenue_range', 'revenue'),
      ...buildRangeParam(props, 'ebitda_range', 'ebitda'),
      ...buildRangeParam(props, 'employee_number_range', 'employees'),
      ...buildTagParam(props, 'states', 'states', 'value'),
      ...buildTagParam(props, 'countries', 'country', 'id'),
      ...buildTagParam(props, 'continents', 'continent', 'id'),
      ...buildTagParam(props, 'directors', 'module', 'id'),
      ...buildTagParam(props, 'analysts', 'analyst', 'id'),
      ...buildTagParam(props, 'deal_makers_2', 'dealMaker2', 'id'),
      ...buildTagParam(props, 'researchers', 'researcher', 'id'),
      ...buildCheckParam(props, 'is_new_profile', 'isNewProfile'),
      ...buildCheckParam(props, 'is_updated_profile', 'isUpdatedProfile'),
      ...buildCheckParam(props, 'is_confirmed_profile', 'isConfirmedProfile'),
      ...buildTagParam(props, 'tags', 'harvcoTags', 'value'),
      ...buildTagParam(props, 'industries', 'industry', 'value'),
      ...buildDateParam(props, 'next_action_dates', 'nextAction'),
      ...buildActionParam(props, 'next_actions', 'nextAction'),
      ...buildTagParam(props, 'descriptions', 'description', 'value'),
      ...buildTagParam(props, 'company_types', 'companyType', 'value'),
      ...buildTagParam(props, 'area_codes', 'areaCode', 'value'),
      ...buildTagParam(props, 'keywords', 'keywords', 'value'),
      ...buildDropdownParam(props, 'project_type', 'projectType'),
      ...buildUploadParam(props, 'upload_search', 'uploadSearch'),
    };

    if (props.getIn(['lastAction', 'lastActivity'])) {
      Object.assign(requestParams, buildDateParam(props, 'last_action_dates', 'lastAction'));
      Object.assign(requestParams, buildActionParam(props, 'last_actions', 'lastAction'));
    } else {
      Object.assign(requestParams, buildActivityParam(props, 'activity', 'lastAction'));
    }

    if (Array.isArray(requestParams.activity)) {
      const users = buildTagParam(props, 'activityUsers', 'activityUsers', 'id');

      if (users) {
        requestParams.activity.push({
          users: users.activityUsers,
        });
      }
    }

    if (props.getIn(['projectType', 'checked'])) {
      requestParams.project_type = props.getIn(['projectType', 'selected', 'value']);
    }

    if (props.getIn(['mergeResultOnly', 'checked'])) {
      requestParams.merge_result_only = props.getIn(['mergeResultOnly', 'checked']);
    }

    if (props.getIn(['lastAction', 'projectType', 'checked'])) {
      if (Array.isArray(requestParams.activity)) {
        const { activity } = requestParams;

        activity.push({
          project_type: props.getIn(['lastAction', 'projectType', 'selected', 'value']),
        });
        Object.assign(requestParams, { activity });
      }
    }

    if (props.getIn(['lastAction', 'statusChanged'])) {
      if (Array.isArray(requestParams.activity)) {
        const statusChangedFromValues = props.getIn(['lastAction', 'statusChangedFrom', 'text']).toJS();
        const statusChangedToValues = props.getIn(['lastAction', 'statusChangedTo', 'text']).toJS();
        const activity = requestParams.activity.slice();

        activity.push({
          status_changed_from: {
            min: statusChangedFromValues.min,
            max: statusChangedFromValues.max,
          },
        });
        activity.push({
          status_changed_to: {
            min: statusChangedToValues.min,
            max: statusChangedToValues.max,
          },
        });
        Object.assign(requestParams, { activity });
      }
    }

    if (props.getIn(['buyer', 'checked'])) {
      const selectedList = props.getIn(['buyer', 'selectedList']).toJS();
      const selectedListParam = props.getIn(['buyer', 'selectedListParam']).toJS();

      const includedBuyers = selectedListParam.filter((item, i) => !selectedList[i].exclude);
      const excludedBuyers = selectedListParam.filter((item, i) => selectedList[i].exclude);

      if (selectedListParam.length > 0) {
        requestParams.buyers = {
          included: includedBuyers,
          excluded: excludedBuyers,
        };
      }
    }

    if (props.getIn(['country', 'checked']) && props.getIn(['country', 'selectedList']).size === 0) {
      requestParams.countries = {
        included: [],
        excluded: [],
      };
    }

    return requestParams;
  }

  handleGetNextPageData(page, keepSelection = true) {
    const requestParam = this.buildRequestParam(this.props.targets);

    if (!isEmpty(requestParam)) {
      const sortBy = buildSortByParam(this.props.targets);

      this.props.handleBrowse({
        name: 'targets',
        params: requestParam,
        page,
        sortBy: sortBy.replace(NUMBER, char => `_${char}`),
        keepSelection,
      });
    }
  }

  handleChange(data) {
    if (data.filterName === 'sortBy') {
      this.keepSelection = true;
    }

    const extra = data.key ? { key: data.key } : {};

    this.props.handleUpdateFilter({
      ...extra,
      name: 'targets',
      filterName: data.filterName,
      filterData: data.filterData,
    });
  }

  handleGetSuggestionForStates({ value }) {
    const suggestions = states.filter(
      state =>
        state
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'targets',
      filterName: 'states',
      filterData: {
        suggestions,
      },
    });
  }

  handleGetSuggestionForCountry({ value }) {
    this.props.handleGetCountrySuggestion('targets', value);
  }

  handleGetNextSuggestionForCountry({ value }) {
    const info = this.props.targets.getIn(['country', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage && currentPage < info.get('totalPages')) {
      this.props.handleGetCountrySuggestion('targets', value, currentPage + 1);
    }
  }

  handleGetSuggestionForContinent({ value }) {
    this.props.handleGetContinentSuggestion('targets', value);
  }

  handleGetNextSuggestionForContinent({ value }) {
    const info = this.props.targets.getIn(['continent', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage && currentPage < info.get('totalPages')) {
      this.props.handleGetContinentSuggestion('targets', value, currentPage + 1);
    }
  }

  handleGetSuggestionForModule({ value }) {
    this.props.handleGetModuleSuggestion('targets', value);
  }

  handleGetNextSuggestionForModule({ value }) {
    const info = this.props.targets.getIn(['module', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetModuleSuggestion('targets', value, currentPage + 1);
    }
  }

  handleGetSuggestionForAnalyst({ value }) {
    this.props.handleGetUsersSuggestion('targets', 'analyst', value, 1, true);
  }

  handleGetNextSuggestionForAnalyst({ value }) {
    const info = this.props.targets.getIn(['analyst', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('targets', 'analyst', value, currentPage + 1, true);
    }
  }

  handleGetSuggestionForDealMaker({ value }) {
    this.props.handleGetUsersSuggestion('targets', 'dealMaker2', value, 1, true);
  }

  handleGetNextSuggestionForDealMaker({ value }) {
    const info = this.props.targets.getIn(['dealMaker2', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('targets', 'dealMaker2', value, currentPage + 1, true);
    }
  }

  handleGetSuggestionForResearcher({ value }) {
    this.props.handleGetUsersSuggestion('targets', 'researcher', value, 1, true);
  }

  handleGetSuggestionForActivityUser({ value }) {
    this.props.handleGetUsersSuggestion('targets', 'activityUsers', value, 1, true);
  }

  handleGetNextSuggestionForResearcher({ value }) {
    const info = this.props.targets.getIn(['researcher', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('targets', 'researcher', value, currentPage + 1, true);
    }
  }

  handleGetNextSuggestionForActivityUser({ value }) {
    const info = this.props.targets.getIn(['activityUsers', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('targets', 'activityUsers', value, currentPage + 1, true);
    }
  }

  handleSubFilterChange(data) {
    this.props.handleUpdateTargetsBuyerFilter(data);
  }

  handleSubActivityFilterChange(data) {
    this.props.handleUpdateTargetsActivityFilter(data);
  }

  handleGetBuyerSuggestionForBuyer({ value }) {
    this.props.handleGetBuyerNameSuggestion(value);
  }

  handleGetNextBuyerSuggestionForBuyer({ value }) {
    const info = this.props.targets.getIn(['buyer', 'buyer', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetBuyerNameSuggestion(value, currentPage + 1);
    }
  }

  handleGetProjectSuggestionForBuyer({ value }) {
    const buyerSelected = this.props.targets.getIn(['buyer', 'buyer', 'selected']);

    if (buyerSelected) {
      this.props.handleGetBuyerProjectSuggestion(buyerSelected.get('id'), value);
    }
  }

  handleGetNextProjectSuggestionForBuyer({ value }) {
    const buyerSelected = this.props.targets.getIn(['buyer', 'buyer', 'selected']);
    const info = this.props.targets.getIn(['buyer', 'project', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (buyerSelected && currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetBuyerProjectSuggestion(buyerSelected.get('id'), value, currentPage + 1);
    }
  }

  handleGetApprovalSuggestionForBuyer({ value }) {
    const projectSelected = this.props.targets.getIn(['buyer', 'project', 'selected']);

    if (projectSelected) {
      this.props.handleGetBuyerApprovalSuggestion(projectSelected.get('id'), value);
    }
  }

  handleGetNextApprovalSuggestionForBuyer({ value }) {
    const projectSelected = this.props.targets.getIn(['buyer', 'project', 'selected']);
    const info = this.props.targets.getIn(['buyer', 'approval', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (projectSelected && currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetBuyerApprovalSuggestion(projectSelected.get('id'), value, currentPage + 1);
    }
  }

  handleGetPrioritySuggestionForBuyer({ value }) {
    const suggestions = [
      { value: 'A', name: 'A' },
      { value: 'B', name: 'B' },
      { value: 'C', name: 'C' },
    ];

    if (value === '') {
      this.props.handleUpdateTargetsBuyerFilter({
        filterName: 'priority',
        filterData: {
          suggestions,
        },
      });
    }
  }

  handleGetSuggestionForNextAction({ value }) {
    const suggestions = activities.filter(
      activity =>
        activity
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'targets',
      filterName: 'nextAction',
      filterData: {
        suggestions,
      },
    });
  }

  handleGetSuggestionForLastAction({ value }) {
    const suggestions = activities.filter(
      activity =>
        activity
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'targets',
      filterName: 'lastAction',
      filterData: {
        suggestions,
      },
    });
  }

  handleGetSuggestionForCompanyType({ value }) {
    const suggestions = companyTypes.filter(
      companyType =>
        companyType
          .get('value')
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1,
    );

    this.props.handleUpdateFilter({
      name: 'targets',
      filterName: 'companyType',
      filterData: {
        suggestions,
      },
    });
  }

  handleDownloadExport(idList, params) {
    const url = `${config.API_BASE_URL}/api/v1/browse/targets/export`;

    const afterSuccess = () => {
      if (idList.length > SAFE_EXPORT_TARGETS_NUMBER) {
        this.props.handleExportWithSearchCriteria(url, idList, params);
      } else {
        downloadBrowseFile(url, { selected_ids: idList });
      }
    };

    this.props.checkExportAvailability({
      params: {
        selected_ids: idList,
      },
      callback: {
        afterSuccess,
        afterError: ({ resBody }) => {
          if (resBody) {
            showError(this.context.openPopup, [resBody.message], this.context.closePopup());
          }
        },
      },
    });
  }

  handleDoubleClickRow(row) {
    window.open(`/company/${row.data.id}`, '_blank');
  }

  onToggle() {
    this.props.updateTabVisibility({
      field: 'targets.showTabControls',
      value: !this.props.targets.get('showTabControls'),
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  handleClearFilter(name) {
    this.props.handleClearFilter({ name });
  }

  handleAbortRequest(name) {
    this.props.handleAbortRequest(name);
  }

  //* Check whether current user has access to current tab or not. */
  checkPageAccess() {
    return checkBrowsePageAccess(this.props.currentUser, 'targets');
  }

  render() {
    const {
      statuses,
      targets,
      handleTargetsAddTag,
      handleTargetSetNextActions,
      handleTargetPushNextActionsDates,
      handleSaveAddUpdateBuyer,
      handleTargetDelete,
      handleTargetDeleteConfirmation,
      handleConfirmMerging,
      handleUpdateQueryResult,
      massSelectRows,
      ...rest
    } = this.props;
    const controlClassName = classNames('BrowseControls', {
      hidden: !targets.get('showTabControls'),
    });

    const params = {
      requestParams: this.buildRequestParam(targets),
      stateParams: this.buildFiltersStateParam(targets),
    };

    const hasParams = !isEmpty(params.requestParams);

    const hasPageAccess = this.checkPageAccess();

    return (
      <div className="tab-pane active" id="browse-targets" role="tabpanel">
        {hasPageAccess && (
          <div className="BrowseTabs-inner">
            <BrowseTable
              columnDefs={columnDefs}
              data={targets}
              hasParams={hasParams}
              name="targets"
              onAbortRequest={this.handleAbortRequest}
              onAddTag={handleTargetsAddTag}
              onChange={this.handleChange}
              onClearFilter={this.handleClearFilter}
              onConfirmMerging={handleConfirmMerging}
              onDelete={handleTargetDelete}
              onDeleteConfirmation={handleTargetDeleteConfirmation}
              onDoubleClickRow={this.handleDoubleClickRow}
              onDownloadExport={this.handleDownloadExport}
              onGetNextPageData={this.handleGetNextPageData}
              onGetPageData={this.handleGetNextPageData}
              onGridReady={this.onGridReady}
              onPushNextActionsDates={handleTargetPushNextActionsDates}
              onSaveAddUpdateBuyer={handleSaveAddUpdateBuyer}
              onSelectRows={massSelectRows}
              onSetNextActions={handleTargetSetNextActions}
              onToggle={this.onToggle}
              onUpdateQueryResult={handleUpdateQueryResult}
              requestParams={params.requestParams}
              stateParams={params.stateParams}
              {...rest}
            />
            <div className={controlClassName}>
              <BrowseStatusesFilter
                filter={targetsFilter.get('currentStatus')}
                info={targets.get('currentStatus')}
                onChange={this.handleChange}
                statuses={statuses}
              />
              <BrowseStatusesFilter
                filter={targetsFilter.get('highStatus')}
                info={targets.get('highStatus')}
                onChange={this.handleChange}
                statuses={statuses}
              />
              <BrowseInputRange
                filter={targetsFilter.get('revenue')}
                info={targets.get('revenue')}
                onChange={this.handleChange}
              />
              <BrowseInputRange
                filter={targetsFilter.get('ebitda')}
                info={targets.get('ebitda')}
                onChange={this.handleChange}
              />
              <BrowseEmployeeInputRange
                filter={targetsFilter.get('employees')}
                info={targets.get('employees')}
                onChange={this.handleChange}
              />
              <BrowseAutoCompleteTag
                filter={targetsFilter.get('states')}
                info={targets.get('states')}
                onChange={this.handleChange}
                onGetSuggestion={this.handleGetSuggestionForStates}
              />
              <BrowseAutoCompleteCountry
                filterContinent={targetsFilter.get('continent')}
                filterCountry={targetsFilter.get('country')}
                infoContinent={targets.get('continent')}
                infoCountry={targets.get('country')}
                onChange={this.handleChange}
                onGetContinentSuggestion={this.handleGetSuggestionForContinent}
                onGetCountrySuggestion={this.handleGetSuggestionForCountry}
                onGetNextContinentSuggestion={this.handleGetNextSuggestionForContinent}
                onGetNextCountrySuggestion={this.handleGetNextSuggestionForCountry}
              />
              <BrowseAutoCompleteTag
                filter={targetsFilter.get('module')}
                info={targets.get('module')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForModule}
                onGetSuggestion={this.handleGetSuggestionForModule}
              />
              <BrowseAutoCompleteTag
                filter={targetsFilter.get('analyst')}
                info={targets.get('analyst')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForAnalyst}
                onGetSuggestion={this.handleGetSuggestionForAnalyst}
              />
              <BrowseAutoCompleteTag
                filter={targetsFilter.get('dealMaker2')}
                info={targets.get('dealMaker2')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForDealMaker}
                onGetSuggestion={this.handleGetSuggestionForDealMaker}
              />
              <BrowseAutoCompleteTag
                filter={targetsFilter.get('researcher')}
                info={targets.get('researcher')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForResearcher}
                onGetSuggestion={this.handleGetSuggestionForResearcher}
              />
              <BrowseBuyerFilter
                filter={targetsFilter.get('buyer')}
                info={targets.get('buyer')}
                onChange={this.handleChange}
                onGetApprovalSuggestion={this.handleGetApprovalSuggestionForBuyer}
                onGetBuyerSuggestion={this.handleGetBuyerSuggestionForBuyer}
                onGetNextApprovalSuggestion={this.handleGetNextApprovalSuggestionForBuyer}
                onGetNextBuyerSuggestion={this.handleGetNextBuyerSuggestionForBuyer}
                onGetNextProjectSuggestion={this.handleGetNextProjectSuggestionForBuyer}
                onGetPrioritySuggestion={this.handleGetPrioritySuggestionForBuyer}
                onGetProjectSuggestion={this.handleGetProjectSuggestionForBuyer}
                onSubFilterChange={this.handleSubFilterChange}
                rangeFilterData={statuses}
              />
              <BrowseInputTag
                filter={targetsFilter.get('harvcoTags')}
                info={targets.get('harvcoTags')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseInputTag
                filter={targetsFilter.get('industry')}
                info={targets.get('industry')}
                onChange={this.handleChange}
                isBIC
                submitOnEnter
              />
              <BrowseActionFilter
                filter={targetsFilter.get('nextAction')}
                info={targets.get('nextAction')}
                onChange={this.handleChange}
                onGetSuggestion={this.handleGetSuggestionForNextAction}
              />
              <BrowseActivityFilter
                filter={targetsFilter.get('lastAction')}
                info={targets.get('lastAction')}
                onChange={this.handleChange}
                onGetActivityUserSuggestion={this.handleGetSuggestionForActivityUser}
                onGetNextActivityUserSuggestion={this.handleGetNextSuggestionForActivityUser}
                onGetSuggestion={this.handleGetSuggestionForLastAction}
                onSubActivityFilterChange={this.handleSubActivityFilterChange}
                projectTypeFilter={targetsFilter.get('projectType')}
                projectTypeInfo={targets.getIn(['lastAction', 'projectType'])}
                statuses={statuses}
                usersFilter={targetsFilter.getIn(['lastAction', 'activityUsers'])}
                usersInfo={targets.get('activityUsers')}
              />
              <BrowseInputTag
                filter={targetsFilter.get('description')}
                info={targets.get('description')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseAutoCompleteTag
                filter={targetsFilter.get('companyType')}
                info={targets.get('companyType')}
                onChange={this.handleChange}
                onGetSuggestion={this.handleGetSuggestionForCompanyType}
              />
              <BrowseInputTag
                filter={targetsFilter.get('areaCode')}
                info={targets.get('areaCode')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseInputTag
                filter={targetsFilter.get('keywords')}
                info={targets.get('keywords')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseDropDown
                filter={targetsFilter.get('projectType')}
                info={targets.get('projectType')}
                onChange={this.handleChange}
              />
              <BrowseUploadSearch
                clearStatus={!params.requestParams.upload_search}
                filter={targetsFilter.get('uploadSearch')}
                info={targets.get('uploadSearch')}
                onChange={this.handleChange}
                submitOnEnter
              />
              <BrowseCheckbox
                filter={targetsFilter.get('mergeResultOnly')}
                info={targets.get('mergeResultOnly')}
                onChange={this.handleChange}
              >
                <>&nbsp;</>
              </BrowseCheckbox>
            </div>
          </div>
        )}
      </div>
    );
  }
}

BrowseTargets.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

BrowseTargets.propTypes = {
  checkExportAvailability: PropTypes.func.isRequired,
  handleAbortRequest: PropTypes.func.isRequired,
  handleBrowse: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  handleGetBuyerApprovalSuggestion: PropTypes.func.isRequired,
  handleGetBuyerNameSuggestion: PropTypes.func.isRequired,
  handleGetBuyerProjectSuggestion: PropTypes.func.isRequired,
  handleGetContinentSuggestion: PropTypes.func.isRequired,
  handleGetCountrySuggestion: PropTypes.func.isRequired,
  handleGetModuleSuggestion: PropTypes.func.isRequired,
  handleGetUsersSuggestion: PropTypes.func.isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
  handleUpdateTargetsActivityFilter: PropTypes.func.isRequired,
  handleUpdateTargetsBuyerFilter: PropTypes.func.isRequired,
  updateTabVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  targets: state.browse.get('targets'),
  statuses: state.statuses.get('targetStatuses'),
  currentUser: state.auth.get('user'),
});

const mapDispatchToProps = dispatch => ({
  onFetchStatuses: () => dispatch(fetchTargetStatuses()),
  ...bindActionCreators(browseActionCreators, dispatch),
  push,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(withRouter(BrowseTargets));
