import { fromJS, List, Map } from 'immutable';

import { TYPE_FINANCIAL, TYPE_STRATEGIC } from './constants/common';
import constants from './constants';
import tables from './tables';

const RESEARCHER = 'researcher';
const RESEARCH_INTERN = 'researchintern';
const JUNIOR_ANALYST = 'junioranalyst';
const PROCESS_MANAGER = 'processmanager';
const ANALYST_ASSOCIATE = 'analystassociate';
const DIRECTOR = 'director';
const FINANCE_ADMIN = 'financeadmin';
const ADMINISTRATOR = 'superuser';

// Static values
const config = {
  appName: 'Harvey CRM',
  color: '#f5b300',
  tables: {
    fund: {
      buyer: {
        columnDefs: tables.fund.buyer,
      },
    },
    contactBuyer: tables.contact.buyer,
    contactTarget: tables.contact.target,
    tradeShowCompaniesTable: {
      columnDefs: tables.tradeshow.companies,
    },
    companyExecutive: {
      columnDefs: [
        { headerName: 'Contact', field: 'dsplContactFLName', width: 100 },
        { headerName: 'Pri. Phone', field: 'dsplPhone', width: 100 },
        { headerName: 'Pri. Email', field: 'dsplEmail', width: 100 },
        {
          headerName: 'Current Status',
          field: 'execCurrentStatus',
          width: 100,
          suppressSizeToFit: true,
        },
        {
          headerName: 'High Status',
          field: 'execHighStatus',
          width: 100,
          suppressSizeToFit: true,
        },
        { headerName: 'Title', field: 'title', width: 50 },
        {
          headerName: 'Start Year',
          field: 'startYear',
          width: 70,
          suppressSizeToFit: true,
        },
        {
          headerName: 'End Year',
          field: 'endYear',
          width: 70,
          suppressSizeToFit: true,
        },
        { headerName: 'Primary Industry', field: '', width: 50 },
        { headerName: 'Primary Buyer', field: '', width: 50 },
        {
          headerName: 'Quality',
          field: 'dsplQuality',
          width: 60,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Interest',
          field: 'dsplInterest',
          width: 60,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Relationship',
          field: 'dsplRelationship',
          width: 90,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Resume',
          field: 'dsplLegacyResume',
          width: 70,
          suppressSizeToFit: true,
        },
        { headerName: 'Harvco Visit', field: '', width: 50 },
        { headerName: 'Employment', field: '', width: 50 },
      ],
    },
    companyLP: {
      columnDefs: [
        { headerName: 'Name', field: 'fLName', width: 100 },
        {
          headerName: 'Primary Relationship',
          field: 'lpPrimaryRelUserName',
          width: 100,
        },
        {
          headerName: 'Current Status',
          field: 'lpCurrentStatus',
          width: 100,
          suppressSizeToFit: true,
        },
        {
          headerName: 'High Status',
          field: 'lpHighStatus',
          width: 100,
          suppressSizeToFit: true,
        },
        { headerName: 'Notes', field: 'lpNotes', width: 50 },
      ],
    },
    companyTradeGroups: {
      columnDefs: [
        {
          headerName: 'Name',
          field: 'name',
          width: 300,
        },
        {
          headerName: '',
          children: [
            {
              headerName: 'Abbr.',
              field: 'abbr',
              width: 200,
              sortable: false,
            },
          ],
        },
        {
          headerName: 'Website',
          field: 'website',
          width: 300,
        },
        {
          headerName: 'Date',
          children: [
            {
              field: 'startDate',
              width: 80,
              sortable: true,
            },
            {
              field: 'endDate',
              width: 80,
              sortable: true,
            },
          ],
        },
        {
          headerName: 'Location',
          children: [
            {
              field: 'city',
              width: 100,
              sortable: true,
            },
            {
              field: 'state',
              width: 60,
              sortable: true,
            },
          ],
        },
        {
          headerName: 'Targets',
          field: 'targets',
          width: 60,
        },
        {
          headerName: '',
          children: [
            {
              field: 'total',
              width: 60,
              sortable: false,
            },
          ],
        },
      ],
    },
    companyBuyerLeads: {
      columnDefs: [
        {
          headerName: 'Curr',
          field: 'currentStatus',
          width: 100,
          suppressSizeToFit: true,
          sort: 'desc',
        },
        {
          headerName: 'High',
          field: 'highStatus',
          width: 100,
          suppressSizeToFit: true,
        },
        { headerName: 'Target', field: 'dsplTargetLegalName', width: 100 },
        { headerName: 'Project', field: 'dsplProjectCategory', width: 110 },
        {
          headerName: 'Approval List',
          field: 'applistLabel',
          width: 100,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Priority',
          field: 'priority',
          width: 100,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Approved',
          field: 'approved',
          width: 100,
          suppressSizeToFit: true,
        },
        { headerName: 'Industry', field: 'dsplIndustry', width: 50 },
        { headerName: 'City', field: 'dsplCity', width: 50 },
        {
          headerName: 'State',
          field: 'dsplState',
          width: 50,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Revenue',
          field: 'dsplRevenue',
          width: 100,
          suppressSizeToFit: true,
        },
      ],
    },
    companyBuyerProjects: {
      platform: {
        columnDefs: [
          { headerName: 'Category', field: 'category', width: 200 },
          { headerName: 'Active', field: 'active', width: 30 },
          { headerName: 'Harvco Lead', field: 'dsplHarvcoLeadUserName', width: 40 },
          { headerName: 'Client Lead', field: 'dsplClientLeadFLName', width: 100 },
          { headerName: 'Executive', field: 'dsplExecFLName', width: 40 },
          { headerName: '# Targets.', field: 'dsplTargetCount', width: 80, suppressSizeToFit: true },
          { headerName: '# Approved', field: 'dsplApprovedCount', width: 80, suppressSizeToFit: true },
          { headerName: '% Approved', field: 'dsplApprovedPercent', width: 80, suppressSizeToFit: true },
          { headerName: 'Contacted #(%)', field: 'numberOfContacted', width: 95, suppressSizeToFit: true },
          { headerName: 'Leads #(%)', field: 'numberOfLeads', width: 80, suppressSizeToFit: true },
          { headerName: 'CC #(%)', field: 'numberOfCc', width: 70, suppressSizeToFit: true },
          { headerName: 'Visit #(%)', field: 'numberOfVisit', width: 80, suppressSizeToFit: true },
        ],
      },
      addon: {
        columnDefs: [
          { headerName: 'Buyer', field: 'dsplBuyerLegalName', width: 150 },
          { headerName: 'Harvco Lead', field: 'dsplHarvcoLeadUserName', width: 40 },
          { headerName: 'Client Lead', field: 'dsplClientLeadFLName', width: 100 },
          { headerName: '# Targets.', field: 'dsplTargetCount', width: 100, suppressSizeToFit: true },
          { headerName: '# Approved', field: 'dsplApprovedCount', width: 100, suppressSizeToFit: true },
          { headerName: '% Approved', field: 'dsplApprovedPercent', width: 100, suppressSizeToFit: true },
          { headerName: 'Contacted #(%)', field: 'numberOfContacted', width: 100, suppressSizeToFit: true },
          { headerName: 'Leads #(%)', field: 'numberOfLeads', width: 100, suppressSizeToFit: true },
          { headerName: 'CC #(%)', field: 'numberOfCc', width: 100, suppressSizeToFit: true },
          { headerName: 'Visit #(%)', field: 'numberOfVisit', width: 100, suppressSizeToFit: true },
        ],
      },
    },
    companyBuyerCEO: {
      currentExecutiveLeads: {
        columnDefs: [
          { headerName: 'Name', field: 'dsplExecFLName', width: 150 },
          { headerName: 'Current Status', field: 'currentStatus', width: 40 },
          { headerName: 'High Status', field: 'highStatus', width: 40 },
          { headerName: 'Industry', field: 'industry', width: 60 },
        ],
      },
      activeProjects: {
        columnDefs: [
          {
            headerName: 'Category',
            field: 'category.value',
            width: 150,
            editable: true,
          },
          {
            headerName: 'Details',
            field: 'details.value',
            width: 40,
            editable: true,
          },
          {
            headerName: 'Harvco Lead',
            field: 'dsplHarvcoLeadUserName.value',
            width: 100,
          },
          {
            headerName: 'Client Lead',
            field: 'dsplClientLeadFLName.value',
            width: 60,
          },
          {
            headerName: 'Status',
            field: 'status.value',
            width: 60,
            editable: true,
          },
        ],
      },
    },
    leadsReport: {
      columnDefs: tables.leadsreport.companies,
    },
    userList: {
      columns: tables.user.columns,
    },
    project: {
      target: tables.project.target,
      targetBuyer: tables.project.targetBuyer,
      approval: tables.project.approval,
      approvalListIds: tables.project.approvalListIds,
    },
    nextaction: {
      action: tables.nextaction.action,
      backlogActivities: tables.nextaction.blActivities,
    },
    companyTarget: {
      columns: [
        { title: 'Curr', field: 'curr' },
        { title: 'High', field: 'high' },
        { title: 'Target', field: 'target' },
        { title: 'App List', field: 'appList' },
        { title: 'Pri', field: 'pri' },
        { title: 'Approved', field: 'approved' },
        { title: 'Revenue', field: 'revenue' },
        { title: 'Employees', field: 'employees' },
        { title: 'EBITDA', field: 'ebitda' },
        { title: 'City', field: 'city' },
        { title: 'State', field: 'state' },
        { title: 'Web', field: 'web' },
        { title: 'Harvo Lead', field: 'harvoLead' },
      ],
    },
    approval: {
      associate: tables.approval.associate,
      online: tables.approval.online,
      recipients: tables.approval.recipients,
    },
    backlog: {
      columns: tables.backlog.columns,
    },
  },
  tabs: {
    contact: [
      {
        name: 'TARGET',
        key: 'target',
        to: '/contact/:contactId/target',
        className: 'nav-tab__target',
      },
      {
        name: 'BUYER',
        key: 'buyer',
        to: '/contact/:contactId/buyer',
        className: 'nav-tab__buyer',
      },
      {
        name: 'EXECUTIVE',
        key: 'exec',
        to: '/contact/:contactId/executive',
        className: 'nav-tab__executive',
      },
      {
        name: 'LP',
        key: 'lp',
        to: '/contact/:contactId/lp',
        className: 'nav-tab__lp',
      },
    ],
    browse: [
      { name: 'Targets', to: '/browse/targets' },
      { name: 'Buyers', to: '/browse/buyers' },
      { name: 'Executives', to: '/browse/executives' },
      { name: 'Projects', to: '/browse/projects' },
    ],
    company: [
      {
        name: 'TARGET',
        key: 'target',
        to: '/company/:companyId/target',
        className: 'nav-tab__target',
      },
      {
        name: 'BUYER',
        key: 'buyer',
        to: '/company/:companyId/buyer',
        className: 'nav-tab__buyer',
      },
      {
        name: 'EXECUTIVE',
        key: 'exec',
        to: '/company/:companyId/executive',
        className: 'nav-tab__executive',
      },
      {
        name: 'TRADE GROUPS',
        key: 'trade-groups',
        to: '/trade-groups',
        className: 'nav-tab__tradeGroups',
      },
    ],
  },
  values: {
    states: [
      { name: 'AL', value: 'Alabama' },
      { name: 'AK', value: 'Alaska' },
      { name: 'AZ', value: 'Arizona' },
      { name: 'AR', value: 'Arkansas' },
      { name: 'CA', value: 'California' },
      { name: 'CO', value: 'Colorado' },
      { name: 'CT', value: 'Connecticut' },
      { name: 'DE', value: 'Delaware' },
      { name: 'DC', value: 'District of Columbia' },
      { name: 'FL', value: 'Florida' },
      { name: 'GA', value: 'Georgia' },
      { name: 'HI', value: 'Hawaii' },
      { name: 'ID', value: 'Idaho' },
      { name: 'IL', value: 'Illinois' },
      { name: 'IN', value: 'Indiana' },
      { name: 'IA', value: 'Iowa' },
      { name: 'KS', value: 'Kansas' },
      { name: 'KY', value: 'Kentucky' },
      { name: 'LA', value: 'Louisiana' },
      { name: 'ME', value: 'Maine' },
      { name: 'MD', value: 'Maryland' },
      { name: 'MA', value: 'Massachusetts' },
      { name: 'MI', value: 'Michigan' },
      { name: 'MN', value: 'Minnesota' },
      { name: 'MS', value: 'Mississippi' },
      { name: 'MO', value: 'Missouri' },
      { name: 'MT', value: 'Montana' },
      { name: 'NE', value: 'Nebraska' },
      { name: 'NV', value: 'Nevada' },
      { name: 'NH', value: 'New Hampshire' },
      { name: 'NJ', value: 'New Jersey' },
      { name: 'NM', value: 'New Mexico' },
      { name: 'NY', value: 'New York' },
      { name: 'NC', value: 'North Carolina' },
      { name: 'ND', value: 'North Dakota' },
      { name: 'OH', value: 'Ohio' },
      { name: 'OK', value: 'Oklahoma' },
      { name: 'OR', value: 'Oregon' },
      { name: 'PA', value: 'Pennsylvania' },
      { name: 'PR', value: 'PR' },
      { name: 'RI', value: 'Rhode Island' },
      { name: 'SC', value: 'South Carolina' },
      { name: 'SD', value: 'South Dakota' },
      { name: 'TN', value: 'Tennessee' },
      { name: 'TX', value: 'Texas' },
      { name: 'UT', value: 'Utah' },
      { name: 'VT', value: 'Vermont' },
      { name: 'VI', value: 'VI' },
      { name: 'VA', value: 'Virginia' },
      { name: 'WA', value: 'Washington' },
      { name: 'WV', value: 'West Virginia' },
      { name: 'WI', value: 'Wisconsin' },
      { name: 'WY', value: 'Wyoming' },
      { name: 'AB', value: 'AB' },
      { name: 'BC', value: 'BC' },
      { name: 'MB', value: 'MB' },
      { name: 'NB', value: 'NB' },
      { name: 'NL', value: 'NL' },
      { name: 'NS', value: 'NS' },
      { name: 'NT', value: 'NT' },
      { name: 'NU', value: 'NU' },
      { name: 'ON', value: 'ON' },
      { name: 'PE', value: 'PE' },
      { name: 'QC', value: 'QC' },
      { name: 'SK', value: 'SK' },
      { name: 'YT', value: 'YT' },
    ],
    activities: [
      { value: 'M1', name: 'M1', group: 1 },
      { value: 'M2', name: 'M2', group: 1 },
      { value: 'CM2', name: 'CM2', group: 1 },
      { value: 'eM1', name: 'eM1', group: 7 },
      { value: 'eM2', name: 'eM2', group: 7 },
      { value: 'eCM2', name: 'eCM2', group: 7 },
      { value: 'M1b', name: 'M1b', group: 2 },
      { value: 'M2b', name: 'M2b', group: 2 },
      { value: 'CM2b', name: 'CM2b', group: 2 },
      { value: 'eM1b', name: 'eM1b' },
      { value: 'eM2b', name: 'eM2b' },
      { value: 'eCM2b', name: 'eCM2b' },
      { value: 'E1', name: 'E1', group: 3 },
      { value: 'E2', name: 'E2', group: 3 },
      { value: 'E3', name: 'E3', group: 3 },
      { value: 'E4', name: 'E4', group: 3 },
      { value: 'E5', name: 'E5', group: 3 },
      { value: 'E6', name: 'E6', group: 3 },
      { value: 'E7', name: 'E7', group: 3 },
      { value: 'E8', name: 'E8', group: 3 },
      { value: 'E9', name: 'E9', group: 3 },
      { value: 'E1b', name: 'E1b' },
      { value: 'E2b', name: 'E2b' },
      { value: 'E3b', name: 'E3b' },
      { value: 'E4b', name: 'E4b' },
      { value: 'E5b', name: 'E5b' },
      { value: 'E6b', name: 'E6b' },
      { value: 'E7b', name: 'E7b' },
      { value: 'E8b', name: 'E8b' },
      { value: 'E9b', name: 'E9b' },
      { value: 'F1', name: 'F1', group: 4 },
      { value: 'F2', name: 'F2', group: 4 },
      { value: 'F3', name: 'F3', group: 4 },
      { value: 'F4', name: 'F4', group: 4 },
      { value: 'F5', name: 'F5', group: 4 },
      { value: 'F6', name: 'F6', group: 4 },
      { value: 'F7', name: 'F7', group: 4 },
      { value: 'F8', name: 'F8', group: 4 },
      { value: 'F9', name: 'F9', group: 4 },
      { value: 'C1', name: 'C1', group: 5 },
      { value: 'C2', name: 'C2', group: 5 },
      { value: 'C3', name: 'C3', group: 5 },
      { value: 'C4', name: 'C4', group: 5 },
      { value: 'C5', name: 'C5', group: 5 },
      { value: 'C6', name: 'C6', group: 5 },
      { value: 'C7', name: 'C7', group: 5 },
      { value: 'C8', name: 'C8', group: 5 },
      { value: 'C9', name: 'C9', group: 5 },
      { value: 'C1b', name: 'C1b' },
      { value: 'C2b', name: 'C2b' },
      { value: 'C3b', name: 'C3b' },
      { value: 'C4b', name: 'C4b' },
      { value: 'C5b', name: 'C5b' },
      { value: 'C6b', name: 'C6b' },
      { value: 'C7b', name: 'C7b' },
      { value: 'C8b', name: 'C8b' },
      { value: 'C9b', name: 'C9b' },
      { value: 'CR', name: 'CR', group: 6 },
      { value: 'Call', name: 'Call', group: 8 },
      { value: 'Email', name: 'Email', group: 8 },
      { value: 'Fax', name: 'Fax', group: 8 },
      { value: 'Letter', name: 'Letter' },
      { value: 'NDA Executed - Harvey', name: 'NDA Executed - Harvey' },
      { value: 'NDA Executed - Client', name: 'NDA Executed - Client' },
      { value: 'Sent Lead', name: 'Sent Lead' },
      { value: 'HarvCo Meeting', name: 'HarvCo Meeting', group: 9 },
      { value: 'Conference Call', name: 'Conference Call', group: 9 },
      { value: 'Visit', name: 'Visit', group: 9 },
      { value: 'Trade Show Visit', name: 'Trade Show Visit', group: 9 },
      { value: 'Buyer Passed', name: 'Buyer Passed' },
      { value: 'Verbal Offer Submitted', name: 'Verbal Offer Submitted' },
      { value: 'IOI Submitted', name: 'IOI Submitted' },
      { value: 'LOI Submitted', name: 'LOI Submitted' },
      { value: 'LOI Executed', name: 'LOI Executed' },
      { value: 'Deal Closed', name: 'Deal Closed' },
      { value: 'Research', name: 'Research', group: 10 },
      { value: 'News', name: 'News' },
      { value: '(Custom)', name: '(Custom)', group: 10 },
      { value: 'IE1', name: 'IE1' },
      { value: 'One-Off', name: 'One-Off', group: 12 },
      { value: 'Deferred Compensation', name: 'Deferred Compensation', group: 11 },
      { value: 'Conflict Check', name: 'Conflict Check', group: 9 },
    ],
    companyTypes: [
      { value: 'Private', name: 'Private' },
      { value: 'DivPri', name: 'DivPri' },
      { value: 'LBO', name: 'LBO' },
      { value: 'DivLBO', name: 'DivLBO' },
      { value: 'Public', name: 'Public', hasTicker: true },
      { value: 'DivPub', name: 'DivPub' },
      { value: 'VC', name: 'VC' },
      { value: 'DivVC', name: 'DivVC' },
      { value: 'ESOP', name: 'ESOP' },
      { value: 'NFP', name: 'NFP' },
      { value: 'DivNFP', name: 'DivNFP' },
      { value: 'Gov', name: 'Gov' },
      { value: 'JV', name: 'JV' },
      { value: 'ForPri', name: 'ForPri' },
      { value: 'ForPub', name: 'ForPub', hasTicker: true },
      { value: 'DivFor', name: 'DivFor' },
    ],
    contact: {
      vCardUrl: '/api/v1/people/:contactId/vcard/',
      prefixes: constants.contact.prefixes,
      suffixes: constants.contact.suffixes,
      footerDropDown: [
        {
          name: 'New Target',
          onClickField: 'onNewTarget',
          attributes: { 'aria-hidden': 'true' },
        },
        {
          name: 'New Buyer',
          onClickField: 'onNewBuyer',
          attributes: { 'aria-hidden': 'true' },
        },
        {
          name: 'New Executive',
          onClickField: 'onNewExecutive',
          attributes: { 'aria-hidden': 'true' },
        },
        {
          name: 'New LP',
          onClickField: 'onNewLp',
          attributes: { 'aria-hidden': 'true' },
        },
        { name: 'Delete...', onClickField: 'onDel', attributes: { href: '#' } },
      ],
    },
    contactExecutive: {
      status: constants.contact.executiveStatuses,
      quality: constants.contact.executiveQualities,
      interest: constants.contact.executiveInterests,
      relation: constants.contact.executiveRelations,
      qualitiesOfExecutive: constants.contact.executiveCheckboxes,
    },
    contactLP: {
      statuses: constants.contact.lpStatuses,
    },
    targetCompany: {
      companyTypes: [
        { name: 'Mustard', attributes: {} },
        { name: 'Ketchup', attributes: {} },
        { name: 'Relish', attributes: {} },
      ],
      companyStates: [
        { name: 'SK', attributes: {} },
        { name: 'CA', attributes: {} },
        { name: 'MI', attributes: {} },
      ],
    },
    company: {
      emailTemplate: constants.company.emailTemplate,
      statuses: constants.company.targetStatuses,
      buyerStatuses: constants.company.buyerStatuses,
      tickerExchange: constants.company.tickerExchange,
      footerDropDown: [
        {
          name: 'New Target',
          onClickField: 'onNewTarget',
          attributes: { 'aria-hidden': 'true' },
          key: 'target',
        },
        {
          name: 'New Buyer',
          onClickField: 'onNewBuyer',
          attributes: { 'aria-hidden': 'true' },
          key: 'buyer',
        },
        {
          name: 'New Executive',
          onClickField: 'onNewExecutive',
          attributes: { 'aria-hidden': 'true' },
          key: 'exec',
        },
        {
          name: 'New LP',
          onClickField: 'onNewLp',
          attributes: { 'aria-hidden': 'true' },
          key: 'lp',
        },
        {
          name: 'Delete...',
          onClickField: 'onDel',
          attributes: { href: '#' },
          key: 'del',
        },
      ],
    },
    project: {
      types: constants.project.types,
      modes: constants.project.modes,
    },
    search: {
      menu: constants.search.menu,
    },
    shared: {
      targetStatuses: constants.shared.targetStatuses,
    },
    approval: {
      naPopup: constants.approval.naPopup,
    },
    eventActivities: {
      target: [
        { value: 'M1', name: 'M1', group: 1 },
        { value: 'M2', name: 'M2', group: 1 },
        { value: 'CM2', name: 'CM2', group: 1 },
        { value: 'eM1', name: 'eM1', group: 7 },
        { value: 'eM2', name: 'eM2', group: 7 },
        { value: 'eCM2', name: 'eCM2', group: 7 },
        { value: 'M1b', name: 'M1b', group: 2 },
        { value: 'M2b', name: 'M2b', group: 2 },
        { value: 'CM2b', name: 'CM2b', group: 2 },
        { value: 'eM1b', name: 'eM1b' },
        { value: 'eM2b', name: 'eM2b' },
        { value: 'eCM2b', name: 'eCM2b' },
        { value: 'E1', name: 'E1', group: 3 },
        { value: 'E2', name: 'E2', group: 3 },
        { value: 'E3', name: 'E3', group: 3 },
        { value: 'E4', name: 'E4', group: 3 },
        { value: 'E5', name: 'E5', group: 3 },
        { value: 'E6', name: 'E6', group: 3 },
        { value: 'E7', name: 'E7', group: 3 },
        { value: 'E8', name: 'E8', group: 3 },
        { value: 'E9', name: 'E9', group: 3 },
        { value: 'E1b', name: 'E1b' },
        { value: 'E2b', name: 'E2b' },
        { value: 'E3b', name: 'E3b' },
        { value: 'E4b', name: 'E4b' },
        { value: 'E5b', name: 'E5b' },
        { value: 'E6b', name: 'E6b' },
        { value: 'E7b', name: 'E7b' },
        { value: 'E8b', name: 'E8b' },
        { value: 'E9b', name: 'E9b' },
        { value: 'F1', name: 'F1', group: 4 },
        { value: 'F2', name: 'F2', group: 4 },
        { value: 'F3', name: 'F3', group: 4 },
        { value: 'F4', name: 'F4', group: 4 },
        { value: 'F5', name: 'F5', group: 4 },
        { value: 'F6', name: 'F6', group: 4 },
        { value: 'F7', name: 'F7', group: 4 },
        { value: 'F8', name: 'F8', group: 4 },
        { value: 'F9', name: 'F9', group: 4 },
        {
          value: 'C1',
          name: 'C1',
          group: 5,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C2',
          name: 'C2',
          group: 5,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C3',
          name: 'C3',
          group: 5,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C4',
          name: 'C4',
          group: 5,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C5',
          name: 'C5',
          group: 5,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C6',
          name: 'C6',
          group: 5,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C7',
          name: 'C7',
          group: 5,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C8',
          name: 'C8',
          group: 5,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C9',
          name: 'C9',
          group: 5,
          showDescription: true,
          showDealNotes: true,
        },
        { value: 'C1b', name: 'C1b' },
        { value: 'C2b', name: 'C2b' },
        { value: 'C3b', name: 'C3b' },
        { value: 'C4b', name: 'C4b' },
        { value: 'C5b', name: 'C5b' },
        { value: 'C6b', name: 'C6b' },
        { value: 'C7b', name: 'C7b' },
        { value: 'C8b', name: 'C8b' },
        { value: 'C9b', name: 'C9b' },
        { value: 'CR', name: 'CR', group: 6, showDescription: true, showDealNotes: true },
        { value: 'Call', name: 'Call', group: 8, showDescription: true, showDealNotes: true },
        { value: 'Email', name: 'Email', group: 8, showDescription: true, showEmail: true, showDealNotes: true },
        { value: 'Fax', name: 'Fax', group: 8, showDescription: true, showDealNotes: true },
        { value: 'Letter', name: 'Letter', group: 8, showDescription: true, showDealNotes: true },
        { value: 'NDA Executed - Harvey', name: 'NDA Executed - Harvey', group: 9, showNDAExecuted: true },
        { value: 'NDA Executed - Client', name: 'NDA Executed - Client', group: 9, showNDAExecuted: true },
        { value: 'Conflict Check', name: 'Conflict Check', group: 9 },
        { value: 'Sent Lead', name: 'Sent Lead', group: 9, showDescription: true },
        { value: 'HarvCo Meeting', name: 'HarvCo Meeting', group: 9, showDescription: true, showDealNotes: true },
        { value: 'Conference Call', name: 'Conference Call', group: 9, showDescription: true, showDealNotes: true },
        { value: 'Visit', name: 'Visit', group: 9, showDescription: true, showDealNotes: true },
        { value: 'Trade Show Visit', name: 'Trade Show Visit', group: 9, showDescription: true, showDealNotes: true },
        { value: 'Buyer Passed', name: 'Buyer Passed', group: 9, showDescription: true },
        {
          value: 'Update Call',
          name: 'Update Call',
          group: 8,
          showDescription: true,
          showEmail: true,
          showDealNotes: true,
        },
        {
          value: 'Research',
          name: 'Research',
          group: 10,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'News',
          name: 'News',
          group: 10,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: '(Custom)',
          name: '(Custom)',
          group: 10,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'Verbal Offer Submitted',
          name: 'Verbal Offer Submitted',
          group: 11,
          showDescription: true,
          showOfferAmount: true,
        },
        {
          value: 'IOI Submitted',
          name: 'IOI Submitted',
          group: 11,
          showDescription: true,
          showOfferAmount: true,
        },
        {
          value: 'LOI Submitted',
          name: 'LOI Submitted',
          group: 11,
          showDescription: true,
          showOfferAmount: true,
        },
        {
          value: 'LOI Executed',
          name: 'LOI Executed',
          group: 11,
          showDescription: true,
          showOfferAmount: true,
        },
        {
          value: 'Deal Closed',
          name: 'Deal Closed',
          group: 11,
          showDescription: true,
        },
        {
          value: 'Deferred Compensation',
          name: 'Deferred Compensation',
          group: 11,
          showDescription: true,
        },
        {
          value: 'New Target Profile',
          name: 'New Target Profile',
          group: 11,
          showDescription: true,
          showOfferAmount: true,
        },
        {
          value: 'Added New Buyer',
          name: 'Added New Buyer',
          group: 11,
          showDescription: true,
          showOfferAmount: true,
        },
        { value: 'IE1', name: 'IE1' },
        {
          value: 'One-Off',
          name: 'One-Off',
          group: 12,
          showDescription: true,
        },
      ],
      buyer: [
        {
          value: 'Call',
          name: 'Call',
          group: 1,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'Email',
          name: 'Email',
          group: 1,
          showDescription: true,
          showDealNotes: true,
          showEmail: true,
        },
        {
          value: 'Research',
          name: 'Research',
          group: 2,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'News',
          name: 'News',
          group: 2,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'Meeting',
          name: 'Meeting',
          group: 3,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: '(Custom)',
          name: '(Custom)',
          group: 4,
          showDescription: true,
          showDealNotes: true,
        },
      ],
      exec: [
        {
          value: 'LI1',
          name: 'LI1',
          group: 1,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'LI2',
          name: 'LI2',
          group: 1,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'LI3',
          name: 'LI3',
          group: 1,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'E1',
          name: 'E1',
          group: 3,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'E2',
          name: 'E2',
          group: 3,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'E3',
          name: 'E3',
          group: 3,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'E9',
          name: 'E9',
          group: 3,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C1',
          name: 'C1',
          group: 4,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C2',
          name: 'C2',
          group: 4,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C3',
          name: 'C3',
          group: 4,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'C9',
          name: 'C9',
          group: 4,
          showDescription: true,
          showDealNotes: true,
        },
        { value: 'CR', name: 'CR', group: 5, showDescription: true, showDealNotes: true },
        { value: 'Call', name: 'Call', group: 8, showDescription: true, showDealNotes: true },
        { value: 'Email', name: 'Email', group: 8, showDescription: true, showEmail: true, showDealNotes: true },
        { value: 'LinkedIn', name: 'LinkedIn', group: 8, showDescription: true, showDealNotes: true },
        { value: 'NDA Executed - Harvey', name: 'NDA Executed - Harvey', group: 9, showNDAExecuted: true },
        { value: 'NDA Executed - Client', name: 'NDA Executed - Client', group: 9, showNDAExecuted: true },
        { value: 'Resume Received', name: 'Resume Received', group: 9, showNDAExecuted: true },
        { value: 'Sent Lead', name: 'Sent Lead', group: 9, showDescription: true },
        { value: 'HarvCo Meeting', name: 'HarvCo Meeting', group: 9, showDescription: true, showDealNotes: true },
        { value: 'Conference Call', name: 'Conference Call', group: 9, showDescription: true, showDealNotes: true },
        { value: 'Visit', name: 'Visit', group: 9, showDescription: true, showDealNotes: true },
        {
          value: 'Harvco Event Visit',
          name: 'Harvco Event Visit',
          group: 9,
          showDescription: true,
          showDealNotes: true,
        },
        { value: 'Client Passed', name: 'Client Passed', group: 9, showDescription: true },
        {
          value: 'Update Call',
          name: 'Update Call',
          group: 9,
          showDescription: true,
          showEmail: true,
          showDealNotes: true,
        },
        {
          value: 'Research',
          name: 'Research',
          group: 10,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'News',
          name: 'News',
          group: 10,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: '(Custom)',
          name: '(Custom)',
          group: 10,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'Term Sheet Submitted',
          name: 'Term Sheet Submitted',
          group: 10,
          showDescription: true,
          showDealNotes: true,
        },
        {
          value: 'Term Sheet Executed',
          name: 'Term Sheet Executed',
          group: 10,
          showDescription: true,
          showDealNotes: true,
        },
      ],
      lp: [],
    },
  },
  main: {
    offersAndBetterReport: '/api/v1/reports/offers_and_better/',
    retainerReport: '/api/v1/reports/retainer/',
    leadsAndBetterReport: '/api/v1/reports/leads_and_better/',
    CCVisitReport: '/api/v1/reports/cc_visit/',
    OutreachReport: '/api/v1/reports/outreach/',
    CeoOutreachReport: '/api/v1/reports/ceo_outreach/',
    activityReport: '/api/v1/reports/activity/',
    conflictReport: '/api/v1/reports/conflict/',
    header: {
      title: 'Saritasa user',
      buttons: [
        {
          title: 'Stats',
          action: 'stats',
          icon: 'cog',
          type: 'dropdown',
          id: 'downloadDropdown',
          to: '#downloadDropdown',
          elements: [
            {
              title: 'Download CC/Visits Report',
              onClickField: 'onCCVisitsReport',
              type: 'link',
              to: '/',
            },
            {
              title: 'Download Offers & Better Report',
              target: '_blank',
              href: '/api/v1/reports/offers_and_better',
              download: true,
            },
          ],
        },
        {
          title: 'Add user',
          to: 'user',
          icon: 'plus',
          type: 'link',
          target: '_blank',
        },
        {
          title: 'Next Actions',
          to: 'next-actions',
          icon: 'flag',
          type: 'link',
          target: '_blank',
        },
        {
          title: 'Backlog',
          to: 'mailing-backlog',
          icon: 'clock-o',
          type: 'link',
          target: '_blank',
        },
      ],
    },
    controls: {
      search: {
        buttonText: 'Search',
        inputText: 'browse/query',
      },
      dropdown: {
        text: 'Show/hide',
        elements: [
          {
            key: 'researches',
            name: 'Research',
            access: [ADMINISTRATOR, DIRECTOR, JUNIOR_ANALYST, FINANCE_ADMIN, RESEARCH_INTERN],
          },
          { key: 'backlog', name: 'Backlog' },
          { key: 'tc1', name: 'C1s (T)' },
          { key: 'ttl', name: 'Top Leads (T)' },
          { key: 'tcna', name: 'Custom Next Actions (T)' },
          { key: 'tcp', name: 'Current Projects (T)' },
          { key: 'tmtd', name: 'MTD CCs & Visits (T)' },
          { key: 'trc', name: 'Recently Called (T)' },
          { key: 'ecall', name: 'Calls (E)' },
          { key: 'eemail', name: 'Emails (E)' },
          { key: 'ecna', name: 'Custom Next Actions (E)' },
          { key: 'emtdstats', name: 'MTD CCs & Visits (E)' },
          { key: 'etl', name: 'Top Leads (E)' },
          { key: 'erc', name: 'Recently Called (E)' },
          {
            key: 'pd',
            name: 'Deletion Requests',
            access: [PROCESS_MANAGER, ANALYST_ASSOCIATE, DIRECTOR, ADMINISTRATOR, FINANCE_ADMIN],
          },
        ],
      },
    },
    widgets: {
      researches: {
        title: 'Research',
        columns: [
          { headerName: 'Date', field: 'date', width: 80 },
          { headerName: 'Profile Type', field: 'profileType', width: 120 },
          { headerName: 'Company', field: 'legalName', width: 200 },
          { headerName: 'Website', field: 'web', width: 360 },
          { headerName: 'Project', field: 'project', width: 100 },
          { headerName: 'Rev/Emp', field: 'revenueEmployees', width: 100 },
          { headerName: 'User', field: 'userName', width: 100 },
          { headerName: 'Cur/Hi', field: 'currentHighStatuses', width: 80 },
          { headerName: 'DM1', field: 'dm1', width: 80 },
          { headerName: 'DM2', field: 'dm2', width: 80 },
        ],
      },
      backlog: {
        urlTemplate: '/backlog',
        title: 'Backlog',
        columns: [
          { headerName: '#', field: '#', width: 25 },
          {
            headerName: 'Primary DM',
            field: 'recordOwnerUserName',
            width: 100,
          },
          {
            headerName: 'Secondary DM',
            field: 'recordSubOwnerUserName',
            width: 100,
          },
          { headerName: 'Buyer', field: 'buyerLegalName', width: 250 },
          { headerName: 'Project', field: 'projectCategory', width: 160 },
          { headerName: 'Next Action', field: 'activity', width: 80 },
          { headerName: 'Quantity', field: 'targetCount', width: 70 },
        ],
      },
      tc1: {
        urlTemplate: '/company/:targetId/',
        title: 'C1s (T)',
        columns: [
          { headerName: 'Date', field: 'date' },
          { headerName: 'Company', field: 'targetLegalName' },
          { headerName: 'Buyer', field: 'buyerLegalName' },
          { headerName: 'Project', field: 'projectCategory' },
          { headerName: 'Priority', field: 'priority' },
          { headerName: 'City', field: 'targetCity' },
          { headerName: 'State', field: 'targetState' },
          { headerName: 'User', field: 'userName' },
        ],
      },
      ttl: {
        urlTemplate: '/company/:entityId/',
        title: 'Top Leads (T)',
        columns: [
          { headerName: 'Current', field: 'targetCurrentStatus' },
          { headerName: 'Company', field: 'legalName' },
          { headerName: 'Buyer', field: 'buyerLegalName' },
          { headerName: 'Project', field: 'projectCategory' },
          { headerName: 'Revenue', field: 'revenue' },
          { headerName: 'Next Action Date', field: 'naDate' },
          { headerName: 'Next Action', field: 'naActivity' },
        ],
      },
      tcna: {
        urlTemplate: '/company/:targetId/',
        title: 'Custom Next Actions (T)',
        columns: [
          { headerName: 'Date', field: 'date' },
          { headerName: 'Company', field: 'targetLegalName' },
          { headerName: 'Next Action', field: 'activity' },
          { headerName: 'Buyer', field: 'buyerLegalName' },
          { headerName: 'Project', field: 'projectCategory' },
          { headerName: 'Priority', field: 'priority' },
        ],
      },
      pd: {
        title: 'Deletion Requests',
        selectedIds: [],
        columns: [
          { headerName: '', field: 'selected', width: 26 },
          { headerName: 'Company', field: 'companyLegalName' },
          { headerName: 'Date', field: 'date' },
          { headerName: 'Requested By', field: 'userFullName' },
        ],
      },
      tcp: {
        urlTemplate: '/project/:projectId/',
        title: 'Current Projects (T)',
        columns: [
          { headerName: 'Client', field: 'buyerLegalName' },
          { headerName: 'Project', field: 'category' },
          { headerName: 'Type', field: 'type' },
          { headerName: 'Last List', field: 'date' },
          { headerName: 'Targets', field: 'targets' },
          { headerName: 'Leads', field: 'leads' },
          { headerName: 'C#(%)', field: 'percentOfContacted' },
          { headerName: 'NAs', field: 'nextActionCount' },
          { headerName: 'Users', field: 'users' },
        ],
      },
      tmtd: {
        title: 'MTD CCs & Visits (T)',
        columns: [
          { headerName: 'Date', field: 'date' },
          { headerName: 'Activity', field: 'activity' },
          { headerName: 'Company', field: 'targetLegalName' },
          { headerName: 'Buyer', field: 'buyerLegalName' },
          { headerName: 'Project', field: 'projectCategory' },
        ],
      },
      trc: {
        urlTemplate: '/company/:targetId/',
        title: 'Recently Called (T)',
        columns: [
          { headerName: 'Date', field: 'date' },
          { headerName: 'Company', field: 'targetLegalName' },
          { headerName: 'Buyer', field: 'buyerLegalName' },
          { headerName: 'Project', field: 'projectCategory' },
          { headerName: 'Priority', field: 'priority' },
        ],
      },
      ecall: {
        urlTemplate: '/contact/:execId/',
        title: 'Calls (E)',
        columns: [
          { headerName: 'Date', field: 'date' },
          { headerName: 'Name', field: 'execFLName' },
          { headerName: 'Current', field: 'execCurrentStatus' },
          { headerName: 'High', field: 'execHighStatus' },
          { headerName: 'Company', field: 'execCompanyLegalName' },
        ],
      },
      eemail: {
        urlTemplate: '/contact/:execId/',
        title: 'Emails (E)',
        columns: [
          { headerName: 'Date', field: 'date' },
          { headerName: 'Name', field: 'execFLName' },
          { headerName: 'Current', field: 'execCurrentStatus' },
          { headerName: 'High', field: 'execHighStatus' },
          { headerName: 'Company', field: 'execCompanyLegalName' },
        ],
      },
      ecna: {
        urlTemplate: '/contact/:execId/',
        title: 'Custom Next Actions (E)',
        columns: [
          { headerName: 'Date', field: 'date' },
          { headerName: 'Activity', field: 'activity' },
          { headerName: 'Name', field: 'execFLName' },
          { headerName: 'Current', field: 'execCurrentStatus' },
          { headerName: 'High', field: 'execHighStatus' },
          { headerName: 'Company', field: 'execCompanyLegalName' },
        ],
      },
      emtdstats: {
        urlTemplate: '/contact/:execId/',
        title: 'MTD CCs & Visits (E)',
        columns: [
          { headerName: 'Date', field: 'date' },
          { headerName: 'Activity', field: 'activity' },
          { headerName: 'Name', field: 'execFLName' },
        ],
      },
      etl: {
        urlTemplate: '/contact/:contactId/',
        title: 'Top Leads (E)',
        columns: [
          { headerName: 'Current', field: 'execCurrentStatus' },
          { headerName: 'High', field: 'execHighStatus' },
          { headerName: 'Name', field: 'execFLName' },
          { headerName: 'Fund', field: 'fundLegalName' },
          { headerName: 'Project', field: 'eprojectCategory' },
        ],
      },
      erc: {
        urlTemplate: '/contact/:execId/',
        title: 'Recently Called (E)',
        columns: [
          { headerName: 'Date', field: 'date' },
          { headerName: 'Name', field: 'execFLName' },
          { headerName: 'Current', field: 'execCurrentStatus' },
          { headerName: 'High', field: 'execHighStatus' },
          { headerName: 'Company', field: 'execCompanyLegalName' },
        ],
      },
    },
  },
  search: {
    controls: {
      buttonText: 'Search',
      inputText: 'Enter search searchingTerm...',
    },
  },
  browse: {
    table: {
      columnDefs: {
        targetsCustom: [
          { headerName: '', field: 'selected', width: 26 },
          { headerName: 'Name', field: 'targetName', width: 180 },
          { headerName: 'Buyer', field: 'buyerName', width: 100 },
          { headerName: 'Current Status', field: 'currentStatus', width: 70, sort: 'desc' },
          { headerName: 'High Status', field: 'highStatus', width: 60 },
          { headerName: 'Revenue', field: 'revenue', width: 45 },
          { headerName: 'EBITDA', field: 'ebitda', width: 45 },
          { headerName: 'State', field: 'state', width: 40 },
          { headerName: 'Next Action Date', field: 'nextActionDate', width: 80 },
          { headerName: 'Next Action', field: 'nextAction', width: 80 },
          { headerName: 'Last Action Date', field: 'lastActionDate', width: 80 },
          { headerName: 'Last Action', field: 'lastAction', width: 80 },
          { headerName: 'Description', field: 'description', width: 125 },
          { headerName: 'Module', field: 'directorName', width: 70 },
          { headerName: 'A/A', field: 'analystName', width: 80 },
        ],
        targets: [
          { headerName: '', field: 'selected', width: 26 },
          { headerName: 'Name', field: 'targetName', width: 180 },
          { headerName: 'Curr', field: 'currentStatus', width: 40 },
          { headerName: 'High', field: 'highStatus', width: 40 },
          { headerName: 'Module', field: 'directorName', width: 70 },
          { headerName: 'DM1', field: 'analystName', width: 70 },
          { headerName: 'DM2', field: 'dealMaker2', width: 70 },
          { headerName: 'Buyer', field: 'buyerName', width: 70 },
          {
            headerName: 'Project',
            field: 'projectName',
            renderType: 'projectGroup',
            width: 110,
          },
          { headerName: 'Approval list', field: 'approvalListName', width: 50 },
          { headerName: 'Pri.', field: 'priority', width: 50 },
          { headerName: 'Approved', field: 'approved', width: 72 },
          { headerName: 'Revenue', field: 'revenue', width: 38 },
          { headerName: 'Employees', field: 'employees', width: 60 },
          { headerName: 'EBITDA', field: 'ebitda', width: 45 },
          { headerName: 'Type', field: 'companyType', width: 70 },
          { headerName: 'Parent', field: 'parentName', width: 100 },
          { headerName: 'City', field: 'city', width: 90 },
          { headerName: 'State', field: 'state', width: 40 },
          { headerName: 'Country', field: 'countryAbbr', width: 80 },
          { headerName: 'Industry', field: 'industryCategory', width: 80 },
          {
            headerName: 'Next Action Date',
            field: 'nextActionDate',
            width: 80,
          },
          { headerName: 'Next Action', field: 'nextAction', width: 80 },
          {
            headerName: 'Last Action Date',
            field: 'lastActionDate',
            width: 80,
          },
          { headerName: 'Last Action', field: 'lastAction', width: 80 },
          { headerName: 'Description', field: 'description', width: 125 },
        ],
        buyers: [
          { headerName: '', field: 'selected', width: 26 },
          { headerName: 'Name', field: 'buyerName', width: 150 },
          { headerName: 'Curr', field: 'currentStatus', width: 40 },
          { headerName: 'High', field: 'highStatus', width: 40 },
          { headerName: 'B. Type', field: 'buyerType', width: 60 },
          { headerName: 'Co. Type', field: 'companyType', width: 60 },
          { headerName: 'Pri.', field: 'priority', width: 40 },
          {
            headerName: 'HarvCo Contact',
            field: 'buyerHarvcoLeadUserName',
            width: 80,
          },
          { headerName: 'City', field: 'city', width: 80 },
          { headerName: 'State', field: 'state', width: 40 },
          { headerName: '# Targets.', field: 'numberOfTargets', width: 60 },
          {
            headerName: '# Approved',
            field: 'numberOfApprovedTargets',
            width: 60,
          },
          { headerName: '% Approved', field: 'approvedPercent', width: 60 },
          { headerName: '# Mailed', field: 'numberOfMailedTargets', width: 60 },
          { headerName: '% Mailed', field: 'mailedPercent', width: 60 },
          { headerName: '# Called', field: 'numberOfCalledTargets', width: 70 },
          { headerName: '% Called', field: 'calledPercent', width: 60 },
          { headerName: 'Parent', field: 'nameOfParentCompany', width: 100 },
          {
            headerName: 'Next Action Date',
            field: 'nextActionDate',
            width: 100,
          },
          { headerName: 'Next Action', field: 'nextAction', width: 100 },
          {
            headerName: 'Last Action Date',
            field: 'lastActionDate',
            width: 100,
          },
          { headerName: 'Last Action', field: 'lastAction', width: 100 },
        ],
        executives: [
          {
            headerName: '',
            field: 'selected',
            width: 20,
            suppressSizeToFit: true,
          },
          { headerName: 'Last Name', field: 'lastName', width: 90 },
          { headerName: 'First Name', field: 'firstName', width: 90 },
          { headerName: 'Nick Name', field: 'nick', width: 90 },
          { headerName: 'Curr', field: 'currentStatus', width: 40 },
          { headerName: 'High', field: 'highStatus', width: 40 },
          { headerName: 'Analyst/Assoc.', field: 'analyst', width: 70 },
          { headerName: 'Fund', field: 'fundName', width: 70 },
          { headerName: 'Project', field: 'projectName', width: 110 },
          { headerName: 'Industry', field: 'industryCategory', width: 110 },
          { headerName: 'Employed', field: 'employed', width: 35 },
          { headerName: 'Age', field: 'age', width: 30 },
          { headerName: 'Q', field: 'quality', width: 40 },
          { headerName: 'I', field: 'interest', width: 40 },
          { headerName: 'R', field: 'relationship', width: 40 },
          { headerName: 'Resume', field: 'resume', width: 35 },
          { headerName: 'Needs Fund', field: 'needsFund', width: 35 },
          { headerName: 'Next Step', field: 'nextStep', width: 80 },
          { headerName: 'Last Researched', field: 'lastResearched', width: 80 },
          {
            headerName: 'Last Researched Date',
            field: 'lastResearchedDate',
            width: 80,
          },
          {
            headerName: 'Next Action Date',
            field: 'nextActionDate',
            width: 80,
          },
          { headerName: 'Next Action', field: 'nextAction', width: 80 },
          {
            headerName: 'Last Action Date',
            field: 'lastActionDate',
            width: 80,
          },
          { headerName: 'Last Action', field: 'lastAction', width: 94 },
          { headerName: 'Deal Notes', field: 'dealNotes', width: 95 },
        ],
        projects: [
          { headerName: 'Buyer', field: 'buyerName', width: 400 },
          { headerName: 'Category', field: 'category', width: 300 },
          { headerName: 'HarvCo Lead', field: 'harvcoLead', width: 150 },
          { headerName: '# Targets', field: 'targetNumber', width: 150 },
          {
            headerName: 'Current App. List',
            field: 'curApprovalListName',
            width: 150,
          },
          { headerName: 'Details', field: 'details', width: 242 },
          { headerName: 'Status', field: 'status', width: 150 },
        ],
      },
    },
    filter: {
      targets: {
        currentStatus: {
          label: 'Current Status',
          name: 'currentStatus',
          minValue: 0,
          maxValue: 10,
          step: 0.01,
        },
        highStatus: {
          label: 'High Status',
          name: 'highStatus',
          minValue: 0,
          maxValue: 10,
          step: 0.01,
        },
        revenue: {
          label: 'Revenue',
          name: 'revenue',
          minValue: 0,
          maxValue: 500,
          step: 1,
        },
        ebitda: {
          label: 'EBITDA',
          name: 'ebitda',
          minValue: 0,
          maxValue: 500,
          step: 1,
        },
        employees: {
          label: 'Employees',
          name: 'employees',
        },
        states: {
          label: 'States',
          name: 'states',
          valueField: 'id',
          renderField: 'value',
        },
        country: {
          label: 'Country',
          name: 'country',
          placeholder: 'Country',
          valueField: 'id',
          renderField: 'text',
        },
        continent: {
          label: 'Continent',
          name: 'continent',
          placeholder: 'Continent',
          valueField: 'id',
          renderField: 'name',
        },
        module: {
          label: 'Module',
          name: 'module',
          valueField: 'id',
          renderField: 'text',
        },
        analyst: {
          label: 'Dealmaker 1',
          name: 'analyst',
          valueField: 'id',
          renderField: 'text',
        },
        dealMaker2: {
          label: 'Dealmaker 2',
          name: 'dealMaker2',
          valueField: 'id',
          renderField: 'text',
        },
        researcher: {
          label: 'Researcher',
          name: 'researcher',
          valueField: 'id',
          renderField: 'text',
          filters: [
            { name: 'isNewProfile', title: 'New Profile' },
            { name: 'isUpdatedProfile', title: 'Updated Profile' },
            { name: 'isConfirmedProfile', title: 'Confirmed Profile' },
          ],
        },
        buyer: {
          label: 'Buyer',
          name: 'buyer',
          buyer: {
            inputLabel: 'Buyer',
            name: 'buyer',
            className: 'form-control',
            formGroupClass: 'form-group--inline form-group--input w90p',
            valueField: 'id',
            renderField: 'text',
            keepText: true,
          },
          currentStatus: {
            label: 'Current Status',
            name: 'currentStatus',
            minValue: 0,
            maxValue: 10,
            step: 0.01,
          },
          highStatus: {
            label: 'High Status',
            name: 'highStatus',
            minValue: 0,
            maxValue: 10,
            step: 0.01,
          },
          project: {
            inputLabel: 'Project',
            name: 'project',
            className: 'form-control',
            formGroupClass: 'form-group--inline form-group--input w90p',
            valueField: 'id',
            renderField(suggestion) {
              return `${suggestion.category} (${suggestion.harvcoLead})`;
            },
            keepText: true,
          },
          approval: {
            inputLabel: 'Approval list',
            name: 'approval',
            className: 'form-control',
            formGroupClass: 'form-group--inline form-group--input w90p',
            valueField: 'id',
            renderField: 'text',
            keepText: true,
          },
          priority: {
            inputLabel: 'Priority',
            name: 'priority',
            className: 'form-control',
            formGroupClass: 'form-group--inline form-group--input w90p',
            valueField: 'value',
            renderField: 'name',
            keepText: true,
          },
          approved: [
            { name: '', value: '', attributes: {} },
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
            { name: 'No Response', value: 'No Response', attributes: {} },
          ],
        },
        harvcoTags: {
          label: 'HarvCo Tags',
          name: 'harvcoTags',
          valueField: 'id',
          renderField: 'value',
        },
        industry: {
          label: 'Industry',
          name: 'industry',
          valueField: 'id',
          renderField: 'text',
        },
        nextAction: {
          label: 'Next Action',
          name: 'nextAction',
          eventActivity: {
            name: 'nextAction',
            placeholder: 'Event Activity',
            valueField: 'id',
            renderField: 'value',
            keepText: true,
          },
        },
        lastAction: {
          label: 'Activity',
          name: 'lastAction',
          eventActivity: {
            name: 'lastAction',
            placeholder: 'Event Activity',
            valueField: 'id',
            renderField: 'value',
            keepText: true,
          },
          statusChanged: false,
          statusChangedFrom: {
            label: 'From',
            name: 'statusChangedFrom',
            minValue: 0,
            maxValue: 10,
            step: 0.01,
          },
          statusChangedTo: {
            label: 'To',
            name: 'statusChangedTo',
            minValue: 0,
            maxValue: 10,
            step: 0.01,
          },
          activityUsers: {
            label: 'Users',
            name: 'activityUsers',
            placeholder: 'Completed By',
            valueField: 'id',
            renderField: 'text',
          },
        },
        description: {
          label: 'Description',
          name: 'description',
          valueField: 'id',
          renderField: 'value',
        },
        companyType: {
          label: 'Company Type',
          name: 'companyType',
          valueField: 'id',
          renderField: 'value',
        },
        areaCode: {
          label: 'Area Code',
          name: 'areaCode',
          valueField: 'id',
          renderField: 'value',
        },
        keywords: {
          label: 'Keywords',
          name: 'keywords',
          description:
            'searches description, research notes, deal notes, email log, products, markets, industries and HarvCo tags simultaneously',
          valueField: 'id',
          renderField: 'value',
        },
        projectType: {
          label: 'Project Type',
          name: 'projectType',
          options: [
            { name: 'Platform', value: 'Platform', attributes: {} },
            { name: 'Add-on', value: 'Add-on', attributes: {} },
          ],
        },
        uploadSearch: {
          label: 'Upload Search',
          name: 'uploadSearch',
          fileType: 'xlsx',
        },
        mergeResultOnly: {
          label: 'Merge Result Only',
          name: 'mergeResultOnly',
        },
      },
      buyers: {
        buyerType: {
          label: 'Buyer Type',
          name: 'buyerType',
          financial: {
            label: TYPE_FINANCIAL,
            name: 'financial',
          },
          strategic: {
            label: TYPE_STRATEGIC,
            name: 'strategic',
          },
        },
        priority: {
          label: 'Priority',
          name: 'priority',
          valueField: 'id',
          renderField: 'value',
          enableDynamicAdd: true,
        },
        currentStatus: {
          label: 'Current Status',
          name: 'currentStatus',
          minValue: 0,
          maxValue: 10,
          step: 0.01,
        },
        highStatus: {
          label: 'High Status',
          name: 'highStatus',
          minValue: 0,
          maxValue: 10,
          step: 0.01,
        },
        projectActivity: {
          label: 'Project Activity',
          name: 'projectActivity',
          contacted: {
            label: 'Contacted (4.6+ High Status)',
            name: 'contacted',
          },
          currentLead: {
            label: 'Current Leads (5+ Status)',
            name: 'currentLead',
          },
          totalLead: {
            label: 'Total Leads (5+ High Status)',
            name: 'totalLead',
          },
          cc: {
            label: 'CCs (5.5+ High Status)',
            name: 'cc',
          },
          visit: {
            label: 'Visits (6+ High Status)',
            name: 'visit',
          },
          offer: {
            label: 'Offers (8+ High Status)',
            name: 'offer',
          },
        },
        revenue: {
          label: 'Revenue Criteria',
          name: 'revenue',
          minValue: 0,
          maxValue: 500,
          step: 1,
        },
        ebitda: {
          label: 'EBITDA Criteria',
          name: 'ebitda',
          minValue: 0,
          maxValue: 500,
          step: 1,
        },
        ev: {
          label: 'EV Criteria',
          name: 'ev',
          minValue: 0,
          maxValue: 10,
          step: 0.01,
        },
        transactionTypes: {
          label: 'Transaction Types',
          name: 'transactionTypes',
          buyout: {
            label: 'Buyout',
            name: 'buyout',
          },
          recap: {
            label: 'Recap',
            name: 'recap',
          },
          mezz: {
            label: 'Mezz',
            name: 'mezz',
          },
          carveout: {
            label: 'Carve-Out',
            name: 'carveout',
          },
          takeprivate: {
            label: 'Take Private',
            name: 'takeprivate',
          },
          minority: {
            label: 'Minority',
            name: 'minority',
          },
          turnaround: {
            label: 'Turnaround',
            name: 'turnaround',
          },
        },
        geography: {
          label: 'Geographic Criteria',
          name: 'geography',
          description: '',
          valueField: 'id',
          renderField: 'value',
        },
        industry: {
          label: 'Industry Criteria',
          name: 'industry',
          valueField: 'id',
          renderField: 'text',
        },
        harvcoContact: {
          label: 'HarvCo Contact',
          name: 'harvcoContact',
          valueField: 'id',
          renderField: 'text',
        },
        companyType: {
          label: 'Company Type',
          name: 'companyType',
          valueField: 'id',
          renderField: 'value',
        },
        companyState: {
          label: 'State',
          name: 'companyState',
          valueField: 'id',
          renderField: 'value',
        },
        areaCode: {
          label: 'Area Code',
          name: 'areaCode',
          description: "Buyer's own phone number - not its geographic investment criteria",
          valueField: 'id',
          renderField: 'value',
        },
        nextAction: {
          label: 'Next Action',
          name: 'nextAction',
          eventActivity: {
            name: 'nextAction',
            placeholder: 'Event Activity',
            valueField: 'id',
            renderField: 'value',
            keepText: true,
          },
        },
        activity: {
          label: 'Activity',
          name: 'activity',
          eventActivity: {
            name: 'activity',
            placeholder: 'Event Activity',
            valueField: 'id',
            renderField: 'value',
            keepText: true,
          },
          statusChanged: false,
          statusChangedFrom: {
            label: 'From',
            name: 'statusChangedFrom',
            minValue: 0,
            maxValue: 10,
            step: 0.01,
          },
          statusChangedTo: {
            label: 'To',
            name: 'statusChangedTo',
            minValue: 0,
            maxValue: 10,
            step: 0.01,
          },
          activityUsers: {
            label: 'Users',
            name: 'activityUsers',
            placeholder: 'Completed By',
            valueField: 'id',
            renderField: 'text',
          },
          buyerType: {
            label: 'Buyer Type',
            name: 'buyerType',
            options: [
              { name: TYPE_FINANCIAL, value: TYPE_FINANCIAL, attributes: {} },
              { name: TYPE_STRATEGIC, value: TYPE_STRATEGIC, attributes: {} },
            ],
          },
        },
        harvcoTags: {
          label: 'HarvCo Tags',
          name: 'harvcoTags',
          valueField: 'id',
          renderField: 'value',
        },
        country: {
          label: 'Country',
          name: 'country',
          placeholder: 'Country',
          valueField: 'id',
          renderField: 'text',
        },
        continent: {
          label: 'Continent',
          name: 'continent',
          placeholder: 'Continent',
          valueField: 'id',
          renderField: 'name',
        },
        startDate: {
          label: 'Start Date',
          name: 'startDate',
          valueField: 'id',
          renderField: 'value',
        },
        endDate: {
          label: 'End Date',
          name: 'endDate',
          valueField: 'id',
          renderField: 'value',
        },
        keywords: {
          label: 'Keywords',
          name: 'keywords',
          description: 'searches full name, abbreviated name, deal notes, email log',
          valueField: 'id',
          renderField: 'value',
        },
        mergeResultOnly: {
          label: 'Merge Result Only',
          name: 'mergeResultOnly',
        },
      },
      executives: {
        currentStatus: {
          label: 'Current Status',
          name: 'currentStatus',
          minValue: 0,
          maxValue: 10,
          step: 0.01,
        },
        highStatus: {
          label: 'High Status',
          name: 'highStatus',
          minValue: 0,
          maxValue: 10,
          step: 0.01,
        },
        module: {
          label: 'Module',
          name: 'module',
          valueField: 'id',
          renderField: 'text',
        },
        analyst: {
          label: 'Analyst/Associate',
          name: 'analyst',
          valueField: 'id',
          renderField: 'text',
        },
        dealmaker: {
          label: '3rd Dealmaker',
          name: 'dealmaker',
          valueField: 'id',
          renderField: 'text',
        },
        researcher: {
          label: 'Researcher',
          name: 'researcher',
          valueField: 'id',
          renderField: 'text',
        },
        fund: {
          label: 'Fund',
          name: 'fund',
          fund: {
            inputLabel: 'Fund',
            name: 'fund',
            className: 'form-control',
            formGroupClass: 'form-group--inline form-group--input',
            valueField: 'id',
            renderField: 'text',
            keepText: true,
          },
          project: {
            inputLabel: 'Project',
            name: 'project',
            className: 'form-control',
            formGroupClass: 'form-group--inline form-group--input',
            valueField: 'id',
            renderField: 'text',
            keepText: true,
          },
        },
        harvcoTags: {
          label: 'HarvCo Tags',
          name: 'harvcoTags',
          valueField: 'id',
          renderField: 'value',
        },
        industry: {
          label: 'Industry',
          name: 'industry',
          valueField: 'id',
          renderField: 'text',
        },
        nextAction: {
          label: 'Next Action',
          name: 'nextAction',
          eventActivity: {
            name: 'nextAction',
            placeholder: 'Event Activity',
            valueField: 'id',
            renderField: 'value',
            keepText: true,
          },
        },
        activity: {
          label: 'Activity',
          name: 'activity',
          eventActivity: {
            name: 'activity',
            placeholder: 'Event Activity',
            valueField: 'id',
            renderField: 'value',
            keepText: true,
          },
          statusChanged: false,
          statusChangedFrom: {
            label: 'From',
            name: 'statusChangedFrom',
            minValue: 0,
            maxValue: 10,
            step: 0.01,
          },
          statusChangedTo: {
            label: 'To',
            name: 'statusChangedTo',
            minValue: 0,
            maxValue: 10,
            step: 0.01,
          },
          activityUsers: {
            label: 'Users',
            name: 'activityUsers',
            placeholder: 'Completed By',
            valueField: 'id',
            renderField: 'text',
          },
        },
        areaCode: {
          label: 'Area Code',
          name: 'areaCode',
          valueField: 'id',
          renderField: 'value',
        },
        projectType: {
          label: 'Project Type',
          name: 'projectType',
          options: [
            { name: 'Platform', value: 'Platform', attributes: {} },
            { name: 'Add-on', value: 'Add-on', attributes: {} },
          ],
        },
        companyState: {
          label: 'State',
          name: 'companyState',
          valueField: 'id',
          renderField: 'value',
        },
        keywords: {
          label: 'Keywords',
          name: 'keywords',
          description:
            'searches description, research notes, deal notes, email log, products, markets, industries and HarvCo tags simultaneously',
          valueField: 'id',
          renderField: 'value',
        },
        resume: {
          label: 'Resume',
          name: 'resume',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
        mbo: {
          label: 'MBO',
          name: 'mbo',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
        corpBuyer: {
          label: 'Corp. Buyer',
          name: 'corpBuyer',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
        needsFund: {
          label: 'Needs Fund',
          name: 'needsFund',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
        peExperience: {
          label: 'PE Experience',
          name: 'peExperience',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
        events: {
          label: 'Events',
          name: 'events',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
        meet: {
          label: 'Meet',
          name: 'meet',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
        quality: {
          label: 'Quality',
          name: 'quality',
          wcm: {
            label: '5 - WCM',
            name: 'wcm',
          },
          solid: {
            label: '4.5 - Solid Manager',
            name: 'solid',
          },
          good: {
            label: '4 - Good Manager',
            name: 'good',
          },
          goodNotVerified: {
            label: '3.9 - Good Manager (Not Verified)',
            name: 'goodNotVerified',
          },
          average: {
            label: '3 - Average Manager',
            name: 'average',
          },
          weaker: {
            label: '2 - Weaker Manager',
            name: 'weaker',
          },
          poor: {
            label: '1 - Poor Manager/Executive',
            name: 'poor',
          },
          unsure: {
            label: '0 - Quality Unsure',
            name: 'unsure',
          },
        },
        interest: {
          label: 'Interest',
          name: 'interest',
          very: {
            label: '3 - Very Interested',
            name: 'very',
          },
          somewhat: {
            label: '2 - Somewhat Interested',
            name: 'somewhat',
          },
          no: {
            label: '1 - No Interest',
            name: 'no',
          },
          unknown: {
            label: '0 - Interest Unknown',
            name: 'unknown',
          },
        },
        relationship: {
          label: 'Relationship',
          name: 'relationship',
          strong: {
            label: '3 - Strong Relationship',
            name: 'strong',
          },
          semi: {
            label: '2 - Semi-Strong Relationship',
            name: 'semi',
          },
          brief: {
            label: '1 - Brief/Limited Contact',
            name: 'brief',
          },
          no: {
            label: '0 - No Relationship',
            name: 'no',
          },
        },
        doNotMail: {
          label: 'Do Not Mail',
          name: 'doNotMail',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
        employed: {
          label: 'Employed',
          name: 'employed',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
      },
      projects: {
        buyer: {
          label: 'Buyer',
          name: 'buyer',
          valueField: 'id',
          renderField: 'text',
        },
        harvcoContact: {
          label: 'Harvco Lead',
          name: 'harvcoContact',
          valueField: 'id',
          renderField: 'text',
        },
        users: {
          label: 'Users',
          name: 'users',
          valueField: 'id',
          renderField: 'text',
        },
        active: {
          label: 'Active',
          name: 'active',
          options: [
            { name: 'Yes', value: 'Yes', attributes: {} },
            { name: 'No', value: 'No', attributes: {} },
          ],
        },
        category: {
          label: 'Name',
          name: 'category',
          valueField: 'id',
          renderField: 'text',
        },
        industry: {
          label: 'Industry',
          name: 'industry',
          valueField: 'id',
          renderField: 'text',
        },
      },
    },
    contextActionList: {
      targets: [
        {
          name: 'Add Tag to Selected',
          attributes: {},
          key: 'addTagToSelected',
          access: [
            RESEARCH_INTERN,
            JUNIOR_ANALYST,
            PROCESS_MANAGER,
            ANALYST_ASSOCIATE,
            DIRECTOR,
            ADMINISTRATOR,
            FINANCE_ADMIN,
          ],
        },
        {
          name: 'Add Industry Tag to Selected Targets',
          attributes: {},
          key: 'addIndustryTagToSelected',
          access: [
            RESEARCH_INTERN,
            JUNIOR_ANALYST,
            PROCESS_MANAGER,
            ANALYST_ASSOCIATE,
            DIRECTOR,
            ADMINISTRATOR,
            FINANCE_ADMIN,
          ],
        },
        {
          name: 'Assign Trade Show to Selected Targets',
          attributes: {},
          key: 'assignTradeShowToSelected',
          access: [
            RESEARCH_INTERN,
            JUNIOR_ANALYST,
            PROCESS_MANAGER,
            ANALYST_ASSOCIATE,
            DIRECTOR,
            ADMINISTRATOR,
            FINANCE_ADMIN,
          ],
        },
        {
          name: 'Set Next Action for Selected',
          attributes: {},
          key: 'setNextActionForSelected',
          access: [PROCESS_MANAGER, ANALYST_ASSOCIATE, DIRECTOR, ADMINISTRATOR, FINANCE_ADMIN, JUNIOR_ANALYST],
        },
        {
          name: 'Push Next Action Dates for Selected',
          attributes: {},
          key: 'pushNextActionDateForSelected',
          access: [PROCESS_MANAGER, ANALYST_ASSOCIATE, DIRECTOR, ADMINISTRATOR, FINANCE_ADMIN, JUNIOR_ANALYST],
        },
        {
          name: 'Add/Update Buyer for Selected',
          attributes: {},
          key: 'addUpdateBuyerForSelected',
          access: [PROCESS_MANAGER, ANALYST_ASSOCIATE, DIRECTOR, ADMINISTRATOR, FINANCE_ADMIN, JUNIOR_ANALYST],
        },
        {
          name: 'Flip Selected to Different Team',
          attributes: {},
          key: 'flipSelectedToDifferentTeam',
          access: [PROCESS_MANAGER, ANALYST_ASSOCIATE, DIRECTOR, ADMINISTRATOR, FINANCE_ADMIN, JUNIOR_ANALYST],
        },
        {
          name: 'Export Selected',
          attributes: {},
          key: 'exportSelected',
          access: [PROCESS_MANAGER, ANALYST_ASSOCIATE, DIRECTOR, ADMINISTRATOR, FINANCE_ADMIN],
        },
        {
          name: 'Delete Selected',
          attributes: {},
          key: 'deleteSelected',
          access: [ANALYST_ASSOCIATE, DIRECTOR, ADMINISTRATOR, FINANCE_ADMIN],
        },
        {
          name: 'Delete Selected',
          attributes: {},
          key: 'deleteConfirmationSelected',
          access: [RESEARCHER, RESEARCH_INTERN, JUNIOR_ANALYST, PROCESS_MANAGER],
        },
        {
          name: 'Backlog selected',
          attributes: {},
          key: 'backlogSelected',
          access: [PROCESS_MANAGER, ANALYST_ASSOCIATE, DIRECTOR, ADMINISTRATOR, FINANCE_ADMIN, JUNIOR_ANALYST],
        },
        {
          name: 'Run Windward Report',
          attributes: {},
          key: 'runWindwardReport',
          access: [PROCESS_MANAGER, ANALYST_ASSOCIATE, DIRECTOR, ADMINISTRATOR, FINANCE_ADMIN, JUNIOR_ANALYST],
        },
        {
          name: 'Merge Confirm',
          attributes: {},
          key: 'mergeConfirm',
          access: [DIRECTOR, ADMINISTRATOR],
        },
      ],
      buyers: [{ name: 'Export Selected', attributes: {}, key: 'exportSelected' }],
      executives: [
        {
          name: 'Add Tag to Selected',
          attributes: {},
          key: 'addTagToSelected',
        },
        { name: 'Export Selected', attributes: {}, key: 'exportSelected' },
      ],
    },
    accesses: [
      // We use hard enum for browse page tabs access id instead request "GET: {{host}}/api/v1/users/browse_accesses" to simplify.
      { name: 'targets', title: 'Targets', key: 1 },
      { name: 'buyers', title: 'Buyers', key: 2 },
      { name: 'executives', title: 'Execs', key: 3 },
      { name: 'projects', title: 'Projects', key: 4 },
    ],
  },
  project: {
    list: {
      columns: [
        'Curr',
        'High',
        'Target',
        'App List',
        'Pri',
        'Approved',
        'Revenue',
        'Employees',
        'EBITDA',
        'City',
        'State',
        'Web',
        'Harvco Lead',
      ],
      cells: [
        4.7,
        6.0,
        'BlissLights, Inc.',
        '',
        'A',
        '',
        25,
        23,
        '',
        'Escondido',
        'CA',
        'http://www.blisslights.com/',
        'ehartley',
      ],
    },
  },
  tableCells: [
    {
      text: 'Date',
    },
    {
      text: 'Activity',
    },
    {
      text: 'Name',
    },
    {
      text: 'Buyer',
    },
    {
      text: 'Project',
    },
  ],
  tabCells: [
    {
      text: 'Date',
    },
    {
      text: 'Activity',
    },
    {
      text: 'Name',
    },
    {
      text: 'Buyer',
    },
    {
      text: 'Project',
    },
  ],
  actionFilters: {
    types: [
      { name: 'Target', key: 'target' },
      { name: 'Buyer', key: 'buyer' },
      { name: 'Exec', key: 'exec' },
      { name: 'LP', key: 'lp' },
    ],
    mailings: [
      { name: 'PM mailing', key: 'isletter' },
      { name: 'PM emails', key: 'isemail' },
      { name: 'All others', key: 'isnonpm' },
    ],
    activities: [
      { name: 'M1', key: 'm1' },
      { name: 'M2', key: 'm2' },
      { name: 'CM2', key: 'cm2' },
      { name: 'eM1', key: 'em1' },
      { name: 'eM2', key: 'em2' },
      { name: 'eCM2', key: 'ecm2' },
      { name: 'C_', key: 'cx' },
      { name: 'E_', key: 'ex' },
      { name: 'F_', key: 'fx' },
      { name: 'Call', key: 'call' },
      { name: 'Email', key: 'email' },
      { name: 'Fax', key: 'fax' },
      { name: 'CR', key: 'cr' },
      { name: 'KIT', key: 'kit' },
      { name: '(Custom)', key: 'custom' },
      { name: 'All others', key: 'other' },
    ],
  },
  actionList: [
    { name: 'Add Tag to Selected', attributes: {}, key: 'addTagToSelected' },
    {
      name: 'Set Next Action for Selected',
      attributes: {},
      key: 'setNextActionForSelected',
    },
    {
      name: 'Push Next Action Dates for Selected',
      attributes: {},
      key: 'pushNextActionDateForSelected',
    },
    {
      name: 'Add/Update Buyer for Selected',
      attributes: {},
      key: 'addUpdateBuyerForSelected',
    },
    {
      name: 'Flip Selected to Different Team',
      attributes: {},
      key: 'flipSelectedToDifferentTeam',
    },
    { name: 'Export Selected', attributes: {}, key: 'exportSelected' },
    { name: 'Delete Selected', attributes: {}, key: 'deleteSelected' },
    { name: 'Delete Selected', attributes: {}, key: 'deleteConfirmationSelected' },
    {
      name: 'Add Industry Tag to Selected Targets',
      attributes: {},
      key: 'addIndustryTagToSelected',
    },
    { name: 'Backlog selected', attributes: {}, key: 'backlogSelected' },
    { name: 'Run Windward Report', attributes: {}, key: 'runWindwardReport' },
    {
      name: 'Assign Trade Show to Selected Targets',
      attributes: {},
      key: 'assignTradeShowToSelected',
    },
    {
      name: 'Confirm merge',
      attributes: {},
      key: 'mergeConfirm',
    },
  ],
  company: {
    states: [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'DC',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VI',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
      'AB',
      'BC',
      'MB',
      'NB',
      'NL',
      'NS',
      'NT',
      'NU',
      'ON',
      'PE',
      'QC',
      'SK',
      'YT',
    ],
    entityTypes: [
      '',
      'Private',
      'DivPri',
      'LBO',
      'DivLBO',
      'Public',
      'DivPub',
      'VC',
      'DivVC',
      'ESOP',
      'NFP',
      'DivNFP',
      'Gov',
      'JV',
      'ForPri',
      'ForPub',
      'DivFor',
    ],
    parentCompanies: [
      '',
      'Private',
      'DivPri',
      'LBO',
      'DivLBO',
      'Public',
      'DivPub',
      'VC',
      'DivVC',
      'ESOP',
      'NFP',
      'DivNFP',
      'Gov',
      'JV',
      'ForPri',
      'ForPub',
      'DivFor',
    ],
    executive: {
      table: {},
    },
  },
  mailingHistory: {
    table: {
      columnDefs: [
        {
          headerName: 'Date',
          field: 'date',
          width: 100,
          suppressSizeToFit: true,
        },
        { headerName: '', field: 'icon', width: 40, suppressSizeToFit: true },
        {
          headerName: 'Activity',
          field: 'activity',
          width: 70,
          suppressSizeToFit: true,
        },
        { headerName: 'Module', field: 'recordOwnerUserName', width: 100 },
        { headerName: 'A/A', field: 'recordSubOwnerUserName', width: 100 },
        { headerName: 'Buyer (Type)', field: 'buyerLegalName', width: 100 },
        { headerName: 'Project', field: 'projectCategory', width: 100 },
        { headerName: '#', field: 'targetCount', width: 100 },
      ],
    },
  },
  mailingCreate: {
    table: {
      columnDefs: [
        {
          headerName: '',
          field: 'selected',
          width: 20,
          suppressSizeToFit: true,
        },
        { headerName: 'Company Name', field: 'targetLegalName', width: 200 },
        { headerName: 'Web', field: 'targetWeb', width: 200 },
        {
          headerName: 'Next Action',
          field: 'naActivity',
          width: 100,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Next Action Date',
          field: 'naDate',
          width: 200,
          suppressSizeToFit: true,
        },
        { headerName: 'Email(Verified)', field: 'emailVerified', width: 50 },
        { headerName: 'Logos', field: 'logos', width: 40 },
      ],
    },
  },
  mailingInfo: {
    table: {
      columnDefs: [
        {
          headerName: 'NA',
          field: 'naActivity',
          width: 40,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Date',
          field: 'naDate',
          width: 70,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Company Name',
          field: 'targetLegalName',
          width: 120,
          editable: true,
          updateType: 'company',
          updateField: 'legal_name',
          suppressSizeToFit: true,
          cellStyle: { 'white-space': 'normal' },
        },
        {
          headerName: 'Abbrev. Name',
          field: 'targetAbbrName',
          width: 120,
          editable: true,
          updateType: 'company',
          updateField: 'abbr_name',
          suppressSizeToFit: true,
          cellStyle: { 'white-space': 'normal' },
        },
        {
          headerName: 'Rev.',
          field: 'targetRevenue',
          width: 40,
          suppressSizeToFit: true,
          editable: true,
          updateType: 'company',
          updateField: 'revenue',
        },
        {
          headerName: 'Description',
          field: 'targetDescription',
          width: 280,
          editable: true,
          updateType: 'company',
          updateField: 'description',
          suppressSizeToFit: true,
          cellStyle: { 'white-space': 'normal' },
        },
        {
          headerName: 'Address',
          field: 'contactAddress',
          width: 180,
          editable: true,
          updateType: 'company',
          updateField: 'address',
          suppressSizeToFit: true,
          cellStyle: { 'white-space': 'normal' },
        },
        {
          headerName: 'City',
          field: 'contactCity',
          width: 100,
          suppressSizeToFit: true,
          editable: true,
          updateType: 'company',
          updateField: 'city',
        },
        {
          headerName: 'State',
          field: 'contactState',
          width: 45,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Zip',
          field: 'contactZip',
          width: 50,
          suppressSizeToFit: true,
          editable: true,
          updateType: 'company',
          updateField: 'zip',
        },
        {
          headerName: 'Country',
          field: 'contactCountry',
          width: 60,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Pref.',
          field: 'contactPrefix',
          width: 40,
          suppressSizeToFit: true,
          editable: true,
          updateType: 'people',
          updateField: 'prefix',
        },
        {
          headerName: 'Contact First Name',
          field: 'contactFirstName',
          width: 125,
          suppressSizeToFit: true,
          editable: true,
          updateType: 'people',
          updateField: 'first_name',
        },
        {
          headerName: 'Contact Last Name',
          field: 'contactLastName',
          width: 125,
          suppressSizeToFit: true,
          editable: true,
          updateType: 'people',
          updateField: 'last_name',
        },
        {
          headerName: 'Sfx.',
          field: 'contactSuffix',
          width: 40,
          suppressSizeToFit: true,
          editable: true,
          updateType: 'people',
          updateField: 'suffix',
        },
        {
          headerName: 'Contact Title',
          field: 'contactTitle',
          width: 200,
          editable: true,
          updateType: 'contactTitle',
          updateField: 'title',
        },
        {
          headerName: 'Email',
          field: 'contactEmail',
          width: 200,
          editable: true,
          updateType: 'email',
          updateField: 'email',
        },
        {
          headerName: 'Web',
          field: 'targetWeb',
          width: 200,
          editable: true,
          updateType: 'company',
          updateField: 'web',
        },
      ],
    },
    values: {
      footerDropDown: [
        {
          name: 'Project Template',
          onClickField: 'onProjectTemplate',
          attributes: { 'aria-hidden': 'true' },
        },
        {
          name: 'Mailing Labels',
          onClickField: 'onMailingLabelsMerge',
          attributes: { 'aria-hidden': 'true' },
        },
        {
          name: 'Download Merge Source',
          onClickField: 'onDownloadMergeSource',
          attributes: { 'aria-hidden': 'true' },
        },
        {
          name: 'Quick Merge Source',
          onClickField: 'onQuickMergeSource',
          attributes: { 'aria-hidden': 'true' },
        },
        {
          name: 'Download Logos',
          onClickField: 'onDownloadLogos',
          attributes: { 'aria-hidden': 'true' },
        },
        {
          name: 'Address Labels',
          onClickField: 'onAddressLabels',
          attributes: {
            'aria-hidden': 'true',
            className: 'disabled-link',
            disabled: true,
          },
        },
      ],
    },
  },
};

// Variables
config.API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

config.main.offersAndBetterReport = `${process.env.REACT_APP_API_BASE_URL}${config.main.offersAndBetterReport}`;
config.main.retainerReport = `${process.env.REACT_APP_API_BASE_URL}${config.main.retainerReport}`;
config.main.leadsAndBetterReport = `${process.env.REACT_APP_API_BASE_URL}${config.main.leadsAndBetterReport}`;
config.main.CCVisitReport = `${process.env.REACT_APP_API_BASE_URL}${config.main.CCVisitReport}`;
config.main.OutreachReport = `${process.env.REACT_APP_API_BASE_URL}${config.main.OutreachReport}`;
config.main.CeoOutreachReport = `${process.env.REACT_APP_API_BASE_URL}${config.main.CeoOutreachReport}`;
config.main.activityReport = `${process.env.REACT_APP_API_BASE_URL}${config.main.activityReport}`;
config.main.conflictReport = `${process.env.REACT_APP_API_BASE_URL}${config.main.conflictReport}`;
config.values.contact.vCardUrl = `${process.env.REACT_APP_API_BASE_URL}${config.values.contact.vCardUrl}`;

config.constants = fromJS(constants);
config.main = fromJS(config.main);
config.tables = fromJS(config.tables);
config.values = fromJS(config.values);
config.search = fromJS(config.search);
config.tabs = fromJS(config.tabs);
config.tableCells = fromJS(config.tableCells);
config.tabCells = fromJS(config.tabCells);
config.actionFilters = fromJS(config.actionFilters);
config.actionList = fromJS(config.actionList);
config.browse = fromJS(config.browse);
config.mailingHistory = fromJS(config.mailingHistory);
config.mailingCreate = fromJS(config.mailingCreate);
config.mailingInfo = fromJS(config.mailingInfo);
config.permisionError = List(["You don't have enough privilege to access this page"]);
config.noteBoxSize = Map({
  min: 200,
  default: 350,
});
config.eventPopupSize = {
  width: 670,
  height: 600,
  minConstraints: [500, 600],
};
config.createEmailTemplatePopupSize = {
  width: 700,
  height: 300,
  minConstraints: [500, 300],
};
// Request throttling
config.REQUEST_THROTTLING = 50;
config.NEXT_ACTION_REQUEST_THROTTLING = 1000;
config.NUMBER_OF_MIN_CHARACTER = 2;

config.VIEW_MODE = 'view';
config.EDIT_MODE = 'edit';
config.SUGGEST_MODE = 'suggest';
config.LOADING_MODE = 'loading';

// Variables
config.API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

// TODO: use procese.env
config.GOOGLE_API_KEY = 'AIzaSyC2fgI8v3-pjcX-Mz-pNw-SjuhA-D1Cjq8';

// User roles
config.RESEARCHER = RESEARCHER;
config.RESEARCH_INTERN = RESEARCH_INTERN;
config.JUNIOR_ANALYST = JUNIOR_ANALYST;
config.PROCESS_MANAGER = PROCESS_MANAGER;
config.ANALYST_ASSOCIATE = ANALYST_ASSOCIATE;
config.DIRECTOR = DIRECTOR;
config.ADMINISTRATOR = ADMINISTRATOR;
config.FINANCE_ADMIN = FINANCE_ADMIN;

config.phoneValidation = {
  title: 'Phone',
  description:
    'should be empty or valid format (111-111-1111 x[extension] or +[numbers, spaces and dashes] x[extension])',
  anyOf: [
    { type: 'null' },
    { type: 'string', pattern: /^$/ },
    { type: 'string', pattern: /^\d{3}-\d{3}-\d{4}(\s+(x|X) {1}\d+)?$/ },
    { type: 'string', pattern: /^\+[\d\s-]+(\s+(x|X) {1}\d+)?$/ },
  ],
};

config.contactPhoneValidation = {
  title: 'Phone',
  description: 'should be empty or should be a valid format (111-111-1111) or +[numbers, spaces and dashes]',
  anyOf: [
    { type: 'string', pattern: /^$/ },
    { type: 'string', pattern: /^\d{10}?$/ },
    { type: 'string', pattern: /^1\d{10}?$/ },
  ],
};

config.BACKLOGGED_EVENTS = [
  'M1',
  'M2',
  'M3',
  'M4',
  'M5',
  'M6',
  'M7',
  'M8',
  'M9',
  'M1b',
  'M2b',
  'M3b',
  'M4b',
  'M5b',
  'M6b',
  'M7b',
  'M8b',
  'M9b',
  'M1c',
  'M2c',
  'M3c',
  'M4c',
  'M5c',
  'M6c',
  'M7c',
  'M8c',
  'M9c',
  'M1d',
  'M2d',
  'M3d',
  'M4d',
  'M5d',
  'M6d',
  'M7d',
  'M8d',
  'M9d',
  'E1',
  'E2',
  'E3',
  'E4',
  'E5',
  'E6',
  'E7',
  'E8',
  'E9',
  'E1b',
  'E2b',
  'E3b',
  'E4b',
  'E5b',
  'E6b',
  'E7b',
  'E8b',
  'E9b',
  'E1c',
  'E2c',
  'E3c',
  'E4c',
  'E5c',
  'E6c',
  'E7c',
  'E8c',
  'E9c',
  'E1d',
  'E2d',
  'E3d',
  'E4d',
  'E5d',
  'E6d',
  'E7d',
  'E8d',
  'E9d',
  'eM1',
  'eM2',
  'eM3',
  'eM4',
  'eM5',
  'eM6',
  'eM7',
  'eM8',
  'eM9',
  'eM1b',
  'eM2b',
  'eM3b',
  'eM4b',
  'eM5b',
  'eM6b',
  'eM7b',
  'eM8b',
  'eM9b',
  'eM1c',
  'eM2c',
  'eM3c',
  'eM4c',
  'eM5c',
  'eM6c',
  'eM7c',
  'eM8c',
  'eM9c',
  'eM1d',
  'eM2d',
  'eM3d',
  'eM4d',
  'eM5d',
  'eM6d',
  'eM7d',
  'eM8d',
  'eM9d',
  'eCM1',
  'eCM2',
  'eCM3',
  'eCM4',
  'eCM5',
  'eCM6',
  'eCM7',
  'eCM8',
  'eCM9',
  'eCM1b',
  'eCM2b',
  'eCM3b',
  'eCM4b',
  'eCM5b',
  'eCM6b',
  'eCM7b',
  'eCM8b',
  'eCM9b',
  'eCM1c',
  'eCM2c',
  'eCM3c',
  'eCM4c',
  'eCM5c',
  'eCM6c',
  'eCM7c',
  'eCM8c',
  'eCM9c',
  'eCM1d',
  'eCM2d',
  'eCM3d',
  'eCM4d',
  'eCM5d',
  'eCM6d',
  'eCM7d',
  'eCM8d',
  'eCM9d',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F1b',
  'F2b',
  'F3b',
  'F4b',
  'F5b',
  'F6b',
  'F7b',
  'F8b',
  'F9b',
  'F1c',
  'F2c',
  'F3c',
  'F4c',
  'F5c',
  'F6c',
  'F7c',
  'F8c',
  'F9c',
  'F1d',
  'F2d',
  'F3d',
  'F4d',
  'F5d',
  'F6d',
  'F7d',
  'F8d',
  'F9d',
  'CM1',
  'CM2',
  'CM3',
  'CM4',
  'CM5',
  'CM6',
  'CM7',
  'CM8',
  'CM9',
  'CM1b',
  'CM2b',
  'CM3b',
  'CM4b',
  'CM5b',
  'CM6b',
  'CM7b',
  'CM8b',
  'CM9b',
  'CM1c',
  'CM2c',
  'CM3c',
  'CM4c',
  'CM5c',
  'CM6c',
  'CM7c',
  'CM8c',
  'CM9c',
  'CM1d',
  'CM2d',
  'CM3d',
  'CM4d',
  'CM5d',
  'CM6d',
  'CM7d',
  'CM8d',
  'CM9d',
];

config.HAS_PARENT_COMPANY = ['DivPri', 'DivLBO', 'DivPub', 'DivVC', 'DivNFP', 'JV', 'DivFor'];

export default config;
